import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, MenuItem, Typography} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {isValid} from 'date-fns';

import {useFormikContext, Field} from 'formik';

import {useTranslation} from 'react-i18next';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {CountrySelect} from 'components/form/countrySelect';
import {OccupationSelect} from 'components/form/occupationSelect';

import {removeSpecialChars, calcAge} from '../../../home/utils';

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from './styles';

const PersonalInfoForm = props => {
  const {expanded, handleChangePanel, occupations, optionalFields} = props;

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormikContext();

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const birthdayValue = values.birthday ? new Date(values.birthday) : null;

  return (
    <ExpansionPanel
      expanded={true || expanded}
      onChange={(event, isExpanded) => {
        handleChangePanel(event, isExpanded);
      }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div>
          <Typography className={classes.heading}>
            {t('form.personalInformation')}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Field name="email">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.email || ''}
                  error={!!errors.email && !!touched.email}
                  helperText={touched.email && errors.email ? errors.email : ''}
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="email"
                  required
                  name={field.name}
                  id="email"
                  label={t('form.email')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Field name="firstName">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.firstName || ''}
                  error={!!errors.firstName && !!touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ''
                  }
                  onChange={e => {
                    setFieldValue(
                      'firstName',
                      String(e.target.value).toUpperCase(),
                    );
                  }}
                  type="text"
                  required
                  id="firstName"
                  name={field.name}
                  label={t('form.firstName')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Field name="lastName">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.lastName || ''}
                  error={!!errors.lastName && !!touched.lastName}
                  helperText={
                    errors.lastName && touched.lastName ? errors.lastName : ''
                  }
                  onChange={e => {
                    setFieldValue(
                      'lastName',
                      String(e.target.value).toUpperCase(),
                    );
                  }}
                  type="text"
                  required
                  id="lastName"
                  name={field.name}
                  label={t('form.lastName')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="birthday">
              {({field}) => (
                <KeyboardDatePicker
                  clearable={false}
                  size="small"
                  autoOk
                  animateYearScrolling
                  required={!optionalFields.includes('birthday')}
                  id="birthday"
                  name={field.name}
                  fullWidth
                  inputVariant="outlined"
                  label={t('form.birthday')}
                  format="dd/MM/yyyy"
                  value={isValid(birthdayValue) ? birthdayValue : null}
                  inputValue={values.birthday}
                  error={!!errors.birthday && !!touched.birthday}
                  helperText={
                    errors.birthday && touched.birthday ? errors.birthday : ''
                  }
                  disableFuture
                  InputAdornmentProps={{position: 'end'}}
                  onChange={(date, value) => {
                    setValues(prevValue => ({
                      ...prevValue,
                      birthday: value,
                      age: calcAge(value),
                    }));
                  }}
                  onError={() => {}}
                  autoComplete="off"
                  variant="dialog"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="genre">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.genre || ''}
                  error={!!errors.genre && !!touched.genre}
                  helperText={errors.genre && touched.genre ? errors.genre : ''}
                  onChange={e => {
                    handleChange(e);
                  }}
                  select
                  required={!optionalFields.includes('genre')}
                  id="genre"
                  name={field.name}
                  label={t('form.genre')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}>
                  <MenuItem key="M" value="M">
                    {t('genres.male')}
                  </MenuItem>
                  <MenuItem key="F" value="F">
                    {t('genres.female')}
                  </MenuItem>
                  <MenuItem key="N" value="N">
                    {t('genres.uninformed')}
                  </MenuItem>
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="nationality">
              {({field}) => (
                <CountrySelect
                  id="nationality"
                  name={field.name}
                  countryField="nationality"
                  countryCodeField="nationalityCode"
                  onChange={({country, countryCode}) => {
                    setValues(prevValue => ({
                      ...prevValue,
                      nationality: country,
                      nationalityCode: countryCode,
                    }));
                  }}
                  required={!optionalFields.includes('nationalityCode')}
                  placeholder={t('form.nationality')}
                  disabled={false}
                  isBrazil={i18n.language === 'pt'}
                  isNationality={false}
                  showErrorWithoutTouched={false}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="maritalStatus">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.maritalStatus || ''}
                  error={!!errors.maritalStatus && !!touched.maritalStatus}
                  helperText={
                    errors.maritalStatus && touched.maritalStatus
                      ? errors.maritalStatus
                      : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  select
                  required={!optionalFields.includes('maritalStatus')}
                  id="maritalStatus"
                  name={field.name}
                  label={t('form.maritalStatus')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}>
                  <MenuItem key="S" value="S">
                    {t('maritalStatus.single')}
                  </MenuItem>
                  <MenuItem key="C" value="C">
                    {t('maritalStatus.married')}
                  </MenuItem>
                  <MenuItem key="V" value="V">
                    {t('maritalStatus.widower')}
                  </MenuItem>
                  <MenuItem key="D" value="D">
                    {t('maritalStatus.separated')}
                  </MenuItem>
                  <MenuItem key="I" value="I">
                    {t('maritalStatus.divorced')}
                  </MenuItem>
                  <MenuItem key="J" value="J">
                    {t('maritalStatus.legallySeparated')}
                  </MenuItem>
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="documentType">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.documentType || ''}
                  error={!!errors.documentType && !!touched.documentType}
                  helperText={
                    errors.documentType && touched.documentType
                      ? errors.documentType
                      : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  select
                  required={values.age >= 18}
                  id="documentType"
                  name={field.name}
                  label={t('form.documentType')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}>
                  <MenuItem key="CPF" value="CPF">
                    {t('documentTypes.cpf')}
                  </MenuItem>
                  <MenuItem key="RG" value="RG">
                    {t('documentTypes.rg')}
                  </MenuItem>
                  <MenuItem key="CN" value="CN">
                    {t('documentTypes.cn')}
                  </MenuItem>
                  <MenuItem key="PASSAPORTE" value="PASSAPORTE">
                    {t('documentTypes.passport')}
                  </MenuItem>
                  <MenuItem key="CIE" value="CIE">
                    {t('documentTypes.cie')}
                  </MenuItem>
                  <MenuItem key="CI" value="CI">
                    {t('documentTypes.ci')}
                  </MenuItem>
                  <MenuItem key="DNI" value="DNI">
                    {t('documentTypes.dni')}
                  </MenuItem>
                  <MenuItem key="RUC" value="RUC">
                    {t('documentTypes.ruc')}
                  </MenuItem>
                  <MenuItem key="RFC" value="RFC">
                    {t('documentTypes.rfc')}
                  </MenuItem>
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="document">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.document || ''}
                  error={!!errors.document && !!touched.document}
                  helperText={
                    errors.document && touched.document ? errors.document : ''
                  }
                  onChange={e => {
                    setFieldValue(
                      'document',
                      removeSpecialChars(e.target.value),
                    );
                  }}
                  type="text"
                  required={values.age >= 18}
                  id="document"
                  name={field.name}
                  label={t('form.document')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="occupation">
              {({field}) => (
                <OccupationSelect
                  id="occupation"
                  name={field.name}
                  labelField="occupation"
                  codeField="occupationCode"
                  onChange={({label, value}) => {
                    setValues(prevValue => ({
                      ...prevValue,
                      occupation: label,
                      occupationCode: value,
                    }));
                  }}
                  placeholder={t('form.occupation')}
                  disabled={false}
                  options={occupations}
                  required={!optionalFields.includes('occupationCode')}
                  showErrorWithoutTouched={false}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

PersonalInfoForm.defaultProps = {
  optionalFields: [],
};

PersonalInfoForm.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleChangePanel: PropTypes.func.isRequired,
  occupations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  optionalFields: PropTypes.arrayOf(PropTypes.string),
};

export default PersonalInfoForm;
