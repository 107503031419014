import React from 'react';

export const TermsPT = () => (
  <>
    <p>
      <small>Em vigor a partir de 12 de março de 2022.</small>
    </p>
    <p>
      Este documento contém informações sobre os serviços da Desbravador
      Check-in prestados para você.
    </p>
    <p>
      Por isso, é importante que leia atentamente o conteúdo deste documento.
    </p>
    <p>
      Após a leitura deste documento, a concordância com Termos e Condições de
      Uso sinaliza a sua ciência e seu parecer favorável em relação a todo o
      conteúdo descrito neste documento.
    </p>

    <h3>1. Termos chave</h3>
    <p>
      No decorrer deste documento, alguns termos se repetirão, esses possuirão
      os seguintes significados:
    </p>
    <p>
      <b>Hóspede</b> - indivíduo que se acomoda por tempo provisório em casa
      alheia, hotel, albergue etc.
    </p>
    <p>
      <b>Meio de Hospedagem</b> - edificação que exerce o comércio da recepção e
      de alojamento de turistas e visitantes em geral.
    </p>
    <p>
      <b>Desbravador</b> - empresa especializada no fornecimento de produtos e
      serviços em tecnologia.
    </p>
    <p>
      <b>Check-in</b> - termo em inglês que corresponde ao Registro de entrada
      do hóspede.
    </p>
    <p>
      <b>On-line</b> - termo em inglês que é usado para se referir a alguém que
      esteja conectado à internet.
    </p>

    <h3>2. Acordo</h3>
    <p>
      Ao usar nossos serviços e/ou acessar qualquer conteúdo ou material que
      seja disponibilizado pela Desbravador Check-in, você celebrará um contrato
      vinculativo com a nossa plataforma, ou seja, você estará assumindo um
      compromisso que obriga tanto a Desbravador quanto você a cumprir certas
      obrigações.
    </p>
    <p>
      Seu acordo conosco inclui a aceitação destes Termos de Uso. Por meio
      destes, você confirma que leu e compreendeu este documento, que o aceita e
      concorda em cumpri-lo.
    </p>
    <p>
      Para usar a Desbravador Check-in você precisa ter: (a) um documento de
      identificação pessoal válido, (b) um e-mail válido (opcional), e (c) ter
      18 anos ou mais e/ou ser legalmente responsável por si próprio. Ser maior
      de 18 anos ou ser responsável por si mesmo é necessário pois é necessário
      que a lei diga que você tem poder e responsabilidade para aderir este
      contrato.
    </p>
    <p>
      Ao concordar com os termos aqui descritos, você também assume que todas as
      informações de cadastro que enviar ao Meio de Hospedagem, através da
      plataforma Desbravador Check-in, são verdadeiras, precisas e completas, se
      comprometendo a manter suas informações sempre atualizadas e corretas.
    </p>
    <p>
      Ainda, você está ciente que todas as suas informações e dados pessoais são
      fornecidos por você como titular dos dados e não por qualquer outra pessoa
      ou instituição. Está ciente também de todo o conteúdo destes termos e de
      como você autoriza os Meios de Hospedagem a utilizarem dos seus dados
      pessoais.
    </p>
    <p>
      A Desbravador Check-in procura sempre prestar um serviço de excelência,
      por isso é transparente quanto ao uso dos dados pessoais daqueles que
      contratam seus serviços e produtos.
    </p>
    <p>
      Se você não concordar (ou não puder cumprir) com este documento, então,
      não poderá usar a Desbravador Check-in.
    </p>

    <h3>3 - O que é e como funciona?</h3>
    <p>
      A Desbravador Check-in é uma ferramenta gratuita que permite que você
      realize o seu check-in de forma online em diversos Meios de Hospedagem no
      mundo tudo. A Desbravador Check-in é a alternativa simples, prática e
      gratuita que permite que antecipe seu check-in no hotel, tornando sua
      entrada e estadia mais rápida e ainda mais agradável.
    </p>

    <p>
      <b>
        A partir do momento em que você concluir uma reserva em um
        estabelecimento que use a Desbravador Check-in, você receberá um link do
        meio de hospedagem escolhido (seja hotel, pousada ou outros) para que
        possa fazer o seu check-in de forma online.
      </b>
    </p>

    <p>
      Para fazer o check-in online é necessário aceitar nossos Termos de Uso e
      digitar o seu CPF ou Passaporte. Após, preencha todos os dados pessoais e
      as informações sobre a viagem que serão solicitadas, conforme determina o
      art. 3, da Portaria 177/20111, que estabelece o Sistema Nacional de
      Registro de Hóspedes - SNRHos, regulamenta a adoção da Ficha Nacional de
      Registro de Hóspedes - FNRH e do Boletim de Ocupação Hoteleira - BOH e dá
      outras providências e, por último, clique no botão “Enviar” para fazer o
      envio da sua ficha de registro para o Meio de Hospedagem em que foi
      realizada a reserva.
    </p>

    <p>
      O Meio de Hospedagem selecionado receberá, de forma online, em formato de
      uma Ficha de Registro de Hóspedes, as suas informações cadastradas por
      meio do link. Após a realização do seu check-in através da Desbravador
      Check-in, é necessário apenas ir à recepção do Meio de Hospedagem onde irá
      se hospedar, se apresentar e retirar a chave da sua acomodação.
    </p>

    <h3>3.1 - Aviso legal e disposições gerais</h3>
    <p>
      A Desbravador Check-in é uma ferramenta tecnológica facilitadora entre o
      Meio de Hospedagem e o Hóspede, não possuindo qualquer vínculo ou
      responsabilidade nessa relação comercial. Por não possuir nenhum vínculo,
      não pode ser responsabilizada por qualquer dano ou prejuízo advindo da
      relação entre o Meio de Hospedagem e o Hóspede, independente do que vier a
      causar tal dano.
    </p>
    <p>
      <b>
        Você fica ciente que, no momento da efetivação da reserva, está sujeito
        às políticas estabelecidas pelo Meio de Hospedagem.
      </b>
    </p>
    <p>
      Assim, ao fazer uma reserva em uma acomodação você aceita e concorda com
      as condições de cancelamento e de não comparecimento (‘no show’), bem como
      concorda com quaisquer outros termos e condições estipulados pelos Meio de
      Hospedagem que possam ser aplicados a sua reserva ou a sua hospedagem,
      incluindo serviços prestados e/ou produtos oferecidos.
    </p>
    <p>
      <b>
        A Desbravador Check-in não disponibiliza a opção de cancelamento de
        reserva ou de reversão do check-in realizado.
      </b>
    </p>
    <p>
      Caso você queira rever, ajustar ou cancelar sua reserva, ou caso você
      deseje efetuar pagamento, prorrogar sua reserva, acrescentar hóspedes na
      mesma acomodação, mudar de quarto ou tiver interesse em algum serviço
      extra, deverá realizar esses pedidos diretamente ao Meio de Hospedagem.
    </p>
    <p>
      Se você tiver que chegar mais tarde ou atrasado no dia do check-in ou se
      apenas for chegar no dia seguinte, você precisa comunicar (de forma
      imediata e a tempo) essa situação ao Meio de Hospedagem. Tal comunicação é
      necessária para que eles tenham uma ideia de quando você vai chegar,
      evitar o cancelamento do seu quarto ou a cobrança da taxa de não
      comparecimento. Importante esclarecer que a Desbravador Check-in não
      possui responsabilidade pelas consequências (cobranças de taxas,
      cancelamento e outros) referentes a sua chegada atrasada ou não
      comparecimento no Meio de Hospedagem.
    </p>
    <p>
      Em hipótese alguma a Desbravador Check-in, incluindo seus diretores,
      funcionários, representantes, subsidiários, empresas afiliadas,
      distribuidores, parceiros, licenciados, agentes ou outras pessoas
      envolvidas na criação, patrocínio, promoção ou criação da Desbravador
      Check-in e do seu conteúdo, poderá ser responsabilizado por:
    </p>
    <ul>
      <li>
        qualquer dano ou perda punível, especial, indireta ou consequente, dano
        moral, qualquer perda de produção, lucro, rendimentos, contrato, perda
        ou dano de influência ou reputação, perda do direito à indenização;
      </li>
      <li>
        qualquer erro com relação às informações do Meio de Hospedagem como
        aparece em nosso site;
      </li>
      <li>
        serviços prestados ou produtos oferecidos pelo Meio de Hospedagem ou
        outros parceiros de negócios;
      </li>
      <li>
        quaisquer danos (diretos, indiretos, consequentes ou puníveis), perdas
        ou custos suportados, custos processuais, incorridos ou pagos por você,
        conforme, resultante de ou em relação com o uso, impossibilidade de uso
        ou falha do nosso site, ou;
      </li>
      <li>
        qualquer prejuízo (pessoal), morte, danos na propriedade ou outros
        (diretos, indiretos, especiais, consequentes ou puníveis) danos, perdas
        ou custos suportados, custos processuais, incorridos ou pagos por você,
        seja devido a atos (legais), erros, infrações, negligência, fraudes de
        documentos e/ou falsidade ideológica, tentativa de golpe, conduta
        imprópria intencional, omissões, não cumprimento, informações falsas,
        delito ou responsabilidade extracontratual objetiva (totalmente ou
        parcialmente) ao Meio de Hospedagem ou qualquer de seus parceiros de
        negócios (incluindo seus funcionários, diretores administrativos,
        agentes, representantes ou empresas afiliadas), de quem produtos ou
        serviços estejam (direta ou indiretamente) disponíveis, sejam oferecidos
        ou promovidos pelo próprio estabelecimento, incluindo qualquer
        cancelamento (parcial), overbookings (sobrelotações), greve, força
        maior, caso fortuito ou qualquer outro evento que fuja do nosso
        controle, independentemente da teoria legal, sem relação com o fato de a
        Desbravador Check-in ter advertido sobre a possibilidade desses danos, e
        mesmo se uma solução falhar em sua finalidade essencial.
      </li>
    </ul>

    <h3>4. Direitos que você concede à Desbravador Check-in</h3>
    <p>
      Os dados pessoais fornecidos durante a realização check-in online são
      usados pela Desbravador para:
    </p>
    <ul>
      <li>Detectar e prevenir fraudes.</li>
      <li>Desenvolver melhorias no site</li>
      <li>
        Efetuar o envio da sua FNRH (Ficha de Hóspede) para o Meio de Hospedagem
        selecionado, como parte de seu processo de check-in, de acordo com as
        normas e procedimentos aplicáveis, caso o Meio de Hospedagem seja
        sediado no Brasil;
      </li>
      <li>Permitir a comunicação direta com os Meios de Hospedagem;</li>
      <li>
        Compartilhar os dados para a gestão de reservas pelo Meio de Hospedagem
        por meio de PMS (software);
      </li>
      <li>
        Vincular ao seu registro de check-in, em nosso sistema, os dados de
        check-in dos seus acompanhantes, quando eles vierem a fazer o registro
        da FNRH pela Desbravador Check-in, caso o hotel seja sediado no Brasil e
        faça o envio da ficha;
      </li>
      <li>
        Armazenar novos dados e informações pessoais para complementar o seu
        cadastro, com o intuito de permitir que o Meio de Hospedagem compreenda
        melhor as suas preferências e hábitos de consumo e de vida,
        oportunizando que o Meio de Hospedagem proporcione uma melhor
        experiência durante sua estadia;
      </li>
      <li>
        Compartilhar as suas informações constantes de seu cadastro com o Meio
        de Hospedagem onde irá se hospedar, como parte do procedimento de
        check-in, sendo esse o principal objetivo da Desbravador Check-in. Este
        procedimento tem respaldo na Lei Geral de Proteção de Dados (LGPD)
        observados, principalmente, nos artigos 6º e 7º.
      </li>
      <li>
        É de sua ciência que, a partir do momento em que o Meio de Hospedagem
        tem acesso aos seus dados pessoais, caberá única e exclusivamente a ele
        o sigilo e segurança dos seus dados pessoais.
      </li>
    </ul>

    <h3>5. Copyrights</h3>
    <p>
      O serviço da Desbravador Check-In é disponibilizado apenas para uso
      pessoal, sendo proibido, por qualquer motivo:
    </p>
    <ul>
      <li>
        Cópia, redistribuição, transferência, venda ou disponibilização para o
        público de qualquer parte do sistema Desbravador Check-in e seu
        conteúdo, ou fazer, de outra forma, qualquer uso que não seja o que está
        expressamente permitido neste acordo ou a lei aplicável, ou que
        infrinja, de outra forma, os direitos de propriedade intelectual (como
        direitos autorais);
      </li>
      <li>
        Executar engenharia reversa, descompilar, desmontar, modificar ou criar
        trabalhos derivativos com base no sistema Desbravador Check-in, no
        conteúdo ou em qualquer parte deste;
      </li>
      <li>
        Burlar qualquer tecnologia usada pela Desbravador Check-in, em busca de
        qualquer vantagem ou benefício próprio;
      </li>
      <li>
        Vender, alugar, sublicenciar ou arrendar qualquer parte da Desbravador
        Check-in ou de seu conteúdo;
      </li>
      <li>
        Modificar artificialmente a contagem de check-ins ou manipular de outra
        forma o sistema Desbravador Check-in usando um script ou outro processo
        automatizado;
      </li>
    </ul>

    <h3>6. Limitações e modificações do sistema</h3>
    <p>
      A Desbravador Check-in se esforçará para manter o sistema em operação. No
      entanto, possíveis dificuldades técnicas ou manutenção poderão ocorrer,
      sendo possível ocorrer interrupções temporárias. Por isso, a Desbravador
      Check-in se reserva ao direito de, periodicamente e a qualquer momento,
      modificar ou descontinuar, temporária ou permanentemente, funções e
      recursos da Desbravador Check-in, com ou sem aviso, sem que isso implique
      em qualquer responsabilização, exceto onde for proibido por lei, com
      relação a qualquer interrupção, modificação ou descontinuação da
      Desbravador Check-in ou de qualquer função ou recurso dele.
    </p>

    <h3>7. Segurança, garantia e responsabilidade</h3>
    <p>
      Nos empenhamos para prestar o melhor serviço possível. Você usa a
      Desbravador Check-in por livre e espontânea vontade, por sua conta e
      risco. A Desbravador Check-in não garante que o serviço esteja livre de
      malware ou outros componentes prejudiciais. Além disso, a Desbravador
      Check-in não faz declarações nem assegura, endossa, garante ou assume a
      responsabilidade por quaisquer aplicativos de terceiros (ou pelo conteúdo
      desses), ou por qualquer outro produto ou serviço divulgado ou oferecido
      por um terceiro no (ou por meio do) sistema Desbravador Check-in, ou
      qualquer site com hiperlinks, ou integrado em qualquer banner ou outra
      propaganda. A Desbravador Check-in não é responsável pelo conteúdo
      inserido por você em seu check-in online. Você compreende e concorda que a
      Desbravador Check-in não é responsável por nenhuma transação entre você e
      o Meio de Hospedagem ou por seus dados exteriorizados pelo Meio de
      Hospedagem, na Desbravador Check-in ou por meio dele.
    </p>
    <p>
      A Desbravador Check-in adota procedimentos para prevenir o acesso sem
      autorização e a utilização indevida de suas informações pessoais, de modo
      que seus dados fiquem protegidos. Também utiliza procedimentos de
      segurança e restrições técnicas e físicas para acessar e utilizar as
      informações pessoais nos servidores. Apenas funcionários autorizados podem
      acessar informações pessoais, durante o desempenho de seu trabalho.
    </p>

    <h3>8. Política de Privacidade</h3>
    <p>
      Respeitamos a privacidade dos nossos clientes e das empresas com as quais
      trabalhamos. Damos muita importância à confiança que depositam no nosso
      serviço e nos comprometemos em proteger e guardar todas as informações
      pessoais e empresariais que nos são dadas. Todas as informações que
      coletamos estão relacionadas com a prestação de serviços da Desbravador
      Check-in e suas características. Suas informações poderão ser atualizadas
      a qualquer momento dentro da plataforma online.
    </p>

    <h3>9. Alterações neste documento</h3>
    <p>
      Ocasionalmente, poderemos, a nosso critério, fazer alterações nos Termos e
      Condições de Uso. Quando fizermos alterações materiais neste documento,
      publicaremos o aviso na página inicial da Desbravador Check-in. Caso você
      continue utilizando a Desbravador Check-in depois que as alterações
      tiverem sido feitas, entenderemos que você aceitou as alterações.
      Portanto, certifique-se de ler atentamente qualquer aviso do tipo. Se após
      eventuais alterações nos termos, você não quiser mais usar a Desbravador
      Check-in, poderá encerrar seu contrato entrando em contato conosco por
      algum dos canais mencionados abaixo:
    </p>
    <ul>
      <li>e-mail: suporte@desbravador.com.br</li>
      <li>
        Endereço: Rua Mal. José B. Bormann, 1001-E, Jardim Itália, 89802-121,
        Chapecó/SC
      </li>
      <li>Telefone: +55 (49) 3321-0900</li>
    </ul>
    <p>
      Você também poderá solicitar informações a respeito da privacidade dos
      seus dados, sempre que desejar, nos comprometemos a te responder com a
      maior brevidade possível.
    </p>
    <p>
      Se qualquer previsão do presente Termo e Condições de Uso for tida como
      inválida ou inexecutável em conformidade com a lei aplicável, o
      dispositivo inválido ou inexequível será considerado substituído por um
      dispositivo válido e exequível que melhor se equipare à intenção do
      dispositivo original, continuando os termos e condições restantes em pleno
      vigor e efeito.
    </p>

    <h3>10. Vigência e encerramento do cadastro</h3>
    <p>
      O contrato continuará em vigência até que sejam encerrados os vínculos por
      você ou pela Desbravador Check-in. No entanto, você confirma e concorda
      que o acesso concedido por você, em relação ao conteúdo, é irrevogável e,
      portanto, continuará existindo mesmo após a expiração ou o término deste
      acordo por qualquer motivo.
    </p>
    <p>
      A Desbravador Check-in poderá encerrar o acordo ou suspender seu acesso ao
      sistema a qualquer momento, incluindo no caso do uso não autorizado real
      ou suspeito da Desbravador Check-in, ou da não conformidade com o acordo
    </p>
    <p>
      Para que seus dados pessoais sejam excluídos da base de dados da
      Desbravador Check-in, deverá ser solicitada a exclusão do seu cadastro
      junto à Desbravador Check-in, tal solicitação deve comunicar a revogação
      de consentimento do uso dados, podendo ser realizada a qualquer momento.
    </p>
    <p>
      Os dados pessoais serão eliminados no prazo de até 30 (trinta) dias após o
      requerimento de exclusão, permanecendo autorizada a conservação deles para
      as finalidades elencadas no artigo 16º da LGPD ou na hipótese de outra
      base legal autorizadora. Salienta-se que após a exclusão, caso queira
      reativar o seu cadastro, não será possível recuperar os dados, sendo
      necessário realizar novo cadastro no site da Desbravador Check-in.
    </p>
    <p>
      Para solicitar a exclusão do seu cadastro junto à Desbravador Check-in
      entre em contato conosco através do e-mail suporte@desbravador.com.br, com
      o assunto “Exclusão dos dados Desbravador Check-in”. Esta seção estará em
      vigor até a medida permitida pela lei aplicável. Você poderá cancelar este
      acordo a qualquer momento, sem qualquer ônus.
    </p>
    <p>
      No caso da não solicitação de cancelamento, os dados permanecerão ativos e
      protegidos de acordo com a LGPD.
    </p>

    <h3>11. Da Legislação e Foro</h3>
    <p>
      Os presentes Termos de Uso serão regidos de acordo com a legislação
      brasileira vigente na data de adesão do Usuário e qualquer controvérsia
      decorrente de seus termos, condições ou cláusulas, terá como foro
      competente o Foro Central da Comarca da Chapecó/SC, renunciando as partes
      a qualquer outro, por mais privilegiado que seja.
    </p>
  </>
);
