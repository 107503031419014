import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import FlagIconFactory from 'react-flag-icon-css';
import {useFormikContext} from 'formik';

import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';

import {COUNTRY_OPTIONS} from 'helpers/constants';

const FlagIcon = FlagIconFactory(React, {useCssModules: false});

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
    },
  },
});

export const CountrySelect = ({
  id,
  name,
  countryField,
  countryCodeField,
  onChange,
  placeholder,
  disabled,
  isBrazil,
  isNationality,
  required,
  showErrorWithoutTouched,
}) => {
  const classes = useStyles();
  const {errors, values, touched, setFieldTouched} = useFormikContext();

  const getLabel = useCallback(
    option => {
      if (isNationality) {
        if (isBrazil) {
          return option.nationalityName;
        }
        return option.nationalityNameInternational;
      }
      if (isBrazil) {
        return option.name;
      }
      return option.nameInternational;
    },
    [isBrazil, isNationality],
  );

  const getName = useCallback(
    option => {
      if (isNationality) {
        return option.nationalityName;
      }

      return option.name;
    },
    [isNationality],
  );

  const isInvalid = () => {
    if (showErrorWithoutTouched) {
      return !!errors[countryCodeField];
    }
    return !!errors[countryCodeField] && !!touched[countryCodeField];
  };

  const errorText = () => {
    if (showErrorWithoutTouched) {
      return errors[countryCodeField] || '';
    }
    return touched[countryCodeField] ? errors[countryCodeField] || '' : '';
  };

  return (
    <Autocomplete
      value={
        values[countryField] && values[countryCodeField]
          ? {
              label: values[countryField],
              value: values[countryCodeField],
            }
          : null
      }
      onChange={(event, newValue) => {
        if (newValue) {
          const value = {
            country: newValue.name,
            countryCode: newValue.value,
          };
          onChange(value);
        } else {
          const value = {
            country: '',
            countryCode: null,
          };
          onChange(value);
        }
      }}
      onBlur={() => {
        setFieldTouched(countryCodeField);
      }}
      size="small"
      id={id}
      name={name}
      style={{width: '100%'}}
      options={COUNTRY_OPTIONS.map(c => ({
        iso: c.iso,
        label: getLabel(c),
        name: getName(c),
        value: c.ibge,
      }))}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={option => option.label || ''}
      getOptionSelected={option => option}
      renderOption={option => (
        <>
          <span>
            <FlagIcon code={String(option.iso).toLowerCase()} />
          </span>
          {option.label}
        </>
      )}
      autoComplete={false}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          error={isInvalid()}
          helperText={errorText()}
          required={required}
          label={placeholder}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'nope',
          }}
        />
      )}
    />
  );
};

CountrySelect.defaultProps = {
  isBrazil: true,
  isNationality: false,
  required: true,
  showErrorWithoutTouched: true,
};

CountrySelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  countryField: PropTypes.string.isRequired,
  countryCodeField: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isBrazil: PropTypes.bool,
  isNationality: PropTypes.bool,
  required: PropTypes.bool,
  showErrorWithoutTouched: PropTypes.bool,
};
