import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useParams} from 'react-router-dom';

import {Backdrop, CircularProgress} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {transformValuestoSend} from 'services/dsl/transformRegister';
import {transformConfig} from 'services/dsl/transformConfigResponse';

import {useTranslation} from 'react-i18next';

import {Header} from 'routes/partials/header';
import {Main} from 'routes/partials/main';
import {Footer} from 'routes/partials/footer';

import api from 'services/motorApi';

import {RegisterForm} from './components/form';
import {RegisterDone} from './components/registerDone';
import {FormError} from '../home/components/formError';
import {FormSkeleton} from '../home/components/formSkeleton';

import {useStyles} from './styles';

const INITIAL_STATE = {
  loading: true,
  error: false,
  config: null,
  done: false,
};

const Register = () => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const formikRef = useRef();

  const [sending, setSending] = useState(false);

  const [state, setState] = useState({
    ...INITIAL_STATE,
  });

  const {t, i18n} = useTranslation();
  const {token} = useParams();

  const loadReservationData = useCallback(async () => {
    setState({
      ...INITIAL_STATE,
    });

    const configData = await api.get(
      `/api.php?r=PreCheckIn/Config&token=${token}&lng=${i18n.language}`,
    );

    if (configData.ok) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: false,
        config: transformConfig(configData.data),
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: true,
        config: null,
      }));
    }
  }, [token, i18n]);

  useEffect(() => {
    if (token) {
      loadReservationData();
    }
  }, [token, loadReservationData]);

  const handleSubmit = useCallback(
    async data => {
      setSending(true);

      formikRef.current.setSubmitting(false);

      const postData = transformValuestoSend(token, data);

      const registerResponse = await api.post(
        `/interface.php?r=Usuario/Enviar`,
        postData,
      );

      if (registerResponse.ok && registerResponse.data) {
        if (registerResponse.data?.result) {
          setSending(false);

          enqueueSnackbar(t('msg.sentSuccess'), {
            variant: 'success',
          });

          if (formikRef.current) {
            formikRef.current.resetForm();
          }

          setState(prevState => ({
            ...prevState,
            done: true,
          }));
        } else {
          setSending(false);

          enqueueSnackbar(registerResponse.data?.msg, {
            variant: 'error',
          });

          formikRef.current.resetForm();
        }
      } else {
        const error = registerResponse.data?.error || t('msg.sentError');
        setSending(false);

        enqueueSnackbar(error, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, t, token],
  );

  if (!token) {
    return <FormError title={t('error.token')} />;
  }

  if (state.error && !state.loading) {
    return <FormError title={t('error.error')} />;
  }

  const renderForm = () => {
    const {config, done} = state;

    if (done) {
      return (
        <RegisterDone
          onPress={() => {
            setState(prevState => ({
              ...prevState,
              done: false,
            }));
          }}
        />
      );
    }

    return (
      <RegisterForm
        ref={formikRef}
        config={config}
        handleSubmit={data => {
          handleSubmit(data);
        }}
      />
    );
  };

  return (
    <>
      <Header showLogo={false} />
      <Backdrop className={classes.backdrop} open={sending} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Main>{state.loading ? <FormSkeleton /> : renderForm()}</Main>
      <Footer />
    </>
  );
};

export default Register;
