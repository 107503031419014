import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    overflow: 'auto',
  },
}));
