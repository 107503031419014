import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  logoContainer: {
    display: 'flex',
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
    maxHeight: 100,
    marginBottom: 20,
  },
}));
