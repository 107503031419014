import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import {useTranslation} from 'react-i18next';

const SITE_KEY = '6LckhasZAAAAAHxOJlDaA7b0ewwE-jObNoIE3r6i';

export const Recaptcha = forwardRef(({onChange}, ref) => {
  const {i18n} = useTranslation();

  return (
    <ReCAPTCHA
      hl={i18n.language}
      ref={ref}
      sitekey={SITE_KEY}
      onChange={value => {
        onChange(value);
      }}
    />
  );
});

Recaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
};
