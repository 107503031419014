/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core';
import {Field, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export const FullNameModal = ({open, handleCancel, handleConfirm}) => {
  const {t} = useTranslation();
  const {values, errors, touched, handleChange} = useFormikContext();

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        maxWidth="md"
        fullWidth>
        <DialogTitle />
        <DialogContent>
          <DialogContentText>{t('search.enterFistName')}</DialogContentText>

          <Field name="firstName">
            {({field}) => (
              <TextField
                size="small"
                value={values.firstName || ''}
                error={!!errors.firstName && !!touched.firstName}
                helperText={touched.firstName ? errors.firstName : ''}
                onChange={e => {
                  handleChange(e);
                }}
                type="text"
                required
                name={field.name}
                id="firstName"
                label={t('form.firstName')}
                fullWidth
                variant="outlined"
                autoComplete="off"
              />
            )}
          </Field>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('actions.cancel')}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t('actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FullNameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};
