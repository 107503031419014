/* eslint-disable no-console */
import api from './services/motorApi';

api.addMonitor(response => {
  if (process.env.NODE_ENV === 'development') {
    console.group('API RESPONSE');
    console.log('REQUEST:', response.config);
    console.log('STATUS:', response.status);
    console.log('DATA:', response.data);
    console.groupEnd();
  }
});
