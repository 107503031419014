import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, Grid, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '15px',
    boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.3)`,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  identifierContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  subheader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  guestItem: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    margin: 0,
  },
  buttonChange: {
    textTransform: 'none',
    fontSize: '1rem',
    marginTop: 10,
    minWidth: 150,
  },
}));

export const GuestSelect = ({
  logo,
  identifier,
  options,
  value,
  handleChangeGuest,
}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="logo hotel" />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.identifierContainer}>
            <Typography className={classes.heading} component="h4">
              {identifier}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Box
        my={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start">
        {options.map(option => {
          const el = [];
          if (option.guests && option.guests.length > 0) {
            option.guests.forEach(guest => {
              if (String(guest.id) === String(value)) {
                el.push(
                  <p
                    key={guest.id}
                    value={guest.id}
                    className={classes.guestItem}>
                    {guest.name}
                  </p>,
                );
              }
            });
            return el;
          }
          return el;
        })}
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            handleChangeGuest();
          }}
          color="primary"
          className={classes.buttonChange}>
          {t('form.changeGuest')}
        </Button>
      </Box>
    </Box>
  );
};

GuestSelect.defaultProps = {
  value: null,
};

GuestSelect.propTypes = {
  logo: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string,
  handleChangeGuest: PropTypes.func.isRequired,
};
