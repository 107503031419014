/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {Chip, Fab, Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = ({palette, shape, spacing}) => ({
  root: {},
  imageContainer: {
    position: 'relative',
    zIndex: 10,
    textAlign: 'center',
    '&:hover $image': {
      opacity: 0.3,
    },
    '&:hover $removeButton': {
      opacity: 1,
    },
  },
  image: {
    height: 80,
    width: 'initial',
    maxWidth: '100%',
    color: palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    borderRadius: shape.borderRadius,
    zIndex: 5,
    opacity: 1,
  },
  removeButton: {
    transition: '.5s ease',
    position: 'absolute',
    opacity: 0,
    top: spacing(-1),
    right: spacing(-1),
    width: 40,
    height: 40,
    '&:focus': {
      opacity: 1,
    },
  },
});

function PreviewList({
  fileObjects,
  handleRemove,
  showFileNames,
  useChipsForPreview,
  classes,
  getPreviewIcon,
}) {
  if (useChipsForPreview) {
    return (
      <Grid spacing={1} direction="row" container className={classes.root}>
        {fileObjects.map((fileObject, i) => (
          <Grid
            item
            // eslint-disable-next-line react/no-array-index-key
            key={`${fileObject.file?.name ?? 'file'}-${i}`}
            className={classes.imageContainer}>
            <Chip
              variant="outlined"
              label={fileObject.file.name}
              onDelete={handleRemove(i)}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid spacing={8} container className={classes.root}>
      {fileObjects.map((fileObject, i) => (
        <Grid
          xs={11}
          sm={5}
          md={4}
          item
          // eslint-disable-next-line react/no-array-index-key
          key={`${fileObject.file?.name ?? 'file'}-${i}`}
          className={classes.imageContainer}>
          {getPreviewIcon(fileObject, classes)}

          {showFileNames && (
            <Typography variant="body1" component="p">
              {fileObject.file.name}
            </Typography>
          )}

          <Fab
            onClick={handleRemove(i)}
            aria-label="Delete"
            className={classes.removeButton}>
            <DeleteIcon />
          </Fab>
        </Grid>
      ))}
    </Grid>
  );
}

PreviewList.defaultProps = {
  showFileNames: false,
  useChipsForPreview: false,
};

PreviewList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  fileObjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getPreviewIcon: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  showFileNames: PropTypes.bool,
  useChipsForPreview: PropTypes.bool,
};

export default withStyles(styles, {name: 'MuiDropzonePreviewList'})(
  PreviewList,
);
