import {parse, format, isValid} from 'date-fns';

import {limitString} from 'helpers/string';

const formatDateToSend = dateFormated => {
  const date = parse(dateFormated, 'dd/MM/yyyy', new Date());
  const newDate = isValid(date) ? format(date, 'yyyy-MM-dd') : null;
  return newDate;
};

export const transformValuestoSend = (
  localizador,
  chavequarto,
  chavehospede,
  data,
) => ({
  localizador,
  chavequarto,
  chavehospede,
  // PERSONAL INFORMATION
  dsemail: data.email,
  dsnome: data.firstName,
  dssobrenome: data.lastName,
  dtnascimento: formatDateToSend(data.birthday),
  cdagequalify: data.ageQualify,
  flsexo: data.genre,
  idnacionalidade: data.nationalityCode,
  dsnacionalidade: data.nationality,
  flestadocivil: data.maritalStatus,
  idtipodoc: data.documentType,
  dsnumerodoc: limitString(data.document, 20),
  iddoctitular: data.idCard || '',
  dsorgao: data.issuer || '',
  cdprofissao: data.occupationCode,
  dsprofissao: data.occupation,
  // CURRENT ADDRESS
  dspais: data.country,
  idpais: data.countryCode,
  idcep: data.zipCode,
  dsestado: data.state,
  idestado: data.stateCode,
  dscidade: data.city,
  idcidade: data.cityCode,
  dsendereco: data.address,
  dsnumero: data.number,
  dsbairro: data.neighborhood,
  dscomplemento: data.complement,
  dsfoneres: data.phone,
  dscelular: data.cellPhone,
  // TRAVEL INFORMATION
  idmotivoviagem: data.purposeTrip,
  idmeiotransp: data.transportation,
  nmplaca: data.licensePlate,
  // LAST DEPARTURE
  idpaisorig: data.lastDepartureCountryCode,
  idcidadeorig: data.lastDepartureCityCode,
  idestadoorig: data.lastDepartureStateCode,
  dspaisorig: data.lastDepartureCountry,
  dsestadoorig: data.lastDepartureState,
  dscidadeorig: data.lastDepartureCity,
  // NEXT DESTINATION
  idpaisdest: data.nextDestinationCountryCode,
  idcidadedest: data.nextDestinationCityCode,
  idestadodest: data.nextDestinationStateCode,
  dspaisdest: data.nextDestinationCountry,
  dsestadodest: data.nextDestinationState,
  dscidadedest: data.nextDestinationCity,
  files: data.files,
  dsrewardscode: data.membership,
});
