import React, {useState, useEffect, useCallback, useMemo} from 'react';

import {
  Backdrop,
  CircularProgress,
  Divider,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Header} from 'routes/partials/header';
import {Main} from 'routes/partials/main';
import {Footer} from 'routes/partials/footer';

import api from 'services/reservasApi';

import {SurveyItem} from './components/surveyItem';
import {SurveySuccess} from './components/surveySuccess';
import {SurveyAnswered} from './components/surveyAnswered';

import {useStyles} from './styles';

import {mapLanguage} from './util';

const Survey = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const [state, setState] = useState({
    questions: [],
    answers: [],
    serie: null,
    title: null,
    logo: null,
    pax: null,
    done: false,
    answered: false,
  });

  const {t, i18n} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const {token} = useParams();

  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);

  const loadQuestions = useCallback(
    async lng => {
      setLoading(true);

      const res = await api.get(
        `/survey.php?token=${token}&lng=${mapLanguage(lng)}`,
      );
      if (res.ok && res.data) {
        const {pesquisa, pax, dados, respondida} = res.data;

        setState({
          questions: pesquisa || [],
          answers: pesquisa
            ? pesquisa.map(p => ({
                code: p.codigo,
                value: null,
                index: null,
                comment: '',
              }))
            : [],
          serie: dados?.id,
          title: dados?.mensagem,
          logo: dados?.logo,
          pax,
          done: false,
          answered: respondida,
        });

        setLoading(false);
      } else {
        const error = res.data?.error || t('error.error');
        setLoading(false);

        enqueueSnackbar(error, {
          variant: 'error',
        });
      }
    },
    [token, enqueueSnackbar, t],
  );

  const handleChangeAnswers = useCallback(
    (code, index, value) => {
      const newState = state.answers.map(a => {
        if (a.code === code) {
          return {
            ...a,
            index,
            value,
          };
        }
        return {
          ...a,
        };
      });
      setState(prev => ({
        ...prev,
        answers: newState,
      }));
    },
    [state.answers],
  );

  const handleChangeComment = useCallback(
    (code, comment) => {
      const newState = state.answers.map(a => {
        if (a.code === code) {
          return {
            ...a,
            comment,
          };
        }
        return {
          ...a,
        };
      });
      setState(prev => ({
        ...prev,
        answers: newState,
      }));
    },
    [state.answers],
  );

  const handleSubmitAnswers = useCallback(async () => {
    setSending(true);
    const {serie, pax} = state;

    const data = {
      respostas: state.answers.map(a => ({
        codigo: a.code,
        resposta: a.value,
        obs: a.comment,
      })),
      origem: {
        pax,
      },
    };

    const res = await api.post(`/cfg2motor.php?id=${serie}&funcao=3`, data);

    setSending(false);

    if (res.ok) {
      setState(prev => ({
        ...prev,
        done: true,
      }));

      enqueueSnackbar(t('survey.success'), {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(t('survey.error'), {
        variant: 'error',
      });
    }
  }, [state, enqueueSnackbar, t]);

  useEffect(() => {
    loadQuestions(currentLanguage);
  }, [loadQuestions, currentLanguage]);

  const disabled = useMemo(
    () => !!state.answers.find(a => !a.value),
    [state.answers],
  );

  const options = useMemo(
    () =>
      state.questions ? state.questions.sort((a, b) => a.ordem - b.ordem) : [],
    [state.questions],
  );

  return (
    <>
      <Header showLogo={false} />
      <Backdrop
        className={classes.backdrop}
        open={loading || sending}
        onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Main>
        <Grid container>
          <div className={classes.root}>
            {!loading &&
            state.questions &&
            state.questions.length > 0 &&
            !state.done &&
            !state.answered ? (
              <>
                {state.logo ? (
                  <Grid item xs={12}>
                    <img src={state.logo} alt="logo hotel" />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {state.pax && state.title ? (
                    <Typography variant="h6" component="h2">
                      {state.pax?.nome}, {state.title}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  {options.map(p => {
                    const find = state.answers.find(a => a.code === p.codigo);
                    return (
                      <SurveyItem
                        key={p.codigo}
                        title={p.nome}
                        code={p.codigo}
                        answers={p.respostas}
                        value={find?.value}
                        index={find?.index}
                        comment={find?.comment}
                        onClick={(code, index, value) => {
                          handleChangeAnswers(code, index, value);
                        }}
                        handleChangeComment={(code, comment) => {
                          handleChangeComment(code, comment);
                        }}
                      />
                    );
                  })}

                  <Divider />

                  <div className={classes.buttons}>
                    <Button
                      size="medium"
                      disabled={disabled}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSubmitAnswers();
                      }}
                      className={classes.button}>
                      {t('survey.send')}
                    </Button>
                  </div>
                </Grid>
              </>
            ) : null}
            {state.done && !loading ? <SurveySuccess /> : null}
            {!loading && state.answered ? <SurveyAnswered /> : null}
          </div>
        </Grid>
      </Main>
      <Footer />
    </>
  );
};

export default Survey;
