import React, {useEffect, useState} from 'react';

import {
  Container,
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@material-ui/core';
import QRCode from 'qrcode.react';

import {encode as btoa} from 'base-64';

import {useTranslation} from 'react-i18next';

const QRCodeGenerator = () => {
  const [type, setType] = useState('REGISTER');
  const [i, setI] = useState('');
  const [p, setP] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [level, setLevel] = useState('L');

  const [qrcodeValue, setQRCodeValue] = useState('');

  useEffect(() => {
    document.title = 'QRCODE GENERATOR';
  }, []);

  const downloadQR = () => {
    const canvas = document.getElementById('qrcode-generated');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrcode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getUrl = () => {
    const DOMAIN = 'https://checkin.desbravador.com.br';
    if (type === 'REGISTER') {
      const token = btoa(
        JSON.stringify({
          id: i,
          senha: p,
        }),
      );
      const link = `${DOMAIN}/register/${token}`;
      return link;
    }
    if (type === 'CHECKIN') {
      const token = btoa(
        JSON.stringify({
          id: i,
          senha: p,
          localizador: identifier,
        }),
      );
      const link = `${DOMAIN}/check-in/${token}`;
      return link;
    }
    return null;
  };

  const openQR = () => {
    const link = getUrl();
    window.open(link, '_blank');
  };

  const generateQRCode = () => {
    const link = getUrl();
    setQRCodeValue(link);
  };

  const {t} = useTranslation();
  return (
    <Container maxWidth="xs">
      <Grid container spacing={3} direction="column" align="center">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="h1" color="primary">
            QRCODE GENERATOR
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}
            select
            required
            name="i"
            id="select"
            label={t('qrcode.type')}
            fullWidth
            variant="outlined"
            autoComplete="off">
            <MenuItem value="REGISTER">{t('qrcode.register')}</MenuItem>
            <MenuItem value="CHECKIN">{t('qrcode.checkin')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            value={i || ''}
            onChange={e => {
              setI(e.target.value);
            }}
            type="text"
            required
            name="i"
            id="i"
            label={t('qrcode.i')}
            fullWidth
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            value={p || ''}
            onChange={e => {
              setP(e.target.value);
            }}
            type="password"
            required
            name="p"
            id="p"
            label={t('qrcode.p')}
            fullWidth
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        {type === 'CHECKIN' ? (
          <Grid item xs={12} md={12}>
            <TextField
              size="small"
              value={identifier || ''}
              onChange={e => {
                setIdentifier(e.target.value);
              }}
              type="text"
              required
              name="identifier"
              id="identifier"
              label={t('qrcode.identifier')}
              fullWidth
              variant="outlined"
              autoComplete="off"
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            fullWidth
            disableElevation
            size="medium"
            disabled={false}
            variant="contained"
            color="primary"
            onClick={() => {
              generateQRCode();
            }}>
            {t('qrcode.generate')}
          </Button>
        </Grid>

        {qrcodeValue ? (
          <>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                value={level}
                onChange={e => {
                  setLevel(e.target.value);
                }}
                select
                required
                name="i"
                id="select"
                label="LEVEL"
                fullWidth
                variant="outlined"
                autoComplete="off">
                <MenuItem value="L">L</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="Q">Q</MenuItem>
                <MenuItem value="H">H</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <QRCode
                  value={qrcodeValue}
                  size={258}
                  level={level}
                  id="qrcode-generated"
                  includeMargin
                />
                <br />
                <Button
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={() => {
                    downloadQR();
                  }}>
                  Download QRCode
                </Button>
                <Button
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={() => {
                    openQR();
                  }}>
                  {t('qrcode.open')}
                </Button>
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Container>
  );
};

export default QRCodeGenerator;
