import {parse, format, isValid} from 'date-fns';
import {decode as atob} from 'base-64';

const formatDateToSend = dateFormated => {
  const date = parse(dateFormated, 'dd/MM/yyyy', new Date());
  const newDate = isValid(date) ? format(date, 'dd/MM/yyyy') : null;
  return newDate;
};

export const transformValuestoSend = (token, data) => {
  const decode = JSON.parse(atob(token));

  return {
    id: decode.id,
    senha: decode.senha,
    // PERSONAL INFORMATION
    dados: {
      dsemail: data.email,
      dsnome: data.firstName,
      dssobrenome: data.lastName,
      dtnascimento: formatDateToSend(data.birthday),
      flsexo: data.genre,
      idnacionalidade: data.nationalityCode,
      dsnacionalidade: data.nationality,
      flestadocivil: data.maritalStatus,
      idtipodoc: data.documentType,
      dsnumerodoc: data.document,
      iddoctitular: data.idCard || '',
      dsorgao: data.issuer || '',
      cdprofissao: data.occupationCode,
      dsprofissao: data.occupation,
      // CURRENT ADDRESS
      dspais: data.country,
      idpais: data.countryCode,
      idcep: data.zipCode,
      dsestado: data.state,
      idestado: data.stateCode,
      dscidade: data.city,
      idcidade: data.cityCode,
      dsendereco: data.address,
      dsnumero: data.number,
      dsbairro: data.neighborhood,
      dscomplemento: data.complement,
      dsfoneres: data.phone,
      dscelular: data.cellPhone,
    },
  };
};
