import React from 'react';

export const TermsES = () => (
  <>
    <p>
      <small>En vigor a partir del 08 de julio de 2020.</small>
    </p>
    <p>
      Este documento contiene informaciones sobre los servicios de Desbravador
      Check-in prestados para usted, como funciona, las directivas legales y, en
      especial, sobre el tratamiento de datos personales, la finalidad de su
      tratamiento y los direchos y obligaciones relacionados.
    </p>
    <p>Lea este documento atentamente.</p>
    <p>
      La conformidad con los Términos y Condiciones de Uso significa su
      consentimiento de forma libre e informada respecto a las disposiciones
      expuestas en este documento.
    </p>

    <h3>1. Términos llave</h3>
    <p>
      Los términos a seguir tendrán los siguientes significados cuando sean
      usados en este documento:
    </p>
    <p>
      <b>Huésped</b> - indivíduo que se instala por tiempo provisorio en casa
      ajena, hotel, albergue etc.
    </p>
    <p>
      <b>Medio de Hospedaje</b> - edificación que ejerce el comercio de
      recepción y de alojamiento de turistas y visitantes en general.
    </p>
    <p>
      <b>Desbravador</b> - empresa especializada en proveer productos y
      servicios en tecnología
    </p>
    <p>
      <b>Check-in</b> - término en inglés que corresponde al Registro de entrada
      del huésped.
    </p>
    <p>
      <b>On-line</b> - término en inglés que significa respectivamente `en
      línea`, es usado para referirse a álguien que esté conectado a la
      internet, que esté disponible para acceder informaciones inmediatamente.
    </p>

    <h3>2. Acuerdo</h3>
    <p>
      Al usar nuestros servicios y/o accesar cualquier contenido o material que
      sea disponible por Desbravador Check-in, usted realizará un contrato que
      lo vincula con nuestra plataforma.
    </p>
    <p>
      Su acuerdo con nosotros incluye aceptar estos Términos de Uso. Usted
      confirma que leyó y comprendió este documento, que lo acepta y concuerda
      en cumplirlo.
    </p>
    <p>
      Para usar el Desbravador Check-in usted precisa (1) tener un documento de
      identidad personal válido, (2) tener un e-mail válido, y (3) tener 18 años
      o más y/o ser legalmente responsable por sí propio, para poder celebrar
      este contrato que lo vincula con nuestra empresa y no estar impedido de
      hacerlo según cualquier ley aplicable.
    </p>
    <p>
      Usted también asume que todas las informaciones de registro que envíar al
      Medio de Hospedaje a través de la plataforma Desbravador Check-in son
      verdaderas, precisas y completas, y concuerda en mantenerlas siempre de
      esa forma.
    </p>
    <p>
      Además, usted está sabiendo que todas sus informaciones y datos personales
      son provistos con su consentimiento explícito como titular de los datos, y
      está conciente de las condiciones de éstos Términos de Uso y de la
      extensión de la autorización de uso de sus datos personales por los Medios
      de Hospedaje.
    </p>
    <p>
      El Desbravador Check-in tiene como contenido la garantía y transparencia
      en el uso de los datos de las personas físicas por cualquier medio,
      teniendo legítimo interés en su solicitud, con el fin de que el servicio
      ofrecido pueda prestarse con toda la excelencia esperada.
    </p>
    <p>
      Si usted no está de acuerdo (o no puede cumplir) con este documento,
      entonces, no podrá usar el Desbravador Check-in.
    </p>

    <h3>3 - ¿Que es y como funciona?</h3>
    <p>
      El Desbravador Check-in es una herramienta gratuita que le permite
      realizar su check-in online en diversos Medios de Hospedaje del mundo, de
      forma anticipada, simple, práctica y gratuita, para que su entrada en los
      hoteles sea rápida y agradable.
    </p>

    <p>
      <b>
        A partir del momento en que usted concluya una reserva en un
        establecimiento que use el Desbravador Check-in, usted recibirá un link
        del Hotel para que pueda hacer su check-in online.
      </b>
    </p>

    <p>
      Para hacer el check-in online es necesario aceptar nuestros Términos de
      Uso y digitar su DNI o Pasaporte para comezar. Complete todos los datos
      personales y las informaciones sobre el viaje que le serán solicitadas, y
      por último clique en el botón “Enviar” para hacer el envío de su ficha de
      registro para el Medio de Hospedaje en que se hizo la reserva.
    </p>

    <p>
      El Medio de Hospedaje seleccionado recibirá sus informaciones registradas
      en formato de una Ficha de Registro de Huésped, de forma virtual. Despúés
      realizar su check-in a través del Desbravador Check-in, bastará dirigirse
      a la recepción del Medio de Hospedaje en el que se va a hospedar para
      presentarse y retirar la llave de su ocupación.
    </p>

    <h3>3.1 - Aviso legal y disposiciones generales</h3>
    <p>
      El Desbravador Check-in es una herramienta facilitadora entre el Medio de
      Hospedaje y el Huésped, no teniendo ningún vínculo o responsabilidad en
      esta relación comercial, y por eso no se puede ser responsabilizada en
      casos fortuitos, de fuerza mayor, por cualquier acto, omisión, daño,
      fraude, tragedia, crimen y/o tentativa de estafa, situación embarazosa y
      otras formas de perjuícios para alguna de las partes.
    </p>
    <p>
      <b>
        El huésped está sujeto a las políticas establecidas por el Medio de
        Hospedaje en el momento de efectivar la reserva.
      </b>
    </p>
    <p>
      Así, al hacer una reserva en una acomodación usted acepta y concuerda con
      las condiciones de cancelación y de no comparecimiento (‘no show’), y
      cualquier término y condiciones (descriptos) extras del Medio de Hospedaje
      que puedan aplicarse a su reserva o a su hospedaje, incluyendo servicios
      prestados y/o productos ofrecidos.
    </p>
    <p>
      <b>
        El Desbravador Check-in no dispone la opção de cancelación de reserva o
        de rever el check-in realizado.
      </b>
    </p>
    <p>
      Caso usted quiera rever, ajustar o cancelar su reserva, o también efectuar
      pago, prorrogación de reserva, agregar personas en la misma ocupación,
      mudar de UH y servicios extras, deberá solicitar directamente al Medio de
      Hospedaje para que él proceda de acuerdo con sus políticas.
    </p>
    <p>
      Si usted tuviera que llegar más tarde o atrasado el día del check-in, o si
      sólo fuera llegar al día siguiente, certifíquese de comunicar eso
      (inmediatamente, y a tiempo) al Medio de Hospedaje para que ellos tengan
      idea de cuando usted llegará, y evitar la cancelación de su habitación o
      cobro de la tasa de no comparecimiento. Al Desbravador Check-in no le
      compete ninguna responsabilidad por las consecuencias referidas a su
      llegada atrasada o cualquier cancelación o cobro hecha por el Hotel en
      relación al no comparecimiento.
    </p>
    <p>
      En hipótesis alguna Desbravador Check-in, incluyendo sus directores,
      empleados, representantes, subsidiarios, empresas afiliadas,
      distribuidores, asociados, licenciados, agentes u otras personas envueltas
      en la creación, patrocinio, promoción o creación del Desbravador Check-in
      y de su contenido, podrá ser responsabilizado por:
    </p>
    <ul>
      <li>
        cualquier daño o pérdida punible, especial, indirecta o consecuente,
        daño moral, alguna pérdida de producción, lucro, rendimientos, contrato,
        y pérdida o daño de influencia o reputación, pérdida del derecho a la
        indemnización;
      </li>
      <li>
        cualquier error con relación a las informaciones del Medio de Hospedaje
        como aparece en nuestro site;
      </li>
      <li>
        servicios prestados o productos ofrecidos por el Medio de Hospedaje u
        otros asociados de negocios;
      </li>
      <li>
        cualquier daños (directos, indirectos, consecuentes o punibles),
        pérdidas o costos soportados, costos procesuales, incurridos o pagos por
        hechos por usted, según, resultado de o en relación con el uso,
        imposibilidad de uso o falla de nuestro site, o;
      </li>
      <li>
        cualquier perjuício (personal), muerte, daños en la propiedad u otros
        (directos, indirectos, especiales, consecuentes o punibles) daños,
        pérdidas o costos soportados, costos procesuales, incurridos o pagados
        por usted, sea debido a actos (legales), errores, infracciones,
        negligencia, fraudes de documentos y/o falsedad ideológica, tentativa de
        estafa, conducta impropia intencional, omisiones, no cumprimiento,
        informaciones falsas, delito o responsabilidad extracontractual objetiva
        (totalmente o parcialmente) al Medio de Hospedaje o cualquiera de sus
        asociados de negocios (incluyendo sus empleados, diretores
        administrativos, agentes, representantes o empresas sociadas), de algún
        producto o servicios estén (directa o indirectamente) disponibles, sean
        ofrecidos o promovidos por el propio establecimiento, incluyendo alguna
        cancelación (parcial), overbookings (sobrecapacidades), huelga, fuerza
        mayor, caso fortuito o cualquier otro evento que escape de nuestro
        control, independientemente de la teoria legal, sin relación con el
        hecho de Desbravador Check-in haya advertido sobre la posibilidad de
        esos daños, y también si una solución fallar en su finalidad esencial.
      </li>
    </ul>

    <h3>4. Derechos que usted concede a Desbravador Check-in</h3>
    <p>
      Usted nos concede el derecho y permiso de usar sus datos personales para:
    </p>
    <ul>
      <li>Detectar y prevenir fraudes.</li>
      <li>Desarrollar mejoras en el site.</li>
      <li>
        Efectuar el envío de su FNRH (Ficha de Hospedaje) para el Medio de
        Hospedaje seleccionado, como parte de su proceso de check-in, de acuerdo
        con las normas y procedimientos aplicables, cuando el hotel esté sediado
        en Brasil y envíe la ficha.
      </li>
      <li>Permitir la comunicación directa con los Medios de Hospedaje.</li>
      <li>
        Compartir todos los datos de su registro con el Hotel donde se irá a
        hospedar, y con el PMS (software) que el Medio de Hospedaje utiliza para
        gestionar las reservas.
      </li>
      <li>
        Vincular a su registro de check-in, en nuestro sistema, los datos de
        check-in de sus acompañantes, cuando ellos vengan a hacer el registro de
        la FNRH por Desbravador Check-in, cuando el hotel tenga sede en Brasil y
        haga el envío de la ficha.
      </li>
      <li>
        Usted autoriza a Desbravador Check-in a solicitar y almacenar nuevos
        datos e informaciones personales para complementar su registro, con la
        intención de permitir que el Medio de Hospedaje comprenda mejo sus
        preferencias y hábitos de consumo y de vida, para que exista la
        oportunidade de que el Hotel proporcione una mejor experiencia durante
        sa estadía.
      </li>
      <li>
        Usted también nos autoriza, de forma expresa, irrefutable e
        incondicional, a repasar todas sus informaciones constantes de su
        registro al medio de hospedaje donde se va a hospedar, como parte del
        procedimiento de check-in, siendo éste el principal objetivo del
        Desbravador Check-in. Este procedimiento tiene respaldo em la Ley
        General de Protección de Datos (LGPD) observados, principalmente, en los
        artículos 6º y 7º.
      </li>
      <li>
        Al completar el registro usted autoriza expresamente a Desbravador
        Check-in a tener acceso a sus datos personales y a,cuando sea necesario,
        transmitirlos o compartirlos con los Medios de Hospedaje, con el
        objetivo de realizar el propósito de esta herramienta. Desbravador
        Check-in se compromete a adoptar todas las medidas de seguridad y buenas
        prácticas para asegurar el sigilo de los datos en los límites y términos
        de este contrato.
      </li>
      <li>
        Es de su conocimiento que, a partir del momento en que el Medio de
        Hospedaje tenga acceso a sus datos personales, cabrá única y
        exclusivamente a él, Medio de Hospedaje, el tratamiento, sigilo y
        seguridad de sus datos personales.
      </li>
    </ul>

    <h3>5. Copyrights</h3>
    <p>
      Desbravador check-in está disponible sólo para uso personal, estando
      prohibido, por cualquier motivo:
    </p>
    <ul>
      <li>
        Copia, redistribución, transferencia, venta o disponerlo para el público
        de cualquier parte del sistema Desbravador Check-in y su contenido, o
        hacer, de otra forma, algún uso que no sea el que está expresamente
        permitido en este acuerdo o la ley aplicable, o que infrinja, de otra
        forma, los derechos de propiedad intelectual (como derechos autorales);
      </li>
      <li>
        Ejecutar ingeñería reversa, descompilar, desmontar, modificar o crear
        trabajos derivados con base en el sistema Desbravador Check-in, en su
        contenido o en cualquier parte de este;
      </li>
      <li>
        Burlar cualquier tecnología usada por Desbravador Check-in, buscando
        alguna ventaja o beneficio propio;
      </li>
      <li>
        Vender, alquilar, sublicenciar o arrendar cualquier parte del
        Desbravador Check-in o de su contenido;
      </li>
      <li>
        Modificar artificialmente el contenido de check-ins o manipular de otra
        forma el sistema Desbravador Check-in usando un script u otro proceso
        automatizado;
      </li>
    </ul>

    <h3>6. Limitaciones y modificaciones del sistema</h3>
    <p>
      El Desbravador Check-in empeñará sus mejores esfuerzos para mantener el
      sistema en operación. A pesar de que posibles dificuldades técnicas o de
      mantenimiento puedan surgir, y así resultar en interrupciones temporarias.
      Desbravador Check-in se reserva el derecho, de periodicamente y en
      cualquier momento, modificar o descontinuar, temporaria o permanentemente,
      funciones y recursos del Desbravador Check-in, con o sin aviso, sin que
      eso implique en alguna responsabilidad, excepto donde fuera prohibido por
      ley, con relación a cualquier interrupción, modificación o descontinuidad
      del Desbravador Check-in o de cualquier función o recurso de él.
    </p>

    <h3>7. Seguridad, garantía y responsabilidad</h3>
    <p>
      Estamos empeñados en prestar el mejor servicio posible. Usted usa el
      Desbravador Check-in por libre y espontánea voluntad, por su cuenta y
      riesgo. Desbravador Check-in no garantiza que el servicio esté libre de
      malware u otros componentes prejudiciales. Además de eso, Desbravador
      Check-in no hace declaraciones ni asegura, endosa, garantiza o asume la
      responsabilidad por cualquier aplicativo de terceros (o por el contenido
      de ellos), o por algún otro producto o servicio divulgado u ofrecido por
      tercero en el (o por medio del) sistema Desbravador Check-in, o cualquier
      site con hiperlinks, o integrado en algún banner u otra propaganda.
      Desbravador Check-in no es responsable por el contenido ingresado por
      usted en su check-in online. Usted comprende y acepta que Desbravador
      Check-in no es responsable por ninguna transacción entre usted y el Medio
      de Hospedaje, o por sus datos divulgados en Desbravador Check-in, o por
      medio de él.
    </p>
    <p>
      Desbravador Check-in adopta procedimientos para prevenir el acceso sin
      autorización y su utilización indebida de suas informaciones personales,
      de modo que sus datos estén protegidos. También utilizamos procedimientos
      de seguridad y restricciones técnicas y físicas para accesar y utilizar
      las informaciones personales de nuestros servidores. Sólo empleados
      autorizados pueden accesar informaciones personales, durante el desempeño
      de su trabajo.
    </p>

    <h3>8. Política de Privacidad</h3>
    <p>
      Respetamos la privacidad de nuestros clientes y de las empresas con ls que
      trabajamos. Damos mucha importancia a la confianza que depositon en
      nuestro servicio y nos comprometemos en proteger y guardar todas las
      informaciones personales y empresariales que se nos dan. Todas las
      informaciones que recibimos están relacionadas con la prestación de
      servicios de Desbravador Check-in y sus características. Sus informaciones
      podrán ser actualizadas en cualquier momento dentro de la plataforma
      online.
    </p>
    <p>
      Desbravador Check-in requiere varias informaciones personales, para
      distintas finalidades, tales como:
    </p>
    <ul>
      <li>Detectar y prevenir fraudes.</li>
      <li>Desarrollar mejoras en el site.</li>
      <li>
        Efectuar el envío de su FNRH para el Medio de Hospedaje seleccionado,
        como parte de su proceso de check-in, de acuerdo con las normas y
        procedimientos, cuando es aplicable.
      </li>
      <li>Permitir la comunicación directa con ls Medios de Hospedaje.</li>
      <li>
        Compartir todos los datos de su registro con el Hotel donde se va a
        hospedar.
      </li>
      <li>
        Vincular a su registro de check-in, en nuestro sistema, los datos de
        check-in de sus acompañantes, cuando ellos vengan a hacer el registro de
        la FNRH por el Desbravador Check-in.
      </li>
    </ul>
    <p>
      Usted es totalmente responsable por todas las informaciones ingresadas en
      su registro ante Desbravador Check-in, siendo totalmente responsable por
      los efectos provenientes de estas informaciones, respondiendo civil y
      criminalmente por actos contrarios a la ley, propaganda engañosa, actos
      obscenos y violación de derechos de autor. Cabe a usted verificar las
      informaciones publicadas en Desbravador Check-in y la necesidad de
      correccciones o actualizaciones.
    </p>

    <h3>9. Modificaciones en este documento</h3>
    <p>
      Ocasionalmente, podremos, a nuestro criterio, hacer alteraciones en los
      Términos y Condiciones de Uso. Cuando hagamos cambios materiales en este
      documento daremos aviso en nuestro site desbravador.com.br/pt/termos. Caso
      usted continúe utilizando el Desbravador Check-in depués que las
      alteraciones hubieran sido hechas entenderemos que usted aceptó las
      modificaciones. Por lo tanto, certifíquese de leer atentamente cualquier
      aviso de ese tipo. Si usted no quisiera usar más el Desbravador Check-in
      después de las alteraciones del documento, podrá cerrar su contrato
      comunicándose con nosotros por medio del e-mail
      suporte@desbravador.com.br, con el asunto “Exclusión de datos Desbravador
      Check-in”.
    </p>
    <p>
      Usted también podrá solicitarnos informaciones respecto de la privacidad
      de sus datos, siempre que desee, y le responderemos con la mayor brevedad
      posible.
    </p>
    <p>
      Si cualquier previsión del presente Término de Uso fuera tenida como
      inválida o inexecutável en conformidad con la ley aplicable, el
      dispositivo inválido o impracticable se considerará reemplazado por un
      dispositivo válido y exigible que se equipare de la mejor manera posible a
      la intención del dispositivo original y todos los términos y condiciones
      restantes continuarán en pleno vigor y efecto.
    </p>

    <h3>10. Vigencia y cierre del registro</h3>
    <p>
      El contrato se continuará aplicando a usted hasta que sean cerrados por
      usted o por Desbravador Check-in. Mientras, usted confirma y concuerda que
      el acceso concedido por usted en relación al contenido, es irrevocable y,
      por lo tanto, continuará después de expirado o terminación de este acuerdo
      por cualquier motivo. Desbravador Check-in podrá cerrar el acuerdo o
      suspender su acceso al sistema en cualquier momento, incluyendo el caso de
      su uso no autorizado real o sospechado por Desbravador Check-in, o de la
      no conformidad con el acuerdo.
    </p>
    <p>
      Para que sus datos personaless se excluyan de la base de datos de
      Desbravador Check-in, necesariamente deberá ser solicitarse que sea
      excluido de su registro ante Desbravador Check-in, con la comunicação de
      revocar el consentimiento, realizada en cualquier momento.
    </p>
    <p>
      Los datos personales se eliminarán después de la solicitud de exclusión,
      em un plazo de hasta 30 (treinta) días, permaneciendo autorizada la
      conservación para las finalidades detalladas en el artículo 16º de la
      LGPD. Y, después de la exclusión, cuando quiera reactivar su registro, no
      será posible recuperar los datos y usted tendrá que hacer un nuevo
      registro en el site de Desbravador Check-in.
    </p>
    <p>
      Para solicitar la exclusión de su registro ante Desbravador Check-in debe
      comunicarse con nosotros por el e-mail suporte@desbravador.com.br, con el
      tema “Exclusión de los datos Desbravador Check-in”. Esta sección estará en
      vigor hasta la medida permitida por la ley aplicable. Usted podrá cancelar
      este acuerdo en cualquier momento, sin ningún gasto.
    </p>
    <p>
      En el caso de no solicitar la cancelación, los datos permanecerán activos
      y protegidos de acuerdo con la LGPD.
    </p>

    <h3>11. De la Legislación y Foro</h3>
    <p>
      Los presentes Términos de Uso se regirán de acuerdo con la legislación
      brasileña vigente en la fecha de adesión del Usuario y cualquier
      controversia que surgen de sus términos, condiciones o cláusulas, tendrá
      como foro competente el Foro Central
    </p>
  </>
);
