import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: '#1b2831',
    textAlign: 'center',
  },
  logoDesbravador: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: '100px',
  },
  footerTitle: {
    margin: 0,
    fontSize: '0.9rem',
    color: theme.palette.text.primary,
  },
  footerCopy: {
    margin: 0,
    marginTop: '5px',
    fontSize: '0.8rem',
    color: theme.palette.text.primary,
  },
}));
