import React from 'react';
import PropTypes from 'prop-types';

import {useFormikContext} from 'formik';

import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

export const OccupationSelect = ({
  id,
  name,
  labelField,
  codeField,
  onChange,
  placeholder,
  disabled,
  options,
  required,
  showErrorWithoutTouched,
}) => {
  const {errors, touched, values, setFieldTouched} = useFormikContext();

  const isInvalid = () => {
    if (showErrorWithoutTouched) {
      return !!errors[codeField];
    }
    return !!errors[codeField] && !!touched[codeField];
  };

  const errorText = () => {
    if (showErrorWithoutTouched) {
      return errors[codeField] || '';
    }
    return touched[codeField] ? errors[codeField] || '' : '';
  };

  return (
    <Autocomplete
      value={
        values[labelField] && values[codeField]
          ? {
              label: values[labelField],
              value: values[codeField],
            }
          : null
      }
      onChange={(event, newValue) => {
        if (newValue) {
          const value = {
            label: newValue.label,
            value: newValue.value,
          };
          onChange(value);
        } else {
          const value = {
            label: '',
            value: null,
          };
          onChange(value);
        }
      }}
      onBlur={() => {
        setFieldTouched(codeField);
      }}
      size="small"
      id={id}
      name={name}
      style={{width: '100%'}}
      options={options || []}
      classes={{}}
      autoHighlight
      getOptionLabel={option => option.label || ''}
      getOptionSelected={option => option}
      renderOption={option => <>{option.label}</>}
      autoComplete={false}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          error={isInvalid()}
          helperText={errorText()}
          required={required}
          label={placeholder}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
          }}
          autoComplete="off"
        />
      )}
    />
  );
};

OccupationSelect.defaultProps = {
  showErrorWithoutTouched: true,
};

OccupationSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  codeField: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  required: PropTypes.bool.isRequired,
  showErrorWithoutTouched: PropTypes.bool,
};
