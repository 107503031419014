import React from 'react';
import {Skeleton} from '@material-ui/lab';
import {Grid, Divider, Box} from '@material-ui/core';
import {useStyles} from './styles';

export const FormSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className={classes.logoContainer}>
              <Skeleton variant="rect" height={35} width={180} />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.identifierContainer}>
              <Skeleton height={25} width={140} />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box py={1}>
              <Skeleton variant="rect" height={30} width="100%" />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box py={1}>
              <Skeleton variant="rect" height={20} width="100%" />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box py={1}>
              <Skeleton variant="rect" height={20} width="100%" />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box py={1}>
              <Skeleton variant="rect" height={20} width="100%" />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={12}>
            <div className={classes.identifierContainer}>
              <Skeleton variant="rect" height={35} width={180} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
