import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useParams} from 'react-router-dom';

import {Backdrop, CircularProgress} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import ReactGA from 'react-ga';

import {transformReservationResponse} from 'services/dsl/transformReservationResponse';
import {transformConfig} from 'services/dsl/transformConfigResponse';
import {transformValuestoSend} from 'services/dsl/transformValuesToSend';
import {verifyGuestEmailAndDocument} from 'services/dsl/verifyGuestEmailAndDocument';

import {useTranslation} from 'react-i18next';

import {Header} from 'routes/partials/header';
import {Main} from 'routes/partials/main';
import {Footer} from 'routes/partials/footer';

import api from 'services/motorApi';

import {GuestList} from './components/guestList';
import {CheckinForm} from './components/form';
import {FormSkeleton} from './components/formSkeleton';
import {FormError} from './components/formError';

import {
  getMainGuestData,
  getGuestOptions,
  getGuestData,
  transformGuestToFormState,
  isUUIDV4,
} from './utils';

import {useStyles} from './styles';

const INITIAL_STATE = {
  loading: true,
  error: false,
  msg: '',
  initialState: null,
  config: null,
  guestActive: null,
  guestOptions: [],
  showGuestList: true,
};

const Home = () => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const formikRef = useRef();

  const [sending, setSending] = useState(false);

  const [state, setState] = useState({
    ...INITIAL_STATE,
  });

  const {t, i18n} = useTranslation();
  const {token} = useParams();

  const loadReservationData = useCallback(async () => {
    setState({
      ...INITIAL_STATE,
    });

    const reservationData = await api.get(
      `/api.php?r=PreCheckIn/GetBook&token=${token}&lng=${i18n.language}`,
    );

    const configData = await api.get(
      `/api.php?r=PreCheckIn/Config&token=${token}&lng=${i18n.language}`,
    );

    if (reservationData.ok && configData.ok) {
      const initialState = transformReservationResponse(reservationData.data);

      const guestOptions = getGuestOptions(initialState);

      setState({
        loading: false,
        error: false,
        msg: '',
        initialState,
        config: transformConfig(configData.data),
        guestActive: null,
        guestOptions,
        showGuestList: true,
      });
    } else {
      setState({
        loading: false,
        error: true,
        msg: reservationData?.data?.error,
        initialState: null,
        config: null,
        guestActive: null,
        guestOptions: [],
        showGuestList: true,
      });
    }
  }, [token, i18n]);

  useEffect(() => {
    if (token) {
      loadReservationData();
    }
  }, [token, loadReservationData]);

  const handleChangeGuestActive = ({guestId, roomId}) => {
    setState(prevState => ({
      ...prevState,
      guestActive: {
        guestId,
        roomId,
      },
      showGuestList: false,
    }));
  };

  const handleSubmit = useCallback(
    async data => {
      setSending(true);

      const {initialState} = state;
      const {identifider, roomKey, guestKey, values} = data;

      const guestKeyFix = isUUIDV4(guestKey) ? 0 : guestKey;

      verifyGuestEmailAndDocument(initialState, guestKey, values)
        .then(async () => {
          const postData = transformValuestoSend(
            identifider,
            roomKey,
            guestKeyFix,
            values,
          );

          const sendPaxResponse = await api.post(
            `/api.php?r=PreCheckIn/sendPax&token=${token}`,
            postData,
          );

          formikRef.current.setSubmitting(false);

          if (sendPaxResponse.ok) {
            setSending(false);

            enqueueSnackbar(t('msg.sentSuccess'), {
              variant: 'success',
            });

            if (process.env.NODE_ENV !== 'development') {
              ReactGA.event({
                category: 'Checkin',
                action: 'CHECKIN',
                label: `${identifider}-${roomKey}-${guestKey}`,
              });
            }

            loadReservationData();
          } else {
            const error = sendPaxResponse.data?.error || t('msg.sentError');
            setSending(false);
            enqueueSnackbar(error, {
              variant: 'error',
            });
          }
        })
        .catch(e => {
          setSending(false);

          enqueueSnackbar(e, {
            variant: 'error',
          });

          formikRef.current.setSubmitting(false);
        });
    },
    [enqueueSnackbar, state, t, token, loadReservationData],
  );

  const toggleShowList = () => {
    setState(prevState => ({
      ...prevState,
      showGuestList: !state.showGuestList,
    }));
  };

  if (!token) {
    return <FormError title={t('error.token')} />;
  }

  if (state.error && !state.loading) {
    return <FormError title={state.msg || t('error.error')} />;
  }

  if (!state.initialState && !state.loading) {
    return <FormError title={t('error.error')} />;
  }

  const renderGuestList = () => {
    const {initialState, guestActive, guestOptions, config} = state;
    return (
      <GuestList
        logo={config?.logo}
        identifier={initialState?.identifier}
        guestId={guestActive?.guestId}
        roomId={guestActive?.roomId}
        options={guestOptions}
        handleChangeGuest={({guestId, roomId}) => {
          if (guestId && roomId) {
            handleChangeGuestActive({
              guestId,
              roomId,
            });
          }
        }}
      />
    );
  };

  const renderForm = () => {
    const {initialState, guestActive, guestOptions, config} = state;

    if (initialState && guestActive.guestId) {
      const guestData = getGuestData(initialState, guestActive.guestId);
      const formData = transformGuestToFormState(guestData.guest);
      const mainGuestData = getMainGuestData(initialState, guestData.roomId);

      return (
        <CheckinForm
          ref={formikRef}
          token={token}
          config={config}
          formData={formData}
          mainGuestData={mainGuestData}
          identifier={initialState.identifier}
          guestOptions={guestOptions}
          guestActive={guestActive}
          handleSubmit={data => {
            handleSubmit(data);
          }}
          toggleShowList={() => {
            toggleShowList();
          }}
        />
      );
    }
    return null;
  };

  const renderContent = () => {
    const {showGuestList} = state;
    return showGuestList ? renderGuestList() : renderForm();
  };

  return (
    <>
      <Header />
      <Backdrop className={classes.backdrop} open={sending} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Main>{state.loading ? <FormSkeleton /> : renderContent()}</Main>
      <Footer />
    </>
  );
};

export default Home;
