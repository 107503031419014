import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  navbar: {},
  toolbar: {},
  toolbarMini: {
    minHeight: 30,
  },
  title: {
    display: 'flex',
    flexGrow: 1,
  },
  logoCheckin: {
    maxHeight: 42,
    height: 'auto',
  },
  language: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));
