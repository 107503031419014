import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {isValid} from 'date-fns';

import {useFormikContext, Field} from 'formik';

import {useTranslation} from 'react-i18next';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {UploadDocumet} from 'components/uploadDocument';
import {CountrySelect} from 'components/form/countrySelect';
import {OccupationSelect} from 'components/form/occupationSelect';

import {BRAZIL_IBGE_CODE} from 'helpers/constants';
import {removeSpecialChars, calcAge} from '../../utils';
import {personalInfoFields} from './util';

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from './styles';

const PersonalInfoForm = ({
  expanded,
  handleChangePanel,
  occupations,
  onBlurEmail,
  optionalFields,
  handleWithoutEmail,
  showWithoutEmail,
  hideOptionalFields,
  documentsNotAllowed,
}) => {
  const {values, handleChange, errors, setFieldValue, setValues} =
    useFormikContext();

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const birthdayValue = values.birthday ? new Date(values.birthday) : null;

  const panelIsInvalid = useMemo(() => {
    let hasErrors = false;
    personalInfoFields.forEach(field => {
      if (errors[field]) {
        hasErrors = true;
      }
    });
    return hasErrors;
  }, [errors]);

  const requiredBirthday = useMemo(
    () => !optionalFields.includes('birthday'),
    [optionalFields],
  );
  const showBirthday = useMemo(() => {
    if (!requiredBirthday && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredBirthday, hideOptionalFields]);

  const requiredGenre = useMemo(
    () => !optionalFields.includes('genre'),
    [optionalFields],
  );
  const showGenre = useMemo(() => {
    if (!requiredGenre && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredGenre, hideOptionalFields]);

  const requiredNacionality = useMemo(
    () => !optionalFields.includes('nationalityCode'),
    [optionalFields],
  );
  const showNacionality = useMemo(() => {
    if (!requiredNacionality && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredNacionality, hideOptionalFields]);

  const requiredMaritalStatus = useMemo(
    () => !optionalFields.includes('maritalStatus'),
    [optionalFields],
  );
  const showMaritalStatus = useMemo(() => {
    if (!requiredMaritalStatus && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredMaritalStatus, hideOptionalFields]);

  const requiredDocument = useMemo(() => values.age >= 18, [values.age]);

  const requiredIdCard = useMemo(
    () =>
      values.age < 18 ||
      (values.nationalityCode && values.nationalityCode !== BRAZIL_IBGE_CODE) ||
      values.documentType === 'RG'
        ? false
        : !optionalFields.includes('idCard'),
    [values.age, values.nationalityCode, values.documentType, optionalFields],
  );
  const showIdCard = useMemo(() => {
    if (!requiredIdCard && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredIdCard, hideOptionalFields]);

  const requiredIssuer = useMemo(
    () =>
      values.age < 18 ||
      (values.nationalityCode && values.nationalityCode !== BRAZIL_IBGE_CODE) ||
      values.documentType === 'RG'
        ? false
        : !optionalFields.includes('issuer'),
    [values.age, values.nationalityCode, values.documentType, optionalFields],
  );
  const showIssuer = useMemo(() => {
    if (!requiredIssuer && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredIssuer, hideOptionalFields]);

  const requiredOccupation = useMemo(
    () => !optionalFields.includes('occupationCode'),
    [optionalFields],
  );
  const showOccupation = useMemo(() => {
    if (!requiredOccupation && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredOccupation, hideOptionalFields]);

  const showMembership = useMemo(
    () => !hideOptionalFields,
    [hideOptionalFields],
  );

  const documentTypesEnabled = useMemo(() => {
    const options = [
      'CPF',
      'RG',
      'CN',
      'PASSPORT',
      'CIE',
      'CI',
      'DNI',
      'RUC',
      'RFC',
    ];

    return options.filter(o => !documentsNotAllowed.includes(o) || o === 'CPF');
  }, [documentsNotAllowed]);

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(event, isExpanded) => {
        handleChangePanel(event, isExpanded);
      }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div>
          <Typography className={classes.heading}>
            {t('form.personalInformation')}
          </Typography>

          {panelIsInvalid ? (
            <Typography className={classes.headingInfoError}>
              {t('validations.invalidForm')}
            </Typography>
          ) : (
            <Typography className={classes.headingInfoSuccess}>
              {t('validations.formOK')}
            </Typography>
          )}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          {showWithoutEmail ? (
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={values.withoutEmail}
                    onChange={() => {
                      handleWithoutEmail(values.withoutEmail);
                    }}
                    name="withoutEmail"
                  />
                }
                label={t('form.withoutEmail')}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} md={12}>
            <Field name="email">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.withoutEmail ? '' : values.email || ''}
                  error={!!errors.email}
                  helperText={errors.email || ''}
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="email"
                  required
                  name={field.name}
                  id="email"
                  label={t('form.email')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  disabled={values.withoutEmail}
                  onBlur={() => {
                    if (!errors.email) {
                      onBlurEmail();
                    }
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Field name="firstName">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.firstName || ''}
                  error={!!errors.firstName}
                  helperText={errors.firstName || ''}
                  onChange={e => {
                    setFieldValue(
                      'firstName',
                      String(e.target.value).toUpperCase(),
                    );
                  }}
                  type="text"
                  required
                  id="firstName"
                  name={field.name}
                  label={t('form.firstName')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Field name="lastName">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.lastName || ''}
                  error={!!errors.lastName}
                  helperText={errors.lastName || ''}
                  onChange={e => {
                    setFieldValue(
                      'lastName',
                      String(e.target.value).toUpperCase(),
                    );
                  }}
                  type="text"
                  required
                  id="lastName"
                  name={field.name}
                  label={t('form.lastName')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
              )}
            </Field>
          </Grid>

          {showBirthday ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="birthday">
                {({field}) => (
                  <KeyboardDatePicker
                    clearable={false}
                    size="small"
                    autoOk
                    animateYearScrolling
                    required={requiredBirthday}
                    id="birthday"
                    name={field.name}
                    fullWidth
                    inputVariant="outlined"
                    label={t('form.birthday')}
                    format="dd/MM/yyyy"
                    value={isValid(birthdayValue) ? birthdayValue : null}
                    inputValue={values.birthday}
                    error={!!errors.birthday}
                    helperText={errors.birthday || ''}
                    disableFuture
                    InputAdornmentProps={{position: 'end'}}
                    onChange={(date, value) => {
                      setValues(prevValue => ({
                        ...prevValue,
                        birthday: value,
                        age: calcAge(value),
                      }));
                    }}
                    onError={() => {}}
                    autoComplete="off"
                    variant="dialog"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showGenre ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="genre">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.genre || ''}
                    error={!!errors.genre}
                    helperText={errors.genre || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    select
                    required={requiredGenre}
                    id="genre"
                    name={field.name}
                    label={t('form.genre')}
                    fullWidth
                    variant="outlined"
                    autoComplete="off">
                    <MenuItem key="M" value="M">
                      {t('genres.male')}
                    </MenuItem>
                    <MenuItem key="F" value="F">
                      {t('genres.female')}
                    </MenuItem>
                    <MenuItem key="N" value="N">
                      {t('genres.uninformed')}
                    </MenuItem>
                  </TextField>
                )}
              </Field>
            </Grid>
          ) : null}

          {showNacionality ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="nationality">
                {({field}) => (
                  <CountrySelect
                    id="nationality"
                    name={field.name}
                    countryField="nationality"
                    countryCodeField="nationalityCode"
                    onChange={({country, countryCode}) => {
                      setValues(prevValue => ({
                        ...prevValue,
                        nationality: country,
                        nationalityCode: countryCode,
                      }));
                    }}
                    required={requiredNacionality}
                    placeholder={t('form.nationality')}
                    disabled={false}
                    isBrazil={i18n.language === 'pt'}
                    isNationality
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showMaritalStatus ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="maritalStatus">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.maritalStatus || ''}
                    error={!!errors.maritalStatus}
                    helperText={errors.maritalStatus || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    select
                    required={requiredMaritalStatus}
                    id="maritalStatus"
                    name={field.name}
                    label={t('form.maritalStatus')}
                    fullWidth
                    variant="outlined"
                    autoComplete="off">
                    <MenuItem key="S" value="S">
                      {t('maritalStatus.single')}
                    </MenuItem>
                    <MenuItem key="C" value="C">
                      {t('maritalStatus.married')}
                    </MenuItem>
                    <MenuItem key="V" value="V">
                      {t('maritalStatus.widower')}
                    </MenuItem>
                    <MenuItem key="D" value="D">
                      {t('maritalStatus.separated')}
                    </MenuItem>
                    <MenuItem key="I" value="I">
                      {t('maritalStatus.divorced')}
                    </MenuItem>
                    <MenuItem key="J" value="J">
                      {t('maritalStatus.legallySeparated')}
                    </MenuItem>
                  </TextField>
                )}
              </Field>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={6} md={4}>
            <Field name="documentType">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.documentType || ''}
                  error={!!errors.documentType}
                  helperText={errors.documentType || ''}
                  onChange={e => {
                    handleChange(e);
                  }}
                  select
                  required={requiredDocument}
                  id="documentType"
                  name={field.name}
                  label={t('form.documentType')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off">
                  {documentTypesEnabled.map(documentType => (
                    <MenuItem key={documentType} value={documentType}>
                      {t(`documentTypes.${String(documentType).toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name="document">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.document || ''}
                  error={!!errors.document}
                  helperText={errors.document || ''}
                  onChange={e => {
                    setFieldValue(
                      'document',
                      removeSpecialChars(e.target.value),
                    );
                  }}
                  type="text"
                  required={requiredDocument}
                  id="document"
                  name={field.name}
                  label={t('form.document')}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              )}
            </Field>
          </Grid>

          {showIdCard ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="idCard">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.idCard || ''}
                    error={!!errors.idCard}
                    helperText={errors.idCard || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="text"
                    required={requiredIdCard}
                    id="idCard"
                    name={field.name}
                    label={t('form.idCard')}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showIssuer ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="issuer">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.issuer || ''}
                    error={!!errors.issuer}
                    helperText={errors.issuer || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="text"
                    required={requiredIssuer}
                    id="issuer"
                    name={field.name}
                    label={t('form.issuer')}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showOccupation ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="occupation">
                {({field}) => (
                  <OccupationSelect
                    id="occupation"
                    name={field.name}
                    labelField="occupation"
                    codeField="occupationCode"
                    onChange={({label, value}) => {
                      setValues(prevValue => ({
                        ...prevValue,
                        occupation: label,
                        occupationCode: value,
                      }));
                    }}
                    placeholder={t('form.occupation')}
                    disabled={false}
                    options={occupations}
                    required={requiredOccupation}
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={6} md={8}>
            <UploadDocumet
              initialFiles={values.files}
              onSave={fileList => {
                setFieldValue('files', fileList);
              }}
            />
          </Grid>

          {showMembership ? (
            <Grid item xs={12} sm={6} md={4}>
              <Field name="membership">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.membership || ''}
                    error={!!errors.membership}
                    helperText={errors.membership || ''}
                    onChange={e => {
                      setFieldValue(
                        'membership',
                        removeSpecialChars(e.target.value),
                      );
                    }}
                    type="text"
                    required={false}
                    id="membership"
                    name={field.name}
                    label={t('form.membership')}
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                  />
                )}
              </Field>
            </Grid>
          ) : null}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

PersonalInfoForm.defaultProps = {
  optionalFields: [],
  showWithoutEmail: true,
  hideOptionalFields: false,
  documentsNotAllowed: [],
};

PersonalInfoForm.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleChangePanel: PropTypes.func.isRequired,
  occupations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onBlurEmail: PropTypes.func.isRequired,
  optionalFields: PropTypes.arrayOf(PropTypes.string),
  documentsNotAllowed: PropTypes.arrayOf(PropTypes.string),
  handleWithoutEmail: PropTypes.func.isRequired,
  showWithoutEmail: PropTypes.bool,
  hideOptionalFields: PropTypes.bool,
};

export default PersonalInfoForm;
