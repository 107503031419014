import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import {useFormikContext, Field} from 'formik';

import {useTranslation} from 'react-i18next';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

import {cepSearch} from 'services/cepSearch';

import {BRAZIL_IBGE_CODE} from 'helpers/constants';

import {CountrySelect} from 'components/form/countrySelect';
import {StateSelect} from 'components/form/stateSelect';
import {CitySelect} from 'components/form/citySelect';

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from './styles';

const AddressForm = props => {
  const {expanded, handleChangePanel, optionalFields} = props;
  const {values, handleChange, handleBlur, errors, touched, setValues} =
    useFormikContext();
  const [searchingZipCode, setSearchingZipCode] = useState(false);

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const searchZipCode = async () => {
    setSearchingZipCode(true);
    const search = values.zipCode;
    try {
      const result = await cepSearch(search);
      if (result) {
        setValues(
          {
            ...values,
            ...result,
          },
          true,
        );
      }
      setSearchingZipCode(false);
    } catch (e) {
      setSearchingZipCode(false);
    }
  };

  const renderCountry = () => (
    <Field name="country">
      {({field}) => (
        <CountrySelect
          id="country"
          name={field.name}
          countryField="country"
          countryCodeField="countryCode"
          onChange={({country, countryCode}) => {
            setValues(prevValue => ({
              ...prevValue,
              country,
              countryCode,
              state: '',
              stateCode: null,
              city: '',
              cityCode: null,
            }));
          }}
          placeholder={t('form.country')}
          disabled={false}
          isBrazil={i18n.language === 'pt'}
          required={!optionalFields.includes('countryCode')}
          showErrorWithoutTouched={false}
        />
      )}
    </Field>
  );

  const renderState = () => (
    <Field name="state">
      {({field}) => (
        <StateSelect
          id="state"
          name={field.name}
          stateField="state"
          stateCodeField="stateCode"
          isSelect={values.countryCode === BRAZIL_IBGE_CODE}
          onChange={({state, stateCode}) => {
            setValues(prevValue => ({
              ...prevValue,
              state,
              stateCode,
              city: '',
              cityCode: null,
            }));
          }}
          placeholder={t('form.state')}
          disabled={!values.countryCode}
          countryCode={values.countryCode}
          required={!optionalFields.includes('state')}
          showErrorWithoutTouched={false}
        />
      )}
    </Field>
  );

  const renderCity = () => {
    let disabled = false;
    if (!values.stateCode && !values.countryCode) {
      disabled = true;
    }
    if (values.countryCode === BRAZIL_IBGE_CODE && !values.stateCode) {
      disabled = true;
    }
    return (
      <Field name="city">
        {({field}) => (
          <CitySelect
            id="city"
            name={field.name}
            cityField="city"
            cityCodeField="cityCode"
            isSelect={values.countryCode === BRAZIL_IBGE_CODE}
            values={values}
            errors={errors}
            onChange={({city, cityCode}) => {
              setValues(prevValue => ({
                ...prevValue,
                city,
                cityCode,
              }));
            }}
            placeholder={t('form.city')}
            disabled={disabled}
            stateCode={values.stateCode}
            required={!optionalFields.includes('city')}
            showErrorWithoutTouched={false}
          />
        )}
      </Field>
    );
  };

  return (
    <ExpansionPanel
      expanded={true || expanded}
      onChange={(event, isExpanded) => {
        handleChangePanel(event, isExpanded);
      }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div>
          <Typography className={classes.heading}>
            {t('form.currentAddress')}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            {renderCountry()}
          </Grid>

          <Grid item xs={12} sm={4} md={6}>
            <Field name="zipCode">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.zipCode || ''}
                  error={!!errors.zipCode && !!touched.zipCode}
                  helperText={
                    errors.zipCode && touched.zipCode ? errors.zipCode : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="tel"
                  required={!optionalFields.includes('zipCode')}
                  name={field.name}
                  id="zipCode"
                  label={t('form.zipCode')}
                  fullWidth
                  variant="outlined"
                  autoComplete="nope"
                  InputProps={
                    values.countryCode === BRAZIL_IBGE_CODE
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={searchingZipCode}
                                aria-label=""
                                onClick={() => {
                                  searchZipCode();
                                }}
                                onMouseDown={() => {}}
                                edge="end">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {renderState()}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {renderCity()}
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <Field name="address">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.address || ''}
                  error={!!errors.address && !!touched.address}
                  helperText={
                    errors.address && touched.address ? errors.address : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="text"
                  required={!optionalFields.includes('address')}
                  name={field.name}
                  id="address"
                  label={t('form.address')}
                  fullWidth
                  variant="outlined"
                  autoComplete="nope"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Field name="number">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.number || ''}
                  error={!!errors.number && !!touched.number}
                  helperText={
                    errors.number && touched.number ? errors.number : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="text"
                  required={!optionalFields.includes('number')}
                  name={field.name}
                  id="number"
                  label={t('form.number')}
                  fullWidth
                  variant="outlined"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={8} md={4}>
            <Field name="neighborhood">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.neighborhood || ''}
                  error={!!errors.neighborhood && !!touched.neighborhood}
                  helperText={
                    errors.neighborhood && touched.neighborhood
                      ? errors.neighborhood
                      : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="text"
                  required={!optionalFields.includes('neighborhood')}
                  name={field.name}
                  id="neighborhood"
                  label={t('form.neighborhood')}
                  fullWidth
                  variant="outlined"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Field name="complement">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.complement || ''}
                  error={!!errors.complement && !!touched.complement}
                  helperText={
                    errors.complement && touched.complement
                      ? errors.complement
                      : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="text"
                  required={false}
                  name={field.name}
                  id="complement"
                  label={t('form.complement')}
                  fullWidth
                  variant="outlined"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Field name="phone">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.phone || ''}
                  error={!!errors.phone && !!touched.phone}
                  helperText={errors.phone && touched.phone ? errors.phone : ''}
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="tel"
                  required={!optionalFields.includes('phone')}
                  name={field.name}
                  id="phone"
                  label={t('form.phone')}
                  fullWidth
                  variant="outlined"
                  autoComplete="nope"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Field name="cellPhone">
              {({field}) => (
                <TextField
                  size="small"
                  value={values.cellPhone || ''}
                  error={!!errors.cellPhone && !!touched.cellPhone}
                  helperText={
                    errors.cellPhone && touched.cellPhone
                      ? errors.cellPhone
                      : ''
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  type="tel"
                  required={!optionalFields.includes('cellPhone')}
                  name={field.name}
                  id="cellPhone"
                  label={t('form.cellPhone')}
                  fullWidth
                  variant="outlined"
                  autoComplete="nope"
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

AddressForm.defaultProps = {
  optionalFields: [],
};

AddressForm.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleChangePanel: PropTypes.func.isRequired,
  optionalFields: PropTypes.arrayOf(PropTypes.string),
};

export default AddressForm;
