import i18n from 'locale/i18n';

export const verifyGuestEmailAndDocument = (initialState, guestKey, values) => {
  const ERROR_EMAIL = i18n.t('msg.emailAlreadyInUse');
  const ERROR_DOC = i18n.t('msg.documentAlreadyInUse');

  const {email, documentType, document} = values;

  let valid = true;
  let error = null;

  initialState.rooms.forEach(room => {
    room.guests.forEach(guest => {
      if (guest.id !== guestKey) {
        if (email === guest.email) {
          valid = false;
          error = ERROR_EMAIL;
        }
        if (
          documentType &&
          documentType === guest.documentType &&
          document &&
          document === guest.document
        ) {
          valid = false;
          error = ERROR_DOC;
        }
      }
    });
  });

  return new Promise((resolve, reject) => {
    if (valid) {
      resolve(true);
    } else {
      reject(error);
    }
  });
};
