import * as Yup from 'yup';
import i18n from 'locale/i18n';
import {isValid, isPast, parse, sub, isAfter} from 'date-fns';
import {BRAZIL_IBGE_CODE} from 'helpers/constants';
import {validateCpf} from 'utils/validateCpf';

const REQUIRED = i18n.t('validations.required');
const INVALID_EMAIL = i18n.t('validations.invalidEmail');
const INVALID_VALUE = i18n.t('validations.invalidValue');
const MAX_LENGTH = i18n.t('validations.maxLength');
const INVALID_DATE = i18n.t('validations.invalidDate');
const INVALID_CPF = i18n.t('validations.invalidCPF');
const REQUIRE_CPF_BRAZIL_18 = i18n.t('validations.brazilRequireCPF');

export const FormSchema = (optionalFields = []) => {
  const isRequired = (field, value) => {
    if (!optionalFields.includes(field) && !value) {
      return false;
    }
    return true;
  };

  const isOneOf = (field, value, options = []) => {
    if (optionalFields.includes(field)) {
      return true;
    }
    return options.includes(value);
  };

  return Yup.object().shape({
    // PERSONAL INFORMATION
    membership: Yup.string()
      .max(40, ({max}) => `${MAX_LENGTH} ${max}`)
      .nullable(),
    email: Yup.string().email(INVALID_EMAIL).required(REQUIRED).nullable(),
    firstName: Yup.string()
      .test('required', REQUIRED, value => isRequired('firstName', value))
      .max(40, ({max}) => `${MAX_LENGTH} ${max}`)
      .nullable(),
    lastName: Yup.string()
      .test('required', REQUIRED, value => isRequired('lastName', value))
      .max(60, ({max}) => `${MAX_LENGTH} ${max}`)
      .nullable(),
    birthday: Yup.string()
      .test('required', REQUIRED, value => isRequired('birthday', value))
      .test('validDate', INVALID_DATE, value => {
        if (!value) {
          return true;
        }
        const date = parse(value, 'dd/MM/yyyy', new Date());
        const date120YearsAgo = sub(new Date(), {
          years: 120,
        });

        return isValid(date) && isPast(date) && isAfter(date, date120YearsAgo);
      })
      .nullable(),
    genre: Yup.string()
      .test('required', REQUIRED, value => isRequired('genre', value))
      .test('isOneOf', INVALID_VALUE, value =>
        isOneOf('genre', value, ['M', 'F', 'N']),
      )
      .nullable(),
    nationalityCode: Yup.string()
      .test('required', REQUIRED, value => isRequired('nationalityCode', value))
      .nullable(),
    maritalStatus: Yup.string()
      .test('required', REQUIRED, value => isRequired('maritalStatus', value))
      .test('isOneOf', INVALID_VALUE, value =>
        isOneOf('maritalStatus', value, ['S', 'C', 'V', 'D', 'I', 'J']),
      )
      .nullable(),
    documentType: Yup.string()
      .when('age', {
        is: value => value < 18,
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .required(REQUIRED)
          .test('isOneOf', INVALID_VALUE, value =>
            isOneOf('documentType', value, [
              'CPF',
              'RG',
              'CN',
              'PASSAPORTE',
              'CIE',
              'CI',
              'DNI',
              'RUC',
              'RFC',
            ]),
          )
          .nullable(),
      })
      .when(['age', 'nationalityCode'], {
        is: (age, nationalityCode) =>
          age > 18 && nationalityCode === BRAZIL_IBGE_CODE,
        then: Yup.string()
          .test(
            'REQUIRE_CPF_BRAZIL_18',
            REQUIRE_CPF_BRAZIL_18,
            value => value === 'CPF',
          )
          .nullable(),
      })
      .nullable(),
    document: Yup.string()
      .when('documentType', {
        is: val => val === 'CPF',
        then: Yup.string()
          .test('CPF', INVALID_CPF, value =>
            value ? validateCpf(value) : true,
          )
          .nullable(),
      })
      .when('age', {
        is: value => value < 18,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required(REQUIRED).nullable(),
      })
      .nullable(),
    occupationCode: Yup.string()
      .test('required', REQUIRED, value => isRequired('occupationCode', value))
      .nullable(),
    // ADDRESS
    countryCode: Yup.string()
      .test('required', REQUIRED, value => isRequired('countryCode', value))
      .nullable(),
    zipCode: Yup.string()
      .test('required', REQUIRED, value => isRequired('zipCode', value))
      .nullable(),
    city: Yup.string()
      .test('required', REQUIRED, value => isRequired('city', value))
      .nullable(),
    state: Yup.string()
      .test('required', REQUIRED, value => isRequired('state', value))
      .nullable(),
    address: Yup.string()
      .test('required', REQUIRED, value => isRequired('address', value))
      .nullable(),
    number: Yup.string()
      .test('required', REQUIRED, value => isRequired('number', value))
      .nullable(),
    neighborhood: Yup.string()
      .test('required', REQUIRED, value => isRequired('neighborhood', value))
      .nullable(),
    complement: Yup.string().nullable(),
    phone: Yup.string()
      .test('required', REQUIRED, value => isRequired('phone', value))
      .nullable(),
    cellPhone: Yup.string()
      .test('required', REQUIRED, value => isRequired('cellPhone', value))
      .nullable(),
  });
};
