import React from 'react';
import PropTypes from 'prop-types';

import {useFormikContext} from 'formik';

import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {CITY_OPTIONS} from 'helpers/constants';

export const CitySelect = ({
  isSelect,
  id,
  name,
  cityField,
  cityCodeField,
  onChange,
  placeholder,
  disabled,
  stateCode,
  required,
  showErrorWithoutTouched,
}) => {
  const {errors, values, touched, setFieldTouched} = useFormikContext();

  const isInvalid = () => {
    if (showErrorWithoutTouched) {
      return !!errors[cityField];
    }
    return !!errors[cityField] && !!touched[cityField];
  };

  const errorText = () => {
    if (showErrorWithoutTouched) {
      return errors[cityField] || '';
    }
    return touched[cityField] ? errors[cityField] || '' : '';
  };

  return isSelect ? (
    <Autocomplete
      value={
        values[cityField] && values[cityCodeField]
          ? {
              label: values[cityField],
              value: values[cityCodeField],
            }
          : null
      }
      onChange={(event, newValue) => {
        if (newValue) {
          const value = {
            city: newValue.label,
            cityCode: newValue.value,
          };
          onChange(value);
        } else {
          const value = {
            city: '',
            cityCode: null,
          };
          onChange(value);
        }
      }}
      onBlur={() => {
        setFieldTouched(cityField);
      }}
      size="small"
      id={id}
      name={name}
      style={{width: '100%'}}
      options={
        stateCode ? CITY_OPTIONS.filter(c => c.state.value === stateCode) : []
      }
      classes={{}}
      autoHighlight
      getOptionLabel={option => option.label || ''}
      getOptionSelected={option => option}
      renderOption={option => <>{option.label}</>}
      autoComplete={false}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          error={isInvalid()}
          helperText={errorText()}
          required={required}
          label={placeholder}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'nope',
          }}
        />
      )}
    />
  ) : (
    <TextField
      size="small"
      value={values[cityField] || ''}
      error={isInvalid()}
      helperText={errorText()}
      onChange={e => {
        const value = {
          city: e.target.value,
          cityCode: null,
        };
        onChange(value);
      }}
      onBlur={() => {
        setFieldTouched(cityField);
      }}
      type="text"
      required={required}
      id={id}
      name={name}
      label={placeholder}
      fullWidth
      variant="outlined"
      disabled={disabled}
      autoComplete="nope"
    />
  );
};

CitySelect.defaultProps = {
  stateCode: undefined,
  required: true,
  showErrorWithoutTouched: true,
};

CitySelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cityField: PropTypes.string.isRequired,
  cityCodeField: PropTypes.string.isRequired,
  isSelect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  stateCode: PropTypes.number,
  required: PropTypes.bool,
  showErrorWithoutTouched: PropTypes.bool,
};
