import React from 'react';

import {Grid} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import LogoDslEn from 'assets/img/logo_rodape_en.png';
import LogoDslEs from 'assets/img/logo_rodape_es.png';
import LogoDslPt from 'assets/img/logo_rodape.png';

import {useStyles} from './styles';

export const Footer = () => {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const lng = i18n.language;
  return (
    <footer className={classes.footer}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {lng === 'en' ? (
            <img
              src={LogoDslEn}
              alt="logo desbravador"
              className={classes.logoDesbravador}
            />
          ) : null}
          {lng === 'es' ? (
            <img
              src={LogoDslEs}
              alt="logo desbravador"
              className={classes.logoDesbravador}
            />
          ) : null}
          {lng !== 'es' && lng !== 'en' ? (
            <img
              src={LogoDslPt}
              alt="logo desbravador"
              className={classes.logoDesbravador}
            />
          ) : null}
          {/* <p className={classes.footerTitle}>www.desbravador.com.br</p>
          <p className={classes.footerTitle}>ouvidoria@desbravador.com.br</p> */}
          <p className={classes.footerCopy}>{t('footer.copyright')} 2020 ©</p>
        </Grid>
      </Grid>
    </footer>
  );
};
