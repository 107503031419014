/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useState, useMemo, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';

import {Typography, TextField} from '@material-ui/core';
import {Star, Comment} from '@material-ui/icons';

import clsx from 'clsx';
import {useStyles} from './styles';

export const SurveyItem = ({
  title,
  code,
  value,
  index,
  answers,
  onClick,
  handleChangeComment,
  comment,
}) => {
  const [showObs, setShowObs] = useState(false);

  const obsRef = useRef();

  useEffect(() => {
    if (showObs && obsRef.current) {
      setTimeout(() => {
        if (obsRef.current) {
          obsRef.current.focus();
        }
      }, 300);
    }
  }, [showObs]);

  const classes = useStyles();
  const {t} = useTranslation();

  const options = useMemo(
    () => answers.sort((a, b) => a.ordem - b.ordem),
    [answers],
  );

  return (
    <div className={classes.container}>
      <Typography variant="h6" component="p" color="primary" align="left">
        {title}
      </Typography>
      <div className={classes.surveyTitleContainer}>
        <button
          type="button"
          className={classes.commentButton}
          onClick={() => {
            setShowObs(!showObs);
          }}>
          <Typography
            className={classes.commentButtonTitle}
            color="textPrimary">
            {t('survey.comment')}
          </Typography>
          <Comment color="primary" fontSize="large" />
        </button>
      </div>
      <div className={classes.surveyItemButtons}>
        {options.map((a, i) => (
          <div key={a.codigo}>
            <button
              className={
                value === a.codigo || i < index
                  ? clsx(classes.starButtonActive, classes.starButton)
                  : classes.starButton
              }
              type="button"
              onClick={() => {
                onClick(code, i, a.codigo);
              }}>
              <Star color="primary" fontSize="large" />
            </button>
          </div>
        ))}
      </div>
      {showObs ? (
        <TextField
          inputRef={obsRef}
          placeholder={t('survey.obs')}
          multiline
          rows={3}
          onChange={event => {
            handleChangeComment(code, event.target.value);
          }}
          value={comment}
          fullWidth
          variant="outlined"
          autoComplete="off"
          inputProps={{maxLength: 200}}
        />
      ) : null}
    </div>
  );
};

SurveyItem.defaultProps = {
  value: null,
  index: null,
  comment: '',
  answers: [],
};

SurveyItem.propTypes = {
  title: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  value: PropTypes.number,
  index: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  handleChangeComment: PropTypes.func.isRequired,
  comment: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
};
