import {makeStyles, withStyles} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import {
  Accordion as MuiExpansionPanel,
  AccordionDetails as MuiExpansionPanelDetails,
  AccordionSummary as MuiExpansionPanelSummary,
} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    flexShrink: 0,
  },
  headingInfoError: {
    fontSize: theme.typography.pxToRem(12),
    color: red[500],
    fontWeight: 'bold',
  },
  headingInfoSuccess: {
    fontSize: theme.typography.pxToRem(12),
    color: green[500],
    fontWeight: 'bold',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    color: theme.palette.text.secondary,
    margin: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  agreements: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    minWidth: 150,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  text: {
    fontSize: '14px',
    margin: '2px 0',
  },
}));

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '15px',
    boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.3)`,
    marginBottom: 20,
    margin: '16px 0',
    '&:not(:last-child)': {},
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: 20,
    },
  },
  expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    minHeight: 56,
    '&$expanded': {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      minHeight: 56,
    },
  },
  focusVisible: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);
