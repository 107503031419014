import apisauce from 'apisauce';
import {encode as btoa} from 'base-64';

const api = apisauce.create({
  baseURL: 'https://motor.desbravador.com.br/reservas2012b',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    Authorization: `Basic ${btoa('iservice:I6dHJ1ZX0.9VqUwfgMXHcEArC3rFQ')}`,
  },
});

export default api;
