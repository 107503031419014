import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '15px',
    boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.3)`,
  },
  buttons: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    minWidth: 150,
  },
}));
