import React, {useState, forwardRef, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';

import {Button, FormControlLabel, Checkbox, Grid} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';

import {Formik, Form} from 'formik';

import {guestsTransform} from 'services/dsl/transformReservationResponse';
import api from 'services/motorApi';

import PersonalInfoForm from './PersonalInfoForm';
import AddressForm from './AddressForm';
import TravelInfoForm from './TravelInfoForm';
import {GuestSelect} from './guestSelect';
import {TermsModal} from './termsModal';
import {TermsModalHotel} from './termsModalHotel';
import {EmailExistModal} from './emailExistModal';

import {useStyles} from './styles';

import {FormSchema} from './rules';

import {
  filterUseInformationGuest,
  filterMainGuestData,
  mergeFormValues,
  mapFormValues,
  generateEmail,
  calcAge,
} from '../../utils';

export const CheckinForm = forwardRef(
  (
    {
      config,
      identifier,
      formData,
      mainGuestData,
      guestOptions,
      guestActive,
      handleSubmit,
      token,
      toggleShowList,
    },
    formikRef,
  ) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptTermsHotel, setAcceptTermsHotel] = useState(false);
    const [dataExistState, setDataExistState] = useState({
      modalVisible: false,
      searching: false,
      data: null,
    });
    const [expanded, setExpanded] = useState('panel1');

    const handleChangePanel = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const onBlurEmail = useCallback(
      async email => {
        const response = await api.get(
          `/api.php?r=PreCheckIn/checkHospede&token=${token}&email=${email}`,
        );

        if (response.ok && response.data) {
          const guest = guestsTransform([{...response.data}]);
          setDataExistState(prevdataExistState => ({
            ...prevdataExistState,
            modalVisible: true,
            searching: true,
            data: filterUseInformationGuest(guest[0]),
          }));
        }
      },
      [token],
    );

    const optionalFields = useMemo(
      () => mapFormValues(config.optionalFields || []),
      [config],
    );
    const hideOptionalFields = useMemo(
      () => config.hideOptionalFields,
      [config],
    );
    const documentsNotAllowed = useMemo(
      () => config.documentsNotAllowed,
      [config],
    );

    const age = useMemo(() => calcAge(formData.birthday), [formData]);

    const renderForm = () => (
      <Formik
        innerRef={formikRef}
        enableReinitialize={false}
        initialValues={{
          ...formData,
          withoutEmail: false,
          age,
        }}
        validationSchema={FormSchema(optionalFields)}
        validateOnMount
        onSubmit={values => {
          if (!acceptTerms || !acceptTermsHotel) {
            enqueueSnackbar(t('msg.termsOfUser'), {
              variant: 'warning',
            });

            formikRef.current.setSubmitting(false);
            return;
          }

          const data = {
            identifier,
            roomKey: guestActive.roomId,
            guestKey: guestActive.guestId,
            values,
          };
          handleSubmit(data);
        }}>
        {({
          isSubmitting,
          isValidating,
          submitForm,
          isValid,
          values,
          setValues,
        }) => (
          <>
            <Form noValidate autoComplete="off">
              <GuestSelect
                logo={config?.logo}
                identifier={identifier}
                value={guestActive?.guestId}
                options={guestOptions}
                handleChangeGuest={() => {
                  toggleShowList();
                }}
              />

              <PersonalInfoForm
                expanded={expanded === 'panel1'}
                handleChangePanel={handleChangePanel('panel1')}
                occupations={config?.occupations}
                onBlurEmail={() => {
                  onBlurEmail(values.email);
                }}
                optionalFields={optionalFields}
                hideOptionalFields={hideOptionalFields}
                documentsNotAllowed={documentsNotAllowed}
                showWithoutEmail={
                  !formData.mainGuest || config?.optionalHolderEmail
                }
                handleWithoutEmail={withoutEmail => {
                  if (withoutEmail) {
                    setValues(prevValues => ({
                      ...prevValues,
                      email: '',
                      withoutEmail: false,
                    }));
                  } else {
                    const newEmail = generateEmail({
                      identifier,
                      guestId: guestActive.guestId,
                    });
                    setValues(prevValues => ({
                      ...prevValues,
                      email: newEmail,
                      withoutEmail: true,
                    }));
                  }
                }}
              />

              {!formData.mainGuest && mainGuestData ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <p className={classes.text}>{t('form.copyQuestion')}</p>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        const filteredData = filterMainGuestData(mainGuestData);
                        setValues(prevValues => ({
                          ...prevValues,
                          ...filteredData,
                        }));

                        enqueueSnackbar(t('form.copySuccess'), {
                          variant: 'success',
                        });
                      }}
                      className="">
                      {t('form.copy')}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              <AddressForm
                expanded={expanded === 'panel2'}
                handleChangePanel={handleChangePanel('panel2')}
                optionalFields={optionalFields}
                hideOptionalFields={hideOptionalFields}
              />

              <TravelInfoForm
                expanded={expanded === 'panel3'}
                handleChangePanel={handleChangePanel('panel3')}
                optionalFields={optionalFields}
                hideOptionalFields={hideOptionalFields}
              />

              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className={classes.agreements}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          onChange={() => {
                            setAcceptTerms(!acceptTerms);
                          }}
                          value
                          color="primary"
                          checked={acceptTerms}
                        />
                      }
                      label={t('form.acceptTerms')}
                    />

                    <TermsModal />
                  </div>
                  <div className={classes.agreements}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          onChange={() => {
                            setAcceptTermsHotel(!acceptTermsHotel);
                          }}
                          value
                          color="primary"
                          checked={acceptTermsHotel}
                        />
                      }
                      label={t('form.acceptTermsHotel')}
                    />

                    <TermsModalHotel terms={config.termsOfUse} />
                  </div>
                </Grid>
              </Grid>

              <div className={classes.buttons}>
                <Button
                  size="medium"
                  disabled={isSubmitting || isValidating}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (isValid) {
                      submitForm();
                    } else {
                      enqueueSnackbar(t('msg.invalidForm'), {
                        variant: 'error',
                      });
                    }
                  }}
                  className={classes.button}>
                  {t('actions.checkin')}
                </Button>
              </div>

              <EmailExistModal
                email={values.email}
                data={dataExistState.data}
                open={dataExistState.modalVisible}
                handleClose={() => {
                  setDataExistState({
                    ...dataExistState,
                    modalVisible: false,
                    searching: false,
                    data: null,
                  });
                }}
                handleConfirm={() => {
                  const newValues = dataExistState.data;
                  setDataExistState({
                    ...dataExistState,
                    modalVisible: false,
                    searching: false,
                    data: null,
                  });
                  setValues(prevValues => ({
                    ...prevValues,
                    ...mergeFormValues(prevValues, newValues),
                  }));
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    );

    return <div className={classes.root}>{renderForm()}</div>;
  },
);

CheckinForm.defaultProps = {
  guestOptions: [],
  guestActive: null,
  mainGuestData: {},
};

CheckinForm.propTypes = {
  token: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    birthday: PropTypes.string,
    mainGuest: PropTypes.bool,
  }).isRequired,
  mainGuestData: PropTypes.shape({}),
  config: PropTypes.shape({
    optionalFields: PropTypes.arrayOf(PropTypes.string),
    documentsNotAllowed: PropTypes.arrayOf(PropTypes.string),
    termsOfUse: PropTypes.string,
    logo: PropTypes.string,
    occupations: PropTypes.arrayOf(PropTypes.shape({})),
    hideOptionalFields: PropTypes.bool,
    optionalHolderEmail: PropTypes.bool,
  }).isRequired,
  guestOptions: PropTypes.arrayOf(PropTypes.shape({})),
  guestActive: PropTypes.shape({
    guestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    roomId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  handleSubmit: PropTypes.func.isRequired,
  toggleShowList: PropTypes.func.isRequired,
};
