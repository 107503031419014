import React, {forwardRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  TextField,
  Divider,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {isValid} from 'date-fns';

import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';

import {Formik, Form, Field} from 'formik';

import {Recaptcha} from 'components/recaptcha';

import {useStyles} from './styles';

import {FormSchema} from './rules';

import {INITIAL_VALUES} from './utils';
import {removeSpecialChars} from '../../../home/utils';
import {FullNameModal} from './fullNameModal';

export const SearchForm = forwardRef(
  ({handleSubmit, handleCancel, modalOpen}, ref) => {
    const {formikRef, recaptchaRef} = ref;
    const {t} = useTranslation();
    const {identifier} = useParams();

    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const initialValues = useMemo(() => {
      if (identifier) {
        return {
          ...INITIAL_VALUES,
          identifier,
        };
      }
      return INITIAL_VALUES;
    }, [identifier]);

    return (
      <Box className={classes.root} p={2}>
        <Formik
          innerRef={formikRef}
          enableReinitialize={false}
          initialValues={initialValues}
          validationSchema={FormSchema()}
          validateOnMount={false}
          onSubmit={values => {
            handleSubmit(values);
          }}>
          {({
            isSubmitting,
            isValidating,
            submitForm,
            touched,
            setFieldValue,
            handleChange,
            values,
            errors,
            setValues,
          }) => {
            const dateValue = values.checkinDate
              ? new Date(values.checkinDate)
              : null;

            return (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" component="h1" color="primary">
                      {t('search.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.check === 'IDENTIFIER'}
                            onChange={() => {
                              setValues({
                                ...values,
                                docuemnt: '',
                                check: 'IDENTIFIER',
                              });
                            }}
                            name="identifier"
                            color="primary"
                          />
                        }
                        label={t('search.identifier')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.check === 'DOCUMENT'}
                            onChange={() => {
                              setValues({
                                ...values,
                                identifier: '',
                                check: 'DOCUMENT',
                              });
                            }}
                            name="document"
                            color="primary"
                          />
                        }
                        label={t('search.document')}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {values.check === 'IDENTIFIER' ? (
                      <Field name="identifier">
                        {({field}) => (
                          <TextField
                            size="small"
                            value={values.identifier || ''}
                            error={!!errors.identifier && !!touched.identifier}
                            helperText={
                              touched.identifier ? errors.identifier : ''
                            }
                            onChange={e => {
                              handleChange(e);
                            }}
                            type="text"
                            required
                            name={field.name}
                            id="identifier"
                            label={t('search.identifier')}
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    ) : null}
                    {values.check === 'DOCUMENT' ? (
                      <Field name="document">
                        {({field}) => (
                          <TextField
                            size="small"
                            value={values.document || ''}
                            error={!!errors.document && !!touched.document}
                            helperText={touched.document ? errors.document : ''}
                            onChange={e => {
                              setFieldValue(
                                'document',
                                removeSpecialChars(e.target.value),
                              );
                            }}
                            type="text"
                            required
                            name={field.name}
                            id="document"
                            label={t('search.document')}
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="lastName">
                      {({field}) => (
                        <TextField
                          size="small"
                          value={values.lastName || ''}
                          error={!!errors.lastName && !!touched.lastName}
                          helperText={touched.lastName ? errors.lastName : ''}
                          onChange={e => {
                            handleChange(e);
                          }}
                          type="text"
                          required
                          name={field.name}
                          id="lastName"
                          label={t('form.lastName')}
                          fullWidth
                          variant="outlined"
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field name="checkinDate">
                      {({field}) => (
                        <KeyboardDatePicker
                          clearable={false}
                          size="small"
                          autoOk
                          animateYearScrolling
                          required
                          id="checkinDate"
                          name={field.name}
                          fullWidth
                          inputVariant="outlined"
                          label={t('search.checkinDate')}
                          format="dd/MM/yyyy"
                          value={isValid(dateValue) ? dateValue : null}
                          inputValue={values.checkinDate}
                          error={!!errors.checkinDate && !!touched.checkinDate}
                          helperText={
                            touched.checkinDate ? errors.checkinDate : ''
                          }
                          disablePast
                          InputAdornmentProps={{position: 'end'}}
                          onChange={(date, value) => {
                            setFieldValue('checkinDate', value);
                          }}
                          onError={() => {}}
                          autoComplete="off"
                          variant="dialog"
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Recaptcha
                      ref={recaptchaRef}
                      onChange={recaptcha => {
                        setFieldValue('recaptcha', recaptcha);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className={classes.buttons}>
                      <Button
                        disableElevation
                        size="medium"
                        disabled={isSubmitting || isValidating}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (isValid) {
                            submitForm();
                          } else {
                            enqueueSnackbar(t('msg.invalidForm'), {
                              variant: 'error',
                            });
                          }
                        }}
                        className={classes.button}>
                        {t('actions.start')}
                      </Button>
                    </div>
                  </Grid>

                  <FullNameModal
                    open={modalOpen}
                    handleCancel={() => {
                      handleCancel();
                    }}
                    handleConfirm={() => {
                      submitForm();
                    }}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  },
);

SearchForm.defaultProps = {
  modalOpen: false,
};

SearchForm.propTypes = {
  modalOpen: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
