import React, {useState, useRef, useCallback} from 'react';

import {Backdrop, CircularProgress} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';

import {useTranslation} from 'react-i18next';
import {format, parse} from 'date-fns';

import CheckinLogo from 'assets/img/checkin-logo-horizontal.png';

import {Header} from 'routes/partials/header';
import {Main} from 'routes/partials/main';
import {Footer} from 'routes/partials/footer';

import api from 'services/motorApi';

import {SearchForm} from './components/form';

import {useStyles} from './styles';

const Search = () => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const formikRef = useRef();
  const recaptchaRef = useRef();

  const resetRecaptcha = () => {
    setTimeout(() => {
      recaptchaRef.current.reset();
      formikRef.current.setFieldValue('recaptcha', null);
    }, 500);
  };

  const handleSubmit = useCallback(
    async data => {
      const {
        identifier,
        lastName,
        firstName,
        checkinDate,
        document,
        recaptcha,
      } = data;

      if (!recaptcha && !modalOpen) {
        formikRef.current.setSubmitting(false);

        enqueueSnackbar(t('msg.recaptcha'), {
          variant: 'warning',
        });
        return;
      }

      setModalOpen(false);

      const date = parse(checkinDate, 'dd/MM/yyyy', new Date());
      const newDate = format(date, 'yyyy-MM-dd');

      setSearching(true);

      const response = await api.get(
        `/api.php?r=PreCheckIn/GetToken&datain=${newDate}&sobrenome=${String(
          lastName,
        ).toUpperCase()}&nomecompleto=${String(
          firstName,
        ).toUpperCase()}&localizador=${identifier}&documento=${document}&recaptcha=${recaptcha}`,
      );

      if (response.ok && response.data) {
        if (response.data.pedirnome) {
          setSearching(false);
          setModalOpen(true);

          formikRef.current.setSubmitting(false);
        } else if (response.data.token) {
          const {token} = response.data;

          setSearching(false);

          navigate(`/check-in/${token}`, {replace: true});
        }
      } else {
        const error = response.data?.error || t('msg.reservationNotFound');
        setSearching(false);

        formikRef.current.setFieldValue('firstName', '');
        formikRef.current.setSubmitting(false);

        enqueueSnackbar(error, {
          variant: 'error',
        });

        resetRecaptcha();
      }
    },
    [enqueueSnackbar, navigate, t, modalOpen],
  );

  return (
    <>
      <Header showLogo={false} />
      <Backdrop
        className={classes.backdrop}
        open={searching}
        onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Main>
        <>
          <div className={classes.logoContainer}>
            <img
              src={CheckinLogo}
              alt="logo checkin"
              className={classes.logo}
            />
          </div>
          <SearchForm
            ref={{formikRef, recaptchaRef}}
            handleSubmit={data => {
              handleSubmit(data);
            }}
            handleCancel={() => {
              setModalOpen(false);
              formikRef.current.setFieldValue('firstName', '');
            }}
            modalOpen={modalOpen}
          />
        </>
      </Main>
      <Footer />
    </>
  );
};

export default Search;
