/* eslint-disable react/jsx-props-no-spreading */
import React, {useEffect} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {encode as btoa} from 'base-64';

import Home from 'pages/home';
import Search from 'pages/search';
import Survey from 'pages/survey';
import Register from 'pages/register';
import QRCode from 'pages/qrcode';

import {NotFound} from './partials/notFound';
import {Layout} from './partials/layout';

const RedirectParamsDemo = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const paramsDemo = btoa(
      JSON.stringify({
        id: '4441',
        senha: 'Hotel@4441',
        localizador: '4441ID13965705',
        chavequarto: 34054210,
        guidquarto: '4441-ROL-DET_06598303',
        chavehospede: '23277385',
      }),
    );

    navigate(`/check-in/${paramsDemo}`, {replace: true});
  }, [navigate]);

  return null;
};

const RedirectSearch = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/search`, {replace: true});
  }, [navigate]);

  return null;
};

const RedirectCheckin = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.token) {
      navigate(`/check-in/${params.token}`, {replace: true});
    } else {
      navigate(`/check-in`, {replace: true});
    }
  }, [navigate, params.token]);

  return null;
};

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/search" element={<Search />}>
          <Route path=":identifier" element={<Search />} />
        </Route>

        <Route path="/check-in" element={<Home />}>
          <Route path=":token" element={<Home />} />
        </Route>

        <Route path="/home" element={<RedirectCheckin />}>
          <Route path=":token" element={<RedirectCheckin />} />
        </Route>

        <Route path="/survey" element={<Survey />}>
          <Route path=":token" element={<Survey />} />
        </Route>

        <Route path="/register" element={<Register />}>
          <Route path=":token" element={<Register />} />
        </Route>

        <Route path="/qrcode" element={<QRCode />} />

        {process.env.NODE_ENV === 'development' ? (
          <Route index path="/" element={<RedirectParamsDemo />} />
        ) : (
          <Route index path="/" element={<RedirectSearch />} />
        )}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
