import {
  BRAZIL_IBGE_CODE,
  COUNTRY_OPTIONS,
  STATE_OPTIONS,
  CITY_OPTIONS,
} from 'helpers/constants';
import {isValid, differenceInYears, parse} from 'date-fns';

import i18n from 'locale/i18n';

import _ from 'lodash';
import MD5 from 'crypto-js/md5';

export const removeSpecialChars = str =>
  str ? String(str).replace(/[^a-zA-Z0-9]/g, '') : null;

export const INITIAL_VALUES = {
  // PERSONAL INFORMATION
  email: '',
  firstName: '',
  lastName: '',
  birthday: null,
  ageQualify: null,
  genre: '',
  nationality: '',
  nationalityCode: null,
  maritalStatus: '',
  documentType: '',
  document: '',
  idCard: '',
  issuer: '',
  occupation: '',
  occupationCode: null,
  // ADDRESS
  country: '',
  countryCode: null,
  zipCode: '',
  city: '',
  cityCode: null,
  state: '',
  stateCode: null,
  address: '',
  number: '',
  neighborhood: '',
  complement: '',
  phone: '',
  cellPhone: '',
  // TRAVEL INFORMATION
  purposeTrip: null,
  transportation: null,
  licensePlate: null,

  lastDepartureCountry: '',
  lastDepartureCountryCode: null,
  lastDepartureState: '',
  lastDepartureStateCode: null,
  lastDepartureCity: '',
  lastDepartureCityCode: null,
  nextDestinationCountry: '',
  nextDestinationCountryCode: null,
  nextDestinationState: '',
  nextDestinationStateCode: null,
  nextDestinationCity: '',
  nextDestinationCityCode: null,
};

export const getMainGuestData = (initialState, roomId) => {
  try {
    let mainGuestData = null;
    const findRoom = initialState.rooms.find(r => r.id === roomId);
    if (findRoom) {
      const findMainGuest = findRoom.guests.find(g => g.mainGuest === true);
      mainGuestData = findMainGuest;
    }
    return mainGuestData;
  } catch (e) {
    return null;
  }
};

export const getGuestData = (initialState, guestId) => {
  let guestData = null;
  initialState.rooms.forEach(r => {
    r.guests.forEach(g => {
      if (g.id === guestId) {
        guestData = {roomId: r.id, guest: {...g}};
      }
    });
  });
  return guestData;
};

const findCountry = (countryName, countryCode, nationality = false) => {
  const country = COUNTRY_OPTIONS.find(
    c =>
      String(nationality ? c.nationalityName : c.name).toUpperCase() ===
        String(countryName).toUpperCase() ||
      String(
        nationality ? c.nationalityNameInternational : c.nameInternational,
      ).toUpperCase() === String(countryName).toUpperCase() ||
      parseInt(c.ibge, 10) === parseInt(countryCode, 10),
  );
  if (country) {
    return {
      label: nationality ? country.nationalityName : country.name,
      value: country.ibge,
    };
  }

  return {
    label: null,
    value: null,
  };
};

const findState = (countryCode, stateName, stateCode) => {
  if (countryCode && countryCode === BRAZIL_IBGE_CODE) {
    return STATE_OPTIONS.find(
      c =>
        String(c.label).toUpperCase() === String(stateName).toUpperCase() ||
        parseInt(c.value, 10) === parseInt(stateCode, 10),
    );
  }
  return {
    label: stateCode ? stateName : null,
    value: stateCode,
  };
};

const findCity = (countryCode, cityName, cityCode) => {
  if (countryCode && countryCode === BRAZIL_IBGE_CODE) {
    return CITY_OPTIONS.find(
      c =>
        String(c.label).toUpperCase() === String(cityName).toUpperCase() ||
        parseInt(c.value, 10) === parseInt(cityCode, 10),
    );
  }
  return {
    label: cityCode ? cityName : null,
    value: cityCode,
  };
};

const parseInteger = num =>
  Number.isNaN(parseInt(num, 10)) ? null : parseInt(num, 10);

export const transformGuestToFormState = guest => {
  const countryFind = findCountry(guest.country, guest.countryCode, false);
  const nationalityFind = findCountry(
    guest.nationality,
    guest.nationalityCode,
    true,
  );

  const stateFind = findState(guest.countryCode, guest.state, guest.stateCode);
  const cityFind = findCity(guest.countryCode, guest.city, guest.cityCode);

  const countryFindLastDeparture = findCountry(
    guest.lastDepartureCountry,
    guest.lastDepartureCountryCode,
    false,
  );

  const stateFindLastDeparture = findState(
    guest.lastDepartureCountryCode,
    guest.lastDepartureState,
    guest.lastDepartureStateCode,
  );

  const cityFindLastDeparture = findCity(
    guest.lastDepartureCountryCode,
    guest.lastDepartureCity,
    guest.lastDepartureCityCode,
  );

  const countryFindNextDestination = findCountry(
    guest.nextDestinationCountry,
    guest.nextDestinationCountryCode,
    false,
  );

  const stateFindNextDestination = findState(
    guest.nextDestinationCountryCode,
    guest.nextDestinationState,
    guest.nextDestinationStateCode,
  );

  const cityFindNextDestination = findCity(
    guest.nextDestinationCountryCode,
    guest.nextDestinationCity,
    guest.nextDestinationCityCode,
  );

  return {
    ...INITIAL_VALUES,
    mainGuest: guest.mainGuest,
    email: guest.email,
    firstName: guest.firstName ? String(guest.firstName).toUpperCase() : '',
    lastName: guest.lastName ? String(guest.lastName).toUpperCase() : '',
    birthday: guest.birthday,
    ageQualify: guest.ageQualify,
    genre: guest.genre,
    nationality: nationalityFind ? nationalityFind.label : null,
    nationalityCode: nationalityFind ? nationalityFind.value : null,
    maritalStatus: guest.maritalStatus,
    documentType: guest.documentType,
    document: removeSpecialChars(guest.document),
    idCard: guest.idCard,
    issuer: guest.issuer,
    occupation: guest.occupation,
    occupationCode: guest.occupationCode,
    // ADDRESS
    country: countryFind ? countryFind.label : null,
    countryCode: countryFind ? countryFind.value : null,
    zipCode: guest.zipCode,
    city: cityFind ? cityFind.label : null,
    cityCode: cityFind ? parseInteger(cityFind.value) : null,
    state: stateFind ? stateFind.label : null,
    stateCode: stateFind ? parseInteger(stateFind.value) : null,
    address: guest.address,
    number: guest.number,
    neighborhood: guest.neighborhood,
    complement: guest.complement,
    phone: guest.phone,
    cellPhone: guest.cellPhone,
    // TRAVEL INFORMATION
    purposeTrip: guest.purposeTrip,
    transportation: guest.transportation,
    licensePlate: guest.licensePlate,

    lastDepartureCountry: countryFindLastDeparture
      ? countryFindLastDeparture.label
      : null,
    lastDepartureCountryCode: countryFindLastDeparture
      ? countryFindLastDeparture.value
      : null,
    lastDepartureState: stateFindLastDeparture
      ? stateFindLastDeparture.label
      : null,
    lastDepartureStateCode: stateFindLastDeparture
      ? parseInteger(stateFindLastDeparture.value)
      : null,
    lastDepartureCity: cityFindLastDeparture
      ? cityFindLastDeparture.label
      : null,
    lastDepartureCityCode: cityFindLastDeparture
      ? parseInteger(cityFindLastDeparture.value)
      : null,
    nextDestinationCountry: countryFindNextDestination
      ? countryFindNextDestination.label
      : null,
    nextDestinationCountryCode: countryFindNextDestination
      ? countryFindNextDestination.value
      : null,
    nextDestinationState: stateFindNextDestination
      ? stateFindNextDestination.label
      : null,
    nextDestinationStateCode: stateFindNextDestination
      ? parseInteger(stateFindNextDestination.value)
      : null,
    nextDestinationCity: cityFindNextDestination
      ? cityFindNextDestination.label
      : null,
    nextDestinationCityCode: cityFindNextDestination
      ? parseInteger(cityFindNextDestination.value)
      : null,
    files: guest.files || [],
    membership: guest.membership || '',
  };
};

export const filterMainGuestData = mainGuestData => {
  const data = transformGuestToFormState(mainGuestData);
  return _.pick(data, [
    'country',
    'countryCode',
    'zipCode',
    'city',
    'cityCode',
    'state',
    'stateCode',
    'address',
    'number',
    'neighborhood',
    'complement',
    'phone',
    'cellPhone',
    // TRAVEL INFORMATION
    'purposeTrip',
    'transportation',
    'licensePlate',
    'lastDepartureCountry',
    'lastDepartureCountryCode',
    'lastDepartureState',
    'lastDepartureStateCode',
    'lastDepartureCity',
    'lastDepartureCityCode',
    'nextDestinationCountry',
    'nextDestinationCountryCode',
    'nextDestinationState',
    'nextDestinationStateCode',
    'nextDestinationCity',
  ]);
};

export const filterUseInformationGuest = guest => {
  const data = transformGuestToFormState(guest);
  return _.pickBy(data, _.identity);
};

export const mergeFormValues = (values, valuesToMerge) =>
  _.assign(values, _.pick(valuesToMerge, _.keys(values)));

export const getGuestOptions = initialState => {
  if (!initialState) {
    return [];
  }
  const guestOptions = initialState.rooms.map(r => ({
    ...r,
    guests: r.guests.map((g, index) => ({
      id: g.id,
      name: g.name || `${i18n.t('form.companion')} ${index}`,
      email: g.email,
      roomId: r.id,
      checkinOK: g.checkinOK,
    })),
  }));
  return guestOptions;
};

export const mapFormValues = values => {
  const mapValues = {
    dsnome: 'firstName',
    dssobrenome: 'lastName',
    dtnascimento: 'birthday',
    cdagequalify: 'ageQualify',
    flsexo: 'genre',
    dsnacionalidade: 'nationality',
    idnacionalidade: 'nationalityCode',
    flestadocivil: 'maritalStatus',
    idtipodoc: 'documentType',
    dsnumerodoc: 'document',
    dsorgao: 'issuer',
    iddoctitular: 'idCard',
    dsprofissao: 'occupation',
    cdprofissao: 'occupationCode',
    // ADDRESS
    dspais: 'country',
    idpais: 'countryCode',
    idcep: 'zipCode',
    dsestado: 'state',
    idestado: 'stateCode',
    dscidade: 'city',
    idcidade: 'cityCode',
    dsendereco: 'address',
    dsnumero: 'number',
    dsbairro: 'neighborhood',
    dscomplemento: 'complement',
    dsfoneres: 'phone',
    dscelular: 'cellPhone',
    // TRAVEL INFORMATION
    idmotivoviagem: 'purposeTrip',
    idmeiotransp: 'transportation',
    nmplaca: 'licensePlate',
    dspaisorig: 'lastDepartureCountry',
    idpaisorig: 'lastDepartureCountryCode',
    dsestadoorig: 'lastDepartureState',
    idestadoorig: 'lastDepartureStateCode',
    dscidadeorig: 'lastDepartureCity',
    idcidadeorig: 'lastDepartureCityCode',
    dspaisdest: 'nextDestinationCountry',
    idpaisdest: 'nextDestinationCountryCode',
    dsestadodest: 'nextDestinationState',
    idestadodest: 'nextDestinationStateCode',
    dscidadedest: 'nextDestinationCity',
    idcidadedest: 'nextDestinationCityCode',
    files: 'files',
    membership: 'dsrewardscode',
  };
  const tmp = [];
  values.forEach(value => {
    if (Object.hasOwnProperty.call(mapValues, value)) {
      tmp.push(mapValues[value]);
    }
  });
  return tmp;
};

export const generateCode = () =>
  String(
    Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15),
  ).toUpperCase();

export const isUUIDV4 = uuidv4 => {
  const v4Regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return v4Regex.test(uuidv4);
};

export const generateEmail = ({guestId}) => {
  const id = isUUIDV4(guestId) || !guestId ? generateCode() : guestId;
  const email = MD5(`${id}`).toString();
  return `${email}@desbravadorcheckin.com.br`;
};

export const calcAge = birthday => {
  if (!birthday) {
    return null;
  }
  const date = parse(birthday, 'dd/MM/yyyy', new Date());
  return isValid(date) ? differenceInYears(new Date(), date) : null;
};
