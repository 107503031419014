/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, forwardRef} from 'react';
import {
  Button,
  Link,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {TermsEN} from './termsEN';
import {TermsPT} from './termsPT';
import {TermsES} from './termsES';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export const TermsModal = () => {
  const [open, setOpen] = useState(false);
  const {t, i18n} = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const lng = i18n.language;

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={e => {
          e.preventDefault();
          handleClickOpen();
        }}>
        {t('form.readTerms')}
      </Link>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
        fullWidth>
        <DialogTitle>{t('form.termosOfUse')}</DialogTitle>
        <DialogContent>
          {lng === 'pt' ? <TermsPT /> : null}
          {lng === 'es' ? <TermsES /> : null}
          {lng !== 'pt' && lng !== 'es' ? <TermsEN /> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
