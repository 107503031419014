import React from 'react';
import PropTypes from 'prop-types';
import {Done} from '@material-ui/icons';
import {Grid, Box, Typography, Button, Divider} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {useStyles} from './styles';

export const RegisterDone = ({onPress}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5" component="p" color="primary" align="center">
          {t('register.successTitle')}
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="textPrimary"
          align="center">
          {t('register.successDescription')}
        </Typography>
        <Box justifyContent="center" display="flex">
          <Done fontSize="large" color="primary" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider />
        <div className={classes.root}>
          <Button
            color="primary"
            onClick={() => {
              onPress();
            }}
            variant="outlined">
            {t('register.new')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

RegisterDone.propTypes = {
  onPress: PropTypes.func.isRequired,
};
