import React from 'react';

import {useTranslation} from 'react-i18next';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import AppRoutes from 'routes';

import './interceptor';

const localeMap = {
  en: enLocale,
  pt: brLocale,
  es: esLocale,
};

export default function App() {
  const {i18n} = useTranslation();

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[i18n.language]}>
      <AppRoutes />
    </MuiPickersUtilsProvider>
  );
}
