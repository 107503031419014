import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {
  AppBar,
  Toolbar,
  Button,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import i18n from 'locale/i18n';
import LogoCheckin from 'assets/img/checkin-online.png';

import {useStyles} from './styles';

const languages = [
  {code: 'pt', title: 'Portuguese'},
  {code: 'es', title: 'Spanish'},
  {code: 'en', title: 'English'},
  {code: 'ja', title: 'Japanese'},
];

export const Header = ({showLogo}) => {
  const classes = useStyles();
  const [languageMenu, setLanguageMenu] = useState(null);

  const handleLanguageIconClick = event => {
    setLanguageMenu(event.currentTarget);
  };

  return (
    <AppBar position="fixed" className={classes.navbar}>
      <Toolbar className={showLogo ? classes.toolbar : classes.toolbarMini}>
        <div className={classes.title}>
          {showLogo ? (
            <img
              src={LogoCheckin}
              alt="logo checkin"
              className={classes.logoCheckin}
            />
          ) : null}
        </div>

        <Tooltip title="Language" enterDelay={300}>
          <Button color="inherit" onClick={handleLanguageIconClick}>
            <span className={classes.language}>{i18n.language}</span>
            <ExpandMoreIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Menu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={() => {
            setLanguageMenu(null);
          }}>
          {languages.map(lng => (
            <MenuItem
              component="button"
              data-no-link="true"
              key={lng.code}
              selected={false}
              onClick={() => {
                setLanguageMenu(null);
                i18n.changeLanguage(lng.code);
              }}
              lang={lng.code}
              hrefLang={lng.code}>
              {lng.title}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  showLogo: true,
};

Header.propTypes = {
  showLogo: PropTypes.bool,
};
