import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {},
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  heading: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

const Header = ({logo}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root} p={0}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography className={classes.heading} component="h4">
            {t('register.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="logo hotel" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default Header;
