import {BRAZIL_IBGE_CODE, STATE_OPTIONS} from 'helpers/constants';

export const cepSearch = zipcode =>
  new Promise((resolve, reject) => {
    const valida = /^[0-9]{8}$/;
    const zipcodeSearch = zipcode ? String(zipcode).replace(/\D/g, '') : '';

    if (zipcodeSearch !== '' && valida.test(zipcodeSearch)) {
      fetch(`https://viacep.com.br/ws/${zipcodeSearch}/json/`, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(data => {
          if (!data.erro) {
            const findState = STATE_OPTIONS.find(s => s.initials === data.uf);

            const res = {
              countryCode: BRAZIL_IBGE_CODE,
              country: 'Brasil',
              neighborhood: data.bairro || '',
              complement: data.complemento || '',
              cityCode: data.ibge ? data.ibge : null,
              city: data.localidade || '',
              address: data.logradouro || '',
              state: findState ? findState.label : '',
              stateCode: findState ? findState.value : null,
            };
            resolve(res);
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    } else {
      reject();
    }
  });
