import React from 'react';
import PropTypes from 'prop-types';

import {Container} from '@material-ui/core';

import {useStyles} from './styles';

export const Main = ({children}) => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="md" className={classes.container}>
        {children}
      </Container>
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.element.isRequired,
};
