export const BRAZIL_IBGE_CODE = '01058';

export const COUNTRY_OPTIONS = [
  {
    iso: 'BR',
    name: 'BRASIL',
    iso31661: 'BRA',
    nameInternational: 'Brazil',
    ibge: '01058',
    nationalityName: 'BRASILEIRA',
    nationalityNameInternational: 'Brazilian',
  },
  {
    iso: 'AF',
    name: 'AFEGANISTÃO',
    iso31661: 'AFG',
    nameInternational: 'Afghanistan',
    ibge: '00132',
    nationalityName: 'AFEGÃ',
    nationalityNameInternational: 'Afghan',
  },
  {
    iso: 'ZA',
    name: 'ÁFRICA DO SUL',
    iso31661: 'ZAF',
    nameInternational: 'South Africa',
    ibge: '07560',
    nationalityName: 'SUL-AFRICANA',
    nationalityNameInternational: 'South African',
  },
  {
    iso: 'AL',
    name: 'ALBANIA, REPÚBLICA  DA',
    iso31661: 'ALB',
    nameInternational: 'Albania',
    ibge: '00175',
    nationalityName: 'ALBANESA',
    nationalityNameInternational: 'Albanian',
  },
  {
    iso: 'DE',
    name: 'ALEMANHA',
    iso31661: 'DEU',
    nameInternational: 'Germany',
    ibge: '00230',
    nationalityName: 'ALEMÃ',
    nationalityNameInternational: 'German',
  },
  {
    iso: 'AD',
    name: 'ANDORRA',
    iso31661: 'AND',
    nameInternational: 'Andorra',
    ibge: '00370',
    nationalityName: 'ANDORRANA',
    nationalityNameInternational: 'Andorran',
  },
  {
    iso: 'AO',
    name: 'ANGOLA',
    iso31661: 'AGO',
    nameInternational: 'Angola',
    ibge: '00400',
    nationalityName: 'ANGOLANA',
    nationalityNameInternational: 'Angolan',
  },
  {
    iso: 'AI',
    name: 'ANGUILLA',
    iso31661: 'AIA',
    nameInternational: 'Anguilla',
    ibge: '00418',
    nationalityName: 'ANGUILANA',
    nationalityNameInternational: 'Anguillan',
  },
  {
    iso: 'AG',
    name: 'ANTIGUA E BARBUDA',
    iso31661: 'ATG',
    nameInternational: 'Antigua and Barbuda',
    ibge: '00434',
    nationalityName: 'ANTIGUANA',
    nationalityNameInternational: 'Antiguan or Barbudan',
  },
  // {
  //   iso: 'AN',
  //   name: 'ANTILHAS HOLANDESAS',
  //   iso31661: 'ANT',
  //   nameInternational: 'Netherlands Antilles',
  //   ibge: '00477',
  //   nationalityName: 'HOLANDESA',
  //   nationalityNameInternational: 'Dutch, Netherlandic',
  // },
  {
    iso: 'SA',
    name: 'ARÁBIA SAUDITA',
    iso31661: 'SAU',
    nameInternational: 'Saudi Arabia',
    ibge: '00531',
    nationalityName: 'ÁRABE',
    nationalityNameInternational: 'Saudi, Saudi Arabian',
  },
  {
    iso: 'DZ',
    name: 'ARGÉLIA',
    iso31661: 'DZA',
    nameInternational: 'Algeria',
    ibge: '00590',
    nationalityName: 'ARGELINA',
    nationalityNameInternational: 'Algerian',
  },
  {
    iso: 'AR',
    name: 'ARGENTINA',
    iso31661: 'ARG',
    nameInternational: 'Argentina',
    ibge: '00639',
    nationalityName: 'ARGENTINA',
    nationalityNameInternational: 'Argentine',
  },
  {
    iso: 'AM',
    name: 'ARMÉNIA, REPÚBLICA DA',
    iso31661: 'ARM',
    nameInternational: 'Armenia',
    ibge: '00647',
    nationalityName: 'ARMÊNIA',
    nationalityNameInternational: 'Armenian',
  },
  {
    iso: 'AW',
    name: 'ARUBA',
    iso31661: 'ABW',
    nameInternational: 'Aruba',
    ibge: '00655',
    nationalityName: 'ARUBANA',
    nationalityNameInternational: 'Aruban',
  },
  {
    iso: 'AU',
    name: 'AUSTRALIA',
    iso31661: 'AUS',
    nameInternational: 'Australia',
    ibge: '00698',
    nationalityName: 'AUSTRALIANA',
    nationalityNameInternational: 'Australian',
  },
  {
    iso: 'AT',
    name: 'AUSTRIA',
    iso31661: 'AUT',
    nameInternational: 'Austria',
    ibge: '00728',
    nationalityName: 'AUSTRÍACA',
    nationalityNameInternational: 'Austrian',
  },
  {
    iso: 'AZ',
    name: 'AZERBAIJAO, REPÚBLICA DO',
    iso31661: 'AZE',
    nameInternational: 'Azerbaijan',
    ibge: '00736',
    nationalityName: 'AZERI',
    nationalityNameInternational: 'Azerbaijani, Azeri',
  },
  {
    iso: 'BS',
    name: 'BAHAMAS, ILHAS',
    iso31661: 'BHS',
    nameInternational: 'Bahamas',
    ibge: '00779',
    nationalityName: 'BAHAMENSE',
    nationalityNameInternational: 'Bahamian',
  },
  {
    iso: 'BH',
    name: 'BAHREIN, ILHAS',
    iso31661: 'BHR',
    nameInternational: 'Bahrain',
    ibge: '00809',
    nationalityName: 'BARENITA',
    nationalityNameInternational: 'Bahraini',
  },
  {
    iso: 'BD',
    name: 'BANGLADESH',
    iso31661: 'BGD',
    nameInternational: 'Bangladesh',
    ibge: '00817',
    nationalityName: 'BENGALESA',
    nationalityNameInternational: 'Bangladeshi',
  },
  {
    iso: 'BB',
    name: 'BARBADOS',
    iso31661: 'BRB',
    nameInternational: 'Barbados',
    ibge: '00833',
    nationalityName: 'BARBADIANA',
    nationalityNameInternational: 'Barbadian',
  },
  {
    iso: 'BY',
    name: 'BELARUS, REPÚBLICA DA',
    iso31661: 'BLR',
    nameInternational: 'Belarus',
    ibge: '00850',
    nationalityName: 'BIELO-RUSSIA',
    nationalityNameInternational: 'Belarusian',
  },
  {
    iso: 'BE',
    name: 'BÉLGICA',
    iso31661: 'BEL',
    nameInternational: 'Belgium',
    ibge: '00876',
    nationalityName: 'BELGA',
    nationalityNameInternational: 'Belgian',
  },
  {
    iso: 'BZ',
    name: 'BELIZE',
    iso31661: 'BLZ',
    nameInternational: 'Belize',
    ibge: '00884',
    nationalityName: 'BELIZENHA',
    nationalityNameInternational: 'Belizean',
  },
  {
    iso: 'BJ',
    name: 'BENIN',
    iso31661: 'BEN',
    nameInternational: 'Benin',
    ibge: '02291',
    nationalityName: 'BENINENSE',
    nationalityNameInternational: 'Beninese, Beninois',
  },
  {
    iso: 'BM',
    name: 'BERMUDAS',
    iso31661: 'BMU',
    nameInternational: 'Bermuda',
    ibge: '00906',
    nationalityName: 'BERMUDENSE',
    nationalityNameInternational: 'Bermudian, Bermudan',
  },
  {
    iso: 'BW',
    name: 'BOTSUANA',
    iso31661: 'BWA',
    nameInternational: 'Botswana',
    ibge: '01015',
    nationalityName: 'BOTSUANA',
    nationalityNameInternational: 'Motswana, Botswanan',
  },
  {
    iso: 'BN',
    name: 'BRUNEI',
    iso31661: 'BRN',
    nameInternational: 'Brunei Darussalam',
    ibge: '01082',
    nationalityName: 'BRUNEIANA',
    nationalityNameInternational: 'Bruneian',
  },
  {
    iso: 'BG',
    name: 'BULGARIA, REPÚBLICA DA',
    iso31661: 'BGR',
    nameInternational: 'Bulgaria',
    ibge: '01112',
    nationalityName: 'BÚLGARA',
    nationalityNameInternational: 'Bulgarian',
  },
  {
    iso: 'BF',
    name: 'BURKINA FASO',
    iso31661: 'BFA',
    nameInternational: 'Burkina Faso',
    ibge: '00310',
    nationalityName: 'BURQUINENSE',
    nationalityNameInternational: 'Burkinabé',
  },
  {
    iso: 'BI',
    name: 'BURUNDI',
    iso31661: 'BDI',
    nameInternational: 'Burundi',
    ibge: '01155',
    nationalityName: 'BURUNDIANA',
    nationalityNameInternational: 'Burundian',
  },
  {
    iso: 'BT',
    name: 'BUTÃO',
    iso31661: 'BTN',
    nameInternational: 'Bhutan',
    ibge: '01198',
    nationalityName: 'BUTANESA',
    nationalityNameInternational: 'Bhutanese',
  },
  {
    iso: 'CV',
    name: 'CABO VERDE, REPÚBLICA DE',
    iso31661: 'CPV',
    nameInternational: 'Cabo Verde',
    ibge: '01279',
    nationalityName: 'CABO-VERDIANA',
    nationalityNameInternational: 'Cabo Verdean',
  },
  {
    iso: 'CM',
    name: 'CAMAROES',
    iso31661: 'CMR',
    nameInternational: 'Cameroon',
    ibge: '01457',
    nationalityName: 'CAMARONENSE',
    nationalityNameInternational: 'Cameroonian',
  },
  {
    iso: 'KH',
    name: 'CAMBOJA',
    iso31661: 'KHM',
    nameInternational: 'Cambodia',
    ibge: '01414',
    nationalityName: 'CAMBODJANA',
    nationalityNameInternational: 'Cambodian',
  },
  {
    iso: 'CA',
    name: 'CANADÁ',
    iso31661: 'CAN',
    nameInternational: 'Canada',
    ibge: '01490',
    nationalityName: 'CANADENSE',
    nationalityNameInternational: 'Canadian',
  },
  {
    iso: 'QA',
    name: 'CATAR',
    iso31661: 'QAT',
    nameInternational: 'Qatar',
    ibge: '01546',
    nationalityName: 'CATARENSE',
    nationalityNameInternational: 'Qatari',
  },
  {
    iso: 'KY',
    name: 'CAYMAN, ILHAS',
    iso31661: 'CYM',
    nameInternational: 'Cayman Islands',
    ibge: '01376',
    nationalityName: 'CAIMANESA',
    nationalityNameInternational: 'Caymanian',
  },
  {
    iso: 'KZ',
    name: 'CAZAQUISTÃO, REPÚBLICA DO',
    iso31661: 'KAZ',
    nameInternational: 'Kazakhstan',
    ibge: '01538',
    nationalityName: 'CAZAQUE',
    nationalityNameInternational: 'Kazakhstani, Kazakh',
  },
  {
    iso: 'TD',
    name: 'CHADE, REPÚBLICA',
    iso31661: 'TCD',
    nameInternational: 'Chad',
    ibge: '07889',
    nationalityName: 'CHADINENSE',
    nationalityNameInternational: 'Chadian',
  },
  {
    iso: 'CL',
    name: 'CHILE',
    iso31661: 'CHL',
    nameInternational: 'Chile',
    ibge: '01589',
    nationalityName: 'CHILENA',
    nationalityNameInternational: 'Chilean',
  },
  {
    iso: 'CN',
    name: 'CHINA, REPÚBLICA POPULAR',
    iso31661: 'CHN',
    nameInternational: 'China',
    ibge: '01600',
    nationalityName: 'CHINESA',
    nationalityNameInternational: 'Chinese',
  },
  {
    iso: 'CY',
    name: 'CHIPRE',
    iso31661: 'CYP',
    nameInternational: 'Cyprus',
    ibge: '01635',
    nationalityName: 'CIPRIOTA',
    nationalityNameInternational: 'Cypriot',
  },
  {
    iso: 'CX',
    name: 'CHRISTMAS, ILHA (NAVIDAD)',
    iso31661: 'CXR',
    nameInternational: 'Christmas Island',
    ibge: '05118',
    nationalityName: 'DA ILHA CHRISTMAS',
    nationalityNameInternational: 'Christmas Island',
  },
  {
    iso: 'SG',
    name: 'CINGAPURA',
    iso31661: 'SGP',
    nameInternational: 'Singapore',
    ibge: '07412',
    nationalityName: 'CINGAPURIANA',
    nationalityNameInternational: 'Singaporean',
  },
  {
    iso: 'CC',
    name: 'COCOS(KEELING),ILHAS',
    iso31661: 'CCK',
    nameInternational: 'Cocos (Keeling) Islands',
    ibge: '01651',
    nationalityName: 'DAS ILHAS COCOS',
    nationalityNameInternational: 'Cocos Island',
  },
  {
    iso: 'CO',
    name: 'COLÔMBIA',
    iso31661: 'COL',
    nameInternational: 'Colombia',
    ibge: '01694',
    nationalityName: 'COLOMBIANA',
    nationalityNameInternational: 'Colombian',
  },
  {
    iso: 'KM',
    name: 'COMORES, ILHAS',
    iso31661: 'COM',
    nameInternational: 'Comoros',
    ibge: '01732',
    nationalityName: 'COMORENSE',
    nationalityNameInternational: 'Comoran, Comorian',
  },
  {
    iso: 'CD',
    name: 'CONGO, REPÚBLICA DEMOCRATICA DO',
    iso31661: 'COD',
    nameInternational: 'Congo (Democratic Republic of the)',
    ibge: '08885',
    nationalityName: 'CONGOLESA',
    nationalityNameInternational: 'Congolese',
  },
  {
    iso: 'CG',
    name: 'CONGO, REPÚBLICA DO',
    iso31661: 'COG',
    nameInternational: 'Congo (Republic of the)',
    ibge: '01775',
    nationalityName: 'CONGOLESA',
    nationalityNameInternational: 'Congolese',
  },
  {
    iso: 'CK',
    name: 'COOK, ILHAS',
    iso31661: 'COK',
    nameInternational: 'Cook Islands',
    ibge: '01830',
    nationalityName: 'COOKENSE',
    nationalityNameInternational: 'Cook Island',
  },
  {
    iso: 'KP',
    name: 'COREIA (DO NORTE), REP.POP.DEMOCRATICA',
    iso31661: 'PRK',
    nameInternational: "Korea (Democratic People's Republic of)",
    ibge: '01872',
    nationalityName: 'NORTE COREANA',
    nationalityNameInternational: 'North Korean',
  },
  {
    iso: 'KR',
    name: 'COREIA (DO SUL), REPÚBLICA DA',
    iso31661: 'KOR',
    nameInternational: 'Korea (Republic of)',
    ibge: '01902',
    nationalityName: 'SUL COREANA',
    nationalityNameInternational: 'South Korean',
  },
  {
    iso: 'CI',
    name: 'COSTA DO MARFIM',
    iso31661: 'CIV',
    nameInternational: "Côte d'Ivoire",
    ibge: '01937',
    nationalityName: 'COSTA-MARFINENSE',
    nationalityNameInternational: 'Ivorian',
  },
  {
    iso: 'CR',
    name: 'COSTA RICA',
    iso31661: 'CRI',
    nameInternational: 'Costa Rica',
    ibge: '01961',
    nationalityName: 'COSTA-RIQUENSE',
    nationalityNameInternational: 'Costa Rican',
  },
  {
    iso: 'HR',
    name: 'CROÁCIA (REPÚBLICA DA)',
    iso31661: 'HRV',
    nameInternational: 'Croatia',
    ibge: '01953',
    nationalityName: 'CROATA',
    nationalityNameInternational: 'Croatian',
  },
  {
    iso: 'CU',
    name: 'CUBA',
    iso31661: 'CUB',
    nameInternational: 'Cuba',
    ibge: '01996',
    nationalityName: 'CUBANA',
    nationalityNameInternational: 'Cuban',
  },
  {
    iso: 'DK',
    name: 'DINAMARCA',
    iso31661: 'DNK',
    nameInternational: 'Denmark',
    ibge: '02321',
    nationalityName: 'DINAMARQUÊSA',
    nationalityNameInternational: 'Danish',
  },
  {
    iso: 'DJ',
    name: 'DJIBOUTI',
    iso31661: 'DJI',
    nameInternational: 'Djibouti',
    ibge: '07838',
    nationalityName: 'DJIBUTIENSE',
    nationalityNameInternational: 'Djiboutian',
  },
  {
    iso: 'DM',
    name: 'DOMINICA,ILHA',
    iso31661: 'DMA',
    nameInternational: 'Dominica',
    ibge: '02356',
    nationalityName: 'DOMINIQUESA',
    nationalityNameInternational: 'Dominican',
  },
  {
    iso: 'EG',
    name: 'EGITO',
    iso31661: 'EGY',
    nameInternational: 'Egypt',
    ibge: '02402',
    nationalityName: 'EGÍPSIA',
    nationalityNameInternational: 'Egyptian',
  },
  {
    iso: 'SV',
    name: 'EL SALVADOR',
    iso31661: 'SLV',
    nameInternational: 'El Salvador',
    ibge: '06874',
    nationalityName: 'SALVADORENHA',
    nationalityNameInternational: 'Salvadoran',
  },
  {
    iso: 'AE',
    name: 'EMIRADOS ARABES UNIDOS',
    iso31661: 'ARE',
    nameInternational: 'United Arab Emirates',
    ibge: '02445',
    nationalityName: 'ÁRABE',
    nationalityNameInternational: 'Emirati, Emirian, Emiri',
  },
  {
    iso: 'EC',
    name: 'EQUADOR',
    iso31661: 'ECU',
    nameInternational: 'Ecuador',
    ibge: '02399',
    nationalityName: 'EQUATORIANA',
    nationalityNameInternational: 'Ecuadorian',
  },
  {
    iso: 'ER',
    name: 'ERITREIA',
    iso31661: 'ERI',
    nameInternational: 'Eritrea',
    ibge: '02437',
    nationalityName: 'ERITRÉIA',
    nationalityNameInternational: 'Eritrean',
  },
  {
    iso: 'SK',
    name: 'ESLOVACA, REPÚBLICA',
    iso31661: 'SVK',
    nameInternational: 'Slovakia',
    ibge: '02470',
    nationalityName: 'ESLOVACA',
    nationalityNameInternational: 'Slovak',
  },
  {
    iso: 'SI',
    name: 'ESLOVENIA, REPÚBLICA DA',
    iso31661: 'SVN',
    nameInternational: 'Slovenia',
    ibge: '02461',
    nationalityName: 'ESLOVENA',
    nationalityNameInternational: 'Slovenian, Slovene',
  },
  {
    iso: 'US',
    name: 'ESTADOS UNIDOS',
    iso31661: 'USA',
    nameInternational: 'United States of America',
    ibge: '02496',
    nationalityName: 'NORTE AMERICANA',
    nationalityNameInternational: 'American',
  },
  {
    iso: 'EE',
    name: 'ESTÔNIA, REPÚBLICA DA',
    iso31661: 'EST',
    nameInternational: 'Estonia',
    ibge: '02518',
    nationalityName: 'ESTONIANA',
    nationalityNameInternational: 'Estonian',
  },
  {
    iso: 'ET',
    name: 'ETIOPIA',
    iso31661: 'ETH',
    nameInternational: 'Ethiopia',
    ibge: '02534',
    nationalityName: 'ETÍOPE',
    nationalityNameInternational: 'Ethiopian',
  },
  {
    iso: 'FK',
    name: 'FALKLAND (ILHAS MALVINAS)',
    iso31661: 'FLK',
    nameInternational: 'Falkland Islands (Malvinas)',
    ibge: '02550',
    nationalityName: 'FALKLANDESA OU MALVINENSE',
    nationalityNameInternational: 'Falkland Island',
  },
  {
    iso: 'FO',
    name: 'FEROÉ (FAROÉ), ILHAS',
    iso31661: 'FRO',
    nameInternational: 'Faroe Islands',
    ibge: '02593',
    nationalityName: 'FAROENSE',
    nationalityNameInternational: 'Faroese',
  },
  {
    iso: 'FJ',
    name: 'FIJI',
    iso31661: 'FJI',
    nameInternational: 'Fiji',
    ibge: '08702',
    nationalityName: 'FIJIANA',
    nationalityNameInternational: 'Fijian',
  },
  {
    iso: 'PH',
    name: 'FILIPINAS',
    iso31661: 'PHL',
    nameInternational: 'Philippines',
    ibge: '02674',
    nationalityName: 'FILIPINA',
    nationalityNameInternational: 'Philippine, Filipino',
  },
  {
    iso: 'FI',
    name: 'FINLÂNDIA',
    iso31661: 'FIN',
    nameInternational: 'Finland',
    ibge: '02712',
    nationalityName: 'FILANDESA',
    nationalityNameInternational: 'Finnish',
  },
  {
    iso: 'FR',
    name: 'FRANÇA',
    iso31661: 'FRA',
    nameInternational: 'France',
    ibge: '02755',
    nationalityName: 'FRANCESA',
    nationalityNameInternational: 'French',
  },
  {
    iso: 'GA',
    name: 'GABÃO',
    iso31661: 'GAB',
    nameInternational: 'Gabon',
    ibge: '02810',
    nationalityName: 'GABONENSE',
    nationalityNameInternational: 'Gabonese',
  },
  {
    iso: 'GM',
    name: 'GÂMBIA',
    iso31661: 'GMB',
    nameInternational: 'Gambia',
    ibge: '02852',
    nationalityName: 'GAMBIANA',
    nationalityNameInternational: 'Gambian',
  },
  {
    iso: 'GH',
    name: 'GANA',
    iso31661: 'GHA',
    nameInternational: 'Ghana',
    ibge: '02895',
    nationalityName: 'GANENSE',
    nationalityNameInternational: 'Ghanaian',
  },
  {
    iso: 'GE',
    name: 'GEORGIA, REPÚBLICA DA',
    iso31661: 'GEO',
    nameInternational: 'Georgia',
    ibge: '02917',
    nationalityName: 'GEORGEANA',
    nationalityNameInternational: 'Georgian',
  },
  {
    iso: 'GI',
    name: 'GIBRALTAR',
    iso31661: 'GIB',
    nameInternational: 'Gibraltar',
    ibge: '02933',
    nationalityName: 'GIBRALTARIANA',
    nationalityNameInternational: 'Gibraltar',
  },
  {
    iso: 'GD',
    name: 'GRANADA',
    iso31661: 'GRD',
    nameInternational: 'Grenada',
    ibge: '02976',
    nationalityName: 'GRANADIANA',
    nationalityNameInternational: 'Grenadian',
  },
  {
    iso: 'GR',
    name: 'GRÉCIA',
    iso31661: 'GRC',
    nameInternational: 'Greece',
    ibge: '03018',
    nationalityName: 'GREGA',
    nationalityNameInternational: 'Greek, Hellenic',
  },
  {
    iso: 'GL',
    name: 'GROENLÂNDIA',
    iso31661: 'GRL',
    nameInternational: 'Greenland',
    ibge: '03050',
    nationalityName: 'GROENLANDESA',
    nationalityNameInternational: 'Greenlandic',
  },
  {
    iso: 'GP',
    name: 'GUADALUPE',
    iso31661: 'GLP',
    nameInternational: 'Guadeloupe',
    ibge: '03093',
    nationalityName: 'GUADALUPANA',
    nationalityNameInternational: 'Guadeloupe',
  },
  {
    iso: 'GU',
    name: 'GUAM',
    iso31661: 'GUM',
    nameInternational: 'Guam',
    ibge: '03131',
    nationalityName: 'GUAMESA',
    nationalityNameInternational: 'Guamanian, Guambat',
  },
  {
    iso: 'GT',
    name: 'GUATEMALA',
    iso31661: 'GTM',
    nameInternational: 'Guatemala',
    ibge: '03174',
    nationalityName: 'GUATEMALTECA',
    nationalityNameInternational: 'Guatemalan',
  },
  {
    iso: 'GG',
    name: 'GUERNSEY, ILHA DO CANAL (INCLUI ALDERNEY E SARK)',
    iso31661: 'GGY',
    nameInternational: 'Guernsey',
    ibge: '03212',
    nationalityName: 'DA ILHA DO CANAL',
    nationalityNameInternational: 'Channel Island',
  },
  {
    iso: 'GY',
    name: 'GUIANA',
    iso31661: 'GUY',
    nameInternational: 'Guyana',
    ibge: '03379',
    nationalityName: 'GUIANENSE',
    nationalityNameInternational: 'Guyanese',
  },
  {
    iso: 'GF',
    name: 'GUIANA FRANCESA',
    iso31661: 'GUF',
    nameInternational: 'French Guiana',
    ibge: '03255',
    nationalityName: 'GUIANESA',
    nationalityNameInternational: 'French Guianese',
  },
  {
    iso: 'GN',
    name: 'GUINE',
    iso31661: 'GIN',
    nameInternational: 'Guinea',
    ibge: '03298',
    nationalityName: 'GUINEANA',
    nationalityNameInternational: 'Guinean',
  },
  {
    iso: 'GW',
    name: 'GUINE-BISSAU',
    iso31661: 'GNB',
    nameInternational: 'Guinea-Bissau',
    ibge: '03344',
    nationalityName: 'GUINEENSE',
    nationalityNameInternational: 'Bissau-Guinean',
  },
  {
    iso: 'GQ',
    name: 'GUINE-EQUATORIAL',
    iso31661: 'GNQ',
    nameInternational: 'Equatorial Guinea',
    ibge: '03310',
    nationalityName: 'GUINÉU-EQUATORIANA',
    nationalityNameInternational: 'Equatorial Guinean, Equatoguinean',
  },
  {
    iso: 'HT',
    name: 'HAITI',
    iso31661: 'HTI',
    nameInternational: 'Haiti',
    ibge: '03417',
    nationalityName: 'HAITIANA',
    nationalityNameInternational: 'Haitian',
  },
  {
    iso: 'HN',
    name: 'HONDURAS',
    iso31661: 'HND',
    nameInternational: 'Honduras',
    ibge: '03450',
    nationalityName: 'HONDURENHA',
    nationalityNameInternational: 'Honduran',
  },
  {
    iso: 'HK',
    name: 'HONG KONG',
    iso31661: 'HKG',
    nameInternational: 'Hong Kong',
    ibge: '03514',
    nationalityName: 'HONCONGUESA',
    nationalityNameInternational: 'Hong Kong, Hong Kongese',
  },
  {
    iso: 'HU',
    name: 'HUNGRIA, REPÚBLICA DA',
    iso31661: 'HUN',
    nameInternational: 'Hungary',
    ibge: '03557',
    nationalityName: 'HÚNGARA',
    nationalityNameInternational: 'Hungarian, Magyar',
  },
  {
    iso: 'YE',
    name: 'IEMEN',
    iso31661: 'YEM',
    nameInternational: 'Yemen',
    ibge: '03573',
    nationalityName: 'IEMENITA',
    nationalityNameInternational: 'Yemeni',
  },
  {
    iso: 'BO',
    name: 'BOLIVIA',
    iso31661: 'BOL',
    nameInternational: 'Bolivia (Plurinational State of)',
    ibge: '00973',
    nationalityName: 'BOLIVIANA',
    nationalityNameInternational: 'Bolivian',
  },
  {
    iso: 'IN',
    name: 'ÍNDIA',
    iso31661: 'IND',
    nameInternational: 'India',
    ibge: '03611',
    nationalityName: 'INDIANA',
    nationalityNameInternational: 'Indian',
  },
  {
    iso: 'ID',
    name: 'INDONÉSIA',
    iso31661: 'IDN',
    nameInternational: 'Indonesia',
    ibge: '03654',
    nationalityName: 'INDONÉSIA',
    nationalityNameInternational: 'Indonesian',
  },
  {
    iso: 'GB',
    name: 'INGLATERRA (REINO UNIDO)',
    iso31661: 'GBR',
    nameInternational: 'United Kingdom of Great Britain and Northern Ireland',
    ibge: '06289',
    nationalityName: 'INGLESA',
    nationalityNameInternational: 'British, UK',
  },
  {
    iso: 'IR',
    name: 'IRÃ, REPÚBLICA ISLAMICA DO',
    iso31661: 'IRN',
    nameInternational: 'Iran',
    ibge: '03727',
    nationalityName: 'IRANIANA',
    nationalityNameInternational: 'Iranian, Persian',
  },
  {
    iso: 'IQ',
    name: 'IRAQUE',
    iso31661: 'IRQ',
    nameInternational: 'Iraq',
    ibge: '03697',
    nationalityName: 'IRAQUIANA',
    nationalityNameInternational: 'Iraqi',
  },
  {
    iso: 'IE',
    name: 'IRLANDA',
    iso31661: 'IRL',
    nameInternational: 'Ireland',
    ibge: '03751',
    nationalityName: 'IRLANDESA',
    nationalityNameInternational: 'Irish',
  },
  {
    iso: 'IS',
    name: 'ISLÂNDIA',
    iso31661: 'ISL',
    nameInternational: 'Iceland',
    ibge: '03794',
    nationalityName: 'ISLANDESA',
    nationalityNameInternational: 'Icelandic',
  },
  {
    iso: 'IL',
    name: 'ISRAEL',
    iso31661: 'ISR',
    nameInternational: 'Israel',
    ibge: '03832',
    nationalityName: 'ISRAELITA',
    nationalityNameInternational: 'Israeli',
  },
  {
    iso: 'IT',
    name: 'ITÁLIA',
    iso31661: 'ITA',
    nameInternational: 'Italy',
    ibge: '03867',
    nationalityName: 'ITALIANA',
    nationalityNameInternational: 'Italian',
  },
  {
    iso: 'JM',
    name: 'JAMAICA',
    iso31661: 'JAM',
    nameInternational: 'Jamaica',
    ibge: '03913',
    nationalityName: 'JAMAICANA',
    nationalityNameInternational: 'Jamaican',
  },
  {
    iso: 'JP',
    name: 'JAPÃO',
    iso31661: 'JPN',
    nameInternational: 'Japan',
    ibge: '03999',
    nationalityName: 'JAPONESA',
    nationalityNameInternational: 'Japanese',
  },
  // {
  //   iso: 'UM-67',
  //   name: 'JOHNSTON, ILHAS',
  //   iso31661: 'JEY',
  //   nameInternational: 'Isla Johnston',
  //   ibge: '03964',
  //   nationalityName: 'DAS ILHAS JOHNSON',
  //   nationalityNameInternational: 'Johnston',
  // },
  {
    iso: 'JO',
    name: 'JORDÂNIA',
    iso31661: 'JOR',
    nameInternational: 'Jordan',
    ibge: '04030',
    nationalityName: 'JORDANA',
    nationalityNameInternational: 'Jordanian',
  },
  {
    iso: 'KI',
    name: 'KIRIBATI',
    iso31661: 'KIR',
    nameInternational: 'Kiribati',
    ibge: '04111',
    nationalityName: 'QUIRIBATIANA',
    nationalityNameInternational: 'I-Kiribati',
  },
  {
    iso: 'LA',
    name: 'LAOS, REP.POP.DEMOCR.DO',
    iso31661: 'LAO',
    nameInternational: "Lao People's Democratic Republic",
    ibge: '04200',
    nationalityName: 'LAOCIANA',
    nationalityNameInternational: 'Lao, Laotian',
  },
  {
    iso: 'LS',
    name: 'LESOTO',
    iso31661: 'LSO',
    nameInternational: 'Lesotho',
    ibge: '04260',
    nationalityName: 'LESOTIANA',
    nationalityNameInternational: 'Basotho',
  },
  {
    iso: 'LV',
    name: 'LETÔNIA, REPÚBLICA DA',
    iso31661: 'LVA',
    nameInternational: 'Latvia',
    ibge: '04278',
    nationalityName: 'LETÃ',
    nationalityNameInternational: 'Latvian',
  },
  {
    iso: 'LB',
    name: 'LÍBANO',
    iso31661: 'LBN',
    nameInternational: 'Lebanon',
    ibge: '04316',
    nationalityName: 'LIBANESA',
    nationalityNameInternational: 'Lebanese',
  },
  {
    iso: 'LR',
    name: 'LIBÉRIA',
    iso31661: 'LBR',
    nameInternational: 'Liberia',
    ibge: '04340',
    nationalityName: 'LIBERIANA',
    nationalityNameInternational: 'Liberian',
  },
  {
    iso: 'LY',
    name: 'LÍBIA',
    iso31661: 'LBY',
    nameInternational: 'Libya',
    ibge: '04383',
    nationalityName: 'LÍBIA',
    nationalityNameInternational: 'Libyan',
  },
  {
    iso: 'LI',
    name: 'LIECHTENSTEIN',
    iso31661: 'LIE',
    nameInternational: 'Liechtenstein',
    ibge: '04405',
    nationalityName: 'LIECHTENSTEINENSE',
    nationalityNameInternational: 'Liechtenstein',
  },
  {
    iso: 'LT',
    name: 'LITUANIA, REPÚBLICA DA',
    iso31661: 'LTU',
    nameInternational: 'Lithuania',
    ibge: '04421',
    nationalityName: 'LITUANA',
    nationalityNameInternational: 'Lithuanian',
  },
  {
    iso: 'LU',
    name: 'LUXEMBURGO',
    iso31661: 'LUX',
    nameInternational: 'Luxembourg',
    ibge: '04456',
    nationalityName: 'LUXEMBURGUESA',
    nationalityNameInternational: 'Luxembourg, Luxembourgish',
  },
  {
    iso: 'MO',
    name: 'MACAU',
    iso31661: 'MAC',
    nameInternational: 'Macao',
    ibge: '04472',
    nationalityName: 'MACAENSE',
    nationalityNameInternational: 'Macanese, Chinese',
  },
  {
    iso: 'MK',
    name: 'MACEDÔNIA, ANT.REP.IUGOSLAVA',
    iso31661: 'MKD',
    nameInternational: 'Macedonia (the former Yugoslav Republic of)',
    ibge: '04499',
    nationalityName: 'MACEDÔNIA',
    nationalityNameInternational: 'Macedonian',
  },
  {
    iso: 'MG',
    name: 'MADAGASCAR',
    iso31661: 'MDG',
    nameInternational: 'Madagascar',
    ibge: '04502',
    nationalityName: 'MALGAXE',
    nationalityNameInternational: 'Malagasy',
  },
  {
    iso: 'MY',
    name: 'MALÁSIA',
    iso31661: 'MYS',
    nameInternational: 'Malaysia',
    ibge: '04553',
    nationalityName: 'MALAIA',
    nationalityNameInternational: 'Malaysian',
  },
  {
    iso: 'MW',
    name: 'MALAUI (MALAWI)',
    iso31661: 'MWI',
    nameInternational: 'Malawi',
    ibge: '04588',
    nationalityName: 'MALAUIANA',
    nationalityNameInternational: 'Malawian',
  },
  {
    iso: 'MV',
    name: 'MALDIVAS',
    iso31661: 'MDV',
    nameInternational: 'Maldives',
    ibge: '04618',
    nationalityName: 'MALDÍVIA',
    nationalityNameInternational: 'Maldivian',
  },
  {
    iso: 'ML',
    name: 'MALI',
    iso31661: 'MLI',
    nameInternational: 'Mali',
    ibge: '04642',
    nationalityName: 'MALIANA',
    nationalityNameInternational: 'Malian, Malinese',
  },
  {
    iso: 'MT',
    name: 'MALTA',
    iso31661: 'MLT',
    nameInternational: 'Malta',
    ibge: '04677',
    nationalityName: 'MALTESA',
    nationalityNameInternational: 'Maltese',
  },
  {
    iso: 'IM',
    name: 'MAN, ILHA DE',
    iso31661: 'IMN',
    nameInternational: 'Isle of Man',
    ibge: '03595',
    nationalityName: 'MANESA',
    nationalityNameInternational: 'Manx',
  },
  {
    iso: 'MP',
    name: 'MARIANAS DO NORTE',
    iso31661: 'MNP',
    nameInternational: 'Northern Mariana Islands',
    ibge: '04723',
    nationalityName: 'NORTE-MARIANENSE',
    nationalityNameInternational: 'Northern Marianan',
  },
  {
    iso: 'MA',
    name: 'MARROCOS',
    iso31661: 'MAR',
    nameInternational: 'Morocco',
    ibge: '04740',
    nationalityName: 'MARROQUINA',
    nationalityNameInternational: 'Moroccan',
  },
  {
    iso: 'MH',
    name: 'MARSHALL,ILHAS',
    iso31661: 'MHL',
    nameInternational: 'Marshall Islands',
    ibge: '04766',
    nationalityName: 'MARSHALLINA',
    nationalityNameInternational: 'Marshallese',
  },
  {
    iso: 'MQ',
    name: 'MARTINICA',
    iso31661: 'MTQ',
    nameInternational: 'Martinique',
    ibge: '04774',
    nationalityName: 'MARTINICANA',
    nationalityNameInternational: 'Martiniquais, Martinican',
  },
  {
    iso: 'MU',
    name: 'MAURICIO',
    iso31661: 'MUS',
    nameInternational: 'Mauritius',
    ibge: '04855',
    nationalityName: 'MAURÍCIA',
    nationalityNameInternational: 'Mauritian',
  },
  {
    iso: 'MR',
    name: 'MAURITÂNIA',
    iso31661: 'MRT',
    nameInternational: 'Mauritania',
    ibge: '04880',
    nationalityName: 'MAURITANA',
    nationalityNameInternational: 'Mauritanian',
  },
  {
    iso: 'YT',
    name: 'MAYOTTE (ILHAS FRANCESAS)',
    iso31661: 'MYT',
    nameInternational: 'Mayotte',
    ibge: '04898',
    nationalityName: 'MAYOTENSE',
    nationalityNameInternational: 'Mahoran',
  },
  {
    iso: 'MX',
    name: 'MÉXICO',
    iso31661: 'MEX',
    nameInternational: 'Mexico',
    ibge: '04936',
    nationalityName: 'MEXICANA',
    nationalityNameInternational: 'Mexican',
  },
  {
    iso: 'MM',
    name: 'MIANMAR (BIRMANIA)',
    iso31661: 'MMR',
    nameInternational: 'Myanmar',
    ibge: '00930',
    nationalityName: 'BIRMANESA',
    nationalityNameInternational: 'Burmese',
  },
  {
    iso: 'FM',
    name: 'MICRONESIA',
    iso31661: 'FSM',
    nameInternational: 'Micronesia (Federated States of)',
    ibge: '04995',
    nationalityName: 'MICRONÉSIA',
    nationalityNameInternational: 'Micronesian',
  },
  {
    iso: 'MZ',
    name: 'MOÇAMBIQUE',
    iso31661: 'MOZ',
    nameInternational: 'Mozambique',
    ibge: '05053',
    nationalityName: 'MOÇAMBICANA',
    nationalityNameInternational: 'Mozambican',
  },
  {
    iso: 'MD',
    name: 'MOLDAVIA, REPÚBLICA DA',
    iso31661: 'MDA',
    nameInternational: 'Moldova (Republic of)',
    ibge: '04944',
    nationalityName: 'MOLDAVA',
    nationalityNameInternational: 'Moldovan',
  },
  {
    iso: 'MC',
    name: 'MÔNACO',
    iso31661: 'MCO',
    nameInternational: 'Monaco',
    ibge: '04952',
    nationalityName: 'MONEGASCA',
    nationalityNameInternational: 'Monégasque, Monacan',
  },
  {
    iso: 'MN',
    name: 'MONGÓLIA',
    iso31661: 'MNG',
    nameInternational: 'Mongolia',
    ibge: '04979',
    nationalityName: 'MONGAL',
    nationalityNameInternational: 'Mongolian',
  },
  {
    iso: 'ME',
    name: 'MONTENEGRO',
    iso31661: 'MNE',
    nameInternational: 'Montenegro',
    ibge: '04985',
    nationalityName: 'MONTENEGRINA',
    nationalityNameInternational: 'Montenegrin',
  },
  {
    iso: 'MS',
    name: 'MONTSERRAT, ILHAS',
    iso31661: 'MSR',
    nameInternational: 'Montserrat',
    ibge: '05010',
    nationalityName: 'DAS ILHAS MONTSERRAT',
    nationalityNameInternational: 'Montserratian',
  },
  {
    iso: 'NA',
    name: 'NAMÍBIA',
    iso31661: 'NAM',
    nameInternational: 'Namibia',
    ibge: '05070',
    nationalityName: 'NAMIBIANA',
    nationalityNameInternational: 'Namibian',
  },
  {
    iso: 'NR',
    name: 'NAURU',
    iso31661: 'NRU',
    nameInternational: 'Nauru',
    ibge: '05088',
    nationalityName: 'NAURUANA',
    nationalityNameInternational: 'Nauruan',
  },
  {
    iso: 'NP',
    name: 'NEPAL',
    iso31661: 'NPL',
    nameInternational: 'Nepal',
    ibge: '05177',
    nationalityName: 'NEPALESA',
    nationalityNameInternational: 'Nepali, Nepalese',
  },
  {
    iso: 'NI',
    name: 'NICARÁGUA',
    iso31661: 'NIC',
    nameInternational: 'Nicaragua',
    ibge: '05215',
    nationalityName: 'NICARAGUENSE',
    nationalityNameInternational: 'Nicaraguan',
  },
  {
    iso: 'NE',
    name: 'NÍGER',
    iso31661: 'NER',
    nameInternational: 'Niger',
    ibge: '05258',
    nationalityName: 'NIGERENSE',
    nationalityNameInternational: 'Nigerien',
  },
  {
    iso: 'NG',
    name: 'NIGÉRIA',
    iso31661: 'NGA',
    nameInternational: 'Nigeria',
    ibge: '05282',
    nationalityName: 'NIGERIANA',
    nationalityNameInternational: 'Nigerian',
  },
  {
    iso: 'NU',
    name: 'NIUE, ILHA',
    iso31661: 'NIU',
    nameInternational: 'Niue',
    ibge: '05312',
    nationalityName: 'NIUENSE',
    nationalityNameInternational: 'Niuean',
  },
  {
    iso: 'NF',
    name: 'NORFOLK, ILHA',
    iso31661: 'NFK',
    nameInternational: 'Norfolk Island',
    ibge: '05355',
    nationalityName: 'NORFOLQUINA',
    nationalityNameInternational: 'Norfolk Island',
  },
  {
    iso: 'NO',
    name: 'NORUEGA',
    iso31661: 'NOR',
    nameInternational: 'Norway',
    ibge: '05380',
    nationalityName: 'NORUEGUESA',
    nationalityNameInternational: 'Norwegian',
  },
  {
    iso: 'NC',
    name: 'NOVA CALEDÔNIA',
    iso31661: 'NCL',
    nameInternational: 'New Caledonia',
    ibge: '05428',
    nationalityName: 'NEOCALEDÔNIA',
    nationalityNameInternational: 'New Caledonian',
  },
  {
    iso: 'NZ',
    name: 'NOVA ZELÂNDIA',
    iso31661: 'NZL',
    nameInternational: 'New Zealand',
    ibge: '05487',
    nationalityName: 'NEOZELANDESA',
    nationalityNameInternational: 'New Zealand, NZ',
  },
  {
    iso: 'OM',
    name: 'OMÃ',
    iso31661: 'OMN',
    nameInternational: 'Oman',
    ibge: '05568',
    nationalityName: 'OMANI',
    nationalityNameInternational: 'Omani',
  },
  {
    iso: 'NL',
    name: 'PAISES BAIXOS (HOLANDA)',
    iso31661: 'NLD',
    nameInternational: 'Netherlands',
    ibge: '05738',
    nationalityName: 'NEERLANDESA OU HOLANDESA',
    nationalityNameInternational: 'Dutch, Netherlandic',
  },
  {
    iso: 'PW',
    name: 'PALAU',
    iso31661: 'PLW',
    nameInternational: 'Palau',
    ibge: '05754',
    nationalityName: 'PALAUENSE',
    nationalityNameInternational: 'Palauan',
  },
  {
    iso: 'PA',
    name: 'PANAMÁ',
    iso31661: 'PAN',
    nameInternational: 'Panama',
    ibge: '05800',
    nationalityName: 'PANAMENHA',
    nationalityNameInternational: 'Panamanian',
  },
  {
    iso: 'PG',
    name: 'PAPUA NOVA GUINÉ',
    iso31661: 'PNG',
    nameInternational: 'Papua New Guinea',
    ibge: '05452',
    nationalityName: 'PAPUASIA',
    nationalityNameInternational: 'Papua New Guinean, Papuan',
  },
  {
    iso: 'PK',
    name: 'PAQUISTÃO',
    iso31661: 'PAK',
    nameInternational: 'Pakistan',
    ibge: '05762',
    nationalityName: 'PAQUISTANESA',
    nationalityNameInternational: 'Pakistani',
  },
  {
    iso: 'PY',
    name: 'PARAGUAI',
    iso31661: 'PRY',
    nameInternational: 'Paraguay',
    ibge: '05860',
    nationalityName: 'PARAGUAIA',
    nationalityNameInternational: 'Paraguayan',
  },
  {
    iso: 'PE',
    name: 'PERU',
    iso31661: 'PER',
    nameInternational: 'Peru',
    ibge: '05894',
    nationalityName: 'PERUANA',
    nationalityNameInternational: 'Peruvian',
  },
  {
    iso: 'PN',
    name: 'PITCAIRN, ILHA',
    iso31661: 'PCN',
    nameInternational: 'Pitcairn',
    ibge: '05932',
    nationalityName: 'PITCAIRNESA',
    nationalityNameInternational: 'Pitcairn Island',
  },
  {
    iso: 'PF',
    name: 'POLINÉSIA FRANCESA',
    iso31661: 'PYF',
    nameInternational: 'French Polynesia',
    ibge: '05991',
    nationalityName: 'POLINÉSIA',
    nationalityNameInternational: 'French Polynesian',
  },
  {
    iso: 'PL',
    name: 'POLÔNIA, REPÚBLICA DA',
    iso31661: 'POL',
    nameInternational: 'Poland',
    ibge: '06033',
    nationalityName: 'POLACA',
    nationalityNameInternational: 'Polish',
  },
  {
    iso: 'PR',
    name: 'PORTO RICO',
    iso31661: 'PRI',
    nameInternational: 'Puerto Rico',
    ibge: '06114',
    nationalityName: 'PORTO-RIQUENHA',
    nationalityNameInternational: 'Puerto Rican',
  },
  {
    iso: 'PT',
    name: 'PORTUGAL',
    iso31661: 'PRT',
    nameInternational: 'Portugal',
    ibge: '06076',
    nationalityName: 'PORTUGUESA',
    nationalityNameInternational: 'Portuguese',
  },
  {
    iso: 'KE',
    name: 'QUÉNIA',
    iso31661: 'KEN',
    nameInternational: 'Kenya',
    ibge: '06238',
    nationalityName: 'QUENIANA',
    nationalityNameInternational: 'Kenyan',
  },
  {
    iso: 'KG',
    name: 'QUIRGUISTÃO, REPÚBLICA',
    iso31661: 'KGZ',
    nameInternational: 'Kyrgyzstan',
    ibge: '06254',
    nationalityName: 'QUIRGUIZ',
    nationalityNameInternational: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
  },
  {
    iso: 'CF',
    name: 'REPÚBLICA CENTRO-AFRICANA',
    iso31661: 'CAF',
    nameInternational: 'Central African Republic',
    ibge: '06408',
    nationalityName: 'CENTRO-AFRICANA',
    nationalityNameInternational: 'Central African',
  },
  {
    iso: 'DO',
    name: 'REPÚBLICA DOMINICANA',
    iso31661: 'DOM',
    nameInternational: 'Dominican Republic',
    ibge: '06475',
    nationalityName: 'DOMINICANA',
    nationalityNameInternational: 'Dominican',
  },
  {
    iso: 'RE',
    name: 'REUNIÃO, ILHA',
    iso31661: 'REU',
    nameInternational: 'Réunion',
    ibge: '06602',
    nationalityName: 'REUNIONENSE',
    nationalityNameInternational: 'Réunionese, Réunionnais',
  },
  {
    iso: 'RO',
    name: 'ROMÉNIA',
    iso31661: 'ROU',
    nameInternational: 'Romania',
    ibge: '06700',
    nationalityName: 'ROMENA',
    nationalityNameInternational: 'Romanian',
  },
  {
    iso: 'RW',
    name: 'RUANDA',
    iso31661: 'RWA',
    nameInternational: 'Rwanda',
    ibge: '06750',
    nationalityName: 'RUANDESA',
    nationalityNameInternational: 'Rwandan',
  },
  {
    iso: 'RU',
    name: 'RUSSIA, FEDERAÇÃO DA',
    iso31661: 'RUS',
    nameInternational: 'Russian Federation',
    ibge: '06769',
    nationalityName: 'RUSSA',
    nationalityNameInternational: 'Russian',
  },
  {
    iso: 'EH',
    name: 'SAARA OCIDENTAL',
    iso31661: 'ESH',
    nameInternational: 'Western Sahara',
    ibge: '06858',
    nationalityName: 'SARÁUI',
    nationalityNameInternational: 'Sahrawi, Sahrawian, Sahraouian',
  },
  {
    iso: 'SB',
    name: 'SALOMÃO, ILHAS',
    iso31661: 'SLB',
    nameInternational: 'Solomon Islands',
    ibge: '06777',
    nationalityName: 'SALOMÔNICA',
    nationalityNameInternational: 'Solomon Island',
  },
  {
    iso: 'WS',
    name: 'SAMOA',
    iso31661: 'WSM',
    nameInternational: 'Samoa',
    ibge: '06904',
    nationalityName: 'SAMOANA',
    nationalityNameInternational: 'Samoan',
  },
  {
    iso: 'AS',
    name: 'SAMOA AMERICANA',
    iso31661: 'ASM',
    nameInternational: 'American Samoa',
    ibge: '06912',
    nationalityName: 'SAMOANA',
    nationalityNameInternational: 'American Samoan',
  },
  {
    iso: 'SM',
    name: 'SAN MARINO',
    iso31661: 'SMR',
    nameInternational: 'San Marino',
    ibge: '06971',
    nationalityName: 'SÃO-MARINHENSE',
    nationalityNameInternational: 'Sammarinese',
  },
  {
    iso: 'SH',
    name: 'SANTA HELENA',
    iso31661: 'SHN',
    nameInternational: 'Saint Helena, Ascension and Tristan da Cunha',
    ibge: '07102',
    nationalityName: 'SANTA-HELENENSE',
    nationalityNameInternational: 'Saint Helenian',
  },
  {
    iso: 'LC',
    name: 'SANTA LUCIA',
    iso31661: 'LCA',
    nameInternational: 'Saint Lucia',
    ibge: '07153',
    nationalityName: 'SANTA-LUCIENSE',
    nationalityNameInternational: 'Saint Lucian',
  },
  {
    iso: 'KN',
    name: 'SÃO CRISTOVÃO E NEVES,ILHAS (SAINT KITTS AND NEVIS)',
    iso31661: 'KNA',
    nameInternational: 'Saint Kitts and Nevis',
    ibge: '06955',
    nationalityName: 'SÃO-CRISTOVENSE',
    nationalityNameInternational: 'Kittitian or Nevisian',
  },
  {
    iso: 'PM',
    name: 'SÃO PEDRO E MIQUELON (SAINT PIERRE AND MIQUELON)',
    iso31661: 'SPM',
    nameInternational: 'Saint Pierre and Miquelon',
    ibge: '07005',
    nationalityName: 'SÃO-PEDRO-MIQUELONENSE',
    nationalityNameInternational: 'Saint-Pierrais or Miquelonnais',
  },
  {
    iso: 'ST',
    name: 'SÃO TOMÉ E PRÍNCIPE, ILHAS',
    iso31661: 'STP',
    nameInternational: 'Sao Tome and Principe',
    ibge: '07200',
    nationalityName: 'SÃO-TOMENSE',
    nationalityNameInternational: 'São Toméan',
  },
  {
    iso: 'VC',
    name: 'SÃO VICENTE E GRANADINAS (SAINT VICENT AND THE GRENADINES)',
    iso31661: 'VCT',
    nameInternational: 'Saint Vincent and the Grenadines',
    ibge: '07056',
    nationalityName: 'SÃO-VICENTINA',
    nationalityNameInternational: 'Saint Vincentian, Vincentian',
  },
  {
    iso: 'SN',
    name: 'SENEGAL',
    iso31661: 'SEN',
    nameInternational: 'Senegal',
    ibge: '07285',
    nationalityName: 'SENEGALESA',
    nationalityNameInternational: 'Senegalese',
  },
  {
    iso: 'SL',
    name: 'SERRA LEOA',
    iso31661: 'SLE',
    nameInternational: 'Sierra Leone',
    ibge: '07358',
    nationalityName: 'SERRA-LEONESA',
    nationalityNameInternational: 'Sierra Leonean',
  },
  {
    iso: 'RS',
    name: 'SÉRVIA, REPÚBLICA',
    iso31661: 'SRB',
    nameInternational: 'Serbia',
    ibge: '07370',
    nationalityName: 'SÉRVIA',
    nationalityNameInternational: 'Serbian',
  },
  {
    iso: 'SC',
    name: 'SEYCHELLES (SEICHELES)',
    iso31661: 'SYC',
    nameInternational: 'Seychelles',
    ibge: '07315',
    nationalityName: 'SEICHELENSE',
    nationalityNameInternational: 'Seychellois',
  },
  {
    iso: 'SY',
    name: 'SÍRIA, REPÚBLICA ARABE DA',
    iso31661: 'SYR',
    nameInternational: 'Syrian Arab Republic',
    ibge: '07447',
    nationalityName: 'SÍRIA',
    nationalityNameInternational: 'Syrian',
  },
  {
    iso: 'SO',
    name: 'SOMALIA',
    iso31661: 'SOM',
    nameInternational: 'Somalia',
    ibge: '07480',
    nationalityName: 'SOMALI',
    nationalityNameInternational: 'Somali, Somalian',
  },
  {
    iso: 'LK',
    name: 'SRI LANKA',
    iso31661: 'LKA',
    nameInternational: 'Sri Lanka',
    ibge: '07501',
    nationalityName: 'CINGALESA',
    nationalityNameInternational: 'Sri Lankan',
  },
  {
    iso: 'SZ',
    name: 'SUAZILÂNDIA',
    iso31661: 'SWZ',
    nameInternational: 'Swaziland',
    ibge: '07544',
    nationalityName: 'SUAZI',
    nationalityNameInternational: 'Swazi',
  },
  {
    iso: 'SD',
    name: 'SUDÃO',
    iso31661: 'SDN',
    nameInternational: 'Sudan',
    ibge: '07595',
    nationalityName: 'SUDANESA',
    nationalityNameInternational: 'Sudanese',
  },
  {
    iso: 'SE',
    name: 'SUÉCIA',
    iso31661: 'SWE',
    nameInternational: 'Sweden',
    ibge: '07641',
    nationalityName: 'SUECA',
    nationalityNameInternational: 'Swedish',
  },
  {
    iso: 'CH',
    name: 'SUIÇA',
    iso31661: 'CHE',
    nameInternational: 'Switzerland',
    ibge: '07676',
    nationalityName: 'SUIÇA',
    nationalityNameInternational: 'Swiss',
  },
  {
    iso: 'SR',
    name: 'SURINAME',
    iso31661: 'SUR',
    nameInternational: 'Suriname',
    ibge: '07706',
    nationalityName: 'SURINAMESA',
    nationalityNameInternational: 'Surinamese',
  },
  {
    iso: 'TH',
    name: 'TAILÂNDIA',
    iso31661: 'THA',
    nameInternational: 'Thailand',
    ibge: '07765',
    nationalityName: 'TAILANDESA',
    nationalityNameInternational: 'Thai',
  },
  {
    iso: 'TW',
    name: 'TAIWAN (ILHA FORMOSA)',
    iso31661: 'TWN',
    nameInternational: 'Taiwan, Province of China',
    ibge: '01619',
    nationalityName: 'TAIUANESA, FOMOSINA OU CHINESA',
    nationalityNameInternational: 'Chinese, Taiwanese',
  },
  {
    iso: 'TJ',
    name: 'TAJIQUISTÃO, REPÚBLICA DO',
    iso31661: 'TJK',
    nameInternational: 'Tajikistan',
    ibge: '07722',
    nationalityName: 'TADJIQUE OU TADJIQUISTANESA',
    nationalityNameInternational: 'Tajikistani',
  },
  {
    iso: 'TZ',
    name: 'TANZÂNIA, REP.UNIDA DA',
    iso31661: 'TZA',
    nameInternational: 'Tanzania, United Republic of',
    ibge: '07803',
    nationalityName: 'TANZANIANA',
    nationalityNameInternational: 'Tanzanian',
  },
  {
    iso: 'CZ',
    name: 'TCHECA, REPÚBLICA (CZECK)',
    iso31661: 'CZE',
    nameInternational: 'Czech Republic',
    ibge: '07919',
    nationalityName: 'TCHECA',
    nationalityNameInternational: 'Czech',
  },
  {
    iso: 'IO',
    name: 'TERRITÓRIO BRIT.OC.INDICO (BRITISH INDIAN OCEAN TERRITORY)',
    iso31661: 'IOT',
    nameInternational: 'British Indian Ocean Territory',
    ibge: '07820',
    nationalityName: 'BRITÂNICA',
    nationalityNameInternational: 'BIOT',
  },
  {
    iso: 'TL',
    name: 'TIMOR LESTE',
    iso31661: 'TLS',
    nameInternational: 'Timor-Leste',
    ibge: '07951',
    nationalityName: 'TIMORENSE',
    nationalityNameInternational: 'Timorese',
  },
  {
    iso: 'TG',
    name: 'TOGO',
    iso31661: 'TGO',
    nameInternational: 'Togo',
    ibge: '08001',
    nationalityName: 'TOGOLESA',
    nationalityNameInternational: 'Togolese',
  },
  {
    iso: 'TO',
    name: 'TONGA',
    iso31661: 'TON',
    nameInternational: 'Tonga',
    ibge: '08109',
    nationalityName: 'TONGANESA',
    nationalityNameInternational: 'Tongan',
  },
  {
    iso: 'TK',
    name: 'TOQUELAU,ILHAS',
    iso31661: 'TKL',
    nameInternational: 'Tokelau',
    ibge: '08052',
    nationalityName: 'TOQUELAUENSE',
    nationalityNameInternational: 'Tokelauan',
  },
  {
    iso: 'TT',
    name: 'TRINIDAD E TOBAGO',
    iso31661: 'TTO',
    nameInternational: 'Trinidad and Tobago',
    ibge: '08150',
    nationalityName: 'TRINITÁRIO-TOBAGUENSE',
    nationalityNameInternational: 'Trinidadian or Tobagonian',
  },
  {
    iso: 'TN',
    name: 'TUNÍSIA',
    iso31661: 'TUN',
    nameInternational: 'Tunisia',
    ibge: '08206',
    nationalityName: 'TUNISIANA',
    nationalityNameInternational: 'Tunisian',
  },
  {
    iso: 'TC',
    name: 'TURCAS E CAICOS, ILHAS',
    iso31661: 'TCA',
    nameInternational: 'Turks and Caicos Islands',
    ibge: '08230',
    nationalityName: 'TURCO-CAICENSE',
    nationalityNameInternational: 'Turks and Caicos Island',
  },
  {
    iso: 'TM',
    name: 'TURCOMENISTÃO, REPÚBLICA DO',
    iso31661: 'TKM',
    nameInternational: 'Turkmenistan',
    ibge: '08249',
    nationalityName: 'TURCOMENA',
    nationalityNameInternational: 'Turkmen',
  },
  {
    iso: 'TR',
    name: 'TURQUIA',
    iso31661: 'TUR',
    nameInternational: 'Turkey',
    ibge: '08273',
    nationalityName: 'TURCA',
    nationalityNameInternational: 'Turkish',
  },
  {
    iso: 'TV',
    name: 'TUVALU',
    iso31661: 'TUV',
    nameInternational: 'Tuvalu',
    ibge: '08281',
    nationalityName: 'TUVALUANA',
    nationalityNameInternational: 'Tuvaluan',
  },
  {
    iso: 'UA',
    name: 'UCRÂNIA',
    iso31661: 'UKR',
    nameInternational: 'Ukraine',
    ibge: '08311',
    nationalityName: 'UCRANIANA',
    nationalityNameInternational: 'Ukrainian',
  },
  {
    iso: 'UG',
    name: 'UGANDA',
    iso31661: 'UGA',
    nameInternational: 'Uganda',
    ibge: '08338',
    nationalityName: 'UGANDENSE',
    nationalityNameInternational: 'Ugandan',
  },
  {
    iso: 'UY',
    name: 'URUGUAI',
    iso31661: 'URY',
    nameInternational: 'Uruguay',
    ibge: '08451',
    nationalityName: 'URUGUAIA',
    nationalityNameInternational: 'Uruguayan',
  },
  {
    iso: 'UZ',
    name: 'UZBEQUISTÃO, REPÚBLICA DO',
    iso31661: 'UZB',
    nameInternational: 'Uzbekistan',
    ibge: '08478',
    nationalityName: 'UZBEQUE',
    nationalityNameInternational: 'Uzbekistani, Uzbek',
  },
  {
    iso: 'VU',
    name: 'VANUATU',
    iso31661: 'VUT',
    nameInternational: 'Vanuatu',
    ibge: '05517',
    nationalityName: 'VANATUENSE',
    nationalityNameInternational: 'Ni-Vanuatu, Vanuatuan',
  },
  {
    iso: 'VA',
    name: 'VATICANO, ESTADO DA CIDADE DO',
    iso31661: 'VAT',
    nameInternational: 'Vatican City State',
    ibge: '08486',
    nationalityName: 'VATICANA',
    nationalityNameInternational: 'Vatican',
  },
  {
    iso: 'VE',
    name: 'VENEZUELA',
    iso31661: 'VEN',
    nameInternational: 'Venezuela (Bolivarian Republic of)',
    ibge: '08508',
    nationalityName: 'VENEZUELANA',
    nationalityNameInternational: 'Venezuelan',
  },
  {
    iso: 'VN',
    name: 'VIETNÃ',
    iso31661: 'VNM',
    nameInternational: 'Vietnam',
    ibge: '08583',
    nationalityName: 'VIETNAMITA',
    nationalityNameInternational: 'Vietnamese',
  },
  {
    iso: 'VG',
    name: 'VIRGENS, ILHAS (BRITÂNICAS)',
    iso31661: 'VGB',
    nameInternational: 'Virgin Islands (British)',
    ibge: '08630',
    nationalityName: 'VIRGINENSE',
    nationalityNameInternational: 'British Virgin Island',
  },
  {
    iso: 'VI',
    name: 'VIRGENS, ILHAS (E.U.A.)',
    iso31661: 'VIR',
    nameInternational: 'Virgin Islands (U.S.)',
    ibge: '08664',
    nationalityName: 'VIRGINENSE',
    nationalityNameInternational: 'U.S. Virgin Island',
  },
  {
    iso: 'ZM',
    name: 'ZÂMBIA',
    iso31661: 'ZMB',
    nameInternational: 'Zambia',
    ibge: '08907',
    nationalityName: 'ZÂMBIO',
    nationalityNameInternational: 'Zambian',
  },
  {
    iso: 'ZW',
    name: 'ZIMBABUÉ (ZIMBABWE)',
    iso31661: 'ZWE',
    nameInternational: 'Zimbabwe',
    ibge: '06653',
    nationalityName: 'ZIMBAUANA',
    nationalityNameInternational: 'Zimbabwean',
  },
  {
    iso: 'ES',
    name: 'ESPANHA',
    iso31661: 'ESP',
    nameInternational: 'Spain',
    ibge: '02453',
    nationalityName: 'ESPANHOLA',
    nationalityNameInternational: 'Spanish',
  },
  {
    iso: 'AX',
    name: 'ILHAS ALAND',
    iso31661: 'ALA',
    nameInternational: 'Aland Islands',
    ibge: '00153',
    nationalityName: 'FINLANDÊS',
    nationalityNameInternational: 'Aland Island',
  },
  {
    iso: 'AQ',
    name: 'ANTARTICA',
    iso31661: 'ATA',
    nameInternational: 'Antarctica',
    ibge: '00420',
    nationalityName: 'ANTARTICA',
    nationalityNameInternational: 'Antarctic',
  },
  {
    iso: 'BV',
    name: 'ILHA BOUVET',
    iso31661: 'BVT',
    nameInternational: 'Bouvet Island',
    ibge: '01023',
    nationalityName: 'NORUEGUÊS',
    nationalityNameInternational: 'Bouvet Island',
  },
  {
    iso: 'GS',
    name: 'ILHAS GEORGIA DO SUL E SANDWICH DO SUL',
    iso31661: 'SGS',
    nameInternational: 'South Georgia and the South Sandwich Islands',
    ibge: '02925',
    nationalityName: 'BRITÂNICO',
    nationalityNameInternational: 'South Georgia or South Sandwich Islands',
  },
  {
    iso: 'HM',
    name: 'ILHA HEARD E ILHAS MACDONALD',
    iso31661: 'HMD',
    nameInternational: 'Heard Island and McDonald Islands',
    ibge: '03433',
    nationalityName: 'AUSTRÍACO',
    nationalityNameInternational: 'Heard Island or McDonald Islands',
  },
  {
    iso: 'SJ',
    name: 'SVALBARD E JAN MAYERN',
    iso31661: 'SJM',
    nameInternational: 'Svalbard and Jan Mayen',
    ibge: '07552',
    nationalityName: 'NORUEGUÊS',
    nationalityNameInternational: 'Svalbard',
  },
  // {
  //   iso: 'N/A',
  //   name: 'TERRAS AUSTRAIS E ANTARTICAS FRANCESAS',
  //   iso31661: 'SGH',
  //   nameInternational: 'French Southern and Antarctic Lands',
  //   ibge: '07811',
  //   nationalityName: 'FRANCÊS',
  //   nationalityNameInternational: 'French',
  // },
  {
    iso: 'WF',
    name: 'ILHAS WALLIS E FUTUNA',
    iso31661: 'WLF',
    nameInternational: 'Wallis and Futuna',
    ibge: '08753',
    nationalityName: 'FRANCÊS',
    nationalityNameInternational: 'Wallis and Futuna, Wallisian or Futunan',
  },
  {
    iso: 'KW',
    name: 'KUWAIT',
    iso31661: 'FGS',
    nameInternational: 'Kuwait',
    ibge: '01988',
    nationalityName: 'KUWAITIANO',
    nationalityNameInternational: 'Kuwaiti',
  },
  {
    iso: 'BQ',
    name: 'BONAIRE',
    iso31661: 'LKJ',
    nameInternational: 'Bonaire, Sint Eustatius and Saba',
    ibge: '00990',
    nationalityName: 'BONAIRE',
    nationalityNameInternational: 'Bonaire',
  },
];

export const STATE_OPTIONS = [
  {
    value: 11,
    initials: 'RO',
    label: 'Rondônia',
  },
  {
    value: 12,
    initials: 'AC',
    label: 'Acre',
  },
  {
    value: 13,
    initials: 'AM',
    label: 'Amazonas',
  },
  {
    value: 14,
    initials: 'RR',
    label: 'Roraima',
  },
  {
    value: 15,
    initials: 'PA',
    label: 'Pará',
  },
  {
    value: 16,
    initials: 'AP',
    label: 'Amapá',
  },
  {
    value: 17,
    initials: 'TO',
    label: 'Tocantins',
  },
  {
    value: 21,
    initials: 'MA',
    label: 'Maranhão',
  },
  {
    value: 22,
    initials: 'PI',
    label: 'Piauí',
  },
  {
    value: 23,
    initials: 'CE',
    label: 'Ceará',
  },
  {
    value: 24,
    initials: 'RN',
    label: 'Rio Grande do Norte',
  },
  {
    value: 25,
    initials: 'PB',
    label: 'Paraíba',
  },
  {
    value: 26,
    initials: 'PE',
    label: 'Pernambuco',
  },
  {
    value: 27,
    initials: 'AL',
    label: 'Alagoas',
  },
  {
    value: 28,
    initials: 'SE',
    label: 'Sergipe',
  },
  {
    value: 29,
    initials: 'BA',
    label: 'Bahia',
  },
  {
    value: 31,
    initials: 'MG',
    label: 'Minas Gerais',
  },
  {
    value: 32,
    initials: 'ES',
    label: 'Espírito Santo',
  },
  {
    value: 33,
    initials: 'RJ',
    label: 'Rio de Janeiro',
  },
  {
    value: 35,
    initials: 'SP',
    label: 'São Paulo',
  },
  {
    value: 41,
    initials: 'PR',
    label: 'Paraná',
  },
  {
    value: 42,
    initials: 'SC',
    label: 'Santa Catarina',
  },
  {
    value: 43,
    initials: 'RS',
    label: 'Rio Grande do Sul',
  },
  {
    value: 50,
    initials: 'MS',
    label: 'Mato Grosso do Sul',
  },
  {
    value: 51,
    initials: 'MT',
    label: 'Mato Grosso',
  },
  {
    value: 52,
    initials: 'GO',
    label: 'Goiás',
  },
  {
    value: 53,
    initials: 'DF',
    label: 'Distrito Federal',
  },
];

export const CITY_OPTIONS = [
  {
    value: 1100015,
    label: "Alta Floresta D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100023,
    label: 'Ariquemes',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100031,
    label: 'Cabixi',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100049,
    label: 'Cacoal',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100056,
    label: 'Cerejeiras',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100064,
    label: 'Colorado do Oeste',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100072,
    label: 'Corumbiara',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100080,
    label: 'Costa Marques',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100098,
    label: "Espigão D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100106,
    label: 'Guajará-Mirim',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100114,
    label: 'Jaru',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100122,
    label: 'Ji-Paraná',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100130,
    label: "Machadinho D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100148,
    label: "Nova Brasilândia D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100155,
    label: 'Ouro Preto do Oeste',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100189,
    label: 'Pimenta Bueno',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100205,
    label: 'Porto Velho',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100254,
    label: 'Presidente Médici',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100262,
    label: 'Rio Crespo',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100288,
    label: 'Rolim de Moura',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100296,
    label: "Santa Luzia D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100304,
    label: 'Vilhena',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100320,
    label: 'São Miguel do Guaporé',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100338,
    label: 'Nova Mamoré',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100346,
    label: "Alvorada D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100379,
    label: 'Alto Alegre dos Parecis',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100403,
    label: 'Alto Paraíso',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100452,
    label: 'Buritis',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100502,
    label: 'Novo Horizonte do Oeste',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100601,
    label: 'Cacaulândia',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100700,
    label: 'Campo Novo de Rondônia',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100809,
    label: 'Candeias do Jamari',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100908,
    label: 'Castanheiras',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100924,
    label: 'Chupinguaia',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1100940,
    label: 'Cujubim',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101005,
    label: 'Governador Jorge Teixeira',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101104,
    label: 'Itapuã do Oeste',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101203,
    label: 'Ministro Andreazza',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101302,
    label: 'Mirante da Serra',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101401,
    label: 'Monte Negro',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101435,
    label: 'Nova União',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101450,
    label: 'Parecis',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101468,
    label: 'Pimenteiras do Oeste',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101476,
    label: 'Primavera de Rondônia',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101484,
    label: "São Felipe D'Oeste",
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101492,
    label: 'São Francisco do Guaporé',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101500,
    label: 'Seringueiras',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101559,
    label: 'Teixeirópolis',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101609,
    label: 'Theobroma',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101708,
    label: 'Urupá',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101757,
    label: 'Vale do Anari',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1101807,
    label: 'Vale do Paraíso',
    state: {value: 11, initials: 'RO', label: 'Rondônia'},
  },
  {
    value: 1200013,
    label: 'Acrelândia',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200054,
    label: 'Assis Brasil',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200104,
    label: 'Brasiléia',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200138,
    label: 'Bujari',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200179,
    label: 'Capixaba',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200203,
    label: 'Cruzeiro do Sul',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200252,
    label: 'Epitaciolândia',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200302,
    label: 'Feijó',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200328,
    label: 'Jordão',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200336,
    label: 'Mâncio Lima',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200344,
    label: 'Manoel Urbano',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200351,
    label: 'Marechal Thaumaturgo',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200385,
    label: 'Plácido de Castro',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200393,
    label: 'Porto Walter',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200401,
    label: 'Rio Branco',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200427,
    label: 'Rodrigues Alves',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200435,
    label: 'Santa Rosa do Purus',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200450,
    label: 'Senador Guiomard',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200500,
    label: 'Sena Madureira',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200609,
    label: 'Tarauacá',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200708,
    label: 'Xapuri',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1200807,
    label: 'Porto Acre',
    state: {value: 12, initials: 'AC', label: 'Acre'},
  },
  {
    value: 1300029,
    label: 'Alvarães',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300060,
    label: 'Amaturá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300086,
    label: 'Anamã',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300102,
    label: 'Anori',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300144,
    label: 'Apuí',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300201,
    label: 'Atalaia do Norte',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300300,
    label: 'Autazes',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300409,
    label: 'Barcelos',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300508,
    label: 'Barreirinha',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300607,
    label: 'Benjamin Constant',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300631,
    label: 'Beruri',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300680,
    label: 'Boa Vista do Ramos',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300706,
    label: 'Boca do Acre',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300805,
    label: 'Borba',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300839,
    label: 'Caapiranga',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1300904,
    label: 'Canutama',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301001,
    label: 'Carauari',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301100,
    label: 'Careiro',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301159,
    label: 'Careiro da Várzea',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301209,
    label: 'Coari',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301308,
    label: 'Codajás',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301407,
    label: 'Eirunepé',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301506,
    label: 'Envira',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301605,
    label: 'Fonte Boa',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301654,
    label: 'Guajará',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301704,
    label: 'Humaitá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301803,
    label: 'Ipixuna',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301852,
    label: 'Iranduba',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301902,
    label: 'Itacoatiara',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1301951,
    label: 'Itamarati',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302009,
    label: 'Itapiranga',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302108,
    label: 'Japurá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302207,
    label: 'Juruá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302306,
    label: 'Jutaí',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302405,
    label: 'Lábrea',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302504,
    label: 'Manacapuru',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302553,
    label: 'Manaquiri',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302603,
    label: 'Manaus',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302702,
    label: 'Manicoré',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302801,
    label: 'Maraã',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1302900,
    label: 'Maués',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303007,
    label: 'Nhamundá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303106,
    label: 'Nova Olinda do Norte',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303205,
    label: 'Novo Airão',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303304,
    label: 'Novo Aripuanã',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303403,
    label: 'Parintins',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303502,
    label: 'Pauini',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303536,
    label: 'Presidente Figueiredo',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303569,
    label: 'Rio Preto da Eva',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303601,
    label: 'Santa Isabel do Rio Negro',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303700,
    label: 'Santo Antônio do Içá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303809,
    label: 'São Gabriel da Cachoeira',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303908,
    label: 'São Paulo de Olivença',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1303957,
    label: 'São Sebastião do Uatumã',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304005,
    label: 'Silves',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304062,
    label: 'Tabatinga',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304104,
    label: 'Tapauá',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304203,
    label: 'Tefé',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304237,
    label: 'Tonantins',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304260,
    label: 'Uarini',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304302,
    label: 'Urucará',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1304401,
    label: 'Urucurituba',
    state: {value: 13, initials: 'AM', label: 'Amazonas'},
  },
  {
    value: 1400027,
    label: 'Amajari',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400050,
    label: 'Alto Alegre',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400100,
    label: 'Boa Vista',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400159,
    label: 'Bonfim',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400175,
    label: 'Cantá',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400209,
    label: 'Caracaraí',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400233,
    label: 'Caroebe',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400282,
    label: 'Iracema',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400308,
    label: 'Mucajaí',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400407,
    label: 'Normandia',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400456,
    label: 'Pacaraima',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400472,
    label: 'Rorainópolis',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400506,
    label: 'São João da Baliza',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400605,
    label: 'São Luiz',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1400704,
    label: 'Uiramutã',
    state: {value: 14, initials: 'RR', label: 'Roraima'},
  },
  {
    value: 1500107,
    label: 'Abaetetuba',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500131,
    label: 'Abel Figueiredo',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500206,
    label: 'Acará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500305,
    label: 'Afuá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500347,
    label: 'Água Azul do Norte',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500404,
    label: 'Alenquer',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500503,
    label: 'Almeirim',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500602,
    label: 'Altamira',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500701,
    label: 'Anajás',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500800,
    label: 'Ananindeua',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500859,
    label: 'Anapu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500909,
    label: 'Augusto Corrêa',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1500958,
    label: 'Aurora do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501006,
    label: 'Aveiro',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501105,
    label: 'Bagre',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501204,
    label: 'Baião',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501253,
    label: 'Bannach',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501303,
    label: 'Barcarena',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501402,
    label: 'Belém',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501451,
    label: 'Belterra',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501501,
    label: 'Benevides',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501576,
    label: 'Bom Jesus do Tocantins',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501600,
    label: 'Bonito',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501709,
    label: 'Bragança',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501725,
    label: 'Brasil Novo',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501758,
    label: 'Brejo Grande do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501782,
    label: 'Breu Branco',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501808,
    label: 'Breves',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501907,
    label: 'Bujaru',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1501956,
    label: 'Cachoeira do Piriá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502004,
    label: 'Cachoeira do Arari',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502103,
    label: 'Cametá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502152,
    label: 'Canaã dos Carajás',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502202,
    label: 'Capanema',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502301,
    label: 'Capitão Poço',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502400,
    label: 'Castanhal',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502509,
    label: 'Chaves',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502608,
    label: 'Colares',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502707,
    label: 'Conceição do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502756,
    label: 'Concórdia do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502764,
    label: 'Cumaru do Norte',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502772,
    label: 'Curionópolis',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502806,
    label: 'Curralinho',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502855,
    label: 'Curuá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502905,
    label: 'Curuçá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502939,
    label: 'Dom Eliseu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1502954,
    label: 'Eldorado do Carajás',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503002,
    label: 'Faro',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503044,
    label: 'Floresta do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503077,
    label: 'Garrafão do Norte',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503093,
    label: 'Goianésia do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503101,
    label: 'Gurupá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503200,
    label: 'Igarapé-Açu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503309,
    label: 'Igarapé-Miri',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503408,
    label: 'Inhangapi',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503457,
    label: 'Ipixuna do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503507,
    label: 'Irituia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503606,
    label: 'Itaituba',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503705,
    label: 'Itupiranga',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503754,
    label: 'Jacareacanga',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503804,
    label: 'Jacundá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1503903,
    label: 'Juruti',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504000,
    label: 'Limoeiro do Ajuru',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504059,
    label: 'Mãe do Rio',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504109,
    label: 'Magalhães Barata',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504208,
    label: 'Marabá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504307,
    label: 'Maracanã',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504406,
    label: 'Marapanim',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504422,
    label: 'Marituba',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504455,
    label: 'Medicilândia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504505,
    label: 'Melgaço',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504604,
    label: 'Mocajuba',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504703,
    label: 'Moju',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504752,
    label: 'Mojuí dos Campos',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504802,
    label: 'Monte Alegre',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504901,
    label: 'Muaná',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504950,
    label: 'Nova Esperança do Piriá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1504976,
    label: 'Nova Ipixuna',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505007,
    label: 'Nova Timboteua',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505031,
    label: 'Novo Progresso',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505064,
    label: 'Novo Repartimento',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505106,
    label: 'Óbidos',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505205,
    label: 'Oeiras do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505304,
    label: 'Oriximiná',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505403,
    label: 'Ourém',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505437,
    label: 'Ourilândia do Norte',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505486,
    label: 'Pacajá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505494,
    label: 'Palestina do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505502,
    label: 'Paragominas',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505536,
    label: 'Parauapebas',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505551,
    label: "Pau D'Arco",
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505601,
    label: 'Peixe-Boi',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505635,
    label: 'Piçarra',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505650,
    label: 'Placas',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505700,
    label: 'Ponta de Pedras',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505809,
    label: 'Portel',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1505908,
    label: 'Porto de Moz',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506005,
    label: 'Prainha',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506104,
    label: 'Primavera',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506112,
    label: 'Quatipuru',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506138,
    label: 'Redenção',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506161,
    label: 'Rio Maria',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506187,
    label: 'Rondon do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506195,
    label: 'Rurópolis',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506203,
    label: 'Salinópolis',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506302,
    label: 'Salvaterra',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506351,
    label: 'Santa Bárbara do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506401,
    label: 'Santa Cruz do Arari',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506500,
    label: 'Santa Izabel do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506559,
    label: 'Santa Luzia do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506583,
    label: 'Santa Maria das Barreiras',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506609,
    label: 'Santa Maria do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506708,
    label: 'Santana do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506807,
    label: 'Santarém',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1506906,
    label: 'Santarém Novo',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507003,
    label: 'Santo Antônio do Tauá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507102,
    label: 'São Caetano de Odivelas',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507151,
    label: 'São Domingos do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507201,
    label: 'São Domingos do Capim',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507300,
    label: 'São Félix do Xingu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507409,
    label: 'São Francisco do Pará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507458,
    label: 'São Geraldo do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507466,
    label: 'São João da Ponta',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507474,
    label: 'São João de Pirabas',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507508,
    label: 'São João do Araguaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507607,
    label: 'São Miguel do Guamá',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507706,
    label: 'São Sebastião da Boa Vista',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507755,
    label: 'Sapucaia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507805,
    label: 'Senador José Porfírio',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507904,
    label: 'Soure',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507953,
    label: 'Tailândia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507961,
    label: 'Terra Alta',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1507979,
    label: 'Terra Santa',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508001,
    label: 'Tomé-Açu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508035,
    label: 'Tracuateua',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508050,
    label: 'Trairão',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508084,
    label: 'Tucumã',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508100,
    label: 'Tucuruí',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508126,
    label: 'Ulianópolis',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508159,
    label: 'Uruará',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508209,
    label: 'Vigia',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508308,
    label: 'Viseu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508357,
    label: 'Vitória do Xingu',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1508407,
    label: 'Xinguara',
    state: {value: 15, initials: 'PA', label: 'Pará'},
  },
  {
    value: 1600055,
    label: 'Serra do Navio',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600105,
    label: 'Amapá',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600154,
    label: 'Pedra Branca do Amapari',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600204,
    label: 'Calçoene',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600212,
    label: 'Cutias',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600238,
    label: 'Ferreira Gomes',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600253,
    label: 'Itaubal',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600279,
    label: 'Laranjal do Jari',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600303,
    label: 'Macapá',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600402,
    label: 'Mazagão',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600501,
    label: 'Oiapoque',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600535,
    label: 'Porto Grande',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600550,
    label: 'Pracuúba',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600600,
    label: 'Santana',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600709,
    label: 'Tartarugalzinho',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1600808,
    label: 'Vitória do Jari',
    state: {value: 16, initials: 'AP', label: 'Amapá'},
  },
  {
    value: 1700251,
    label: 'Abreulândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1700301,
    label: 'Aguiarnópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1700350,
    label: 'Aliança do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1700400,
    label: 'Almas',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1700707,
    label: 'Alvorada',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1701002,
    label: 'Ananás',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1701051,
    label: 'Angico',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1701101,
    label: 'Aparecida do Rio Negro',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1701309,
    label: 'Aragominas',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1701903,
    label: 'Araguacema',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702000,
    label: 'Araguaçu',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702109,
    label: 'Araguaína',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702158,
    label: 'Araguanã',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702208,
    label: 'Araguatins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702307,
    label: 'Arapoema',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702406,
    label: 'Arraias',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702554,
    label: 'Augustinópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702703,
    label: 'Aurora do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1702901,
    label: 'Axixá do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703008,
    label: 'Babaçulândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703057,
    label: 'Bandeirantes do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703073,
    label: 'Barra do Ouro',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703107,
    label: 'Barrolândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703206,
    label: 'Bernardo Sayão',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703305,
    label: 'Bom Jesus do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703602,
    label: 'Brasilândia do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703701,
    label: 'Brejinho de Nazaré',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703800,
    label: 'Buriti do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703826,
    label: 'Cachoeirinha',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703842,
    label: 'Campos Lindos',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703867,
    label: 'Cariri do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703883,
    label: 'Carmolândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703891,
    label: 'Carrasco Bonito',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1703909,
    label: 'Caseara',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1704105,
    label: 'Centenário',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1704600,
    label: 'Chapada de Areia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1705102,
    label: 'Chapada da Natividade',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1705508,
    label: 'Colinas do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1705557,
    label: 'Combinado',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1705607,
    label: 'Conceição do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1706001,
    label: 'Couto Magalhães',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1706100,
    label: 'Cristalândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1706258,
    label: 'Crixás do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1706506,
    label: 'Darcinópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707009,
    label: 'Dianópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707108,
    label: 'Divinópolis do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707207,
    label: 'Dois Irmãos do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707306,
    label: 'Dueré',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707405,
    label: 'Esperantina',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707553,
    label: 'Fátima',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707652,
    label: 'Figueirópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1707702,
    label: 'Filadélfia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1708205,
    label: 'Formoso do Araguaia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1708254,
    label: 'Fortaleza do Tabocão',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1708304,
    label: 'Goianorte',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1709005,
    label: 'Goiatins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1709302,
    label: 'Guaraí',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1709500,
    label: 'Gurupi',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1709807,
    label: 'Ipueiras',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1710508,
    label: 'Itacajá',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1710706,
    label: 'Itaguatins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1710904,
    label: 'Itapiratins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1711100,
    label: 'Itaporã do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1711506,
    label: 'Jaú do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1711803,
    label: 'Juarina',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1711902,
    label: 'Lagoa da Confusão',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1711951,
    label: 'Lagoa do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712009,
    label: 'Lajeado',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712157,
    label: 'Lavandeira',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712405,
    label: 'Lizarda',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712454,
    label: 'Luzinópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712504,
    label: 'Marianópolis do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712702,
    label: 'Mateiros',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1712801,
    label: 'Maurilândia do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1713205,
    label: 'Miracema do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1713304,
    label: 'Miranorte',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1713601,
    label: 'Monte do Carmo',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1713700,
    label: 'Monte Santo do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1713809,
    label: 'Palmeiras do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1713957,
    label: 'Muricilândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1714203,
    label: 'Natividade',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1714302,
    label: 'Nazaré',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1714880,
    label: 'Nova Olinda',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715002,
    label: 'Nova Rosalândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715101,
    label: 'Novo Acordo',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715150,
    label: 'Novo Alegre',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715259,
    label: 'Novo Jardim',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715507,
    label: 'Oliveira de Fátima',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715705,
    label: 'Palmeirante',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1715754,
    label: 'Palmeirópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716109,
    label: 'Paraíso do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716208,
    label: 'Paranã',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716307,
    label: "Pau D'Arco",
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716505,
    label: 'Pedro Afonso',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716604,
    label: 'Peixe',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716653,
    label: 'Pequizeiro',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1716703,
    label: 'Colméia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1717008,
    label: 'Pindorama do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1717206,
    label: 'Piraquê',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1717503,
    label: 'Pium',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1717800,
    label: 'Ponte Alta do Bom Jesus',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1717909,
    label: 'Ponte Alta do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718006,
    label: 'Porto Alegre do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718204,
    label: 'Porto Nacional',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718303,
    label: 'Praia Norte',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718402,
    label: 'Presidente Kennedy',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718451,
    label: 'Pugmil',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718501,
    label: 'Recursolândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718550,
    label: 'Riachinho',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718659,
    label: 'Rio da Conceição',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718709,
    label: 'Rio dos Bois',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718758,
    label: 'Rio Sono',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718808,
    label: 'Sampaio',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718840,
    label: 'Sandolândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718865,
    label: 'Santa Fé do Araguaia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718881,
    label: 'Santa Maria do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718899,
    label: 'Santa Rita do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1718907,
    label: 'Santa Rosa do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1719004,
    label: 'Santa Tereza do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720002,
    label: 'Santa Terezinha do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720101,
    label: 'São Bento do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720150,
    label: 'São Félix do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720200,
    label: 'São Miguel do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720259,
    label: 'São Salvador do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720309,
    label: 'São Sebastião do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720499,
    label: 'São Valério',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720655,
    label: 'Silvanópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720804,
    label: 'Sítio Novo do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720853,
    label: 'Sucupira',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720903,
    label: 'Taguatinga',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720937,
    label: 'Taipas do Tocantins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1720978,
    label: 'Talismã',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1721000,
    label: 'Palmas',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1721109,
    label: 'Tocantínia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1721208,
    label: 'Tocantinópolis',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1721257,
    label: 'Tupirama',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1721307,
    label: 'Tupiratins',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1722081,
    label: 'Wanderlândia',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 1722107,
    label: 'Xambioá',
    state: {value: 17, initials: 'TO', label: 'Tocantins'},
  },
  {
    value: 2100055,
    label: 'Açailândia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100105,
    label: 'Afonso Cunha',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100154,
    label: 'Água Doce do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100204,
    label: 'Alcântara',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100303,
    label: 'Aldeias Altas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100402,
    label: 'Altamira do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100436,
    label: 'Alto Alegre do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100477,
    label: 'Alto Alegre do Pindaré',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100501,
    label: 'Alto Parnaíba',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100550,
    label: 'Amapá do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100600,
    label: 'Amarante do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100709,
    label: 'Anajatuba',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100808,
    label: 'Anapurus',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100832,
    label: 'Apicum-Açu',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100873,
    label: 'Araguanã',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100907,
    label: 'Araioses',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2100956,
    label: 'Arame',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101004,
    label: 'Arari',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101103,
    label: 'Axixá',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101202,
    label: 'Bacabal',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101251,
    label: 'Bacabeira',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101301,
    label: 'Bacuri',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101350,
    label: 'Bacurituba',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101400,
    label: 'Balsas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101509,
    label: 'Barão de Grajaú',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101608,
    label: 'Barra do Corda',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101707,
    label: 'Barreirinhas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101731,
    label: 'Belágua',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101772,
    label: 'Bela Vista do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101806,
    label: 'Benedito Leite',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101905,
    label: 'Bequimão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101939,
    label: 'Bernardo do Mearim',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2101970,
    label: 'Boa Vista do Gurupi',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102002,
    label: 'Bom Jardim',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102036,
    label: 'Bom Jesus das Selvas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102077,
    label: 'Bom Lugar',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102101,
    label: 'Brejo',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102150,
    label: 'Brejo de Areia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102200,
    label: 'Buriti',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102309,
    label: 'Buriti Bravo',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102325,
    label: 'Buriticupu',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102358,
    label: 'Buritirana',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102374,
    label: 'Cachoeira Grande',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102408,
    label: 'Cajapió',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102507,
    label: 'Cajari',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102556,
    label: 'Campestre do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102606,
    label: 'Cândido Mendes',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102705,
    label: 'Cantanhede',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102754,
    label: 'Capinzal do Norte',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102804,
    label: 'Carolina',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2102903,
    label: 'Carutapera',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103000,
    label: 'Caxias',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103109,
    label: 'Cedral',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103125,
    label: 'Central do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103158,
    label: 'Centro do Guilherme',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103174,
    label: 'Centro Novo do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103208,
    label: 'Chapadinha',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103257,
    label: 'Cidelândia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103307,
    label: 'Codó',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103406,
    label: 'Coelho Neto',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103505,
    label: 'Colinas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103554,
    label: 'Conceição do Lago-Açu',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103604,
    label: 'Coroatá',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103703,
    label: 'Cururupu',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103752,
    label: 'Davinópolis',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103802,
    label: 'Dom Pedro',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2103901,
    label: 'Duque Bacelar',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104008,
    label: 'Esperantinópolis',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104057,
    label: 'Estreito',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104073,
    label: 'Feira Nova do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104081,
    label: 'Fernando Falcão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104099,
    label: 'Formosa da Serra Negra',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104107,
    label: 'Fortaleza dos Nogueiras',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104206,
    label: 'Fortuna',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104305,
    label: 'Godofredo Viana',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104404,
    label: 'Gonçalves Dias',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104503,
    label: 'Governador Archer',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104552,
    label: 'Governador Edison Lobão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104602,
    label: 'Governador Eugênio Barros',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104628,
    label: 'Governador Luiz Rocha',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104651,
    label: 'Governador Newton Bello',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104677,
    label: 'Governador Nunes Freire',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104701,
    label: 'Graça Aranha',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104800,
    label: 'Grajaú',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2104909,
    label: 'Guimarães',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105005,
    label: 'Humberto de Campos',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105104,
    label: 'Icatu',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105153,
    label: 'Igarapé do Meio',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105203,
    label: 'Igarapé Grande',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105302,
    label: 'Imperatriz',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105351,
    label: 'Itaipava do Grajaú',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105401,
    label: 'Itapecuru Mirim',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105427,
    label: 'Itinga do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105450,
    label: 'Jatobá',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105476,
    label: 'Jenipapo dos Vieiras',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105500,
    label: 'João Lisboa',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105609,
    label: 'Joselândia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105658,
    label: 'Junco do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105708,
    label: 'Lago da Pedra',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105807,
    label: 'Lago do Junco',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105906,
    label: 'Lago Verde',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105922,
    label: 'Lagoa do Mato',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105948,
    label: 'Lago dos Rodrigues',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105963,
    label: 'Lagoa Grande do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2105989,
    label: 'Lajeado Novo',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106003,
    label: 'Lima Campos',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106102,
    label: 'Loreto',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106201,
    label: 'Luís Domingues',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106300,
    label: 'Magalhães de Almeida',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106326,
    label: 'Maracaçumé',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106359,
    label: 'Marajá do Sena',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106375,
    label: 'Maranhãozinho',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106409,
    label: 'Mata Roma',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106508,
    label: 'Matinha',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106607,
    label: 'Matões',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106631,
    label: 'Matões do Norte',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106672,
    label: 'Milagres do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106706,
    label: 'Mirador',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106755,
    label: 'Miranda do Norte',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106805,
    label: 'Mirinzal',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2106904,
    label: 'Monção',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107001,
    label: 'Montes Altos',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107100,
    label: 'Morros',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107209,
    label: 'Nina Rodrigues',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107258,
    label: 'Nova Colinas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107308,
    label: 'Nova Iorque',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107357,
    label: 'Nova Olinda do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107407,
    label: "Olho d'Água das Cunhãs",
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107456,
    label: 'Olinda Nova do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107506,
    label: 'Paço do Lumiar',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107605,
    label: 'Palmeirândia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107704,
    label: 'Paraibano',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107803,
    label: 'Parnarama',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2107902,
    label: 'Passagem Franca',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108009,
    label: 'Pastos Bons',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108058,
    label: 'Paulino Neves',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108108,
    label: 'Paulo Ramos',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108207,
    label: 'Pedreiras',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108256,
    label: 'Pedro do Rosário',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108306,
    label: 'Penalva',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108405,
    label: 'Peri Mirim',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108454,
    label: 'Peritoró',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108504,
    label: 'Pindaré-Mirim',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108603,
    label: 'Pinheiro',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108702,
    label: 'Pio XII',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108801,
    label: 'Pirapemas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2108900,
    label: 'Poção de Pedras',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109007,
    label: 'Porto Franco',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109056,
    label: 'Porto Rico do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109106,
    label: 'Presidente Dutra',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109205,
    label: 'Presidente Juscelino',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109239,
    label: 'Presidente Médici',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109270,
    label: 'Presidente Sarney',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109304,
    label: 'Presidente Vargas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109403,
    label: 'Primeira Cruz',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109452,
    label: 'Raposa',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109502,
    label: 'Riachão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109551,
    label: 'Ribamar Fiquene',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109601,
    label: 'Rosário',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109700,
    label: 'Sambaíba',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109759,
    label: 'Santa Filomena do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109809,
    label: 'Santa Helena',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2109908,
    label: 'Santa Inês',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110005,
    label: 'Santa Luzia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110039,
    label: 'Santa Luzia do Paruá',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110104,
    label: 'Santa Quitéria do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110203,
    label: 'Santa Rita',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110237,
    label: 'Santana do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110278,
    label: 'Santo Amaro do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110302,
    label: 'Santo Antônio dos Lopes',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110401,
    label: 'São Benedito do Rio Preto',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110500,
    label: 'São Bento',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110609,
    label: 'São Bernardo',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110658,
    label: 'São Domingos do Azeitão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110708,
    label: 'São Domingos do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110807,
    label: 'São Félix de Balsas',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110856,
    label: 'São Francisco do Brejão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2110906,
    label: 'São Francisco do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111003,
    label: 'São João Batista',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111029,
    label: 'São João do Carú',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111052,
    label: 'São João do Paraíso',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111078,
    label: 'São João do Soter',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111102,
    label: 'São João dos Patos',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111201,
    label: 'São José de Ribamar',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111250,
    label: 'São José dos Basílios',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111300,
    label: 'São Luís',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111409,
    label: 'São Luís Gonzaga do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111508,
    label: 'São Mateus do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111532,
    label: 'São Pedro da Água Branca',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111573,
    label: 'São Pedro dos Crentes',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111607,
    label: 'São Raimundo das Mangabeiras',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111631,
    label: 'São Raimundo do Doca Bezerra',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111672,
    label: 'São Roberto',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111706,
    label: 'São Vicente Ferrer',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111722,
    label: 'Satubinha',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111748,
    label: 'Senador Alexandre Costa',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111763,
    label: 'Senador La Rocque',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111789,
    label: 'Serrano do Maranhão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111805,
    label: 'Sítio Novo',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111904,
    label: 'Sucupira do Norte',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2111953,
    label: 'Sucupira do Riachão',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112001,
    label: 'Tasso Fragoso',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112100,
    label: 'Timbiras',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112209,
    label: 'Timon',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112233,
    label: 'Trizidela do Vale',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112274,
    label: 'Tufilândia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112308,
    label: 'Tuntum',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112407,
    label: 'Turiaçu',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112456,
    label: 'Turilândia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112506,
    label: 'Tutóia',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112605,
    label: 'Urbano Santos',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112704,
    label: 'Vargem Grande',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112803,
    label: 'Viana',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112852,
    label: 'Vila Nova dos Martírios',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2112902,
    label: 'Vitória do Mearim',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2113009,
    label: 'Vitorino Freire',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2114007,
    label: 'Zé Doca',
    state: {value: 21, initials: 'MA', label: 'Maranhão'},
  },
  {
    value: 2200053,
    label: 'Acauã',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200103,
    label: 'Agricolândia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200202,
    label: 'Água Branca',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200251,
    label: 'Alagoinha do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200277,
    label: 'Alegrete do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200301,
    label: 'Alto Longá',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200400,
    label: 'Altos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200459,
    label: 'Alvorada do Gurguéia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200509,
    label: 'Amarante',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200608,
    label: 'Angical do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200707,
    label: 'Anísio de Abreu',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200806,
    label: 'Antônio Almeida',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200905,
    label: 'Aroazes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2200954,
    label: 'Aroeiras do Itaim',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201002,
    label: 'Arraial',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201051,
    label: 'Assunção do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201101,
    label: 'Avelino Lopes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201150,
    label: 'Baixa Grande do Ribeiro',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201176,
    label: "Barra D'Alcântara",
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201200,
    label: 'Barras',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201309,
    label: 'Barreiras do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201408,
    label: 'Barro Duro',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201507,
    label: 'Batalha',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201556,
    label: 'Bela Vista do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201572,
    label: 'Belém do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201606,
    label: 'Beneditinos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201705,
    label: 'Bertolínia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201739,
    label: 'Betânia do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201770,
    label: 'Boa Hora',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201804,
    label: 'Bocaina',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201903,
    label: 'Bom Jesus',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201919,
    label: 'Bom Princípio do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201929,
    label: 'Bonfim do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201945,
    label: 'Boqueirão do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201960,
    label: 'Brasileira',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2201988,
    label: 'Brejo do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202000,
    label: 'Buriti dos Lopes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202026,
    label: 'Buriti dos Montes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202059,
    label: 'Cabeceiras do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202075,
    label: 'Cajazeiras do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202083,
    label: 'Cajueiro da Praia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202091,
    label: 'Caldeirão Grande do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202109,
    label: 'Campinas do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202117,
    label: 'Campo Alegre do Fidalgo',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202133,
    label: 'Campo Grande do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202174,
    label: 'Campo Largo do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202208,
    label: 'Campo Maior',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202251,
    label: 'Canavieira',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202307,
    label: 'Canto do Buriti',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202406,
    label: 'Capitão de Campos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202455,
    label: 'Capitão Gervásio Oliveira',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202505,
    label: 'Caracol',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202539,
    label: 'Caraúbas do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202554,
    label: 'Caridade do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202604,
    label: 'Castelo do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202653,
    label: 'Caxingó',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202703,
    label: 'Cocal',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202711,
    label: 'Cocal de Telha',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202729,
    label: 'Cocal dos Alves',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202737,
    label: 'Coivaras',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202752,
    label: 'Colônia do Gurguéia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202778,
    label: 'Colônia do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202802,
    label: 'Conceição do Canindé',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202851,
    label: 'Coronel José Dias',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2202901,
    label: 'Corrente',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203008,
    label: 'Cristalândia do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203107,
    label: 'Cristino Castro',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203206,
    label: 'Curimatá',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203230,
    label: 'Currais',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203255,
    label: 'Curralinhos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203271,
    label: 'Curral Novo do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203305,
    label: 'Demerval Lobão',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203354,
    label: 'Dirceu Arcoverde',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203404,
    label: 'Dom Expedito Lopes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203420,
    label: 'Domingos Mourão',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203453,
    label: 'Dom Inocêncio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203503,
    label: 'Elesbão Veloso',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203602,
    label: 'Eliseu Martins',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203701,
    label: 'Esperantina',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203750,
    label: 'Fartura do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203800,
    label: 'Flores do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203859,
    label: 'Floresta do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2203909,
    label: 'Floriano',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204006,
    label: 'Francinópolis',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204105,
    label: 'Francisco Ayres',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204154,
    label: 'Francisco Macedo',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204204,
    label: 'Francisco Santos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204303,
    label: 'Fronteiras',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204352,
    label: 'Geminiano',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204402,
    label: 'Gilbués',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204501,
    label: 'Guadalupe',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204550,
    label: 'Guaribas',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204600,
    label: 'Hugo Napoleão',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204659,
    label: 'Ilha Grande',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204709,
    label: 'Inhuma',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204808,
    label: 'Ipiranga do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2204907,
    label: 'Isaías Coelho',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205003,
    label: 'Itainópolis',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205102,
    label: 'Itaueira',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205151,
    label: 'Jacobina do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205201,
    label: 'Jaicós',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205250,
    label: 'Jardim do Mulato',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205276,
    label: 'Jatobá do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205300,
    label: 'Jerumenha',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205359,
    label: 'João Costa',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205409,
    label: 'Joaquim Pires',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205458,
    label: 'Joca Marques',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205508,
    label: 'José de Freitas',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205516,
    label: 'Juazeiro do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205524,
    label: 'Júlio Borges',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205532,
    label: 'Jurema',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205540,
    label: 'Lagoinha do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205557,
    label: 'Lagoa Alegre',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205565,
    label: 'Lagoa do Barro do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205573,
    label: 'Lagoa de São Francisco',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205581,
    label: 'Lagoa do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205599,
    label: 'Lagoa do Sítio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205607,
    label: 'Landri Sales',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205706,
    label: 'Luís Correia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205805,
    label: 'Luzilândia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205854,
    label: 'Madeiro',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205904,
    label: 'Manoel Emídio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2205953,
    label: 'Marcolândia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206001,
    label: 'Marcos Parente',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206050,
    label: 'Massapê do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206100,
    label: 'Matias Olímpio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206209,
    label: 'Miguel Alves',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206308,
    label: 'Miguel Leão',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206357,
    label: 'Milton Brandão',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206407,
    label: 'Monsenhor Gil',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206506,
    label: 'Monsenhor Hipólito',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206605,
    label: 'Monte Alegre do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206654,
    label: 'Morro Cabeça no Tempo',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206670,
    label: 'Morro do Chapéu do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206696,
    label: 'Murici dos Portelas',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206704,
    label: 'Nazaré do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206720,
    label: 'Nazária',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206753,
    label: 'Nossa Senhora de Nazaré',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206803,
    label: 'Nossa Senhora dos Remédios',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206902,
    label: 'Novo Oriente do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2206951,
    label: 'Novo Santo Antônio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207009,
    label: 'Oeiras',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207108,
    label: "Olho D'Água do Piauí",
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207207,
    label: 'Padre Marcos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207306,
    label: 'Paes Landim',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207355,
    label: 'Pajeú do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207405,
    label: 'Palmeira do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207504,
    label: 'Palmeirais',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207553,
    label: 'Paquetá',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207603,
    label: 'Parnaguá',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207702,
    label: 'Parnaíba',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207751,
    label: 'Passagem Franca do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207777,
    label: 'Patos do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207793,
    label: "Pau D'Arco do Piauí",
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207801,
    label: 'Paulistana',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207850,
    label: 'Pavussu',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207900,
    label: 'Pedro II',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207934,
    label: 'Pedro Laurentino',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2207959,
    label: 'Nova Santa Rita',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208007,
    label: 'Picos',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208106,
    label: 'Pimenteiras',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208205,
    label: 'Pio IX',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208304,
    label: 'Piracuruca',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208403,
    label: 'Piripiri',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208502,
    label: 'Porto',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208551,
    label: 'Porto Alegre do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208601,
    label: 'Prata do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208650,
    label: 'Queimada Nova',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208700,
    label: 'Redenção do Gurguéia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208809,
    label: 'Regeneração',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208858,
    label: 'Riacho Frio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208874,
    label: 'Ribeira do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2208908,
    label: 'Ribeiro Gonçalves',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209005,
    label: 'Rio Grande do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209104,
    label: 'Santa Cruz do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209153,
    label: 'Santa Cruz dos Milagres',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209203,
    label: 'Santa Filomena',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209302,
    label: 'Santa Luz',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209351,
    label: 'Santana do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209377,
    label: 'Santa Rosa do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209401,
    label: 'Santo Antônio de Lisboa',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209450,
    label: 'Santo Antônio dos Milagres',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209500,
    label: 'Santo Inácio do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209559,
    label: 'São Braz do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209609,
    label: 'São Félix do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209658,
    label: 'São Francisco de Assis do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209708,
    label: 'São Francisco do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209757,
    label: 'São Gonçalo do Gurguéia',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209807,
    label: 'São Gonçalo do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209856,
    label: 'São João da Canabrava',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209872,
    label: 'São João da Fronteira',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209906,
    label: 'São João da Serra',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209955,
    label: 'São João da Varjota',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2209971,
    label: 'São João do Arraial',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210003,
    label: 'São João do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210052,
    label: 'São José do Divino',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210102,
    label: 'São José do Peixe',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210201,
    label: 'São José do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210300,
    label: 'São Julião',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210359,
    label: 'São Lourenço do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210375,
    label: 'São Luis do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210383,
    label: 'São Miguel da Baixa Grande',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210391,
    label: 'São Miguel do Fidalgo',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210409,
    label: 'São Miguel do Tapuio',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210508,
    label: 'São Pedro do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210607,
    label: 'São Raimundo Nonato',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210623,
    label: 'Sebastião Barros',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210631,
    label: 'Sebastião Leal',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210656,
    label: 'Sigefredo Pacheco',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210706,
    label: 'Simões',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210805,
    label: 'Simplício Mendes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210904,
    label: 'Socorro do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210938,
    label: 'Sussuapara',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210953,
    label: 'Tamboril do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2210979,
    label: 'Tanque do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211001,
    label: 'Teresina',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211100,
    label: 'União',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211209,
    label: 'Uruçuí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211308,
    label: 'Valença do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211357,
    label: 'Várzea Branca',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211407,
    label: 'Várzea Grande',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211506,
    label: 'Vera Mendes',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211605,
    label: 'Vila Nova do Piauí',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2211704,
    label: 'Wall Ferraz',
    state: {value: 22, initials: 'PI', label: 'Piauí'},
  },
  {
    value: 2300101,
    label: 'Abaiara',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300150,
    label: 'Acarape',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300200,
    label: 'Acaraú',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300309,
    label: 'Acopiara',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300408,
    label: 'Aiuaba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300507,
    label: 'Alcântaras',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300606,
    label: 'Altaneira',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300705,
    label: 'Alto Santo',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300754,
    label: 'Amontada',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300804,
    label: 'Antonina do Norte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2300903,
    label: 'Apuiarés',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301000,
    label: 'Aquiraz',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301109,
    label: 'Aracati',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301208,
    label: 'Aracoiaba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301257,
    label: 'Ararendá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301307,
    label: 'Araripe',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301406,
    label: 'Aratuba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301505,
    label: 'Arneiroz',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301604,
    label: 'Assaré',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301703,
    label: 'Aurora',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301802,
    label: 'Baixio',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301851,
    label: 'Banabuiú',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301901,
    label: 'Barbalha',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2301950,
    label: 'Barreira',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302008,
    label: 'Barro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302057,
    label: 'Barroquinha',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302107,
    label: 'Baturité',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302206,
    label: 'Beberibe',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302305,
    label: 'Bela Cruz',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302404,
    label: 'Boa Viagem',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302503,
    label: 'Brejo Santo',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302602,
    label: 'Camocim',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302701,
    label: 'Campos Sales',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302800,
    label: 'Canindé',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2302909,
    label: 'Capistrano',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303006,
    label: 'Caridade',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303105,
    label: 'Cariré',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303204,
    label: 'Caririaçu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303303,
    label: 'Cariús',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303402,
    label: 'Carnaubal',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303501,
    label: 'Cascavel',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303600,
    label: 'Catarina',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303659,
    label: 'Catunda',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303709,
    label: 'Caucaia',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303808,
    label: 'Cedro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303907,
    label: 'Chaval',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303931,
    label: 'Choró',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2303956,
    label: 'Chorozinho',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304004,
    label: 'Coreaú',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304103,
    label: 'Crateús',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304202,
    label: 'Crato',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304236,
    label: 'Croatá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304251,
    label: 'Cruz',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304269,
    label: 'Deputado Irapuan Pinheiro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304277,
    label: 'Ererê',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304285,
    label: 'Eusébio',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304301,
    label: 'Farias Brito',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304350,
    label: 'Forquilha',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304400,
    label: 'Fortaleza',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304459,
    label: 'Fortim',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304509,
    label: 'Frecheirinha',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304608,
    label: 'General Sampaio',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304657,
    label: 'Graça',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304707,
    label: 'Granja',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304806,
    label: 'Granjeiro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304905,
    label: 'Groaíras',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2304954,
    label: 'Guaiúba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305001,
    label: 'Guaraciaba do Norte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305100,
    label: 'Guaramiranga',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305209,
    label: 'Hidrolândia',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305233,
    label: 'Horizonte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305266,
    label: 'Ibaretama',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305308,
    label: 'Ibiapina',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305332,
    label: 'Ibicuitinga',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305357,
    label: 'Icapuí',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305407,
    label: 'Icó',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305506,
    label: 'Iguatu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305605,
    label: 'Independência',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305654,
    label: 'Ipaporanga',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305704,
    label: 'Ipaumirim',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305803,
    label: 'Ipu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2305902,
    label: 'Ipueiras',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306009,
    label: 'Iracema',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306108,
    label: 'Irauçuba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306207,
    label: 'Itaiçaba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306256,
    label: 'Itaitinga',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306306,
    label: 'Itapajé',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306405,
    label: 'Itapipoca',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306504,
    label: 'Itapiúna',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306553,
    label: 'Itarema',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306603,
    label: 'Itatira',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306702,
    label: 'Jaguaretama',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306801,
    label: 'Jaguaribara',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2306900,
    label: 'Jaguaribe',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307007,
    label: 'Jaguaruana',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307106,
    label: 'Jardim',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307205,
    label: 'Jati',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307254,
    label: 'Jijoca de Jericoacoara',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307304,
    label: 'Juazeiro do Norte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307403,
    label: 'Jucás',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307502,
    label: 'Lavras da Mangabeira',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307601,
    label: 'Limoeiro do Norte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307635,
    label: 'Madalena',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307650,
    label: 'Maracanaú',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307700,
    label: 'Maranguape',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307809,
    label: 'Marco',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2307908,
    label: 'Martinópole',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308005,
    label: 'Massapê',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308104,
    label: 'Mauriti',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308203,
    label: 'Meruoca',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308302,
    label: 'Milagres',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308351,
    label: 'Milhã',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308377,
    label: 'Miraíma',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308401,
    label: 'Missão Velha',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308500,
    label: 'Mombaça',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308609,
    label: 'Monsenhor Tabosa',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308708,
    label: 'Morada Nova',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308807,
    label: 'Moraújo',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2308906,
    label: 'Morrinhos',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309003,
    label: 'Mucambo',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309102,
    label: 'Mulungu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309201,
    label: 'Nova Olinda',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309300,
    label: 'Nova Russas',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309409,
    label: 'Novo Oriente',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309458,
    label: 'Ocara',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309508,
    label: 'Orós',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309607,
    label: 'Pacajus',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309706,
    label: 'Pacatuba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309805,
    label: 'Pacoti',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2309904,
    label: 'Pacujá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310001,
    label: 'Palhano',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310100,
    label: 'Palmácia',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310209,
    label: 'Paracuru',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310258,
    label: 'Paraipaba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310308,
    label: 'Parambu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310407,
    label: 'Paramoti',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310506,
    label: 'Pedra Branca',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310605,
    label: 'Penaforte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310704,
    label: 'Pentecoste',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310803,
    label: 'Pereiro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310852,
    label: 'Pindoretama',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310902,
    label: 'Piquet Carneiro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2310951,
    label: 'Pires Ferreira',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311009,
    label: 'Poranga',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311108,
    label: 'Porteiras',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311207,
    label: 'Potengi',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311231,
    label: 'Potiretama',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311264,
    label: 'Quiterianópolis',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311306,
    label: 'Quixadá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311355,
    label: 'Quixelô',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311405,
    label: 'Quixeramobim',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311504,
    label: 'Quixeré',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311603,
    label: 'Redenção',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311702,
    label: 'Reriutaba',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311801,
    label: 'Russas',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311900,
    label: 'Saboeiro',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2311959,
    label: 'Salitre',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312007,
    label: 'Santana do Acaraú',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312106,
    label: 'Santana do Cariri',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312205,
    label: 'Santa Quitéria',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312304,
    label: 'São Benedito',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312403,
    label: 'São Gonçalo do Amarante',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312502,
    label: 'São João do Jaguaribe',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312601,
    label: 'São Luís do Curu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312700,
    label: 'Senador Pompeu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312809,
    label: 'Senador Sá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2312908,
    label: 'Sobral',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313005,
    label: 'Solonópole',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313104,
    label: 'Tabuleiro do Norte',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313203,
    label: 'Tamboril',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313252,
    label: 'Tarrafas',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313302,
    label: 'Tauá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313351,
    label: 'Tejuçuoca',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313401,
    label: 'Tianguá',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313500,
    label: 'Trairi',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313559,
    label: 'Tururu',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313609,
    label: 'Ubajara',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313708,
    label: 'Umari',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313757,
    label: 'Umirim',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313807,
    label: 'Uruburetama',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313906,
    label: 'Uruoca',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2313955,
    label: 'Varjota',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2314003,
    label: 'Várzea Alegre',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2314102,
    label: 'Viçosa do Ceará',
    state: {value: 23, initials: 'CE', label: 'Ceará'},
  },
  {
    value: 2400109,
    label: 'Acari',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400208,
    label: 'Açu',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400307,
    label: 'Afonso Bezerra',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400406,
    label: 'Água Nova',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400505,
    label: 'Alexandria',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400604,
    label: 'Almino Afonso',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400703,
    label: 'Alto do Rodrigues',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400802,
    label: 'Angicos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2400901,
    label: 'Antônio Martins',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401008,
    label: 'Apodi',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401107,
    label: 'Areia Branca',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401206,
    label: 'Arês',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401305,
    label: 'Augusto Severo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401404,
    label: 'Baía Formosa',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401453,
    label: 'Baraúna',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401503,
    label: 'Barcelona',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401602,
    label: 'Bento Fernandes',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401651,
    label: 'Bodó',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401701,
    label: 'Bom Jesus',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401800,
    label: 'Brejinho',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401859,
    label: 'Caiçara do Norte',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2401909,
    label: 'Caiçara do Rio do Vento',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402006,
    label: 'Caicó',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402105,
    label: 'Campo Redondo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402204,
    label: 'Canguaretama',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402303,
    label: 'Caraúbas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402402,
    label: 'Carnaúba dos Dantas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402501,
    label: 'Carnaubais',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402600,
    label: 'Ceará-Mirim',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402709,
    label: 'Cerro Corá',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402808,
    label: 'Coronel Ezequiel',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2402907,
    label: 'Coronel João Pessoa',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403004,
    label: 'Cruzeta',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403103,
    label: 'Currais Novos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403202,
    label: 'Doutor Severiano',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403251,
    label: 'Parnamirim',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403301,
    label: 'Encanto',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403400,
    label: 'Equador',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403509,
    label: 'Espírito Santo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403608,
    label: 'Extremoz',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403707,
    label: 'Felipe Guerra',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403756,
    label: 'Fernando Pedroza',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403806,
    label: 'Florânia',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2403905,
    label: 'Francisco Dantas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404002,
    label: 'Frutuoso Gomes',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404101,
    label: 'Galinhos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404200,
    label: 'Goianinha',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404309,
    label: 'Governador Dix-Sept Rosado',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404408,
    label: 'Grossos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404507,
    label: 'Guamaré',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404606,
    label: 'Ielmo Marinho',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404705,
    label: 'Ipanguaçu',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404804,
    label: 'Ipueira',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404853,
    label: 'Itajá',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2404903,
    label: 'Itaú',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405009,
    label: 'Jaçanã',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405108,
    label: 'Jandaíra',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405207,
    label: 'Janduís',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405306,
    label: 'Januário Cicco',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405405,
    label: 'Japi',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405504,
    label: 'Jardim de Angicos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405603,
    label: 'Jardim de Piranhas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405702,
    label: 'Jardim do Seridó',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405801,
    label: 'João Câmara',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2405900,
    label: 'João Dias',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406007,
    label: 'José da Penha',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406106,
    label: 'Jucurutu',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406155,
    label: 'Jundiá',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406205,
    label: "Lagoa d'Anta",
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406304,
    label: 'Lagoa de Pedras',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406403,
    label: 'Lagoa de Velhos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406502,
    label: 'Lagoa Nova',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406601,
    label: 'Lagoa Salgada',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406700,
    label: 'Lajes',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406809,
    label: 'Lajes Pintadas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2406908,
    label: 'Lucrécia',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407005,
    label: 'Luís Gomes',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407104,
    label: 'Macaíba',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407203,
    label: 'Macau',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407252,
    label: 'Major Sales',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407302,
    label: 'Marcelino Vieira',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407401,
    label: 'Martins',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407500,
    label: 'Maxaranguape',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407609,
    label: 'Messias Targino',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407708,
    label: 'Montanhas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407807,
    label: 'Monte Alegre',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2407906,
    label: 'Monte das Gameleiras',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408003,
    label: 'Mossoró',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408102,
    label: 'Natal',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408201,
    label: 'Nísia Floresta',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408300,
    label: 'Nova Cruz',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408409,
    label: "Olho d'Água do Borges",
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408508,
    label: 'Ouro Branco',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408607,
    label: 'Paraná',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408706,
    label: 'Paraú',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408805,
    label: 'Parazinho',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408904,
    label: 'Parelhas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2408953,
    label: 'Rio do Fogo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409100,
    label: 'Passa e Fica',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409209,
    label: 'Passagem',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409308,
    label: 'Patu',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409332,
    label: 'Santa Maria',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409407,
    label: 'Pau dos Ferros',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409506,
    label: 'Pedra Grande',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409605,
    label: 'Pedra Preta',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409704,
    label: 'Pedro Avelino',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409803,
    label: 'Pedro Velho',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2409902,
    label: 'Pendências',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410009,
    label: 'Pilões',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410108,
    label: 'Poço Branco',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410207,
    label: 'Portalegre',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410256,
    label: 'Porto do Mangue',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410306,
    label: 'Serra Caiada',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410405,
    label: 'Pureza',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410504,
    label: 'Rafael Fernandes',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410603,
    label: 'Rafael Godeiro',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410702,
    label: 'Riacho da Cruz',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410801,
    label: 'Riacho de Santana',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2410900,
    label: 'Riachuelo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411007,
    label: 'Rodolfo Fernandes',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411056,
    label: 'Tibau',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411106,
    label: 'Ruy Barbosa',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411205,
    label: 'Santa Cruz',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411403,
    label: 'Santana do Matos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411429,
    label: 'Santana do Seridó',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411502,
    label: 'Santo Antônio',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411601,
    label: 'São Bento do Norte',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411700,
    label: 'São Bento do Trairí',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411809,
    label: 'São Fernando',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2411908,
    label: 'São Francisco do Oeste',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412005,
    label: 'São Gonçalo do Amarante',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412104,
    label: 'São João do Sabugi',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412203,
    label: 'São José de Mipibu',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412302,
    label: 'São José do Campestre',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412401,
    label: 'São José do Seridó',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412500,
    label: 'São Miguel',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412559,
    label: 'São Miguel do Gostoso',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412609,
    label: 'São Paulo do Potengi',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412708,
    label: 'São Pedro',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412807,
    label: 'São Rafael',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2412906,
    label: 'São Tomé',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413003,
    label: 'São Vicente',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413102,
    label: 'Senador Elói de Souza',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413201,
    label: 'Senador Georgino Avelino',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413300,
    label: 'Serra de São Bento',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413359,
    label: 'Serra do Mel',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413409,
    label: 'Serra Negra do Norte',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413508,
    label: 'Serrinha',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413557,
    label: 'Serrinha dos Pintos',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413607,
    label: 'Severiano Melo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413706,
    label: 'Sítio Novo',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413805,
    label: 'Taboleiro Grande',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2413904,
    label: 'Taipu',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414001,
    label: 'Tangará',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414100,
    label: 'Tenente Ananias',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414159,
    label: 'Tenente Laurentino Cruz',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414209,
    label: 'Tibau do Sul',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414308,
    label: 'Timbaúba dos Batistas',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414407,
    label: 'Touros',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414456,
    label: 'Triunfo Potiguar',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414506,
    label: 'Umarizal',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414605,
    label: 'Upanema',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414704,
    label: 'Várzea',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414753,
    label: 'Venha-Ver',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414803,
    label: 'Vera Cruz',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2414902,
    label: 'Viçosa',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2415008,
    label: 'Vila Flor',
    state: {value: 24, initials: 'RN', label: 'Rio Grande do Norte'},
  },
  {
    value: 2500106,
    label: 'Água Branca',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500205,
    label: 'Aguiar',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500304,
    label: 'Alagoa Grande',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500403,
    label: 'Alagoa Nova',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500502,
    label: 'Alagoinha',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500536,
    label: 'Alcantil',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500577,
    label: 'Algodão de Jandaíra',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500601,
    label: 'Alhandra',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500700,
    label: 'São João do Rio do Peixe',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500734,
    label: 'Amparo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500775,
    label: 'Aparecida',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500809,
    label: 'Araçagi',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2500908,
    label: 'Arara',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501005,
    label: 'Araruna',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501104,
    label: 'Areia',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501153,
    label: 'Areia de Baraúnas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501203,
    label: 'Areial',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501302,
    label: 'Aroeiras',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501351,
    label: 'Assunção',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501401,
    label: 'Baía da Traição',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501500,
    label: 'Bananeiras',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501534,
    label: 'Baraúna',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501575,
    label: 'Barra de Santana',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501609,
    label: 'Barra de Santa Rosa',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501708,
    label: 'Barra de São Miguel',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501807,
    label: 'Bayeux',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2501906,
    label: 'Belém',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502003,
    label: 'Belém do Brejo do Cruz',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502052,
    label: 'Bernardino Batista',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502102,
    label: 'Boa Ventura',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502151,
    label: 'Boa Vista',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502201,
    label: 'Bom Jesus',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502300,
    label: 'Bom Sucesso',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502409,
    label: 'Bonito de Santa Fé',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502508,
    label: 'Boqueirão',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502607,
    label: 'Igaracy',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502706,
    label: 'Borborema',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502805,
    label: 'Brejo do Cruz',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2502904,
    label: 'Brejo dos Santos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503001,
    label: 'Caaporã',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503100,
    label: 'Cabaceiras',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503209,
    label: 'Cabedelo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503308,
    label: 'Cachoeira dos Índios',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503407,
    label: 'Cacimba de Areia',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503506,
    label: 'Cacimba de Dentro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503555,
    label: 'Cacimbas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503605,
    label: 'Caiçara',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503704,
    label: 'Cajazeiras',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503753,
    label: 'Cajazeirinhas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503803,
    label: 'Caldas Brandão',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2503902,
    label: 'Camalaú',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504009,
    label: 'Campina Grande',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504033,
    label: 'Capim',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504074,
    label: 'Caraúbas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504108,
    label: 'Carrapateira',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504157,
    label: 'Casserengue',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504207,
    label: 'Catingueira',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504306,
    label: 'Catolé do Rocha',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504355,
    label: 'Caturité',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504405,
    label: 'Conceição',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504504,
    label: 'Condado',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504603,
    label: 'Conde',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504702,
    label: 'Congo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504801,
    label: 'Coremas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504850,
    label: 'Coxixola',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2504900,
    label: 'Cruz do Espírito Santo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505006,
    label: 'Cubati',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505105,
    label: 'Cuité',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505204,
    label: 'Cuitegi',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505238,
    label: 'Cuité de Mamanguape',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505279,
    label: 'Curral de Cima',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505303,
    label: 'Curral Velho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505352,
    label: 'Damião',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505402,
    label: 'Desterro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505501,
    label: 'Vista Serrana',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505600,
    label: 'Diamante',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505709,
    label: 'Dona Inês',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505808,
    label: 'Duas Estradas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2505907,
    label: 'Emas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506004,
    label: 'Esperança',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506103,
    label: 'Fagundes',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506202,
    label: 'Frei Martinho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506251,
    label: 'Gado Bravo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506301,
    label: 'Guarabira',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506400,
    label: 'Gurinhém',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506509,
    label: 'Gurjão',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506608,
    label: 'Ibiara',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506707,
    label: 'Imaculada',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506806,
    label: 'Ingá',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2506905,
    label: 'Itabaiana',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507002,
    label: 'Itaporanga',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507101,
    label: 'Itapororoca',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507200,
    label: 'Itatuba',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507309,
    label: 'Jacaraú',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507408,
    label: 'Jericó',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507507,
    label: 'João Pessoa',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507606,
    label: 'Juarez Távora',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507705,
    label: 'Juazeirinho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507804,
    label: 'Junco do Seridó',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2507903,
    label: 'Juripiranga',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508000,
    label: 'Juru',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508109,
    label: 'Lagoa',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508208,
    label: 'Lagoa de Dentro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508307,
    label: 'Lagoa Seca',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508406,
    label: 'Lastro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508505,
    label: 'Livramento',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508554,
    label: 'Logradouro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508604,
    label: 'Lucena',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508703,
    label: "Mãe d'Água",
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508802,
    label: 'Malta',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2508901,
    label: 'Mamanguape',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509008,
    label: 'Manaíra',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509057,
    label: 'Marcação',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509107,
    label: 'Mari',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509156,
    label: 'Marizópolis',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509206,
    label: 'Massaranduba',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509305,
    label: 'Mataraca',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509339,
    label: 'Matinhas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509370,
    label: 'Mato Grosso',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509396,
    label: 'Maturéia',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509404,
    label: 'Mogeiro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509503,
    label: 'Montadas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509602,
    label: 'Monte Horebe',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509701,
    label: 'Monteiro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509800,
    label: 'Mulungu',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2509909,
    label: 'Natuba',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510006,
    label: 'Nazarezinho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510105,
    label: 'Nova Floresta',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510204,
    label: 'Nova Olinda',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510303,
    label: 'Nova Palmeira',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510402,
    label: "Olho d'Água",
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510501,
    label: 'Olivedos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510600,
    label: 'Ouro Velho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510659,
    label: 'Parari',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510709,
    label: 'Passagem',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510808,
    label: 'Patos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2510907,
    label: 'Paulista',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511004,
    label: 'Pedra Branca',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511103,
    label: 'Pedra Lavrada',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511202,
    label: 'Pedras de Fogo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511301,
    label: 'Piancó',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511400,
    label: 'Picuí',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511509,
    label: 'Pilar',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511608,
    label: 'Pilões',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511707,
    label: 'Pilõezinhos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511806,
    label: 'Pirpirituba',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2511905,
    label: 'Pitimbu',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512002,
    label: 'Pocinhos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512036,
    label: 'Poço Dantas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512077,
    label: 'Poço de José de Moura',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512101,
    label: 'Pombal',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512200,
    label: 'Prata',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512309,
    label: 'Princesa Isabel',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512408,
    label: 'Puxinanã',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512507,
    label: 'Queimadas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512606,
    label: 'Quixaba',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512705,
    label: 'Remígio',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512721,
    label: 'Pedro Régis',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512747,
    label: 'Riachão',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512754,
    label: 'Riachão do Bacamarte',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512762,
    label: 'Riachão do Poço',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512788,
    label: 'Riacho de Santo Antônio',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512804,
    label: 'Riacho dos Cavalos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2512903,
    label: 'Rio Tinto',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513000,
    label: 'Salgadinho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513109,
    label: 'Salgado de São Félix',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513158,
    label: 'Santa Cecília',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513208,
    label: 'Santa Cruz',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513307,
    label: 'Santa Helena',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513356,
    label: 'Santa Inês',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513406,
    label: 'Santa Luzia',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513505,
    label: 'Santana de Mangueira',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513604,
    label: 'Santana dos Garrotes',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513653,
    label: 'Joca Claudino',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513703,
    label: 'Santa Rita',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513802,
    label: 'Santa Teresinha',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513851,
    label: 'Santo André',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513901,
    label: 'São Bento',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513927,
    label: 'São Bentinho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513943,
    label: 'São Domingos do Cariri',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513968,
    label: 'São Domingos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2513984,
    label: 'São Francisco',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514008,
    label: 'São João do Cariri',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514107,
    label: 'São João do Tigre',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514206,
    label: 'São José da Lagoa Tapada',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514305,
    label: 'São José de Caiana',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514404,
    label: 'São José de Espinharas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514453,
    label: 'São José dos Ramos',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514503,
    label: 'São José de Piranhas',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514552,
    label: 'São José de Princesa',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514602,
    label: 'São José do Bonfim',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514651,
    label: 'São José do Brejo do Cruz',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514701,
    label: 'São José do Sabugi',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514800,
    label: 'São José dos Cordeiros',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2514909,
    label: 'São Mamede',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515005,
    label: 'São Miguel de Taipu',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515104,
    label: 'São Sebastião de Lagoa de Roça',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515203,
    label: 'São Sebastião do Umbuzeiro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515302,
    label: 'Sapé',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515401,
    label: 'São Vicente do Seridó',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515500,
    label: 'Serra Branca',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515609,
    label: 'Serra da Raiz',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515708,
    label: 'Serra Grande',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515807,
    label: 'Serra Redonda',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515906,
    label: 'Serraria',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515930,
    label: 'Sertãozinho',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2515971,
    label: 'Sobrado',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516003,
    label: 'Solânea',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516102,
    label: 'Soledade',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516151,
    label: 'Sossêgo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516201,
    label: 'Sousa',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516300,
    label: 'Sumé',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516409,
    label: 'Tacima',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516508,
    label: 'Taperoá',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516607,
    label: 'Tavares',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516706,
    label: 'Teixeira',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516755,
    label: 'Tenório',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516805,
    label: 'Triunfo',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2516904,
    label: 'Uiraúna',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2517001,
    label: 'Umbuzeiro',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2517100,
    label: 'Várzea',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2517209,
    label: 'Vieirópolis',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2517407,
    label: 'Zabelê',
    state: {value: 25, initials: 'PB', label: 'Paraíba'},
  },
  {
    value: 2600054,
    label: 'Abreu e Lima',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600104,
    label: 'Afogados da Ingazeira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600203,
    label: 'Afrânio',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600302,
    label: 'Agrestina',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600401,
    label: 'Água Preta',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600500,
    label: 'Águas Belas',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600609,
    label: 'Alagoinha',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600708,
    label: 'Aliança',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600807,
    label: 'Altinho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2600906,
    label: 'Amaraji',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601003,
    label: 'Angelim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601052,
    label: 'Araçoiaba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601102,
    label: 'Araripina',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601201,
    label: 'Arcoverde',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601300,
    label: 'Barra de Guabiraba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601409,
    label: 'Barreiros',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601508,
    label: 'Belém de Maria',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601607,
    label: 'Belém do São Francisco',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601706,
    label: 'Belo Jardim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601805,
    label: 'Betânia',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2601904,
    label: 'Bezerros',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602001,
    label: 'Bodocó',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602100,
    label: 'Bom Conselho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602209,
    label: 'Bom Jardim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602308,
    label: 'Bonito',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602407,
    label: 'Brejão',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602506,
    label: 'Brejinho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602605,
    label: 'Brejo da Madre de Deus',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602704,
    label: 'Buenos Aires',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602803,
    label: 'Buíque',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2602902,
    label: 'Cabo de Santo Agostinho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603009,
    label: 'Cabrobó',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603108,
    label: 'Cachoeirinha',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603207,
    label: 'Caetés',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603306,
    label: 'Calçado',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603405,
    label: 'Calumbi',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603454,
    label: 'Camaragibe',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603504,
    label: 'Camocim de São Félix',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603603,
    label: 'Camutanga',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603702,
    label: 'Canhotinho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603801,
    label: 'Capoeiras',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603900,
    label: 'Carnaíba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2603926,
    label: 'Carnaubeira da Penha',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604007,
    label: 'Carpina',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604106,
    label: 'Caruaru',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604155,
    label: 'Casinhas',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604205,
    label: 'Catende',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604304,
    label: 'Cedro',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604403,
    label: 'Chã de Alegria',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604502,
    label: 'Chã Grande',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604601,
    label: 'Condado',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604700,
    label: 'Correntes',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604809,
    label: 'Cortês',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2604908,
    label: 'Cumaru',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605004,
    label: 'Cupira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605103,
    label: 'Custódia',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605152,
    label: 'Dormentes',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605202,
    label: 'Escada',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605301,
    label: 'Exu',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605400,
    label: 'Feira Nova',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605459,
    label: 'Fernando de Noronha',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605509,
    label: 'Ferreiros',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605608,
    label: 'Flores',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605707,
    label: 'Floresta',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605806,
    label: 'Frei Miguelinho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2605905,
    label: 'Gameleira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606002,
    label: 'Garanhuns',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606101,
    label: 'Glória do Goitá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606200,
    label: 'Goiana',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606309,
    label: 'Granito',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606408,
    label: 'Gravatá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606507,
    label: 'Iati',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606606,
    label: 'Ibimirim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606705,
    label: 'Ibirajuba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606804,
    label: 'Igarassu',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2606903,
    label: 'Iguaracy',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607000,
    label: 'Inajá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607109,
    label: 'Ingazeira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607208,
    label: 'Ipojuca',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607307,
    label: 'Ipubi',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607406,
    label: 'Itacuruba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607505,
    label: 'Itaíba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607604,
    label: 'Ilha de Itamaracá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607653,
    label: 'Itambé',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607703,
    label: 'Itapetim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607752,
    label: 'Itapissuma',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607802,
    label: 'Itaquitinga',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607901,
    label: 'Jaboatão dos Guararapes',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2607950,
    label: 'Jaqueira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608008,
    label: 'Jataúba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608057,
    label: 'Jatobá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608107,
    label: 'João Alfredo',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608206,
    label: 'Joaquim Nabuco',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608255,
    label: 'Jucati',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608305,
    label: 'Jupi',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608404,
    label: 'Jurema',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608453,
    label: 'Lagoa do Carro',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608503,
    label: 'Lagoa de Itaenga',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608602,
    label: 'Lagoa do Ouro',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608701,
    label: 'Lagoa dos Gatos',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608750,
    label: 'Lagoa Grande',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608800,
    label: 'Lajedo',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2608909,
    label: 'Limoeiro',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609006,
    label: 'Macaparana',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609105,
    label: 'Machados',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609154,
    label: 'Manari',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609204,
    label: 'Maraial',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609303,
    label: 'Mirandiba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609402,
    label: 'Moreno',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609501,
    label: 'Nazaré da Mata',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609600,
    label: 'Olinda',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609709,
    label: 'Orobó',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609808,
    label: 'Orocó',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2609907,
    label: 'Ouricuri',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610004,
    label: 'Palmares',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610103,
    label: 'Palmeirina',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610202,
    label: 'Panelas',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610301,
    label: 'Paranatama',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610400,
    label: 'Parnamirim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610509,
    label: 'Passira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610608,
    label: 'Paudalho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610707,
    label: 'Paulista',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610806,
    label: 'Pedra',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2610905,
    label: 'Pesqueira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611002,
    label: 'Petrolândia',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611101,
    label: 'Petrolina',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611200,
    label: 'Poção',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611309,
    label: 'Pombos',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611408,
    label: 'Primavera',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611507,
    label: 'Quipapá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611533,
    label: 'Quixaba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611606,
    label: 'Recife',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611705,
    label: 'Riacho das Almas',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611804,
    label: 'Ribeirão',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2611903,
    label: 'Rio Formoso',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612000,
    label: 'Sairé',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612109,
    label: 'Salgadinho',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612208,
    label: 'Salgueiro',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612307,
    label: 'Saloá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612406,
    label: 'Sanharó',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612455,
    label: 'Santa Cruz',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612471,
    label: 'Santa Cruz da Baixa Verde',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612505,
    label: 'Santa Cruz do Capibaribe',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612554,
    label: 'Santa Filomena',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612604,
    label: 'Santa Maria da Boa Vista',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612703,
    label: 'Santa Maria do Cambucá',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612802,
    label: 'Santa Terezinha',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2612901,
    label: 'São Benedito do Sul',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613008,
    label: 'São Bento do Una',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613107,
    label: 'São Caitano',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613206,
    label: 'São João',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613305,
    label: 'São Joaquim do Monte',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613404,
    label: 'São José da Coroa Grande',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613503,
    label: 'São José do Belmonte',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613602,
    label: 'São José do Egito',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613701,
    label: 'São Lourenço da Mata',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613800,
    label: 'São Vicente Férrer',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2613909,
    label: 'Serra Talhada',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614006,
    label: 'Serrita',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614105,
    label: 'Sertânia',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614204,
    label: 'Sirinhaém',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614303,
    label: 'Moreilândia',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614402,
    label: 'Solidão',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614501,
    label: 'Surubim',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614600,
    label: 'Tabira',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614709,
    label: 'Tacaimbó',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614808,
    label: 'Tacaratu',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2614857,
    label: 'Tamandaré',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615003,
    label: 'Taquaritinga do Norte',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615102,
    label: 'Terezinha',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615201,
    label: 'Terra Nova',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615300,
    label: 'Timbaúba',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615409,
    label: 'Toritama',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615508,
    label: 'Tracunhaém',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615607,
    label: 'Trindade',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615706,
    label: 'Triunfo',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615805,
    label: 'Tupanatinga',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2615904,
    label: 'Tuparetama',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616001,
    label: 'Venturosa',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616100,
    label: 'Verdejante',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616183,
    label: 'Vertente do Lério',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616209,
    label: 'Vertentes',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616308,
    label: 'Vicência',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616407,
    label: 'Vitória de Santo Antão',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2616506,
    label: 'Xexéu',
    state: {value: 26, initials: 'PE', label: 'Pernambuco'},
  },
  {
    value: 2700102,
    label: 'Água Branca',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700201,
    label: 'Anadia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700300,
    label: 'Arapiraca',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700409,
    label: 'Atalaia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700508,
    label: 'Barra de Santo Antônio',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700607,
    label: 'Barra de São Miguel',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700706,
    label: 'Batalha',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700805,
    label: 'Belém',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2700904,
    label: 'Belo Monte',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701001,
    label: 'Boca da Mata',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701100,
    label: 'Branquinha',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701209,
    label: 'Cacimbinhas',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701308,
    label: 'Cajueiro',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701357,
    label: 'Campestre',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701407,
    label: 'Campo Alegre',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701506,
    label: 'Campo Grande',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701605,
    label: 'Canapi',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701704,
    label: 'Capela',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701803,
    label: 'Carneiros',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2701902,
    label: 'Chã Preta',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702009,
    label: 'Coité do Nóia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702108,
    label: 'Colônia Leopoldina',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702207,
    label: 'Coqueiro Seco',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702306,
    label: 'Coruripe',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702355,
    label: 'Craíbas',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702405,
    label: 'Delmiro Gouveia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702504,
    label: 'Dois Riachos',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702553,
    label: 'Estrela de Alagoas',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702603,
    label: 'Feira Grande',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702702,
    label: 'Feliz Deserto',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702801,
    label: 'Flexeiras',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2702900,
    label: 'Girau do Ponciano',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703007,
    label: 'Ibateguara',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703106,
    label: 'Igaci',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703205,
    label: 'Igreja Nova',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703304,
    label: 'Inhapi',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703403,
    label: 'Jacaré dos Homens',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703502,
    label: 'Jacuípe',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703601,
    label: 'Japaratinga',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703700,
    label: 'Jaramataia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703759,
    label: 'Jequiá da Praia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703809,
    label: 'Joaquim Gomes',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2703908,
    label: 'Jundiá',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704005,
    label: 'Junqueiro',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704104,
    label: 'Lagoa da Canoa',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704203,
    label: 'Limoeiro de Anadia',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704302,
    label: 'Maceió',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704401,
    label: 'Major Isidoro',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704500,
    label: 'Maragogi',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704609,
    label: 'Maravilha',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704708,
    label: 'Marechal Deodoro',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704807,
    label: 'Maribondo',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2704906,
    label: 'Mar Vermelho',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705002,
    label: 'Mata Grande',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705101,
    label: 'Matriz de Camaragibe',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705200,
    label: 'Messias',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705309,
    label: 'Minador do Negrão',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705408,
    label: 'Monteirópolis',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705507,
    label: 'Murici',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705606,
    label: 'Novo Lino',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705705,
    label: "Olho d'Água das Flores",
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705804,
    label: "Olho d'Água do Casado",
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2705903,
    label: "Olho d'Água Grande",
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706000,
    label: 'Olivença',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706109,
    label: 'Ouro Branco',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706208,
    label: 'Palestina',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706307,
    label: 'Palmeira dos Índios',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706406,
    label: 'Pão de Açúcar',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706422,
    label: 'Pariconha',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706448,
    label: 'Paripueira',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706505,
    label: 'Passo de Camaragibe',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706604,
    label: 'Paulo Jacinto',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706703,
    label: 'Penedo',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706802,
    label: 'Piaçabuçu',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2706901,
    label: 'Pilar',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707008,
    label: 'Pindoba',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707107,
    label: 'Piranhas',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707206,
    label: 'Poço das Trincheiras',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707305,
    label: 'Porto Calvo',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707404,
    label: 'Porto de Pedras',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707503,
    label: 'Porto Real do Colégio',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707602,
    label: 'Quebrangulo',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707701,
    label: 'Rio Largo',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707800,
    label: 'Roteiro',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2707909,
    label: 'Santa Luzia do Norte',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708006,
    label: 'Santana do Ipanema',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708105,
    label: 'Santana do Mundaú',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708204,
    label: 'São Brás',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708303,
    label: 'São José da Laje',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708402,
    label: 'São José da Tapera',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708501,
    label: 'São Luís do Quitunde',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708600,
    label: 'São Miguel dos Campos',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708709,
    label: 'São Miguel dos Milagres',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708808,
    label: 'São Sebastião',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708907,
    label: 'Satuba',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2708956,
    label: 'Senador Rui Palmeira',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2709004,
    label: "Tanque d'Arca",
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2709103,
    label: 'Taquarana',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2709152,
    label: 'Teotônio Vilela',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2709202,
    label: 'Traipu',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2709301,
    label: 'União dos Palmares',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2709400,
    label: 'Viçosa',
    state: {value: 27, initials: 'AL', label: 'Alagoas'},
  },
  {
    value: 2800100,
    label: 'Amparo de São Francisco',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800209,
    label: 'Aquidabã',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800308,
    label: 'Aracaju',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800407,
    label: 'Arauá',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800506,
    label: 'Areia Branca',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800605,
    label: 'Barra dos Coqueiros',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800670,
    label: 'Boquim',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2800704,
    label: 'Brejo Grande',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801009,
    label: 'Campo do Brito',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801108,
    label: 'Canhoba',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801207,
    label: 'Canindé de São Francisco',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801306,
    label: 'Capela',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801405,
    label: 'Carira',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801504,
    label: 'Carmópolis',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801603,
    label: 'Cedro de São João',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801702,
    label: 'Cristinápolis',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2801900,
    label: 'Cumbe',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802007,
    label: 'Divina Pastora',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802106,
    label: 'Estância',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802205,
    label: 'Feira Nova',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802304,
    label: 'Frei Paulo',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802403,
    label: 'Gararu',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802502,
    label: 'General Maynard',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802601,
    label: 'Gracho Cardoso',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802700,
    label: 'Ilha das Flores',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802809,
    label: 'Indiaroba',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2802908,
    label: 'Itabaiana',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803005,
    label: 'Itabaianinha',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803104,
    label: 'Itabi',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803203,
    label: "Itaporanga d'Ajuda",
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803302,
    label: 'Japaratuba',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803401,
    label: 'Japoatã',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803500,
    label: 'Lagarto',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803609,
    label: 'Laranjeiras',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803708,
    label: 'Macambira',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803807,
    label: 'Malhada dos Bois',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2803906,
    label: 'Malhador',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804003,
    label: 'Maruim',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804102,
    label: 'Moita Bonita',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804201,
    label: 'Monte Alegre de Sergipe',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804300,
    label: 'Muribeca',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804409,
    label: 'Neópolis',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804458,
    label: 'Nossa Senhora Aparecida',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804508,
    label: 'Nossa Senhora da Glória',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804607,
    label: 'Nossa Senhora das Dores',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804706,
    label: 'Nossa Senhora de Lourdes',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804805,
    label: 'Nossa Senhora do Socorro',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2804904,
    label: 'Pacatuba',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805000,
    label: 'Pedra Mole',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805109,
    label: 'Pedrinhas',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805208,
    label: 'Pinhão',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805307,
    label: 'Pirambu',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805406,
    label: 'Poço Redondo',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805505,
    label: 'Poço Verde',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805604,
    label: 'Porto da Folha',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805703,
    label: 'Propriá',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805802,
    label: 'Riachão do Dantas',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2805901,
    label: 'Riachuelo',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806008,
    label: 'Ribeirópolis',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806107,
    label: 'Rosário do Catete',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806206,
    label: 'Salgado',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806305,
    label: 'Santa Luzia do Itanhy',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806404,
    label: 'Santana do São Francisco',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806503,
    label: 'Santa Rosa de Lima',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806602,
    label: 'Santo Amaro das Brotas',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806701,
    label: 'São Cristóvão',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806800,
    label: 'São Domingos',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2806909,
    label: 'São Francisco',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807006,
    label: 'São Miguel do Aleixo',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807105,
    label: 'Simão Dias',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807204,
    label: 'Siriri',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807303,
    label: 'Telha',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807402,
    label: 'Tobias Barreto',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807501,
    label: 'Tomar do Geru',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2807600,
    label: 'Umbaúba',
    state: {value: 28, initials: 'SE', label: 'Sergipe'},
  },
  {
    value: 2900108,
    label: 'Abaíra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900207,
    label: 'Abaré',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900306,
    label: 'Acajutiba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900355,
    label: 'Adustina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900405,
    label: 'Água Fria',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900504,
    label: 'Érico Cardoso',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900603,
    label: 'Aiquara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900702,
    label: 'Alagoinhas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900801,
    label: 'Alcobaça',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2900900,
    label: 'Almadina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901007,
    label: 'Amargosa',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901106,
    label: 'Amélia Rodrigues',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901155,
    label: 'América Dourada',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901205,
    label: 'Anagé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901304,
    label: 'Andaraí',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901353,
    label: 'Andorinha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901403,
    label: 'Angical',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901502,
    label: 'Anguera',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901601,
    label: 'Antas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901700,
    label: 'Antônio Cardoso',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901809,
    label: 'Antônio Gonçalves',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901908,
    label: 'Aporá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2901957,
    label: 'Apuarema',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902005,
    label: 'Aracatu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902054,
    label: 'Araçás',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902104,
    label: 'Araci',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902203,
    label: 'Aramari',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902252,
    label: 'Arataca',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902302,
    label: 'Aratuípe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902401,
    label: 'Aurelino Leal',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902500,
    label: 'Baianópolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902609,
    label: 'Baixa Grande',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902658,
    label: 'Banzaê',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902708,
    label: 'Barra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902807,
    label: 'Barra da Estiva',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2902906,
    label: 'Barra do Choça',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903003,
    label: 'Barra do Mendes',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903102,
    label: 'Barra do Rocha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903201,
    label: 'Barreiras',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903235,
    label: 'Barro Alto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903276,
    label: 'Barrocas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903300,
    label: 'Barro Preto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903409,
    label: 'Belmonte',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903508,
    label: 'Belo Campo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903607,
    label: 'Biritinga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903706,
    label: 'Boa Nova',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903805,
    label: 'Boa Vista do Tupim',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903904,
    label: 'Bom Jesus da Lapa',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2903953,
    label: 'Bom Jesus da Serra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904001,
    label: 'Boninal',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904050,
    label: 'Bonito',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904100,
    label: 'Boquira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904209,
    label: 'Botuporã',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904308,
    label: 'Brejões',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904407,
    label: 'Brejolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904506,
    label: 'Brotas de Macaúbas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904605,
    label: 'Brumado',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904704,
    label: 'Buerarema',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904753,
    label: 'Buritirama',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904803,
    label: 'Caatiba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904852,
    label: 'Cabaceiras do Paraguaçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2904902,
    label: 'Cachoeira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905008,
    label: 'Caculé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905107,
    label: 'Caém',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905156,
    label: 'Caetanos',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905206,
    label: 'Caetité',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905305,
    label: 'Cafarnaum',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905404,
    label: 'Cairu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905503,
    label: 'Caldeirão Grande',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905602,
    label: 'Camacan',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905701,
    label: 'Camaçari',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905800,
    label: 'Camamu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2905909,
    label: 'Campo Alegre de Lourdes',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906006,
    label: 'Campo Formoso',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906105,
    label: 'Canápolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906204,
    label: 'Canarana',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906303,
    label: 'Canavieiras',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906402,
    label: 'Candeal',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906501,
    label: 'Candeias',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906600,
    label: 'Candiba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906709,
    label: 'Cândido Sales',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906808,
    label: 'Cansanção',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906824,
    label: 'Canudos',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906857,
    label: 'Capela do Alto Alegre',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906873,
    label: 'Capim Grosso',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906899,
    label: 'Caraíbas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2906907,
    label: 'Caravelas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907004,
    label: 'Cardeal da Silva',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907103,
    label: 'Carinhanha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907202,
    label: 'Casa Nova',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907301,
    label: 'Castro Alves',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907400,
    label: 'Catolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907509,
    label: 'Catu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907558,
    label: 'Caturama',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907608,
    label: 'Central',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907707,
    label: 'Chorrochó',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907806,
    label: 'Cícero Dantas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2907905,
    label: 'Cipó',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908002,
    label: 'Coaraci',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908101,
    label: 'Cocos',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908200,
    label: 'Conceição da Feira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908309,
    label: 'Conceição do Almeida',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908408,
    label: 'Conceição do Coité',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908507,
    label: 'Conceição do Jacuípe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908606,
    label: 'Conde',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908705,
    label: 'Condeúba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908804,
    label: 'Contendas do Sincorá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2908903,
    label: 'Coração de Maria',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909000,
    label: 'Cordeiros',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909109,
    label: 'Coribe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909208,
    label: 'Coronel João Sá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909307,
    label: 'Correntina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909406,
    label: 'Cotegipe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909505,
    label: 'Cravolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909604,
    label: 'Crisópolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909703,
    label: 'Cristópolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909802,
    label: 'Cruz das Almas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2909901,
    label: 'Curaçá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910008,
    label: 'Dário Meira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910057,
    label: "Dias d'Ávila",
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910107,
    label: 'Dom Basílio',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910206,
    label: 'Dom Macedo Costa',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910305,
    label: 'Elísio Medrado',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910404,
    label: 'Encruzilhada',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910503,
    label: 'Entre Rios',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910602,
    label: 'Esplanada',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910701,
    label: 'Euclides da Cunha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910727,
    label: 'Eunápolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910750,
    label: 'Fátima',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910776,
    label: 'Feira da Mata',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910800,
    label: 'Feira de Santana',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910859,
    label: 'Filadélfia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2910909,
    label: 'Firmino Alves',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911006,
    label: 'Floresta Azul',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911105,
    label: 'Formosa do Rio Preto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911204,
    label: 'Gandu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911253,
    label: 'Gavião',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911303,
    label: 'Gentio do Ouro',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911402,
    label: 'Glória',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911501,
    label: 'Gongogi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911600,
    label: 'Governador Mangabeira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911659,
    label: 'Guajeru',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911709,
    label: 'Guanambi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911808,
    label: 'Guaratinga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911857,
    label: 'Heliópolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2911907,
    label: 'Iaçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912004,
    label: 'Ibiassucê',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912103,
    label: 'Ibicaraí',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912202,
    label: 'Ibicoara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912301,
    label: 'Ibicuí',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912400,
    label: 'Ibipeba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912509,
    label: 'Ibipitanga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912608,
    label: 'Ibiquera',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912707,
    label: 'Ibirapitanga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912806,
    label: 'Ibirapuã',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2912905,
    label: 'Ibirataia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913002,
    label: 'Ibitiara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913101,
    label: 'Ibititá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913200,
    label: 'Ibotirama',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913309,
    label: 'Ichu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913408,
    label: 'Igaporã',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913457,
    label: 'Igrapiúna',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913507,
    label: 'Iguaí',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913606,
    label: 'Ilhéus',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913705,
    label: 'Inhambupe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913804,
    label: 'Ipecaetá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2913903,
    label: 'Ipiaú',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914000,
    label: 'Ipirá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914109,
    label: 'Ipupiara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914208,
    label: 'Irajuba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914307,
    label: 'Iramaia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914406,
    label: 'Iraquara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914505,
    label: 'Irará',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914604,
    label: 'Irecê',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914653,
    label: 'Itabela',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914703,
    label: 'Itaberaba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914802,
    label: 'Itabuna',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2914901,
    label: 'Itacaré',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915007,
    label: 'Itaeté',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915106,
    label: 'Itagi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915205,
    label: 'Itagibá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915304,
    label: 'Itagimirim',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915353,
    label: 'Itaguaçu da Bahia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915403,
    label: 'Itaju do Colônia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915502,
    label: 'Itajuípe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915601,
    label: 'Itamaraju',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915700,
    label: 'Itamari',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915809,
    label: 'Itambé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2915908,
    label: 'Itanagra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916005,
    label: 'Itanhém',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916104,
    label: 'Itaparica',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916203,
    label: 'Itapé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916302,
    label: 'Itapebi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916401,
    label: 'Itapetinga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916500,
    label: 'Itapicuru',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916609,
    label: 'Itapitanga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916708,
    label: 'Itaquara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916807,
    label: 'Itarantim',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916856,
    label: 'Itatim',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2916906,
    label: 'Itiruçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917003,
    label: 'Itiúba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917102,
    label: 'Itororó',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917201,
    label: 'Ituaçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917300,
    label: 'Ituberá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917334,
    label: 'Iuiu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917359,
    label: 'Jaborandi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917409,
    label: 'Jacaraci',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917508,
    label: 'Jacobina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917607,
    label: 'Jaguaquara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917706,
    label: 'Jaguarari',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917805,
    label: 'Jaguaripe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2917904,
    label: 'Jandaíra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918001,
    label: 'Jequié',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918100,
    label: 'Jeremoabo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918209,
    label: 'Jiquiriçá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918308,
    label: 'Jitaúna',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918357,
    label: 'João Dourado',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918407,
    label: 'Juazeiro',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918456,
    label: 'Jucuruçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918506,
    label: 'Jussara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918555,
    label: 'Jussari',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918605,
    label: 'Jussiape',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918704,
    label: 'Lafaiete Coutinho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918753,
    label: 'Lagoa Real',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918803,
    label: 'Laje',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2918902,
    label: 'Lajedão',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919009,
    label: 'Lajedinho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919058,
    label: 'Lajedo do Tabocal',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919108,
    label: 'Lamarão',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919157,
    label: 'Lapão',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919207,
    label: 'Lauro de Freitas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919306,
    label: 'Lençóis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919405,
    label: 'Licínio de Almeida',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919504,
    label: 'Livramento de Nossa Senhora',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919553,
    label: 'Luís Eduardo Magalhães',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919603,
    label: 'Macajuba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919702,
    label: 'Macarani',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919801,
    label: 'Macaúbas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919900,
    label: 'Macururé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919926,
    label: 'Madre de Deus',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2919959,
    label: 'Maetinga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920007,
    label: 'Maiquinique',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920106,
    label: 'Mairi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920205,
    label: 'Malhada',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920304,
    label: 'Malhada de Pedras',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920403,
    label: 'Manoel Vitorino',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920452,
    label: 'Mansidão',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920502,
    label: 'Maracás',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920601,
    label: 'Maragogipe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920700,
    label: 'Maraú',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920809,
    label: 'Marcionílio Souza',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2920908,
    label: 'Mascote',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921005,
    label: 'Mata de São João',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921054,
    label: 'Matina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921104,
    label: 'Medeiros Neto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921203,
    label: 'Miguel Calmon',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921302,
    label: 'Milagres',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921401,
    label: 'Mirangaba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921450,
    label: 'Mirante',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921500,
    label: 'Monte Santo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921609,
    label: 'Morpará',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921708,
    label: 'Morro do Chapéu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921807,
    label: 'Mortugaba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2921906,
    label: 'Mucugê',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922003,
    label: 'Mucuri',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922052,
    label: 'Mulungu do Morro',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922102,
    label: 'Mundo Novo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922201,
    label: 'Muniz Ferreira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922250,
    label: 'Muquém do São Francisco',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922300,
    label: 'Muritiba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922409,
    label: 'Mutuípe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922508,
    label: 'Nazaré',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922607,
    label: 'Nilo Peçanha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922656,
    label: 'Nordestina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922706,
    label: 'Nova Canaã',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922730,
    label: 'Nova Fátima',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922755,
    label: 'Nova Ibiá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922805,
    label: 'Nova Itarana',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922854,
    label: 'Nova Redenção',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2922904,
    label: 'Nova Soure',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923001,
    label: 'Nova Viçosa',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923035,
    label: 'Novo Horizonte',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923050,
    label: 'Novo Triunfo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923100,
    label: 'Olindina',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923209,
    label: 'Oliveira dos Brejinhos',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923308,
    label: 'Ouriçangas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923357,
    label: 'Ourolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923407,
    label: 'Palmas de Monte Alto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923506,
    label: 'Palmeiras',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923605,
    label: 'Paramirim',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923704,
    label: 'Paratinga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923803,
    label: 'Paripiranga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2923902,
    label: 'Pau Brasil',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924009,
    label: 'Paulo Afonso',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924058,
    label: 'Pé de Serra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924108,
    label: 'Pedrão',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924207,
    label: 'Pedro Alexandre',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924306,
    label: 'Piatã',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924405,
    label: 'Pilão Arcado',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924504,
    label: 'Pindaí',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924603,
    label: 'Pindobaçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924652,
    label: 'Pintadas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924678,
    label: 'Piraí do Norte',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924702,
    label: 'Piripá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924801,
    label: 'Piritiba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2924900,
    label: 'Planaltino',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925006,
    label: 'Planalto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925105,
    label: 'Poções',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925204,
    label: 'Pojuca',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925253,
    label: 'Ponto Novo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925303,
    label: 'Porto Seguro',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925402,
    label: 'Potiraguá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925501,
    label: 'Prado',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925600,
    label: 'Presidente Dutra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925709,
    label: 'Presidente Jânio Quadros',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925758,
    label: 'Presidente Tancredo Neves',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925808,
    label: 'Queimadas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925907,
    label: 'Quijingue',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925931,
    label: 'Quixabeira',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2925956,
    label: 'Rafael Jambeiro',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926004,
    label: 'Remanso',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926103,
    label: 'Retirolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926202,
    label: 'Riachão das Neves',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926301,
    label: 'Riachão do Jacuípe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926400,
    label: 'Riacho de Santana',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926509,
    label: 'Ribeira do Amparo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926608,
    label: 'Ribeira do Pombal',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926657,
    label: 'Ribeirão do Largo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926707,
    label: 'Rio de Contas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926806,
    label: 'Rio do Antônio',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2926905,
    label: 'Rio do Pires',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927002,
    label: 'Rio Real',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927101,
    label: 'Rodelas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927200,
    label: 'Ruy Barbosa',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927309,
    label: 'Salinas da Margarida',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927408,
    label: 'Salvador',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927507,
    label: 'Santa Bárbara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927606,
    label: 'Santa Brígida',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927705,
    label: 'Santa Cruz Cabrália',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927804,
    label: 'Santa Cruz da Vitória',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2927903,
    label: 'Santa Inês',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928000,
    label: 'Santaluz',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928059,
    label: 'Santa Luzia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928109,
    label: 'Santa Maria da Vitória',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928208,
    label: 'Santana',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928307,
    label: 'Santanópolis',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928406,
    label: 'Santa Rita de Cássia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928505,
    label: 'Santa Terezinha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928604,
    label: 'Santo Amaro',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928703,
    label: 'Santo Antônio de Jesus',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928802,
    label: 'Santo Estêvão',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928901,
    label: 'São Desidério',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2928950,
    label: 'São Domingos',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929008,
    label: 'São Félix',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929057,
    label: 'São Félix do Coribe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929107,
    label: 'São Felipe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929206,
    label: 'São Francisco do Conde',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929255,
    label: 'São Gabriel',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929305,
    label: 'São Gonçalo dos Campos',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929354,
    label: 'São José da Vitória',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929370,
    label: 'São José do Jacuípe',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929404,
    label: 'São Miguel das Matas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929503,
    label: 'São Sebastião do Passé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929602,
    label: 'Sapeaçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929701,
    label: 'Sátiro Dias',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929750,
    label: 'Saubara',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929800,
    label: 'Saúde',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2929909,
    label: 'Seabra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930006,
    label: 'Sebastião Laranjeiras',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930105,
    label: 'Senhor do Bonfim',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930154,
    label: 'Serra do Ramalho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930204,
    label: 'Sento Sé',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930303,
    label: 'Serra Dourada',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930402,
    label: 'Serra Preta',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930501,
    label: 'Serrinha',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930600,
    label: 'Serrolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930709,
    label: 'Simões Filho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930758,
    label: 'Sítio do Mato',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930766,
    label: 'Sítio do Quinto',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930774,
    label: 'Sobradinho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930808,
    label: 'Souto Soares',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2930907,
    label: 'Tabocas do Brejo Velho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931004,
    label: 'Tanhaçu',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931053,
    label: 'Tanque Novo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931103,
    label: 'Tanquinho',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931202,
    label: 'Taperoá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931301,
    label: 'Tapiramutá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931350,
    label: 'Teixeira de Freitas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931400,
    label: 'Teodoro Sampaio',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931509,
    label: 'Teofilândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931608,
    label: 'Teolândia',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931707,
    label: 'Terra Nova',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931806,
    label: 'Tremedal',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2931905,
    label: 'Tucano',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932002,
    label: 'Uauá',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932101,
    label: 'Ubaíra',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932200,
    label: 'Ubaitaba',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932309,
    label: 'Ubatã',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932408,
    label: 'Uibaí',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932457,
    label: 'Umburanas',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932507,
    label: 'Una',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932606,
    label: 'Urandi',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932705,
    label: 'Uruçuca',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932804,
    label: 'Utinga',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2932903,
    label: 'Valença',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933000,
    label: 'Valente',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933059,
    label: 'Várzea da Roça',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933109,
    label: 'Várzea do Poço',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933158,
    label: 'Várzea Nova',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933174,
    label: 'Varzedo',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933208,
    label: 'Vera Cruz',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933257,
    label: 'Vereda',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933307,
    label: 'Vitória da Conquista',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933406,
    label: 'Wagner',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933455,
    label: 'Wanderley',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933505,
    label: 'Wenceslau Guimarães',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 2933604,
    label: 'Xique-Xique',
    state: {value: 29, initials: 'BA', label: 'Bahia'},
  },
  {
    value: 3100104,
    label: 'Abadia dos Dourados',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100203,
    label: 'Abaeté',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100302,
    label: 'Abre Campo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100401,
    label: 'Acaiaca',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100500,
    label: 'Açucena',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100609,
    label: 'Água Boa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100708,
    label: 'Água Comprida',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100807,
    label: 'Aguanil',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3100906,
    label: 'Águas Formosas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101003,
    label: 'Águas Vermelhas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101102,
    label: 'Aimorés',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101201,
    label: 'Aiuruoca',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101300,
    label: 'Alagoa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101409,
    label: 'Albertina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101508,
    label: 'Além Paraíba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101607,
    label: 'Alfenas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101631,
    label: 'Alfredo Vasconcelos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101706,
    label: 'Almenara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101805,
    label: 'Alpercata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3101904,
    label: 'Alpinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102001,
    label: 'Alterosa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102050,
    label: 'Alto Caparaó',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102100,
    label: 'Alto Rio Doce',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102209,
    label: 'Alvarenga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102308,
    label: 'Alvinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102407,
    label: 'Alvorada de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102506,
    label: 'Amparo do Serra',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102605,
    label: 'Andradas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102704,
    label: 'Cachoeira de Pajeú',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102803,
    label: 'Andrelândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102852,
    label: 'Angelândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3102902,
    label: 'Antônio Carlos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103009,
    label: 'Antônio Dias',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103108,
    label: 'Antônio Prado de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103207,
    label: 'Araçaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103306,
    label: 'Aracitaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103405,
    label: 'Araçuaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103504,
    label: 'Araguari',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103603,
    label: 'Arantina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103702,
    label: 'Araponga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103751,
    label: 'Araporã',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103801,
    label: 'Arapuá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3103900,
    label: 'Araújos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104007,
    label: 'Araxá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104106,
    label: 'Arceburgo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104205,
    label: 'Arcos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104304,
    label: 'Areado',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104403,
    label: 'Argirita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104452,
    label: 'Aricanduva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104502,
    label: 'Arinos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104601,
    label: 'Astolfo Dutra',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104700,
    label: 'Ataléia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104809,
    label: 'Augusto de Lima',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3104908,
    label: 'Baependi',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105004,
    label: 'Baldim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105103,
    label: 'Bambuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105202,
    label: 'Bandeira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105301,
    label: 'Bandeira do Sul',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105400,
    label: 'Barão de Cocais',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105509,
    label: 'Barão de Monte Alto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105608,
    label: 'Barbacena',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105707,
    label: 'Barra Longa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3105905,
    label: 'Barroso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106002,
    label: 'Bela Vista de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106101,
    label: 'Belmiro Braga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106200,
    label: 'Belo Horizonte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106309,
    label: 'Belo Oriente',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106408,
    label: 'Belo Vale',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106507,
    label: 'Berilo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106606,
    label: 'Bertópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106655,
    label: 'Berizal',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106705,
    label: 'Betim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106804,
    label: 'Bias Fortes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3106903,
    label: 'Bicas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107000,
    label: 'Biquinhas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107109,
    label: 'Boa Esperança',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107208,
    label: 'Bocaina de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107307,
    label: 'Bocaiúva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107406,
    label: 'Bom Despacho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107505,
    label: 'Bom Jardim de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107604,
    label: 'Bom Jesus da Penha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107703,
    label: 'Bom Jesus do Amparo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107802,
    label: 'Bom Jesus do Galho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3107901,
    label: 'Bom Repouso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108008,
    label: 'Bom Sucesso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108107,
    label: 'Bonfim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108206,
    label: 'Bonfinópolis de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108255,
    label: 'Bonito de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108305,
    label: 'Borda da Mata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108404,
    label: 'Botelhos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108503,
    label: 'Botumirim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108552,
    label: 'Brasilândia de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108602,
    label: 'Brasília de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108701,
    label: 'Brás Pires',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108800,
    label: 'Braúnas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3108909,
    label: 'Brazópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109006,
    label: 'Brumadinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109105,
    label: 'Bueno Brandão',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109204,
    label: 'Buenópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109253,
    label: 'Bugre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109303,
    label: 'Buritis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109402,
    label: 'Buritizeiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109451,
    label: 'Cabeceira Grande',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109501,
    label: 'Cabo Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109600,
    label: 'Cachoeira da Prata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109709,
    label: 'Cachoeira de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109808,
    label: 'Cachoeira Dourada',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3109907,
    label: 'Caetanópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110004,
    label: 'Caeté',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110103,
    label: 'Caiana',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110202,
    label: 'Cajuri',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110301,
    label: 'Caldas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110400,
    label: 'Camacho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110509,
    label: 'Camanducaia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110608,
    label: 'Cambuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110707,
    label: 'Cambuquira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110806,
    label: 'Campanário',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3110905,
    label: 'Campanha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111002,
    label: 'Campestre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111101,
    label: 'Campina Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111150,
    label: 'Campo Azul',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111200,
    label: 'Campo Belo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111309,
    label: 'Campo do Meio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111408,
    label: 'Campo Florido',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111507,
    label: 'Campos Altos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111606,
    label: 'Campos Gerais',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111705,
    label: 'Canaã',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111804,
    label: 'Canápolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3111903,
    label: 'Cana Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112000,
    label: 'Candeias',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112059,
    label: 'Cantagalo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112109,
    label: 'Caparaó',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112208,
    label: 'Capela Nova',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112307,
    label: 'Capelinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112406,
    label: 'Capetinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112505,
    label: 'Capim Branco',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112604,
    label: 'Capinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112653,
    label: 'Capitão Andrade',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112703,
    label: 'Capitão Enéas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112802,
    label: 'Capitólio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3112901,
    label: 'Caputira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113008,
    label: 'Caraí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113107,
    label: 'Caranaíba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113206,
    label: 'Carandaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113305,
    label: 'Carangola',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113404,
    label: 'Caratinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113503,
    label: 'Carbonita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113602,
    label: 'Careaçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113701,
    label: 'Carlos Chagas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113800,
    label: 'Carmésia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3113909,
    label: 'Carmo da Cachoeira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114006,
    label: 'Carmo da Mata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114105,
    label: 'Carmo de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114204,
    label: 'Carmo do Cajuru',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114303,
    label: 'Carmo do Paranaíba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114402,
    label: 'Carmo do Rio Claro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114501,
    label: 'Carmópolis de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114550,
    label: 'Carneirinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114600,
    label: 'Carrancas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114709,
    label: 'Carvalhópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114808,
    label: 'Carvalhos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3114907,
    label: 'Casa Grande',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115003,
    label: 'Cascalho Rico',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115102,
    label: 'Cássia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115201,
    label: 'Conceição da Barra de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115300,
    label: 'Cataguases',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115359,
    label: 'Catas Altas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115409,
    label: 'Catas Altas da Noruega',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115458,
    label: 'Catuji',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115474,
    label: 'Catuti',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115508,
    label: 'Caxambu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115607,
    label: 'Cedro do Abaeté',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115706,
    label: 'Central de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115805,
    label: 'Centralina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3115904,
    label: 'Chácara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116001,
    label: 'Chalé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116100,
    label: 'Chapada do Norte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116159,
    label: 'Chapada Gaúcha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116209,
    label: 'Chiador',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116308,
    label: 'Cipotânea',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116407,
    label: 'Claraval',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116506,
    label: 'Claro dos Poções',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116605,
    label: 'Cláudio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116704,
    label: 'Coimbra',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116803,
    label: 'Coluna',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3116902,
    label: 'Comendador Gomes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117009,
    label: 'Comercinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117108,
    label: 'Conceição da Aparecida',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117207,
    label: 'Conceição das Pedras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117306,
    label: 'Conceição das Alagoas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117405,
    label: 'Conceição de Ipanema',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117504,
    label: 'Conceição do Mato Dentro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117603,
    label: 'Conceição do Pará',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117702,
    label: 'Conceição do Rio Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117801,
    label: 'Conceição dos Ouros',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117836,
    label: 'Cônego Marinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117876,
    label: 'Confins',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3117900,
    label: 'Congonhal',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118007,
    label: 'Congonhas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118106,
    label: 'Congonhas do Norte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118205,
    label: 'Conquista',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118304,
    label: 'Conselheiro Lafaiete',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118403,
    label: 'Conselheiro Pena',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118502,
    label: 'Consolação',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118601,
    label: 'Contagem',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118700,
    label: 'Coqueiral',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118809,
    label: 'Coração de Jesus',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3118908,
    label: 'Cordisburgo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119005,
    label: 'Cordislândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119104,
    label: 'Corinto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119203,
    label: 'Coroaci',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119302,
    label: 'Coromandel',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119401,
    label: 'Coronel Fabriciano',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119500,
    label: 'Coronel Murta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119609,
    label: 'Coronel Pacheco',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119708,
    label: 'Coronel Xavier Chaves',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119807,
    label: 'Córrego Danta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119906,
    label: 'Córrego do Bom Jesus',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3119955,
    label: 'Córrego Fundo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120003,
    label: 'Córrego Novo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120102,
    label: 'Couto de Magalhães de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120151,
    label: 'Crisólita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120201,
    label: 'Cristais',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120300,
    label: 'Cristália',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120409,
    label: 'Cristiano Otoni',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120508,
    label: 'Cristina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120607,
    label: 'Crucilândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120706,
    label: 'Cruzeiro da Fortaleza',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120805,
    label: 'Cruzília',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120839,
    label: 'Cuparaque',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120870,
    label: 'Curral de Dentro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3120904,
    label: 'Curvelo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121001,
    label: 'Datas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121100,
    label: 'Delfim Moreira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121209,
    label: 'Delfinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121258,
    label: 'Delta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121308,
    label: 'Descoberto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121407,
    label: 'Desterro de Entre Rios',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121506,
    label: 'Desterro do Melo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121605,
    label: 'Diamantina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121704,
    label: 'Diogo de Vasconcelos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121803,
    label: 'Dionísio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3121902,
    label: 'Divinésia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122009,
    label: 'Divino',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122108,
    label: 'Divino das Laranjeiras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122207,
    label: 'Divinolândia de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122306,
    label: 'Divinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122355,
    label: 'Divisa Alegre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122405,
    label: 'Divisa Nova',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122454,
    label: 'Divisópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122470,
    label: 'Dom Bosco',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122504,
    label: 'Dom Cavati',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122603,
    label: 'Dom Joaquim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122702,
    label: 'Dom Silvério',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122801,
    label: 'Dom Viçoso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3122900,
    label: 'Dona Eusébia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123007,
    label: 'Dores de Campos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123106,
    label: 'Dores de Guanhães',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123205,
    label: 'Dores do Indaiá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123304,
    label: 'Dores do Turvo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123403,
    label: 'Doresópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123502,
    label: 'Douradoquara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123528,
    label: 'Durandé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123601,
    label: 'Elói Mendes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123700,
    label: 'Engenheiro Caldas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123809,
    label: 'Engenheiro Navarro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123858,
    label: 'Entre Folhas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3123908,
    label: 'Entre Rios de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124005,
    label: 'Ervália',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124104,
    label: 'Esmeraldas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124203,
    label: 'Espera Feliz',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124302,
    label: 'Espinosa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124401,
    label: 'Espírito Santo do Dourado',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124500,
    label: 'Estiva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124609,
    label: 'Estrela Dalva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124708,
    label: 'Estrela do Indaiá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124807,
    label: 'Estrela do Sul',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3124906,
    label: 'Eugenópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125002,
    label: 'Ewbank da Câmara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125101,
    label: 'Extrema',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125200,
    label: 'Fama',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125309,
    label: 'Faria Lemos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125408,
    label: 'Felício dos Santos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125507,
    label: 'São Gonçalo do Rio Preto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125606,
    label: 'Felisburgo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125705,
    label: 'Felixlândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125804,
    label: 'Fernandes Tourinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125903,
    label: 'Ferros',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3125952,
    label: 'Fervedouro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126000,
    label: 'Florestal',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126109,
    label: 'Formiga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126208,
    label: 'Formoso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126307,
    label: 'Fortaleza de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126406,
    label: 'Fortuna de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126505,
    label: 'Francisco Badaró',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126604,
    label: 'Francisco Dumont',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126703,
    label: 'Francisco Sá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126752,
    label: 'Franciscópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126802,
    label: 'Frei Gaspar',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126901,
    label: 'Frei Inocêncio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3126950,
    label: 'Frei Lagonegro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127008,
    label: 'Fronteira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127057,
    label: 'Fronteira dos Vales',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127073,
    label: 'Fruta de Leite',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127107,
    label: 'Frutal',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127206,
    label: 'Funilândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127305,
    label: 'Galiléia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127339,
    label: 'Gameleiras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127354,
    label: 'Glaucilândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127370,
    label: 'Goiabeira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127388,
    label: 'Goianá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127404,
    label: 'Gonçalves',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127503,
    label: 'Gonzaga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127602,
    label: 'Gouveia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127701,
    label: 'Governador Valadares',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127800,
    label: 'Grão Mogol',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3127909,
    label: 'Grupiara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128006,
    label: 'Guanhães',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128105,
    label: 'Guapé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128204,
    label: 'Guaraciaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128253,
    label: 'Guaraciama',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128303,
    label: 'Guaranésia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128402,
    label: 'Guarani',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128501,
    label: 'Guarará',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128600,
    label: 'Guarda-Mor',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128709,
    label: 'Guaxupé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128808,
    label: 'Guidoval',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3128907,
    label: 'Guimarânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129004,
    label: 'Guiricema',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129103,
    label: 'Gurinhatã',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129202,
    label: 'Heliodora',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129301,
    label: 'Iapu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129400,
    label: 'Ibertioga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129509,
    label: 'Ibiá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129608,
    label: 'Ibiaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129657,
    label: 'Ibiracatu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129707,
    label: 'Ibiraci',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129806,
    label: 'Ibirité',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3129905,
    label: 'Ibitiúra de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130002,
    label: 'Ibituruna',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130051,
    label: 'Icaraí de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130101,
    label: 'Igarapé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130200,
    label: 'Igaratinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130309,
    label: 'Iguatama',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130408,
    label: 'Ijaci',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130507,
    label: 'Ilicínea',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130556,
    label: 'Imbé de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130606,
    label: 'Inconfidentes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130655,
    label: 'Indaiabira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130705,
    label: 'Indianópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130804,
    label: 'Ingaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3130903,
    label: 'Inhapim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131000,
    label: 'Inhaúma',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131109,
    label: 'Inimutaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131158,
    label: 'Ipaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131208,
    label: 'Ipanema',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131307,
    label: 'Ipatinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131406,
    label: 'Ipiaçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131505,
    label: 'Ipuiúna',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131604,
    label: 'Iraí de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131703,
    label: 'Itabira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131802,
    label: 'Itabirinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3131901,
    label: 'Itabirito',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132008,
    label: 'Itacambira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132107,
    label: 'Itacarambi',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132206,
    label: 'Itaguara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132305,
    label: 'Itaipé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132404,
    label: 'Itajubá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132503,
    label: 'Itamarandiba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132602,
    label: 'Itamarati de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132701,
    label: 'Itambacuri',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132800,
    label: 'Itambé do Mato Dentro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3132909,
    label: 'Itamogi',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133006,
    label: 'Itamonte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133105,
    label: 'Itanhandu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133204,
    label: 'Itanhomi',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133303,
    label: 'Itaobim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133402,
    label: 'Itapagipe',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133501,
    label: 'Itapecerica',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133600,
    label: 'Itapeva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133709,
    label: 'Itatiaiuçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133758,
    label: 'Itaú de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133808,
    label: 'Itaúna',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3133907,
    label: 'Itaverava',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134004,
    label: 'Itinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134103,
    label: 'Itueta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134202,
    label: 'Ituiutaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134301,
    label: 'Itumirim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134400,
    label: 'Iturama',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134509,
    label: 'Itutinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134608,
    label: 'Jaboticatubas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134707,
    label: 'Jacinto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134806,
    label: 'Jacuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3134905,
    label: 'Jacutinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135001,
    label: 'Jaguaraçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135050,
    label: 'Jaíba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135076,
    label: 'Jampruca',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135100,
    label: 'Janaúba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135209,
    label: 'Januária',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135308,
    label: 'Japaraíba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135357,
    label: 'Japonvar',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135407,
    label: 'Jeceaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135456,
    label: 'Jenipapo de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135506,
    label: 'Jequeri',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135605,
    label: 'Jequitaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135704,
    label: 'Jequitibá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135803,
    label: 'Jequitinhonha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3135902,
    label: 'Jesuânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136009,
    label: 'Joaíma',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136108,
    label: 'Joanésia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136207,
    label: 'João Monlevade',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136306,
    label: 'João Pinheiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136405,
    label: 'Joaquim Felício',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136504,
    label: 'Jordânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136520,
    label: 'José Gonçalves de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136553,
    label: 'José Raydan',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136579,
    label: 'Josenópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136603,
    label: 'Nova União',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136652,
    label: 'Juatuba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136702,
    label: 'Juiz de Fora',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136801,
    label: 'Juramento',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136900,
    label: 'Juruaia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3136959,
    label: 'Juvenília',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137007,
    label: 'Ladainha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137106,
    label: 'Lagamar',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137205,
    label: 'Lagoa da Prata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137304,
    label: 'Lagoa dos Patos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137403,
    label: 'Lagoa Dourada',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137502,
    label: 'Lagoa Formosa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137536,
    label: 'Lagoa Grande',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137601,
    label: 'Lagoa Santa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137700,
    label: 'Lajinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137809,
    label: 'Lambari',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3137908,
    label: 'Lamim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138005,
    label: 'Laranjal',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138104,
    label: 'Lassance',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138203,
    label: 'Lavras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138302,
    label: 'Leandro Ferreira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138351,
    label: 'Leme do Prado',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138401,
    label: 'Leopoldina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138500,
    label: 'Liberdade',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138609,
    label: 'Lima Duarte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138625,
    label: 'Limeira do Oeste',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138658,
    label: 'Lontra',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138674,
    label: 'Luisburgo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138682,
    label: 'Luislândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138708,
    label: 'Luminárias',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138807,
    label: 'Luz',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3138906,
    label: 'Machacalis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139003,
    label: 'Machado',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139102,
    label: 'Madre de Deus de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139201,
    label: 'Malacacheta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139250,
    label: 'Mamonas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139300,
    label: 'Manga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139409,
    label: 'Manhuaçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139508,
    label: 'Manhumirim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139607,
    label: 'Mantena',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139706,
    label: 'Maravilhas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139805,
    label: 'Mar de Espanha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3139904,
    label: 'Maria da Fé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140001,
    label: 'Mariana',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140100,
    label: 'Marilac',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140159,
    label: 'Mário Campos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140209,
    label: 'Maripá de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140308,
    label: 'Marliéria',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140407,
    label: 'Marmelópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140506,
    label: 'Martinho Campos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140530,
    label: 'Martins Soares',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140555,
    label: 'Mata Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140605,
    label: 'Materlândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140704,
    label: 'Mateus Leme',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140803,
    label: 'Matias Barbosa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140852,
    label: 'Matias Cardoso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3140902,
    label: 'Matipó',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141009,
    label: 'Mato Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141108,
    label: 'Matozinhos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141207,
    label: 'Matutina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141306,
    label: 'Medeiros',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141405,
    label: 'Medina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141504,
    label: 'Mendes Pimentel',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141603,
    label: 'Mercês',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141702,
    label: 'Mesquita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141801,
    label: 'Minas Novas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3141900,
    label: 'Minduri',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142007,
    label: 'Mirabela',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142106,
    label: 'Miradouro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142205,
    label: 'Miraí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142254,
    label: 'Miravânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142304,
    label: 'Moeda',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142403,
    label: 'Moema',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142502,
    label: 'Monjolos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142601,
    label: 'Monsenhor Paulo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142700,
    label: 'Montalvânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142809,
    label: 'Monte Alegre de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3142908,
    label: 'Monte Azul',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143005,
    label: 'Monte Belo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143104,
    label: 'Monte Carmelo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143153,
    label: 'Monte Formoso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143203,
    label: 'Monte Santo de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143302,
    label: 'Montes Claros',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143401,
    label: 'Monte Sião',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143450,
    label: 'Montezuma',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143500,
    label: 'Morada Nova de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143609,
    label: 'Morro da Garça',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143708,
    label: 'Morro do Pilar',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143807,
    label: 'Munhoz',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3143906,
    label: 'Muriaé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144003,
    label: 'Mutum',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144102,
    label: 'Muzambinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144201,
    label: 'Nacip Raydan',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144300,
    label: 'Nanuque',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144359,
    label: 'Naque',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144375,
    label: 'Natalândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144409,
    label: 'Natércia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144508,
    label: 'Nazareno',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144607,
    label: 'Nepomuceno',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144656,
    label: 'Ninheira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144672,
    label: 'Nova Belém',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144706,
    label: 'Nova Era',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144805,
    label: 'Nova Lima',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3144904,
    label: 'Nova Módica',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145000,
    label: 'Nova Ponte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145059,
    label: 'Nova Porteirinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145109,
    label: 'Nova Resende',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145208,
    label: 'Nova Serrana',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145307,
    label: 'Novo Cruzeiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145356,
    label: 'Novo Oriente de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145372,
    label: 'Novorizonte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145406,
    label: 'Olaria',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145455,
    label: "Olhos-d'Água",
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145505,
    label: 'Olímpio Noronha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145604,
    label: 'Oliveira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145703,
    label: 'Oliveira Fortes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145802,
    label: 'Onça de Pitangui',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145851,
    label: 'Oratórios',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145877,
    label: 'Orizânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3145901,
    label: 'Ouro Branco',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146008,
    label: 'Ouro Fino',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146107,
    label: 'Ouro Preto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146206,
    label: 'Ouro Verde de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146255,
    label: 'Padre Carvalho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146305,
    label: 'Padre Paraíso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146404,
    label: 'Paineiras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146503,
    label: 'Pains',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146552,
    label: 'Pai Pedro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146602,
    label: 'Paiva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146701,
    label: 'Palma',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146750,
    label: 'Palmópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3146909,
    label: 'Papagaios',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147006,
    label: 'Paracatu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147105,
    label: 'Pará de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147204,
    label: 'Paraguaçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147303,
    label: 'Paraisópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147402,
    label: 'Paraopeba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147501,
    label: 'Passabém',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147600,
    label: 'Passa Quatro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147709,
    label: 'Passa Tempo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147808,
    label: 'Passa Vinte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147907,
    label: 'Passos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3147956,
    label: 'Patis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148004,
    label: 'Patos de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148103,
    label: 'Patrocínio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148202,
    label: 'Patrocínio do Muriaé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148301,
    label: 'Paula Cândido',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148400,
    label: 'Paulistas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148509,
    label: 'Pavão',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148608,
    label: 'Peçanha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148707,
    label: 'Pedra Azul',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148756,
    label: 'Pedra Bonita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148806,
    label: 'Pedra do Anta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3148905,
    label: 'Pedra do Indaiá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149002,
    label: 'Pedra Dourada',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149101,
    label: 'Pedralva',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149150,
    label: 'Pedras de Maria da Cruz',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149200,
    label: 'Pedrinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149309,
    label: 'Pedro Leopoldo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149408,
    label: 'Pedro Teixeira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149507,
    label: 'Pequeri',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149606,
    label: 'Pequi',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149705,
    label: 'Perdigão',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149804,
    label: 'Perdizes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149903,
    label: 'Perdões',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3149952,
    label: 'Periquito',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150000,
    label: 'Pescador',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150109,
    label: 'Piau',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150158,
    label: 'Piedade de Caratinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150208,
    label: 'Piedade de Ponte Nova',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150307,
    label: 'Piedade do Rio Grande',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150406,
    label: 'Piedade dos Gerais',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150505,
    label: 'Pimenta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150539,
    label: "Pingo d'Água",
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150570,
    label: 'Pintópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150604,
    label: 'Piracema',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150703,
    label: 'Pirajuba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150802,
    label: 'Piranga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3150901,
    label: 'Piranguçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151008,
    label: 'Piranguinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151107,
    label: 'Pirapetinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151206,
    label: 'Pirapora',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151305,
    label: 'Piraúba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151404,
    label: 'Pitangui',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151503,
    label: 'Piumhi',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151602,
    label: 'Planura',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151701,
    label: 'Poço Fundo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151800,
    label: 'Poços de Caldas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3151909,
    label: 'Pocrane',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152006,
    label: 'Pompéu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152105,
    label: 'Ponte Nova',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152131,
    label: 'Ponto Chique',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152170,
    label: 'Ponto dos Volantes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152204,
    label: 'Porteirinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152303,
    label: 'Porto Firme',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152402,
    label: 'Poté',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152501,
    label: 'Pouso Alegre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152600,
    label: 'Pouso Alto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152709,
    label: 'Prados',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152808,
    label: 'Prata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3152907,
    label: 'Pratápolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153004,
    label: 'Pratinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153103,
    label: 'Presidente Bernardes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153202,
    label: 'Presidente Juscelino',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153301,
    label: 'Presidente Kubitschek',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153400,
    label: 'Presidente Olegário',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153509,
    label: 'Alto Jequitibá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153608,
    label: 'Prudente de Morais',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153707,
    label: 'Quartel Geral',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153806,
    label: 'Queluzito',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3153905,
    label: 'Raposos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154002,
    label: 'Raul Soares',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154101,
    label: 'Recreio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154150,
    label: 'Reduto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154200,
    label: 'Resende Costa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154309,
    label: 'Resplendor',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154408,
    label: 'Ressaquinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154457,
    label: 'Riachinho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154507,
    label: 'Riacho dos Machados',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154606,
    label: 'Ribeirão das Neves',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154705,
    label: 'Ribeirão Vermelho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154804,
    label: 'Rio Acima',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3154903,
    label: 'Rio Casca',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155009,
    label: 'Rio Doce',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155108,
    label: 'Rio do Prado',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155207,
    label: 'Rio Espera',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155306,
    label: 'Rio Manso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155405,
    label: 'Rio Novo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155504,
    label: 'Rio Paranaíba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155603,
    label: 'Rio Pardo de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155702,
    label: 'Rio Piracicaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155801,
    label: 'Rio Pomba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3155900,
    label: 'Rio Preto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156007,
    label: 'Rio Vermelho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156106,
    label: 'Ritápolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156205,
    label: 'Rochedo de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156304,
    label: 'Rodeiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156403,
    label: 'Romaria',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156452,
    label: 'Rosário da Limeira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156502,
    label: 'Rubelita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156601,
    label: 'Rubim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156700,
    label: 'Sabará',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156809,
    label: 'Sabinópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3156908,
    label: 'Sacramento',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157005,
    label: 'Salinas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157104,
    label: 'Salto da Divisa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157203,
    label: 'Santa Bárbara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157252,
    label: 'Santa Bárbara do Leste',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157278,
    label: 'Santa Bárbara do Monte Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157302,
    label: 'Santa Bárbara do Tugúrio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157336,
    label: 'Santa Cruz de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157377,
    label: 'Santa Cruz de Salinas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157401,
    label: 'Santa Cruz do Escalvado',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157500,
    label: 'Santa Efigênia de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157609,
    label: 'Santa Fé de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157658,
    label: 'Santa Helena de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157708,
    label: 'Santa Juliana',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157807,
    label: 'Santa Luzia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3157906,
    label: 'Santa Margarida',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158003,
    label: 'Santa Maria de Itabira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158102,
    label: 'Santa Maria do Salto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158201,
    label: 'Santa Maria do Suaçuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158300,
    label: 'Santana da Vargem',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158409,
    label: 'Santana de Cataguases',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158508,
    label: 'Santana de Pirapama',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158607,
    label: 'Santana do Deserto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158706,
    label: 'Santana do Garambéu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158805,
    label: 'Santana do Jacaré',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158904,
    label: 'Santana do Manhuaçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3158953,
    label: 'Santana do Paraíso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159001,
    label: 'Santana do Riacho',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159100,
    label: 'Santana dos Montes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159209,
    label: 'Santa Rita de Caldas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159308,
    label: 'Santa Rita de Jacutinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159357,
    label: 'Santa Rita de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159407,
    label: 'Santa Rita de Ibitipoca',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159506,
    label: 'Santa Rita do Itueto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159605,
    label: 'Santa Rita do Sapucaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159704,
    label: 'Santa Rosa da Serra',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159803,
    label: 'Santa Vitória',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3159902,
    label: 'Santo Antônio do Amparo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160009,
    label: 'Santo Antônio do Aventureiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160108,
    label: 'Santo Antônio do Grama',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160207,
    label: 'Santo Antônio do Itambé',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160306,
    label: 'Santo Antônio do Jacinto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160405,
    label: 'Santo Antônio do Monte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160454,
    label: 'Santo Antônio do Retiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160504,
    label: 'Santo Antônio do Rio Abaixo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160603,
    label: 'Santo Hipólito',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160702,
    label: 'Santos Dumont',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160801,
    label: 'São Bento Abade',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160900,
    label: 'São Brás do Suaçuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3160959,
    label: 'São Domingos das Dores',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161007,
    label: 'São Domingos do Prata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161056,
    label: 'São Félix de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161106,
    label: 'São Francisco',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161205,
    label: 'São Francisco de Paula',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161304,
    label: 'São Francisco de Sales',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161403,
    label: 'São Francisco do Glória',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161502,
    label: 'São Geraldo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161601,
    label: 'São Geraldo da Piedade',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161650,
    label: 'São Geraldo do Baixio',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161700,
    label: 'São Gonçalo do Abaeté',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161809,
    label: 'São Gonçalo do Pará',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3161908,
    label: 'São Gonçalo do Rio Abaixo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162005,
    label: 'São Gonçalo do Sapucaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162104,
    label: 'São Gotardo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162203,
    label: 'São João Batista do Glória',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162252,
    label: 'São João da Lagoa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162302,
    label: 'São João da Mata',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162401,
    label: 'São João da Ponte',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162450,
    label: 'São João das Missões',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162500,
    label: 'São João del Rei',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162559,
    label: 'São João do Manhuaçu',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162575,
    label: 'São João do Manteninha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162609,
    label: 'São João do Oriente',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162658,
    label: 'São João do Pacuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162708,
    label: 'São João do Paraíso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162807,
    label: 'São João Evangelista',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162906,
    label: 'São João Nepomuceno',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162922,
    label: 'São Joaquim de Bicas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162948,
    label: 'São José da Barra',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3162955,
    label: 'São José da Lapa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163003,
    label: 'São José da Safira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163102,
    label: 'São José da Varginha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163201,
    label: 'São José do Alegre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163300,
    label: 'São José do Divino',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163409,
    label: 'São José do Goiabal',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163508,
    label: 'São José do Jacuri',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163607,
    label: 'São José do Mantimento',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163706,
    label: 'São Lourenço',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163805,
    label: 'São Miguel do Anta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3163904,
    label: 'São Pedro da União',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164001,
    label: 'São Pedro dos Ferros',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164100,
    label: 'São Pedro do Suaçuí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164209,
    label: 'São Romão',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164308,
    label: 'São Roque de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164407,
    label: 'São Sebastião da Bela Vista',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164431,
    label: 'São Sebastião da Vargem Alegre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164472,
    label: 'São Sebastião do Anta',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164506,
    label: 'São Sebastião do Maranhão',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164605,
    label: 'São Sebastião do Oeste',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164704,
    label: 'São Sebastião do Paraíso',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164803,
    label: 'São Sebastião do Rio Preto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3164902,
    label: 'São Sebastião do Rio Verde',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165008,
    label: 'São Tiago',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165107,
    label: 'São Tomás de Aquino',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165206,
    label: 'São Thomé das Letras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165305,
    label: 'São Vicente de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165404,
    label: 'Sapucaí-Mirim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165503,
    label: 'Sardoá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165537,
    label: 'Sarzedo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165552,
    label: 'Setubinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165560,
    label: 'Sem-Peixe',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165578,
    label: 'Senador Amaral',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165602,
    label: 'Senador Cortes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165701,
    label: 'Senador Firmino',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165800,
    label: 'Senador José Bento',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3165909,
    label: 'Senador Modestino Gonçalves',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166006,
    label: 'Senhora de Oliveira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166105,
    label: 'Senhora do Porto',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166204,
    label: 'Senhora dos Remédios',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166303,
    label: 'Sericita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166402,
    label: 'Seritinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166501,
    label: 'Serra Azul de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166600,
    label: 'Serra da Saudade',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166709,
    label: 'Serra dos Aimorés',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166808,
    label: 'Serra do Salitre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166907,
    label: 'Serrania',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3166956,
    label: 'Serranópolis de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167004,
    label: 'Serranos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167103,
    label: 'Serro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167202,
    label: 'Sete Lagoas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167301,
    label: 'Silveirânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167400,
    label: 'Silvianópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167509,
    label: 'Simão Pereira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167608,
    label: 'Simonésia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167707,
    label: 'Sobrália',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167806,
    label: 'Soledade de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3167905,
    label: 'Tabuleiro',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168002,
    label: 'Taiobeiras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168051,
    label: 'Taparuba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168101,
    label: 'Tapira',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168200,
    label: 'Tapiraí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168309,
    label: 'Taquaraçu de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168408,
    label: 'Tarumirim',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168507,
    label: 'Teixeiras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168606,
    label: 'Teófilo Otoni',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168705,
    label: 'Timóteo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168804,
    label: 'Tiradentes',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3168903,
    label: 'Tiros',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169000,
    label: 'Tocantins',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169059,
    label: 'Tocos do Moji',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169109,
    label: 'Toledo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169208,
    label: 'Tombos',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169307,
    label: 'Três Corações',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169356,
    label: 'Três Marias',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169406,
    label: 'Três Pontas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169505,
    label: 'Tumiritinga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169604,
    label: 'Tupaciguara',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169703,
    label: 'Turmalina',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169802,
    label: 'Turvolândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3169901,
    label: 'Ubá',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170008,
    label: 'Ubaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170057,
    label: 'Ubaporanga',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170107,
    label: 'Uberaba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170206,
    label: 'Uberlândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170305,
    label: 'Umburatiba',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170404,
    label: 'Unaí',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170438,
    label: 'União de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170479,
    label: 'Uruana de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170503,
    label: 'Urucânia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170529,
    label: 'Urucuia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170578,
    label: 'Vargem Alegre',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170602,
    label: 'Vargem Bonita',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170651,
    label: 'Vargem Grande do Rio Pardo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170701,
    label: 'Varginha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170750,
    label: 'Varjão de Minas',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170800,
    label: 'Várzea da Palma',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3170909,
    label: 'Varzelândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171006,
    label: 'Vazante',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171030,
    label: 'Verdelândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171071,
    label: 'Veredinha',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171105,
    label: 'Veríssimo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171154,
    label: 'Vermelho Novo',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171204,
    label: 'Vespasiano',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171303,
    label: 'Viçosa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171402,
    label: 'Vieiras',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171501,
    label: 'Mathias Lobato',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171600,
    label: 'Virgem da Lapa',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171709,
    label: 'Virgínia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171808,
    label: 'Virginópolis',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3171907,
    label: 'Virgolândia',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3172004,
    label: 'Visconde do Rio Branco',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3172103,
    label: 'Volta Grande',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3172202,
    label: 'Wenceslau Braz',
    state: {value: 31, initials: 'MG', label: 'Minas Gerais'},
  },
  {
    value: 3200102,
    label: 'Afonso Cláudio',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200136,
    label: 'Águia Branca',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200169,
    label: 'Água Doce do Norte',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200201,
    label: 'Alegre',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200300,
    label: 'Alfredo Chaves',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200359,
    label: 'Alto Rio Novo',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200409,
    label: 'Anchieta',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200508,
    label: 'Apiacá',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200607,
    label: 'Aracruz',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200706,
    label: 'Atílio Vivacqua',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200805,
    label: 'Baixo Guandu',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3200904,
    label: 'Barra de São Francisco',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201001,
    label: 'Boa Esperança',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201100,
    label: 'Bom Jesus do Norte',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201159,
    label: 'Brejetuba',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201209,
    label: 'Cachoeiro de Itapemirim',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201308,
    label: 'Cariacica',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201407,
    label: 'Castelo',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201506,
    label: 'Colatina',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201605,
    label: 'Conceição da Barra',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201704,
    label: 'Conceição do Castelo',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201803,
    label: 'Divino de São Lourenço',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3201902,
    label: 'Domingos Martins',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202009,
    label: 'Dores do Rio Preto',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202108,
    label: 'Ecoporanga',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202207,
    label: 'Fundão',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202256,
    label: 'Governador Lindenberg',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202306,
    label: 'Guaçuí',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202405,
    label: 'Guarapari',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202454,
    label: 'Ibatiba',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202504,
    label: 'Ibiraçu',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202553,
    label: 'Ibitirama',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202603,
    label: 'Iconha',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202652,
    label: 'Irupi',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202702,
    label: 'Itaguaçu',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202801,
    label: 'Itapemirim',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3202900,
    label: 'Itarana',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203007,
    label: 'Iúna',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203056,
    label: 'Jaguaré',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203106,
    label: 'Jerônimo Monteiro',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203130,
    label: 'João Neiva',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203163,
    label: 'Laranja da Terra',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203205,
    label: 'Linhares',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203304,
    label: 'Mantenópolis',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203320,
    label: 'Marataízes',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203346,
    label: 'Marechal Floriano',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203353,
    label: 'Marilândia',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203403,
    label: 'Mimoso do Sul',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203502,
    label: 'Montanha',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203601,
    label: 'Mucurici',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203700,
    label: 'Muniz Freire',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203809,
    label: 'Muqui',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3203908,
    label: 'Nova Venécia',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204005,
    label: 'Pancas',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204054,
    label: 'Pedro Canário',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204104,
    label: 'Pinheiros',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204203,
    label: 'Piúma',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204252,
    label: 'Ponto Belo',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204302,
    label: 'Presidente Kennedy',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204351,
    label: 'Rio Bananal',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204401,
    label: 'Rio Novo do Sul',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204500,
    label: 'Santa Leopoldina',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204559,
    label: 'Santa Maria de Jetibá',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204609,
    label: 'Santa Teresa',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204658,
    label: 'São Domingos do Norte',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204708,
    label: 'São Gabriel da Palha',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204807,
    label: 'São José do Calçado',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204906,
    label: 'São Mateus',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3204955,
    label: 'São Roque do Canaã',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205002,
    label: 'Serra',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205010,
    label: 'Sooretama',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205036,
    label: 'Vargem Alta',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205069,
    label: 'Venda Nova do Imigrante',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205101,
    label: 'Viana',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205150,
    label: 'Vila Pavão',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205176,
    label: 'Vila Valério',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205200,
    label: 'Vila Velha',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3205309,
    label: 'Vitória',
    state: {value: 32, initials: 'ES', label: 'Espírito Santo'},
  },
  {
    value: 3300100,
    label: 'Angra dos Reis',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300159,
    label: 'Aperibé',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300209,
    label: 'Araruama',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300225,
    label: 'Areal',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300233,
    label: 'Armação dos Búzios',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300258,
    label: 'Arraial do Cabo',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300308,
    label: 'Barra do Piraí',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300407,
    label: 'Barra Mansa',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300456,
    label: 'Belford Roxo',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300506,
    label: 'Bom Jardim',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300605,
    label: 'Bom Jesus do Itabapoana',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300704,
    label: 'Cabo Frio',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300803,
    label: 'Cachoeiras de Macacu',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300902,
    label: 'Cambuci',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300936,
    label: 'Carapebus',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3300951,
    label: 'Comendador Levy Gasparian',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301009,
    label: 'Campos dos Goytacazes',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301108,
    label: 'Cantagalo',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301157,
    label: 'Cardoso Moreira',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301207,
    label: 'Carmo',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301306,
    label: 'Casimiro de Abreu',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301405,
    label: 'Conceição de Macabu',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301504,
    label: 'Cordeiro',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301603,
    label: 'Duas Barras',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301702,
    label: 'Duque de Caxias',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301801,
    label: 'Engenheiro Paulo de Frontin',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301850,
    label: 'Guapimirim',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301876,
    label: 'Iguaba Grande',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3301900,
    label: 'Itaboraí',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302007,
    label: 'Itaguaí',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302056,
    label: 'Italva',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302106,
    label: 'Itaocara',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302205,
    label: 'Itaperuna',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302254,
    label: 'Itatiaia',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302270,
    label: 'Japeri',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302304,
    label: 'Laje do Muriaé',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302403,
    label: 'Macaé',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302452,
    label: 'Macuco',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302502,
    label: 'Magé',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302601,
    label: 'Mangaratiba',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302700,
    label: 'Maricá',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302809,
    label: 'Mendes',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302858,
    label: 'Mesquita',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3302908,
    label: 'Miguel Pereira',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303005,
    label: 'Miracema',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303104,
    label: 'Natividade',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303203,
    label: 'Nilópolis',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303302,
    label: 'Niterói',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303401,
    label: 'Nova Friburgo',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303500,
    label: 'Nova Iguaçu',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303609,
    label: 'Paracambi',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303708,
    label: 'Paraíba do Sul',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303807,
    label: 'Paraty',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303856,
    label: 'Paty do Alferes',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303906,
    label: 'Petrópolis',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3303955,
    label: 'Pinheiral',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304003,
    label: 'Piraí',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304102,
    label: 'Porciúncula',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304110,
    label: 'Porto Real',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304128,
    label: 'Quatis',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304144,
    label: 'Queimados',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304151,
    label: 'Quissamã',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304201,
    label: 'Resende',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304300,
    label: 'Rio Bonito',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304409,
    label: 'Rio Claro',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304508,
    label: 'Rio das Flores',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304524,
    label: 'Rio das Ostras',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304557,
    label: 'Rio de Janeiro',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304607,
    label: 'Santa Maria Madalena',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304706,
    label: 'Santo Antônio de Pádua',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304755,
    label: 'São Francisco de Itabapoana',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304805,
    label: 'São Fidélis',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3304904,
    label: 'São Gonçalo',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305000,
    label: 'São João da Barra',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305109,
    label: 'São João de Meriti',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305133,
    label: 'São José de Ubá',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305158,
    label: 'São José do Vale do Rio Preto',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305208,
    label: 'São Pedro da Aldeia',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305307,
    label: 'São Sebastião do Alto',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305406,
    label: 'Sapucaia',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305505,
    label: 'Saquarema',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305554,
    label: 'Seropédica',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305604,
    label: 'Silva Jardim',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305703,
    label: 'Sumidouro',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305752,
    label: 'Tanguá',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305802,
    label: 'Teresópolis',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3305901,
    label: 'Trajano de Moraes',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3306008,
    label: 'Três Rios',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3306107,
    label: 'Valença',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3306156,
    label: 'Varre-Sai',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3306206,
    label: 'Vassouras',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3306305,
    label: 'Volta Redonda',
    state: {value: 33, initials: 'RJ', label: 'Rio de Janeiro'},
  },
  {
    value: 3500105,
    label: 'Adamantina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500204,
    label: 'Adolfo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500303,
    label: 'Aguaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500402,
    label: 'Águas da Prata',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500501,
    label: 'Águas de Lindóia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500550,
    label: 'Águas de Santa Bárbara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500600,
    label: 'Águas de São Pedro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500709,
    label: 'Agudos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500758,
    label: 'Alambari',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500808,
    label: 'Alfredo Marcondes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3500907,
    label: 'Altair',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501004,
    label: 'Altinópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501103,
    label: 'Alto Alegre',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501152,
    label: 'Alumínio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501202,
    label: 'Álvares Florence',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501301,
    label: 'Álvares Machado',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501400,
    label: 'Álvaro de Carvalho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501509,
    label: 'Alvinlândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501608,
    label: 'Americana',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501707,
    label: 'Américo Brasiliense',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501806,
    label: 'Américo de Campos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3501905,
    label: 'Amparo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502002,
    label: 'Analândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502101,
    label: 'Andradina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502200,
    label: 'Angatuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502309,
    label: 'Anhembi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502408,
    label: 'Anhumas',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502507,
    label: 'Aparecida',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502606,
    label: "Aparecida d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502705,
    label: 'Apiaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502754,
    label: 'Araçariguama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502804,
    label: 'Araçatuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3502903,
    label: 'Araçoiaba da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503000,
    label: 'Aramina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503109,
    label: 'Arandu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503158,
    label: 'Arapeí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503208,
    label: 'Araraquara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503307,
    label: 'Araras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503356,
    label: 'Arco-Íris',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503406,
    label: 'Arealva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503505,
    label: 'Areias',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503604,
    label: 'Areiópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503703,
    label: 'Ariranha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503802,
    label: 'Artur Nogueira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503901,
    label: 'Arujá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3503950,
    label: 'Aspásia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504008,
    label: 'Assis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504107,
    label: 'Atibaia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504206,
    label: 'Auriflama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504305,
    label: 'Avaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504404,
    label: 'Avanhandava',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504503,
    label: 'Avaré',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504602,
    label: 'Bady Bassitt',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504701,
    label: 'Balbinos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504800,
    label: 'Bálsamo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3504909,
    label: 'Bananal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505005,
    label: 'Barão de Antonina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505104,
    label: 'Barbosa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505203,
    label: 'Bariri',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505302,
    label: 'Barra Bonita',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505351,
    label: 'Barra do Chapéu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505401,
    label: 'Barra do Turvo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505500,
    label: 'Barretos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505609,
    label: 'Barrinha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505708,
    label: 'Barueri',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505807,
    label: 'Bastos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3505906,
    label: 'Batatais',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506003,
    label: 'Bauru',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506102,
    label: 'Bebedouro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506201,
    label: 'Bento de Abreu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506300,
    label: 'Bernardino de Campos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506359,
    label: 'Bertioga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506409,
    label: 'Bilac',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506508,
    label: 'Birigui',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506607,
    label: 'Biritiba Mirim',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506706,
    label: 'Boa Esperança do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506805,
    label: 'Bocaina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3506904,
    label: 'Bofete',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507001,
    label: 'Boituva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507100,
    label: 'Bom Jesus dos Perdões',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507159,
    label: 'Bom Sucesso de Itararé',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507209,
    label: 'Borá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507308,
    label: 'Boracéia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507407,
    label: 'Borborema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507456,
    label: 'Borebi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507506,
    label: 'Botucatu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507605,
    label: 'Bragança Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507704,
    label: 'Braúna',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507753,
    label: 'Brejo Alegre',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507803,
    label: 'Brodowski',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3507902,
    label: 'Brotas',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508009,
    label: 'Buri',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508108,
    label: 'Buritama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508207,
    label: 'Buritizal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508306,
    label: 'Cabrália Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508405,
    label: 'Cabreúva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508504,
    label: 'Caçapava',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508603,
    label: 'Cachoeira Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508702,
    label: 'Caconde',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508801,
    label: 'Cafelândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3508900,
    label: 'Caiabu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509007,
    label: 'Caieiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509106,
    label: 'Caiuá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509205,
    label: 'Cajamar',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509254,
    label: 'Cajati',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509304,
    label: 'Cajobi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509403,
    label: 'Cajuru',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509452,
    label: 'Campina do Monte Alegre',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509502,
    label: 'Campinas',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509601,
    label: 'Campo Limpo Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509700,
    label: 'Campos do Jordão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509809,
    label: 'Campos Novos Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509908,
    label: 'Cananéia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3509957,
    label: 'Canas',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510005,
    label: 'Cândido Mota',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510104,
    label: 'Cândido Rodrigues',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510153,
    label: 'Canitar',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510203,
    label: 'Capão Bonito',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510302,
    label: 'Capela do Alto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510401,
    label: 'Capivari',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510500,
    label: 'Caraguatatuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510609,
    label: 'Carapicuíba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510708,
    label: 'Cardoso',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510807,
    label: 'Casa Branca',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3510906,
    label: 'Cássia dos Coqueiros',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511003,
    label: 'Castilho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511102,
    label: 'Catanduva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511201,
    label: 'Catiguá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511300,
    label: 'Cedral',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511409,
    label: 'Cerqueira César',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511508,
    label: 'Cerquilho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511607,
    label: 'Cesário Lange',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511706,
    label: 'Charqueada',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3511904,
    label: 'Clementina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512001,
    label: 'Colina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512100,
    label: 'Colômbia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512209,
    label: 'Conchal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512308,
    label: 'Conchas',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512407,
    label: 'Cordeirópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512506,
    label: 'Coroados',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512605,
    label: 'Coronel Macedo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512704,
    label: 'Corumbataí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512803,
    label: 'Cosmópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3512902,
    label: 'Cosmorama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513009,
    label: 'Cotia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513108,
    label: 'Cravinhos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513207,
    label: 'Cristais Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513306,
    label: 'Cruzália',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513405,
    label: 'Cruzeiro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513504,
    label: 'Cubatão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513603,
    label: 'Cunha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513702,
    label: 'Descalvado',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513801,
    label: 'Diadema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513850,
    label: 'Dirce Reis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3513900,
    label: 'Divinolândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514007,
    label: 'Dobrada',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514106,
    label: 'Dois Córregos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514205,
    label: 'Dolcinópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514304,
    label: 'Dourado',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514403,
    label: 'Dracena',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514502,
    label: 'Duartina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514601,
    label: 'Dumont',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514700,
    label: 'Echaporã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514809,
    label: 'Eldorado',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514908,
    label: 'Elias Fausto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514924,
    label: 'Elisiário',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3514957,
    label: 'Embaúba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515004,
    label: 'Embu das Artes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515103,
    label: 'Embu-Guaçu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515129,
    label: 'Emilianópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515152,
    label: 'Engenheiro Coelho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515186,
    label: 'Espírito Santo do Pinhal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515194,
    label: 'Espírito Santo do Turvo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515202,
    label: "Estrela d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515301,
    label: 'Estrela do Norte',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515350,
    label: 'Euclides da Cunha Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515400,
    label: 'Fartura',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515509,
    label: 'Fernandópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515608,
    label: 'Fernando Prestes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515657,
    label: 'Fernão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515707,
    label: 'Ferraz de Vasconcelos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515806,
    label: 'Flora Rica',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3515905,
    label: 'Floreal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516002,
    label: 'Flórida Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516101,
    label: 'Florínea',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516200,
    label: 'Franca',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516309,
    label: 'Francisco Morato',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516408,
    label: 'Franco da Rocha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516507,
    label: 'Gabriel Monteiro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516606,
    label: 'Gália',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516705,
    label: 'Garça',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516804,
    label: 'Gastão Vidigal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516853,
    label: 'Gavião Peixoto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3516903,
    label: 'General Salgado',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517000,
    label: 'Getulina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517109,
    label: 'Glicério',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517208,
    label: 'Guaiçara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517307,
    label: 'Guaimbê',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517406,
    label: 'Guaíra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517505,
    label: 'Guapiaçu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517604,
    label: 'Guapiara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517703,
    label: 'Guará',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517802,
    label: 'Guaraçaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3517901,
    label: 'Guaraci',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518008,
    label: "Guarani d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518107,
    label: 'Guarantã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518206,
    label: 'Guararapes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518305,
    label: 'Guararema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518404,
    label: 'Guaratinguetá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518503,
    label: 'Guareí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518602,
    label: 'Guariba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518701,
    label: 'Guarujá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518800,
    label: 'Guarulhos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518859,
    label: 'Guatapará',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3518909,
    label: 'Guzolândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519006,
    label: 'Herculândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519055,
    label: 'Holambra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519071,
    label: 'Hortolândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519105,
    label: 'Iacanga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519204,
    label: 'Iacri',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519253,
    label: 'Iaras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519303,
    label: 'Ibaté',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519402,
    label: 'Ibirá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519501,
    label: 'Ibirarema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519600,
    label: 'Ibitinga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519709,
    label: 'Ibiúna',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519808,
    label: 'Icém',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3519907,
    label: 'Iepê',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520004,
    label: 'Igaraçu do Tietê',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520103,
    label: 'Igarapava',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520202,
    label: 'Igaratá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520301,
    label: 'Iguape',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520400,
    label: 'Ilhabela',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520426,
    label: 'Ilha Comprida',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520442,
    label: 'Ilha Solteira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520509,
    label: 'Indaiatuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520608,
    label: 'Indiana',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520707,
    label: 'Indiaporã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520806,
    label: 'Inúbia Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3520905,
    label: 'Ipaussu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521002,
    label: 'Iperó',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521101,
    label: 'Ipeúna',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521150,
    label: 'Ipiguá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521200,
    label: 'Iporanga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521309,
    label: 'Ipuã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521408,
    label: 'Iracemápolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521507,
    label: 'Irapuã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521606,
    label: 'Irapuru',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521705,
    label: 'Itaberá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521804,
    label: 'Itaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3521903,
    label: 'Itajobi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522000,
    label: 'Itaju',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522109,
    label: 'Itanhaém',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522158,
    label: 'Itaoca',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522208,
    label: 'Itapecerica da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522307,
    label: 'Itapetininga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522406,
    label: 'Itapeva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522505,
    label: 'Itapevi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522604,
    label: 'Itapira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522653,
    label: 'Itapirapuã Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522703,
    label: 'Itápolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522802,
    label: 'Itaporanga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3522901,
    label: 'Itapuí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523008,
    label: 'Itapura',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523107,
    label: 'Itaquaquecetuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523206,
    label: 'Itararé',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523305,
    label: 'Itariri',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523404,
    label: 'Itatiba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523503,
    label: 'Itatinga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523602,
    label: 'Itirapina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523701,
    label: 'Itirapuã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523800,
    label: 'Itobi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3523909,
    label: 'Itu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524006,
    label: 'Itupeva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524105,
    label: 'Ituverava',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524204,
    label: 'Jaborandi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524303,
    label: 'Jaboticabal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524402,
    label: 'Jacareí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524501,
    label: 'Jaci',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524600,
    label: 'Jacupiranga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524709,
    label: 'Jaguariúna',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524808,
    label: 'Jales',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3524907,
    label: 'Jambeiro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525003,
    label: 'Jandira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525102,
    label: 'Jardinópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525201,
    label: 'Jarinu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525300,
    label: 'Jaú',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525409,
    label: 'Jeriquara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525508,
    label: 'Joanópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525607,
    label: 'João Ramalho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525706,
    label: 'José Bonifácio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525805,
    label: 'Júlio Mesquita',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525854,
    label: 'Jumirim',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3525904,
    label: 'Jundiaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526001,
    label: 'Junqueirópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526100,
    label: 'Juquiá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526209,
    label: 'Juquitiba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526308,
    label: 'Lagoinha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526407,
    label: 'Laranjal Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526506,
    label: 'Lavínia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526605,
    label: 'Lavrinhas',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526704,
    label: 'Leme',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526803,
    label: 'Lençóis Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3526902,
    label: 'Limeira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527009,
    label: 'Lindóia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527108,
    label: 'Lins',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527207,
    label: 'Lorena',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527256,
    label: 'Lourdes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527306,
    label: 'Louveira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527405,
    label: 'Lucélia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527504,
    label: 'Lucianópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527603,
    label: 'Luís Antônio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527702,
    label: 'Luiziânia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527801,
    label: 'Lupércio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3527900,
    label: 'Lutécia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528007,
    label: 'Macatuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528106,
    label: 'Macaubal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528205,
    label: 'Macedônia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528304,
    label: 'Magda',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528403,
    label: 'Mairinque',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528502,
    label: 'Mairiporã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528601,
    label: 'Manduri',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528700,
    label: 'Marabá Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528809,
    label: 'Maracaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528858,
    label: 'Marapoama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3528908,
    label: 'Mariápolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529005,
    label: 'Marília',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529104,
    label: 'Marinópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529203,
    label: 'Martinópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529302,
    label: 'Matão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529401,
    label: 'Mauá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529500,
    label: 'Mendonça',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529609,
    label: 'Meridiano',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529658,
    label: 'Mesópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529708,
    label: 'Miguelópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529807,
    label: 'Mineiros do Tietê',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3529906,
    label: 'Miracatu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530003,
    label: 'Mira Estrela',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530102,
    label: 'Mirandópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530201,
    label: 'Mirante do Paranapanema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530300,
    label: 'Mirassol',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530409,
    label: 'Mirassolândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530508,
    label: 'Mococa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530607,
    label: 'Mogi das Cruzes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530706,
    label: 'Mogi Guaçu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530805,
    label: 'Mogi Mirim',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3530904,
    label: 'Mombuca',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531001,
    label: 'Monções',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531100,
    label: 'Mongaguá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531209,
    label: 'Monte Alegre do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531308,
    label: 'Monte Alto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531407,
    label: 'Monte Aprazível',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531506,
    label: 'Monte Azul Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531605,
    label: 'Monte Castelo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531704,
    label: 'Monteiro Lobato',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531803,
    label: 'Monte Mor',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3531902,
    label: 'Morro Agudo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532009,
    label: 'Morungaba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532058,
    label: 'Motuca',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532108,
    label: 'Murutinga do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532157,
    label: 'Nantes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532207,
    label: 'Narandiba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532306,
    label: 'Natividade da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532405,
    label: 'Nazaré Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532504,
    label: 'Neves Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532603,
    label: 'Nhandeara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532702,
    label: 'Nipoã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532801,
    label: 'Nova Aliança',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532827,
    label: 'Nova Campina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532843,
    label: 'Nova Canaã Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532868,
    label: 'Nova Castilho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3532900,
    label: 'Nova Europa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533007,
    label: 'Nova Granada',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533106,
    label: 'Nova Guataporanga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533205,
    label: 'Nova Independência',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533254,
    label: 'Novais',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533304,
    label: 'Nova Luzitânia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533403,
    label: 'Nova Odessa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533502,
    label: 'Novo Horizonte',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533601,
    label: 'Nuporanga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533700,
    label: 'Ocauçu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533809,
    label: 'Óleo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3533908,
    label: 'Olímpia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534005,
    label: 'Onda Verde',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534104,
    label: 'Oriente',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534203,
    label: 'Orindiúva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534302,
    label: 'Orlândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534401,
    label: 'Osasco',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534500,
    label: 'Oscar Bressane',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534609,
    label: 'Osvaldo Cruz',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534708,
    label: 'Ourinhos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534757,
    label: 'Ouroeste',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534807,
    label: 'Ouro Verde',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3534906,
    label: 'Pacaembu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535002,
    label: 'Palestina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535101,
    label: 'Palmares Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535200,
    label: "Palmeira d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535309,
    label: 'Palmital',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535408,
    label: 'Panorama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535507,
    label: 'Paraguaçu Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535606,
    label: 'Paraibuna',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535705,
    label: 'Paraíso',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535804,
    label: 'Paranapanema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3535903,
    label: 'Paranapuã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536000,
    label: 'Parapuã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536109,
    label: 'Pardinho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536208,
    label: 'Pariquera-Açu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536257,
    label: 'Parisi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536307,
    label: 'Patrocínio Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536406,
    label: 'Paulicéia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536505,
    label: 'Paulínia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536570,
    label: 'Paulistânia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536604,
    label: 'Paulo de Faria',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536703,
    label: 'Pederneiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536802,
    label: 'Pedra Bela',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3536901,
    label: 'Pedranópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537008,
    label: 'Pedregulho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537107,
    label: 'Pedreira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537156,
    label: 'Pedrinhas Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537206,
    label: 'Pedro de Toledo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537305,
    label: 'Penápolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537404,
    label: 'Pereira Barreto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537503,
    label: 'Pereiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537602,
    label: 'Peruíbe',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537701,
    label: 'Piacatu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537800,
    label: 'Piedade',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3537909,
    label: 'Pilar do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538006,
    label: 'Pindamonhangaba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538105,
    label: 'Pindorama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538204,
    label: 'Pinhalzinho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538303,
    label: 'Piquerobi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538501,
    label: 'Piquete',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538600,
    label: 'Piracaia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538709,
    label: 'Piracicaba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538808,
    label: 'Piraju',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3538907,
    label: 'Pirajuí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539004,
    label: 'Pirangi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539103,
    label: 'Pirapora do Bom Jesus',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539202,
    label: 'Pirapozinho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539301,
    label: 'Pirassununga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539400,
    label: 'Piratininga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539509,
    label: 'Pitangueiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539608,
    label: 'Planalto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539707,
    label: 'Platina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539806,
    label: 'Poá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3539905,
    label: 'Poloni',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540002,
    label: 'Pompéia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540101,
    label: 'Pongaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540200,
    label: 'Pontal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540259,
    label: 'Pontalinda',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540309,
    label: 'Pontes Gestal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540408,
    label: 'Populina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540507,
    label: 'Porangaba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540606,
    label: 'Porto Feliz',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540705,
    label: 'Porto Ferreira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540754,
    label: 'Potim',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540804,
    label: 'Potirendaba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540853,
    label: 'Pracinha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3540903,
    label: 'Pradópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541000,
    label: 'Praia Grande',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541059,
    label: 'Pratânia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541109,
    label: 'Presidente Alves',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541208,
    label: 'Presidente Bernardes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541307,
    label: 'Presidente Epitácio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541406,
    label: 'Presidente Prudente',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541505,
    label: 'Presidente Venceslau',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541604,
    label: 'Promissão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541653,
    label: 'Quadra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541703,
    label: 'Quatá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541802,
    label: 'Queiroz',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3541901,
    label: 'Queluz',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542008,
    label: 'Quintana',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542107,
    label: 'Rafard',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542206,
    label: 'Rancharia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542305,
    label: 'Redenção da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542404,
    label: 'Regente Feijó',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542503,
    label: 'Reginópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542602,
    label: 'Registro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542701,
    label: 'Restinga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542800,
    label: 'Ribeira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3542909,
    label: 'Ribeirão Bonito',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543006,
    label: 'Ribeirão Branco',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543105,
    label: 'Ribeirão Corrente',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543204,
    label: 'Ribeirão do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543238,
    label: 'Ribeirão dos Índios',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543253,
    label: 'Ribeirão Grande',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543303,
    label: 'Ribeirão Pires',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543402,
    label: 'Ribeirão Preto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543501,
    label: 'Riversul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543600,
    label: 'Rifaina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543709,
    label: 'Rincão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543808,
    label: 'Rinópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3543907,
    label: 'Rio Claro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544004,
    label: 'Rio das Pedras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544103,
    label: 'Rio Grande da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544202,
    label: 'Riolândia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544251,
    label: 'Rosana',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544301,
    label: 'Roseira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544400,
    label: 'Rubiácea',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544509,
    label: 'Rubinéia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544608,
    label: 'Sabino',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544707,
    label: 'Sagres',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544806,
    label: 'Sales',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3544905,
    label: 'Sales Oliveira',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545001,
    label: 'Salesópolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545100,
    label: 'Salmourão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545159,
    label: 'Saltinho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545209,
    label: 'Salto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545308,
    label: 'Salto de Pirapora',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545407,
    label: 'Salto Grande',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545506,
    label: 'Sandovalina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545605,
    label: 'Santa Adélia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545704,
    label: 'Santa Albertina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3545803,
    label: "Santa Bárbara d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546009,
    label: 'Santa Branca',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546108,
    label: "Santa Clara d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546207,
    label: 'Santa Cruz da Conceição',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546256,
    label: 'Santa Cruz da Esperança',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546306,
    label: 'Santa Cruz das Palmeiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546405,
    label: 'Santa Cruz do Rio Pardo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546504,
    label: 'Santa Ernestina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546603,
    label: 'Santa Fé do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546702,
    label: 'Santa Gertrudes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546801,
    label: 'Santa Isabel',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3546900,
    label: 'Santa Lúcia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547007,
    label: 'Santa Maria da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547106,
    label: 'Santa Mercedes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547205,
    label: 'Santana da Ponte Pensa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547304,
    label: 'Santana de Parnaíba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547403,
    label: "Santa Rita d'Oeste",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547502,
    label: 'Santa Rita do Passa Quatro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547601,
    label: 'Santa Rosa de Viterbo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547650,
    label: 'Santa Salete',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547700,
    label: 'Santo Anastácio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547809,
    label: 'Santo André',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3547908,
    label: 'Santo Antônio da Alegria',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548005,
    label: 'Santo Antônio de Posse',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548054,
    label: 'Santo Antônio do Aracanguá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548104,
    label: 'Santo Antônio do Jardim',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548203,
    label: 'Santo Antônio do Pinhal',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548302,
    label: 'Santo Expedito',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548401,
    label: 'Santópolis do Aguapeí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548500,
    label: 'Santos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548609,
    label: 'São Bento do Sapucaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548708,
    label: 'São Bernardo do Campo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548807,
    label: 'São Caetano do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3548906,
    label: 'São Carlos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549003,
    label: 'São Francisco',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549102,
    label: 'São João da Boa Vista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549201,
    label: 'São João das Duas Pontes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549250,
    label: 'São João de Iracema',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549300,
    label: "São João do Pau d'Alho",
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549409,
    label: 'São Joaquim da Barra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549508,
    label: 'São José da Bela Vista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549607,
    label: 'São José do Barreiro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549706,
    label: 'São José do Rio Pardo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549805,
    label: 'São José do Rio Preto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549904,
    label: 'São José dos Campos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3549953,
    label: 'São Lourenço da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550001,
    label: 'São Luiz do Paraitinga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550100,
    label: 'São Manuel',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550209,
    label: 'São Miguel Arcanjo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550308,
    label: 'São Paulo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550407,
    label: 'São Pedro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550506,
    label: 'São Pedro do Turvo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550605,
    label: 'São Roque',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550704,
    label: 'São Sebastião',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550803,
    label: 'São Sebastião da Grama',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3550902,
    label: 'São Simão',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551009,
    label: 'São Vicente',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551108,
    label: 'Sarapuí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551207,
    label: 'Sarutaiá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551306,
    label: 'Sebastianópolis do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551405,
    label: 'Serra Azul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551504,
    label: 'Serrana',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551603,
    label: 'Serra Negra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551702,
    label: 'Sertãozinho',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551801,
    label: 'Sete Barras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3551900,
    label: 'Severínia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552007,
    label: 'Silveiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552106,
    label: 'Socorro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552205,
    label: 'Sorocaba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552304,
    label: 'Sud Mennucci',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552403,
    label: 'Sumaré',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552502,
    label: 'Suzano',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552551,
    label: 'Suzanápolis',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552601,
    label: 'Tabapuã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552700,
    label: 'Tabatinga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552809,
    label: 'Taboão da Serra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3552908,
    label: 'Taciba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553005,
    label: 'Taguaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553104,
    label: 'Taiaçu',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553203,
    label: 'Taiúva',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553302,
    label: 'Tambaú',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553401,
    label: 'Tanabi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553500,
    label: 'Tapiraí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553609,
    label: 'Tapiratiba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553658,
    label: 'Taquaral',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553708,
    label: 'Taquaritinga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553807,
    label: 'Taquarituba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553856,
    label: 'Taquarivaí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553906,
    label: 'Tarabai',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3553955,
    label: 'Tarumã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554003,
    label: 'Tatuí',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554102,
    label: 'Taubaté',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554201,
    label: 'Tejupá',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554300,
    label: 'Teodoro Sampaio',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554409,
    label: 'Terra Roxa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554508,
    label: 'Tietê',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554607,
    label: 'Timburi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554656,
    label: 'Torre de Pedra',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554706,
    label: 'Torrinha',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554755,
    label: 'Trabiju',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554805,
    label: 'Tremembé',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554904,
    label: 'Três Fronteiras',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3554953,
    label: 'Tuiuti',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555000,
    label: 'Tupã',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555109,
    label: 'Tupi Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555208,
    label: 'Turiúba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555307,
    label: 'Turmalina',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555356,
    label: 'Ubarana',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555406,
    label: 'Ubatuba',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555505,
    label: 'Ubirajara',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555604,
    label: 'Uchoa',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555703,
    label: 'União Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555802,
    label: 'Urânia',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3555901,
    label: 'Uru',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556008,
    label: 'Urupês',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556107,
    label: 'Valentim Gentil',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556206,
    label: 'Valinhos',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556305,
    label: 'Valparaíso',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556354,
    label: 'Vargem',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556404,
    label: 'Vargem Grande do Sul',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556453,
    label: 'Vargem Grande Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556503,
    label: 'Várzea Paulista',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556602,
    label: 'Vera Cruz',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556701,
    label: 'Vinhedo',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556800,
    label: 'Viradouro',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556909,
    label: 'Vista Alegre do Alto',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3556958,
    label: 'Vitória Brasil',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3557006,
    label: 'Votorantim',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3557105,
    label: 'Votuporanga',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3557154,
    label: 'Zacarias',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3557204,
    label: 'Chavantes',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 3557303,
    label: 'Estiva Gerbi',
    state: {value: 35, initials: 'SP', label: 'São Paulo'},
  },
  {
    value: 4100103,
    label: 'Abatiá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100202,
    label: 'Adrianópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100301,
    label: 'Agudos do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100400,
    label: 'Almirante Tamandaré',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100459,
    label: 'Altamira do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100509,
    label: 'Altônia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100608,
    label: 'Alto Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100707,
    label: 'Alto Piquiri',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100806,
    label: 'Alvorada do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4100905,
    label: 'Amaporã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101002,
    label: 'Ampére',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101051,
    label: 'Anahy',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101101,
    label: 'Andirá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101150,
    label: 'Ângulo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101200,
    label: 'Antonina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101309,
    label: 'Antônio Olinto',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101408,
    label: 'Apucarana',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101507,
    label: 'Arapongas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101606,
    label: 'Arapoti',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101655,
    label: 'Arapuã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101705,
    label: 'Araruna',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101804,
    label: 'Araucária',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101853,
    label: 'Ariranha do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4101903,
    label: 'Assaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102000,
    label: 'Assis Chateaubriand',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102109,
    label: 'Astorga',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102208,
    label: 'Atalaia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102307,
    label: 'Balsa Nova',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102406,
    label: 'Bandeirantes',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102505,
    label: 'Barbosa Ferraz',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102604,
    label: 'Barracão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102703,
    label: 'Barra do Jacaré',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102752,
    label: 'Bela Vista da Caroba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102802,
    label: 'Bela Vista do Paraíso',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4102901,
    label: 'Bituruna',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103008,
    label: 'Boa Esperança',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103024,
    label: 'Boa Esperança do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103040,
    label: 'Boa Ventura de São Roque',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103057,
    label: 'Boa Vista da Aparecida',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103107,
    label: 'Bocaiúva do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103156,
    label: 'Bom Jesus do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103206,
    label: 'Bom Sucesso',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103222,
    label: 'Bom Sucesso do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103305,
    label: 'Borrazópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103354,
    label: 'Braganey',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103370,
    label: 'Brasilândia do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103404,
    label: 'Cafeara',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103453,
    label: 'Cafelândia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103479,
    label: 'Cafezal do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103503,
    label: 'Califórnia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103602,
    label: 'Cambará',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103701,
    label: 'Cambé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103800,
    label: 'Cambira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103909,
    label: 'Campina da Lagoa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4103958,
    label: 'Campina do Simão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104006,
    label: 'Campina Grande do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104055,
    label: 'Campo Bonito',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104105,
    label: 'Campo do Tenente',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104204,
    label: 'Campo Largo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104253,
    label: 'Campo Magro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104303,
    label: 'Campo Mourão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104402,
    label: 'Cândido de Abreu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104428,
    label: 'Candói',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104451,
    label: 'Cantagalo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104501,
    label: 'Capanema',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104600,
    label: 'Capitão Leônidas Marques',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104659,
    label: 'Carambeí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104709,
    label: 'Carlópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104808,
    label: 'Cascavel',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4104907,
    label: 'Castro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105003,
    label: 'Catanduvas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105102,
    label: 'Centenário do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105201,
    label: 'Cerro Azul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105300,
    label: 'Céu Azul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105409,
    label: 'Chopinzinho',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105508,
    label: 'Cianorte',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105607,
    label: 'Cidade Gaúcha',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105706,
    label: 'Clevelândia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105805,
    label: 'Colombo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4105904,
    label: 'Colorado',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106001,
    label: 'Congonhinhas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106100,
    label: 'Conselheiro Mairinck',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106209,
    label: 'Contenda',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106308,
    label: 'Corbélia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106407,
    label: 'Cornélio Procópio',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106456,
    label: 'Coronel Domingos Soares',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106506,
    label: 'Coronel Vivida',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106555,
    label: 'Corumbataí do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106571,
    label: 'Cruzeiro do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106605,
    label: 'Cruzeiro do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106704,
    label: 'Cruzeiro do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106803,
    label: 'Cruz Machado',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106852,
    label: 'Cruzmaltina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4106902,
    label: 'Curitiba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107009,
    label: 'Curiúva',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107108,
    label: 'Diamante do Norte',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107124,
    label: 'Diamante do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107157,
    label: "Diamante D'Oeste",
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107207,
    label: 'Dois Vizinhos',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107256,
    label: 'Douradina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107306,
    label: 'Doutor Camargo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107405,
    label: 'Enéas Marques',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107504,
    label: 'Engenheiro Beltrão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107520,
    label: 'Esperança Nova',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107538,
    label: 'Entre Rios do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107546,
    label: 'Espigão Alto do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107553,
    label: 'Farol',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107603,
    label: 'Faxinal',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107652,
    label: 'Fazenda Rio Grande',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107702,
    label: 'Fênix',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107736,
    label: 'Fernandes Pinheiro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107751,
    label: 'Figueira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107801,
    label: 'Floraí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107850,
    label: 'Flor da Serra do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4107900,
    label: 'Floresta',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108007,
    label: 'Florestópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108106,
    label: 'Flórida',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108205,
    label: 'Formosa do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108304,
    label: 'Foz do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108320,
    label: 'Francisco Alves',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108403,
    label: 'Francisco Beltrão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108452,
    label: 'Foz do Jordão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108502,
    label: 'General Carneiro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108551,
    label: 'Godoy Moreira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108601,
    label: 'Goioerê',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108650,
    label: 'Goioxim',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108700,
    label: 'Grandes Rios',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108809,
    label: 'Guaíra',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108908,
    label: 'Guairaçá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4108957,
    label: 'Guamiranga',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109005,
    label: 'Guapirama',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109104,
    label: 'Guaporema',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109203,
    label: 'Guaraci',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109302,
    label: 'Guaraniaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109401,
    label: 'Guarapuava',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109500,
    label: 'Guaraqueçaba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109609,
    label: 'Guaratuba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109658,
    label: 'Honório Serpa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109708,
    label: 'Ibaiti',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109757,
    label: 'Ibema',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109807,
    label: 'Ibiporã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4109906,
    label: 'Icaraíma',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110003,
    label: 'Iguaraçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110052,
    label: 'Iguatu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110078,
    label: 'Imbaú',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110102,
    label: 'Imbituva',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110201,
    label: 'Inácio Martins',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110300,
    label: 'Inajá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110409,
    label: 'Indianópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110508,
    label: 'Ipiranga',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110607,
    label: 'Iporã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110656,
    label: 'Iracema do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110706,
    label: 'Irati',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110805,
    label: 'Iretama',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110904,
    label: 'Itaguajé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4110953,
    label: 'Itaipulândia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111001,
    label: 'Itambaracá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111100,
    label: 'Itambé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111209,
    label: "Itapejara d'Oeste",
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111258,
    label: 'Itaperuçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111308,
    label: 'Itaúna do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111407,
    label: 'Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111506,
    label: 'Ivaiporã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111555,
    label: 'Ivaté',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111605,
    label: 'Ivatuba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111704,
    label: 'Jaboti',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111803,
    label: 'Jacarezinho',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4111902,
    label: 'Jaguapitã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112009,
    label: 'Jaguariaíva',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112108,
    label: 'Jandaia do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112207,
    label: 'Janiópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112306,
    label: 'Japira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112405,
    label: 'Japurá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112504,
    label: 'Jardim Alegre',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112603,
    label: 'Jardim Olinda',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112702,
    label: 'Jataizinho',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112751,
    label: 'Jesuítas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112801,
    label: 'Joaquim Távora',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112900,
    label: 'Jundiaí do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4112959,
    label: 'Juranda',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113007,
    label: 'Jussara',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113106,
    label: 'Kaloré',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113205,
    label: 'Lapa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113254,
    label: 'Laranjal',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113304,
    label: 'Laranjeiras do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113403,
    label: 'Leópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113429,
    label: 'Lidianópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113452,
    label: 'Lindoeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113502,
    label: 'Loanda',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113601,
    label: 'Lobato',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113700,
    label: 'Londrina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113734,
    label: 'Luiziana',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113759,
    label: 'Lunardelli',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113809,
    label: 'Lupionópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4113908,
    label: 'Mallet',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114005,
    label: 'Mamborê',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114104,
    label: 'Mandaguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114203,
    label: 'Mandaguari',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114302,
    label: 'Mandirituba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114351,
    label: 'Manfrinópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114401,
    label: 'Mangueirinha',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114500,
    label: 'Manoel Ribas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114609,
    label: 'Marechal Cândido Rondon',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114708,
    label: 'Maria Helena',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114807,
    label: 'Marialva',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4114906,
    label: 'Marilândia do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115002,
    label: 'Marilena',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115101,
    label: 'Mariluz',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115200,
    label: 'Maringá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115309,
    label: 'Mariópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115358,
    label: 'Maripá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115408,
    label: 'Marmeleiro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115457,
    label: 'Marquinho',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115507,
    label: 'Marumbi',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115606,
    label: 'Matelândia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115705,
    label: 'Matinhos',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115739,
    label: 'Mato Rico',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115754,
    label: 'Mauá da Serra',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115804,
    label: 'Medianeira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115853,
    label: 'Mercedes',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4115903,
    label: 'Mirador',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116000,
    label: 'Miraselva',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116059,
    label: 'Missal',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116109,
    label: 'Moreira Sales',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116208,
    label: 'Morretes',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116307,
    label: 'Munhoz de Melo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116406,
    label: 'Nossa Senhora das Graças',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116505,
    label: 'Nova Aliança do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116604,
    label: 'Nova América da Colina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116703,
    label: 'Nova Aurora',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116802,
    label: 'Nova Cantu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116901,
    label: 'Nova Esperança',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4116950,
    label: 'Nova Esperança do Sudoeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117008,
    label: 'Nova Fátima',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117057,
    label: 'Nova Laranjeiras',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117107,
    label: 'Nova Londrina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117206,
    label: 'Nova Olímpia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117214,
    label: 'Nova Santa Bárbara',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117222,
    label: 'Nova Santa Rosa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117255,
    label: 'Nova Prata do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117271,
    label: 'Nova Tebas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117297,
    label: 'Novo Itacolomi',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117305,
    label: 'Ortigueira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117404,
    label: 'Ourizona',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117453,
    label: 'Ouro Verde do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117503,
    label: 'Paiçandu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117602,
    label: 'Palmas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117701,
    label: 'Palmeira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117800,
    label: 'Palmital',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4117909,
    label: 'Palotina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118006,
    label: 'Paraíso do Norte',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118105,
    label: 'Paranacity',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118204,
    label: 'Paranaguá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118303,
    label: 'Paranapoema',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118402,
    label: 'Paranavaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118451,
    label: 'Pato Bragado',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118501,
    label: 'Pato Branco',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118600,
    label: 'Paula Freitas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118709,
    label: 'Paulo Frontin',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118808,
    label: 'Peabiru',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118857,
    label: 'Perobal',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4118907,
    label: 'Pérola',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119004,
    label: "Pérola d'Oeste",
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119103,
    label: 'Piên',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119152,
    label: 'Pinhais',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119202,
    label: 'Pinhalão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119251,
    label: 'Pinhal de São Bento',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119301,
    label: 'Pinhão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119400,
    label: 'Piraí do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119509,
    label: 'Piraquara',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119608,
    label: 'Pitanga',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119657,
    label: 'Pitangueiras',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119707,
    label: 'Planaltina do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119806,
    label: 'Planalto',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119905,
    label: 'Ponta Grossa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4119954,
    label: 'Pontal do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120002,
    label: 'Porecatu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120101,
    label: 'Porto Amazonas',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120150,
    label: 'Porto Barreiro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120200,
    label: 'Porto Rico',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120309,
    label: 'Porto Vitória',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120333,
    label: 'Prado Ferreira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120358,
    label: 'Pranchita',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120408,
    label: 'Presidente Castelo Branco',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120507,
    label: 'Primeiro de Maio',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120606,
    label: 'Prudentópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120655,
    label: 'Quarto Centenário',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120705,
    label: 'Quatiguá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120804,
    label: 'Quatro Barras',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120853,
    label: 'Quatro Pontes',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4120903,
    label: 'Quedas do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121000,
    label: 'Querência do Norte',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121109,
    label: 'Quinta do Sol',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121208,
    label: 'Quitandinha',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121257,
    label: 'Ramilândia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121307,
    label: 'Rancho Alegre',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121356,
    label: "Rancho Alegre D'Oeste",
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121406,
    label: 'Realeza',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121505,
    label: 'Rebouças',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121604,
    label: 'Renascença',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121703,
    label: 'Reserva',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121752,
    label: 'Reserva do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121802,
    label: 'Ribeirão Claro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4121901,
    label: 'Ribeirão do Pinhal',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122008,
    label: 'Rio Azul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122107,
    label: 'Rio Bom',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122156,
    label: 'Rio Bonito do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122172,
    label: 'Rio Branco do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122206,
    label: 'Rio Branco do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122305,
    label: 'Rio Negro',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122404,
    label: 'Rolândia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122503,
    label: 'Roncador',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122602,
    label: 'Rondon',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122651,
    label: 'Rosário do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122701,
    label: 'Sabáudia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122800,
    label: 'Salgado Filho',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4122909,
    label: 'Salto do Itararé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123006,
    label: 'Salto do Lontra',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123105,
    label: 'Santa Amélia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123204,
    label: 'Santa Cecília do Pavão',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123303,
    label: 'Santa Cruz de Monte Castelo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123402,
    label: 'Santa Fé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123501,
    label: 'Santa Helena',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123600,
    label: 'Santa Inês',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123709,
    label: 'Santa Isabel do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123808,
    label: 'Santa Izabel do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123824,
    label: 'Santa Lúcia',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123857,
    label: 'Santa Maria do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123907,
    label: 'Santa Mariana',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4123956,
    label: 'Santa Mônica',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124004,
    label: 'Santana do Itararé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124020,
    label: 'Santa Tereza do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124053,
    label: 'Santa Terezinha de Itaipu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124103,
    label: 'Santo Antônio da Platina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124202,
    label: 'Santo Antônio do Caiuá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124301,
    label: 'Santo Antônio do Paraíso',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124400,
    label: 'Santo Antônio do Sudoeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124509,
    label: 'Santo Inácio',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124608,
    label: 'São Carlos do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124707,
    label: 'São Jerônimo da Serra',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124806,
    label: 'São João',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4124905,
    label: 'São João do Caiuá',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125001,
    label: 'São João do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125100,
    label: 'São João do Triunfo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125209,
    label: "São Jorge d'Oeste",
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125308,
    label: 'São Jorge do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125357,
    label: 'São Jorge do Patrocínio',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125407,
    label: 'São José da Boa Vista',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125456,
    label: 'São José das Palmeiras',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125506,
    label: 'São José dos Pinhais',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125555,
    label: 'São Manoel do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125605,
    label: 'São Mateus do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125704,
    label: 'São Miguel do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125753,
    label: 'São Pedro do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125803,
    label: 'São Pedro do Ivaí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4125902,
    label: 'São Pedro do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126009,
    label: 'São Sebastião da Amoreira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126108,
    label: 'São Tomé',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126207,
    label: 'Sapopema',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126256,
    label: 'Sarandi',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126272,
    label: 'Saudade do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126306,
    label: 'Sengés',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126355,
    label: 'Serranópolis do Iguaçu',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126405,
    label: 'Sertaneja',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126504,
    label: 'Sertanópolis',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126603,
    label: 'Siqueira Campos',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126652,
    label: 'Sulina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126678,
    label: 'Tamarana',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126702,
    label: 'Tamboara',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126801,
    label: 'Tapejara',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4126900,
    label: 'Tapira',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127007,
    label: 'Teixeira Soares',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127106,
    label: 'Telêmaco Borba',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127205,
    label: 'Terra Boa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127304,
    label: 'Terra Rica',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127403,
    label: 'Terra Roxa',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127502,
    label: 'Tibagi',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127601,
    label: 'Tijucas do Sul',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127700,
    label: 'Toledo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127809,
    label: 'Tomazina',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127858,
    label: 'Três Barras do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127882,
    label: 'Tunas do Paraná',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127908,
    label: 'Tuneiras do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127957,
    label: 'Tupãssi',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4127965,
    label: 'Turvo',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128005,
    label: 'Ubiratã',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128104,
    label: 'Umuarama',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128203,
    label: 'União da Vitória',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128302,
    label: 'Uniflor',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128401,
    label: 'Uraí',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128500,
    label: 'Wenceslau Braz',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128534,
    label: 'Ventania',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128559,
    label: 'Vera Cruz do Oeste',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128609,
    label: 'Verê',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128625,
    label: 'Alto Paraíso',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128633,
    label: 'Doutor Ulysses',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128658,
    label: 'Virmond',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128708,
    label: 'Vitorino',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4128807,
    label: 'Xambrê',
    state: {value: 41, initials: 'PR', label: 'Paraná'},
  },
  {
    value: 4200051,
    label: 'Abdon Batista',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200101,
    label: 'Abelardo Luz',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200200,
    label: 'Agrolândia',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200309,
    label: 'Agronômica',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200408,
    label: 'Água Doce',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200507,
    label: 'Águas de Chapecó',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200556,
    label: 'Águas Frias',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200606,
    label: 'Águas Mornas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200705,
    label: 'Alfredo Wagner',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200754,
    label: 'Alto Bela Vista',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200804,
    label: 'Anchieta',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4200903,
    label: 'Angelina',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201000,
    label: 'Anita Garibaldi',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201109,
    label: 'Anitápolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201208,
    label: 'Antônio Carlos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201257,
    label: 'Apiúna',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201273,
    label: 'Arabutã',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201307,
    label: 'Araquari',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201406,
    label: 'Araranguá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201505,
    label: 'Armazém',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201604,
    label: 'Arroio Trinta',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201653,
    label: 'Arvoredo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201703,
    label: 'Ascurra',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201802,
    label: 'Atalanta',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201901,
    label: 'Aurora',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4201950,
    label: 'Balneário Arroio do Silva',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202008,
    label: 'Balneário Camboriú',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202057,
    label: 'Balneário Barra do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202073,
    label: 'Balneário Gaivota',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202081,
    label: 'Bandeirante',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202099,
    label: 'Barra Bonita',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202107,
    label: 'Barra Velha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202131,
    label: 'Bela Vista do Toldo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202156,
    label: 'Belmonte',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202206,
    label: 'Benedito Novo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202305,
    label: 'Biguaçu',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202404,
    label: 'Blumenau',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202438,
    label: 'Bocaina do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202453,
    label: 'Bombinhas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202503,
    label: 'Bom Jardim da Serra',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202537,
    label: 'Bom Jesus',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202578,
    label: 'Bom Jesus do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202602,
    label: 'Bom Retiro',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202701,
    label: 'Botuverá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202800,
    label: 'Braço do Norte',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202859,
    label: 'Braço do Trombudo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202875,
    label: 'Brunópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4202909,
    label: 'Brusque',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203006,
    label: 'Caçador',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203105,
    label: 'Caibi',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203154,
    label: 'Calmon',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203204,
    label: 'Camboriú',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203253,
    label: 'Capão Alto',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203303,
    label: 'Campo Alegre',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203402,
    label: 'Campo Belo do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203501,
    label: 'Campo Erê',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203600,
    label: 'Campos Novos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203709,
    label: 'Canelinha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203808,
    label: 'Canoinhas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203907,
    label: 'Capinzal',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4203956,
    label: 'Capivari de Baixo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204004,
    label: 'Catanduvas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204103,
    label: 'Caxambu do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204152,
    label: 'Celso Ramos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204178,
    label: 'Cerro Negro',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204194,
    label: 'Chapadão do Lageado',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204202,
    label: 'Chapecó',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204251,
    label: 'Cocal do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204301,
    label: 'Concórdia',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204350,
    label: 'Cordilheira Alta',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204400,
    label: 'Coronel Freitas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204459,
    label: 'Coronel Martins',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204509,
    label: 'Corupá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204558,
    label: 'Correia Pinto',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204608,
    label: 'Criciúma',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204707,
    label: 'Cunha Porã',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204756,
    label: 'Cunhataí',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204806,
    label: 'Curitibanos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4204905,
    label: 'Descanso',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205001,
    label: 'Dionísio Cerqueira',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205100,
    label: 'Dona Emma',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205159,
    label: 'Doutor Pedrinho',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205175,
    label: 'Entre Rios',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205191,
    label: 'Ermo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205209,
    label: 'Erval Velho',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205308,
    label: 'Faxinal dos Guedes',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205357,
    label: 'Flor do Sertão',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205407,
    label: 'Florianópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205431,
    label: 'Formosa do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205456,
    label: 'Forquilhinha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205506,
    label: 'Fraiburgo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205555,
    label: 'Frei Rogério',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205605,
    label: 'Galvão',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205704,
    label: 'Garopaba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205803,
    label: 'Garuva',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4205902,
    label: 'Gaspar',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206009,
    label: 'Governador Celso Ramos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206108,
    label: 'Grão Pará',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206207,
    label: 'Gravatal',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206306,
    label: 'Guabiruba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206405,
    label: 'Guaraciaba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206504,
    label: 'Guaramirim',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206603,
    label: 'Guarujá do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206652,
    label: 'Guatambú',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206702,
    label: "Herval d'Oeste",
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206751,
    label: 'Ibiam',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206801,
    label: 'Ibicaré',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4206900,
    label: 'Ibirama',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207007,
    label: 'Içara',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207106,
    label: 'Ilhota',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207205,
    label: 'Imaruí',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207304,
    label: 'Imbituba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207403,
    label: 'Imbuia',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207502,
    label: 'Indaial',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207577,
    label: 'Iomerê',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207601,
    label: 'Ipira',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207650,
    label: 'Iporã do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207684,
    label: 'Ipuaçu',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207700,
    label: 'Ipumirim',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207759,
    label: 'Iraceminha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207809,
    label: 'Irani',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207858,
    label: 'Irati',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4207908,
    label: 'Irineópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208005,
    label: 'Itá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208104,
    label: 'Itaiópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208203,
    label: 'Itajaí',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208302,
    label: 'Itapema',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208401,
    label: 'Itapiranga',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208450,
    label: 'Itapoá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208500,
    label: 'Ituporanga',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208609,
    label: 'Jaborá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208708,
    label: 'Jacinto Machado',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208807,
    label: 'Jaguaruna',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208906,
    label: 'Jaraguá do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4208955,
    label: 'Jardinópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209003,
    label: 'Joaçaba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209102,
    label: 'Joinville',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209151,
    label: 'José Boiteux',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209177,
    label: 'Jupiá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209201,
    label: 'Lacerdópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209300,
    label: 'Lages',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209409,
    label: 'Laguna',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209458,
    label: 'Lajeado Grande',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209508,
    label: 'Laurentino',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209607,
    label: 'Lauro Müller',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209706,
    label: 'Lebon Régis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209805,
    label: 'Leoberto Leal',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209854,
    label: 'Lindóia do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4209904,
    label: 'Lontras',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210001,
    label: 'Luiz Alves',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210035,
    label: 'Luzerna',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210050,
    label: 'Macieira',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210100,
    label: 'Mafra',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210209,
    label: 'Major Gercino',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210308,
    label: 'Major Vieira',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210407,
    label: 'Maracajá',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210506,
    label: 'Maravilha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210555,
    label: 'Marema',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210605,
    label: 'Massaranduba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210704,
    label: 'Matos Costa',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210803,
    label: 'Meleiro',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210852,
    label: 'Mirim Doce',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4210902,
    label: 'Modelo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211009,
    label: 'Mondaí',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211058,
    label: 'Monte Carlo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211108,
    label: 'Monte Castelo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211207,
    label: 'Morro da Fumaça',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211256,
    label: 'Morro Grande',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211306,
    label: 'Navegantes',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211405,
    label: 'Nova Erechim',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211454,
    label: 'Nova Itaberaba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211504,
    label: 'Nova Trento',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211603,
    label: 'Nova Veneza',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211652,
    label: 'Novo Horizonte',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211702,
    label: 'Orleans',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211751,
    label: 'Otacílio Costa',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211801,
    label: 'Ouro',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211850,
    label: 'Ouro Verde',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211876,
    label: 'Paial',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211892,
    label: 'Painel',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4211900,
    label: 'Palhoça',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212007,
    label: 'Palma Sola',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212056,
    label: 'Palmeira',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212106,
    label: 'Palmitos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212205,
    label: 'Papanduva',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212239,
    label: 'Paraíso',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212254,
    label: 'Passo de Torres',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212270,
    label: 'Passos Maia',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212304,
    label: 'Paulo Lopes',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212403,
    label: 'Pedras Grandes',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212502,
    label: 'Penha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212601,
    label: 'Peritiba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212650,
    label: 'Pescaria Brava',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212700,
    label: 'Petrolândia',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212809,
    label: 'Balneário Piçarras',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4212908,
    label: 'Pinhalzinho',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213005,
    label: 'Pinheiro Preto',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213104,
    label: 'Piratuba',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213153,
    label: 'Planalto Alegre',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213203,
    label: 'Pomerode',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213302,
    label: 'Ponte Alta',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213351,
    label: 'Ponte Alta do Norte',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213401,
    label: 'Ponte Serrada',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213500,
    label: 'Porto Belo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213609,
    label: 'Porto União',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213708,
    label: 'Pouso Redondo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213807,
    label: 'Praia Grande',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4213906,
    label: 'Presidente Castello Branco',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214003,
    label: 'Presidente Getúlio',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214102,
    label: 'Presidente Nereu',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214151,
    label: 'Princesa',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214201,
    label: 'Quilombo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214300,
    label: 'Rancho Queimado',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214409,
    label: 'Rio das Antas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214508,
    label: 'Rio do Campo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214607,
    label: 'Rio do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214706,
    label: 'Rio dos Cedros',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214805,
    label: 'Rio do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4214904,
    label: 'Rio Fortuna',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215000,
    label: 'Rio Negrinho',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215059,
    label: 'Rio Rufino',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215075,
    label: 'Riqueza',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215109,
    label: 'Rodeio',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215208,
    label: 'Romelândia',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215307,
    label: 'Salete',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215356,
    label: 'Saltinho',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215406,
    label: 'Salto Veloso',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215455,
    label: 'Sangão',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215505,
    label: 'Santa Cecília',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215554,
    label: 'Santa Helena',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215604,
    label: 'Santa Rosa de Lima',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215653,
    label: 'Santa Rosa do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215679,
    label: 'Santa Terezinha',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215687,
    label: 'Santa Terezinha do Progresso',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215695,
    label: 'Santiago do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215703,
    label: 'Santo Amaro da Imperatriz',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215752,
    label: 'São Bernardino',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215802,
    label: 'São Bento do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4215901,
    label: 'São Bonifácio',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216008,
    label: 'São Carlos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216057,
    label: 'São Cristóvão do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216107,
    label: 'São Domingos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216206,
    label: 'São Francisco do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216255,
    label: 'São João do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216305,
    label: 'São João Batista',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216354,
    label: 'São João do Itaperiú',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216404,
    label: 'São João do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216503,
    label: 'São Joaquim',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216602,
    label: 'São José',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216701,
    label: 'São José do Cedro',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216800,
    label: 'São José do Cerrito',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4216909,
    label: 'São Lourenço do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217006,
    label: 'São Ludgero',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217105,
    label: 'São Martinho',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217154,
    label: 'São Miguel da Boa Vista',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217204,
    label: 'São Miguel do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217253,
    label: 'São Pedro de Alcântara',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217303,
    label: 'Saudades',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217402,
    label: 'Schroeder',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217501,
    label: 'Seara',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217550,
    label: 'Serra Alta',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217600,
    label: 'Siderópolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217709,
    label: 'Sombrio',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217758,
    label: 'Sul Brasil',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217808,
    label: 'Taió',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217907,
    label: 'Tangará',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4217956,
    label: 'Tigrinhos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218004,
    label: 'Tijucas',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218103,
    label: 'Timbé do Sul',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218202,
    label: 'Timbó',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218251,
    label: 'Timbó Grande',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218301,
    label: 'Três Barras',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218350,
    label: 'Treviso',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218400,
    label: 'Treze de Maio',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218509,
    label: 'Treze Tílias',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218608,
    label: 'Trombudo Central',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218707,
    label: 'Tubarão',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218756,
    label: 'Tunápolis',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218806,
    label: 'Turvo',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218855,
    label: 'União do Oeste',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218905,
    label: 'Urubici',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4218954,
    label: 'Urupema',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219002,
    label: 'Urussanga',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219101,
    label: 'Vargeão',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219150,
    label: 'Vargem',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219176,
    label: 'Vargem Bonita',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219200,
    label: 'Vidal Ramos',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219309,
    label: 'Videira',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219358,
    label: 'Vitor Meireles',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219408,
    label: 'Witmarsum',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219507,
    label: 'Xanxerê',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219606,
    label: 'Xavantina',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219705,
    label: 'Xaxim',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4219853,
    label: 'Zortéa',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4220000,
    label: 'Balneário Rincão',
    state: {value: 42, initials: 'SC', label: 'Santa Catarina'},
  },
  {
    value: 4300034,
    label: 'Aceguá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300059,
    label: 'Água Santa',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300109,
    label: 'Agudo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300208,
    label: 'Ajuricaba',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300307,
    label: 'Alecrim',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300406,
    label: 'Alegrete',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300455,
    label: 'Alegria',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300471,
    label: 'Almirante Tamandaré do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300505,
    label: 'Alpestre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300554,
    label: 'Alto Alegre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300570,
    label: 'Alto Feliz',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300604,
    label: 'Alvorada',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300638,
    label: 'Amaral Ferrador',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300646,
    label: 'Ametista do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300661,
    label: 'André da Rocha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300703,
    label: 'Anta Gorda',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300802,
    label: 'Antônio Prado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300851,
    label: 'Arambaré',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300877,
    label: 'Araricá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4300901,
    label: 'Aratiba',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301008,
    label: 'Arroio do Meio',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301057,
    label: 'Arroio do Sal',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301073,
    label: 'Arroio do Padre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301107,
    label: 'Arroio dos Ratos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301206,
    label: 'Arroio do Tigre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301305,
    label: 'Arroio Grande',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301404,
    label: 'Arvorezinha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301503,
    label: 'Augusto Pestana',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301552,
    label: 'Áurea',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301602,
    label: 'Bagé',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301636,
    label: 'Balneário Pinhal',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301651,
    label: 'Barão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301701,
    label: 'Barão de Cotegipe',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301750,
    label: 'Barão do Triunfo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301800,
    label: 'Barracão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301859,
    label: 'Barra do Guarita',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301875,
    label: 'Barra do Quaraí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301909,
    label: 'Barra do Ribeiro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301925,
    label: 'Barra do Rio Azul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4301958,
    label: 'Barra Funda',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302006,
    label: 'Barros Cassal',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302055,
    label: 'Benjamin Constant do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302105,
    label: 'Bento Gonçalves',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302154,
    label: 'Boa Vista das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302204,
    label: 'Boa Vista do Buricá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302220,
    label: 'Boa Vista do Cadeado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302238,
    label: 'Boa Vista do Incra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302253,
    label: 'Boa Vista do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302303,
    label: 'Bom Jesus',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302352,
    label: 'Bom Princípio',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302378,
    label: 'Bom Progresso',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302402,
    label: 'Bom Retiro do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302451,
    label: 'Boqueirão do Leão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302501,
    label: 'Bossoroca',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302584,
    label: 'Bozano',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302600,
    label: 'Braga',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302659,
    label: 'Brochier',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302709,
    label: 'Butiá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302808,
    label: 'Caçapava do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4302907,
    label: 'Cacequi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303004,
    label: 'Cachoeira do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303103,
    label: 'Cachoeirinha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303202,
    label: 'Cacique Doble',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303301,
    label: 'Caibaté',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303400,
    label: 'Caiçara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303509,
    label: 'Camaquã',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303558,
    label: 'Camargo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303608,
    label: 'Cambará do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303673,
    label: 'Campestre da Serra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303707,
    label: 'Campina das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303806,
    label: 'Campinas do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4303905,
    label: 'Campo Bom',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304002,
    label: 'Campo Novo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304101,
    label: 'Campos Borges',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304200,
    label: 'Candelária',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304309,
    label: 'Cândido Godói',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304358,
    label: 'Candiota',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304408,
    label: 'Canela',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304507,
    label: 'Canguçu',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304606,
    label: 'Canoas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304614,
    label: 'Canudos do Vale',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304622,
    label: 'Capão Bonito do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304630,
    label: 'Capão da Canoa',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304655,
    label: 'Capão do Cipó',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304663,
    label: 'Capão do Leão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304671,
    label: 'Capivari do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304689,
    label: 'Capela de Santana',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304697,
    label: 'Capitão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304705,
    label: 'Carazinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304713,
    label: 'Caraá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304804,
    label: 'Carlos Barbosa',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304853,
    label: 'Carlos Gomes',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304903,
    label: 'Casca',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4304952,
    label: 'Caseiros',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305009,
    label: 'Catuípe',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305108,
    label: 'Caxias do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305116,
    label: 'Centenário',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305124,
    label: 'Cerrito',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305132,
    label: 'Cerro Branco',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305157,
    label: 'Cerro Grande',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305173,
    label: 'Cerro Grande do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305207,
    label: 'Cerro Largo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305306,
    label: 'Chapada',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305355,
    label: 'Charqueadas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305371,
    label: 'Charrua',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305405,
    label: 'Chiapetta',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305439,
    label: 'Chuí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305447,
    label: 'Chuvisca',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305454,
    label: 'Cidreira',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305504,
    label: 'Ciríaco',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305587,
    label: 'Colinas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305603,
    label: 'Colorado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305702,
    label: 'Condor',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305801,
    label: 'Constantina',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305835,
    label: 'Coqueiro Baixo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305850,
    label: 'Coqueiros do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305871,
    label: 'Coronel Barros',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305900,
    label: 'Coronel Bicaco',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305934,
    label: 'Coronel Pilar',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305959,
    label: 'Cotiporã',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4305975,
    label: 'Coxilha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306007,
    label: 'Crissiumal',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306056,
    label: 'Cristal',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306072,
    label: 'Cristal do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306106,
    label: 'Cruz Alta',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306130,
    label: 'Cruzaltense',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306205,
    label: 'Cruzeiro do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306304,
    label: 'David Canabarro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306320,
    label: 'Derrubadas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306353,
    label: 'Dezesseis de Novembro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306379,
    label: 'Dilermando de Aguiar',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306403,
    label: 'Dois Irmãos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306429,
    label: 'Dois Irmãos das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306452,
    label: 'Dois Lajeados',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306502,
    label: 'Dom Feliciano',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306551,
    label: 'Dom Pedro de Alcântara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306601,
    label: 'Dom Pedrito',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306700,
    label: 'Dona Francisca',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306734,
    label: 'Doutor Maurício Cardoso',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306759,
    label: 'Doutor Ricardo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306767,
    label: 'Eldorado do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306809,
    label: 'Encantado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306908,
    label: 'Encruzilhada do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306924,
    label: 'Engenho Velho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306932,
    label: 'Entre-Ijuís',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306957,
    label: 'Entre Rios do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4306973,
    label: 'Erebango',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307005,
    label: 'Erechim',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307054,
    label: 'Ernestina',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307104,
    label: 'Herval',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307203,
    label: 'Erval Grande',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307302,
    label: 'Erval Seco',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307401,
    label: 'Esmeralda',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307450,
    label: 'Esperança do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307500,
    label: 'Espumoso',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307559,
    label: 'Estação',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307609,
    label: 'Estância Velha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307708,
    label: 'Esteio',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307807,
    label: 'Estrela',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307815,
    label: 'Estrela Velha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307831,
    label: 'Eugênio de Castro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307864,
    label: 'Fagundes Varela',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4307906,
    label: 'Farroupilha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308003,
    label: 'Faxinal do Soturno',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308052,
    label: 'Faxinalzinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308078,
    label: 'Fazenda Vilanova',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308102,
    label: 'Feliz',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308201,
    label: 'Flores da Cunha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308250,
    label: 'Floriano Peixoto',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308300,
    label: 'Fontoura Xavier',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308409,
    label: 'Formigueiro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308433,
    label: 'Forquetinha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308458,
    label: 'Fortaleza dos Valos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308508,
    label: 'Frederico Westphalen',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308607,
    label: 'Garibaldi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308656,
    label: 'Garruchos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308706,
    label: 'Gaurama',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308805,
    label: 'General Câmara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308854,
    label: 'Gentil',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4308904,
    label: 'Getúlio Vargas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309001,
    label: 'Giruá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309050,
    label: 'Glorinha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309100,
    label: 'Gramado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309126,
    label: 'Gramado dos Loureiros',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309159,
    label: 'Gramado Xavier',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309209,
    label: 'Gravataí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309258,
    label: 'Guabiju',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309308,
    label: 'Guaíba',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309407,
    label: 'Guaporé',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309506,
    label: 'Guarani das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309555,
    label: 'Harmonia',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309571,
    label: 'Herveiras',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309605,
    label: 'Horizontina',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309654,
    label: 'Hulha Negra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309704,
    label: 'Humaitá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309753,
    label: 'Ibarama',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309803,
    label: 'Ibiaçá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309902,
    label: 'Ibiraiaras',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4309951,
    label: 'Ibirapuitã',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310009,
    label: 'Ibirubá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310108,
    label: 'Igrejinha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310207,
    label: 'Ijuí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310306,
    label: 'Ilópolis',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310330,
    label: 'Imbé',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310363,
    label: 'Imigrante',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310405,
    label: 'Independência',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310413,
    label: 'Inhacorá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310439,
    label: 'Ipê',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310462,
    label: 'Ipiranga do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310504,
    label: 'Iraí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310538,
    label: 'Itaara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310553,
    label: 'Itacurubi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310579,
    label: 'Itapuca',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310603,
    label: 'Itaqui',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310652,
    label: 'Itati',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310702,
    label: 'Itatiba do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310751,
    label: 'Ivorá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310801,
    label: 'Ivoti',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310850,
    label: 'Jaboticaba',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310876,
    label: 'Jacuizinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4310900,
    label: 'Jacutinga',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311007,
    label: 'Jaguarão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311106,
    label: 'Jaguari',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311122,
    label: 'Jaquirana',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311130,
    label: 'Jari',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311155,
    label: 'Jóia',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311205,
    label: 'Júlio de Castilhos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311239,
    label: 'Lagoa Bonita do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311254,
    label: 'Lagoão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311270,
    label: 'Lagoa dos Três Cantos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311304,
    label: 'Lagoa Vermelha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311403,
    label: 'Lajeado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311429,
    label: 'Lajeado do Bugre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311502,
    label: 'Lavras do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311601,
    label: 'Liberato Salzano',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311627,
    label: 'Lindolfo Collor',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311643,
    label: 'Linha Nova',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311700,
    label: 'Machadinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311718,
    label: 'Maçambará',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311734,
    label: 'Mampituba',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311759,
    label: 'Manoel Viana',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311775,
    label: 'Maquiné',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311791,
    label: 'Maratá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311809,
    label: 'Marau',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311908,
    label: 'Marcelino Ramos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4311981,
    label: 'Mariana Pimentel',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312005,
    label: 'Mariano Moro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312054,
    label: 'Marques de Souza',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312104,
    label: 'Mata',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312138,
    label: 'Mato Castelhano',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312153,
    label: 'Mato Leitão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312179,
    label: 'Mato Queimado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312203,
    label: 'Maximiliano de Almeida',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312252,
    label: 'Minas do Leão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312302,
    label: 'Miraguaí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312351,
    label: 'Montauri',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312377,
    label: 'Monte Alegre dos Campos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312385,
    label: 'Monte Belo do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312401,
    label: 'Montenegro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312427,
    label: 'Mormaço',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312443,
    label: 'Morrinhos do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312450,
    label: 'Morro Redondo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312476,
    label: 'Morro Reuter',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312500,
    label: 'Mostardas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312609,
    label: 'Muçum',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312617,
    label: 'Muitos Capões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312625,
    label: 'Muliterno',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312658,
    label: 'Não-Me-Toque',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312674,
    label: 'Nicolau Vergueiro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312708,
    label: 'Nonoai',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312757,
    label: 'Nova Alvorada',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312807,
    label: 'Nova Araçá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312906,
    label: 'Nova Bassano',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4312955,
    label: 'Nova Boa Vista',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313003,
    label: 'Nova Bréscia',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313011,
    label: 'Nova Candelária',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313037,
    label: 'Nova Esperança do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313060,
    label: 'Nova Hartz',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313086,
    label: 'Nova Pádua',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313102,
    label: 'Nova Palma',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313201,
    label: 'Nova Petrópolis',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313300,
    label: 'Nova Prata',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313334,
    label: 'Nova Ramada',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313359,
    label: 'Nova Roma do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313375,
    label: 'Nova Santa Rita',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313391,
    label: 'Novo Cabrais',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313409,
    label: 'Novo Hamburgo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313425,
    label: 'Novo Machado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313441,
    label: 'Novo Tiradentes',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313466,
    label: 'Novo Xingu',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313490,
    label: 'Novo Barreiro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313508,
    label: 'Osório',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313607,
    label: 'Paim Filho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313656,
    label: 'Palmares do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313706,
    label: 'Palmeira das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313805,
    label: 'Palmitinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313904,
    label: 'Panambi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4313953,
    label: 'Pantano Grande',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314001,
    label: 'Paraí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314027,
    label: 'Paraíso do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314035,
    label: 'Pareci Novo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314050,
    label: 'Parobé',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314068,
    label: 'Passa Sete',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314076,
    label: 'Passo do Sobrado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314100,
    label: 'Passo Fundo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314134,
    label: 'Paulo Bento',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314159,
    label: 'Paverama',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314175,
    label: 'Pedras Altas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314209,
    label: 'Pedro Osório',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314308,
    label: 'Pejuçara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314407,
    label: 'Pelotas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314423,
    label: 'Picada Café',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314456,
    label: 'Pinhal',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314464,
    label: 'Pinhal da Serra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314472,
    label: 'Pinhal Grande',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314498,
    label: 'Pinheirinho do Vale',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314506,
    label: 'Pinheiro Machado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314548,
    label: 'Pinto Bandeira',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314555,
    label: 'Pirapó',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314605,
    label: 'Piratini',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314704,
    label: 'Planalto',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314753,
    label: 'Poço das Antas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314779,
    label: 'Pontão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314787,
    label: 'Ponte Preta',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314803,
    label: 'Portão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4314902,
    label: 'Porto Alegre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315008,
    label: 'Porto Lucena',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315057,
    label: 'Porto Mauá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315073,
    label: 'Porto Vera Cruz',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315107,
    label: 'Porto Xavier',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315131,
    label: 'Pouso Novo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315149,
    label: 'Presidente Lucena',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315156,
    label: 'Progresso',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315172,
    label: 'Protásio Alves',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315206,
    label: 'Putinga',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315305,
    label: 'Quaraí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315313,
    label: 'Quatro Irmãos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315321,
    label: 'Quevedos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315354,
    label: 'Quinze de Novembro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315404,
    label: 'Redentora',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315453,
    label: 'Relvado',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315503,
    label: 'Restinga Sêca',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315552,
    label: 'Rio dos Índios',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315602,
    label: 'Rio Grande',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315701,
    label: 'Rio Pardo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315750,
    label: 'Riozinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315800,
    label: 'Roca Sales',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315909,
    label: 'Rodeio Bonito',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4315958,
    label: 'Rolador',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316006,
    label: 'Rolante',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316105,
    label: 'Ronda Alta',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316204,
    label: 'Rondinha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316303,
    label: 'Roque Gonzales',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316402,
    label: 'Rosário do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316428,
    label: 'Sagrada Família',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316436,
    label: 'Saldanha Marinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316451,
    label: 'Salto do Jacuí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316477,
    label: 'Salvador das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316501,
    label: 'Salvador do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316600,
    label: 'Sananduva',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316709,
    label: 'Santa Bárbara do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316733,
    label: 'Santa Cecília do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316758,
    label: 'Santa Clara do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316808,
    label: 'Santa Cruz do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316907,
    label: 'Santa Maria',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316956,
    label: 'Santa Maria do Herval',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4316972,
    label: 'Santa Margarida do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317004,
    label: 'Santana da Boa Vista',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317103,
    label: "Sant'Ana do Livramento",
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317202,
    label: 'Santa Rosa',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317251,
    label: 'Santa Tereza',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317301,
    label: 'Santa Vitória do Palmar',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317400,
    label: 'Santiago',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317509,
    label: 'Santo Ângelo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317558,
    label: 'Santo Antônio do Palma',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317608,
    label: 'Santo Antônio da Patrulha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317707,
    label: 'Santo Antônio das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317756,
    label: 'Santo Antônio do Planalto',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317806,
    label: 'Santo Augusto',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317905,
    label: 'Santo Cristo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4317954,
    label: 'Santo Expedito do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318002,
    label: 'São Borja',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318051,
    label: 'São Domingos do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318101,
    label: 'São Francisco de Assis',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318200,
    label: 'São Francisco de Paula',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318309,
    label: 'São Gabriel',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318408,
    label: 'São Jerônimo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318424,
    label: 'São João da Urtiga',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318432,
    label: 'São João do Polêsine',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318440,
    label: 'São Jorge',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318457,
    label: 'São José das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318465,
    label: 'São José do Herval',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318481,
    label: 'São José do Hortêncio',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318499,
    label: 'São José do Inhacorá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318507,
    label: 'São José do Norte',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318606,
    label: 'São José do Ouro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318614,
    label: 'São José do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318622,
    label: 'São José dos Ausentes',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318705,
    label: 'São Leopoldo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318804,
    label: 'São Lourenço do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4318903,
    label: 'São Luiz Gonzaga',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319000,
    label: 'São Marcos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319109,
    label: 'São Martinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319125,
    label: 'São Martinho da Serra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319158,
    label: 'São Miguel das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319208,
    label: 'São Nicolau',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319307,
    label: 'São Paulo das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319356,
    label: 'São Pedro da Serra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319364,
    label: 'São Pedro das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319372,
    label: 'São Pedro do Butiá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319406,
    label: 'São Pedro do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319505,
    label: 'São Sebastião do Caí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319604,
    label: 'São Sepé',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319703,
    label: 'São Valentim',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319711,
    label: 'São Valentim do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319737,
    label: 'São Valério do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319752,
    label: 'São Vendelino',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319802,
    label: 'São Vicente do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4319901,
    label: 'Sapiranga',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320008,
    label: 'Sapucaia do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320107,
    label: 'Sarandi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320206,
    label: 'Seberi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320230,
    label: 'Sede Nova',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320263,
    label: 'Segredo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320305,
    label: 'Selbach',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320321,
    label: 'Senador Salgado Filho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320354,
    label: 'Sentinela do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320404,
    label: 'Serafina Corrêa',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320453,
    label: 'Sério',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320503,
    label: 'Sertão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320552,
    label: 'Sertão Santana',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320578,
    label: 'Sete de Setembro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320602,
    label: 'Severiano de Almeida',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320651,
    label: 'Silveira Martins',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320677,
    label: 'Sinimbu',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320701,
    label: 'Sobradinho',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320800,
    label: 'Soledade',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320859,
    label: 'Tabaí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4320909,
    label: 'Tapejara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321006,
    label: 'Tapera',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321105,
    label: 'Tapes',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321204,
    label: 'Taquara',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321303,
    label: 'Taquari',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321329,
    label: 'Taquaruçu do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321352,
    label: 'Tavares',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321402,
    label: 'Tenente Portela',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321436,
    label: 'Terra de Areia',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321451,
    label: 'Teutônia',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321469,
    label: 'Tio Hugo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321477,
    label: 'Tiradentes do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321493,
    label: 'Toropi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321501,
    label: 'Torres',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321600,
    label: 'Tramandaí',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321626,
    label: 'Travesseiro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321634,
    label: 'Três Arroios',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321667,
    label: 'Três Cachoeiras',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321709,
    label: 'Três Coroas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321808,
    label: 'Três de Maio',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321832,
    label: 'Três Forquilhas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321857,
    label: 'Três Palmeiras',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321907,
    label: 'Três Passos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4321956,
    label: 'Trindade do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322004,
    label: 'Triunfo',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322103,
    label: 'Tucunduva',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322152,
    label: 'Tunas',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322186,
    label: 'Tupanci do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322202,
    label: 'Tupanciretã',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322251,
    label: 'Tupandi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322301,
    label: 'Tuparendi',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322327,
    label: 'Turuçu',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322343,
    label: 'Ubiretama',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322350,
    label: 'União da Serra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322376,
    label: 'Unistalda',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322400,
    label: 'Uruguaiana',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322509,
    label: 'Vacaria',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322525,
    label: 'Vale Verde',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322533,
    label: 'Vale do Sol',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322541,
    label: 'Vale Real',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322558,
    label: 'Vanini',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322608,
    label: 'Venâncio Aires',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322707,
    label: 'Vera Cruz',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322806,
    label: 'Veranópolis',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322855,
    label: 'Vespasiano Corrêa',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4322905,
    label: 'Viadutos',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323002,
    label: 'Viamão',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323101,
    label: 'Vicente Dutra',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323200,
    label: 'Victor Graeff',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323309,
    label: 'Vila Flores',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323358,
    label: 'Vila Lângaro',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323408,
    label: 'Vila Maria',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323457,
    label: 'Vila Nova do Sul',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323507,
    label: 'Vista Alegre',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323606,
    label: 'Vista Alegre do Prata',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323705,
    label: 'Vista Gaúcha',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323754,
    label: 'Vitória das Missões',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323770,
    label: 'Westfália',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 4323804,
    label: 'Xangri-lá',
    state: {value: 43, initials: 'RS', label: 'Rio Grande do Sul'},
  },
  {
    value: 5000203,
    label: 'Água Clara',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5000252,
    label: 'Alcinópolis',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5000609,
    label: 'Amambai',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5000708,
    label: 'Anastácio',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5000807,
    label: 'Anaurilândia',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5000856,
    label: 'Angélica',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5000906,
    label: 'Antônio João',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5001003,
    label: 'Aparecida do Taboado',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5001102,
    label: 'Aquidauana',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5001243,
    label: 'Aral Moreira',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5001508,
    label: 'Bandeirantes',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5001904,
    label: 'Bataguassu',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002001,
    label: 'Batayporã',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002100,
    label: 'Bela Vista',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002159,
    label: 'Bodoquena',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002209,
    label: 'Bonito',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002308,
    label: 'Brasilândia',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002407,
    label: 'Caarapó',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002605,
    label: 'Camapuã',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002704,
    label: 'Campo Grande',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002803,
    label: 'Caracol',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002902,
    label: 'Cassilândia',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5002951,
    label: 'Chapadão do Sul',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003108,
    label: 'Corguinho',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003157,
    label: 'Coronel Sapucaia',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003207,
    label: 'Corumbá',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003256,
    label: 'Costa Rica',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003306,
    label: 'Coxim',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003454,
    label: 'Deodápolis',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003488,
    label: 'Dois Irmãos do Buriti',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003504,
    label: 'Douradina',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003702,
    label: 'Dourados',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003751,
    label: 'Eldorado',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003801,
    label: 'Fátima do Sul',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5003900,
    label: 'Figueirão',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004007,
    label: 'Glória de Dourados',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004106,
    label: 'Guia Lopes da Laguna',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004304,
    label: 'Iguatemi',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004403,
    label: 'Inocência',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004502,
    label: 'Itaporã',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004601,
    label: 'Itaquiraí',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004700,
    label: 'Ivinhema',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004809,
    label: 'Japorã',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5004908,
    label: 'Jaraguari',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005004,
    label: 'Jardim',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005103,
    label: 'Jateí',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005152,
    label: 'Juti',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005202,
    label: 'Ladário',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005251,
    label: 'Laguna Carapã',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005400,
    label: 'Maracaju',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005608,
    label: 'Miranda',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005681,
    label: 'Mundo Novo',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005707,
    label: 'Naviraí',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5005806,
    label: 'Nioaque',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006002,
    label: 'Nova Alvorada do Sul',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006200,
    label: 'Nova Andradina',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006259,
    label: 'Novo Horizonte do Sul',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006275,
    label: 'Paraíso das Águas',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006309,
    label: 'Paranaíba',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006358,
    label: 'Paranhos',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006408,
    label: 'Pedro Gomes',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006606,
    label: 'Ponta Porã',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5006903,
    label: 'Porto Murtinho',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007109,
    label: 'Ribas do Rio Pardo',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007208,
    label: 'Rio Brilhante',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007307,
    label: 'Rio Negro',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007406,
    label: 'Rio Verde de Mato Grosso',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007505,
    label: 'Rochedo',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007554,
    label: 'Santa Rita do Pardo',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007695,
    label: 'São Gabriel do Oeste',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007703,
    label: 'Sete Quedas',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007802,
    label: 'Selvíria',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007901,
    label: 'Sidrolândia',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007935,
    label: 'Sonora',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007950,
    label: 'Tacuru',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5007976,
    label: 'Taquarussu',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5008008,
    label: 'Terenos',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5008305,
    label: 'Três Lagoas',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5008404,
    label: 'Vicentina',
    state: {value: 50, initials: 'MS', label: 'Mato Grosso do Sul'},
  },
  {
    value: 5100102,
    label: 'Acorizal',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100201,
    label: 'Água Boa',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100250,
    label: 'Alta Floresta',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100300,
    label: 'Alto Araguaia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100359,
    label: 'Alto Boa Vista',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100409,
    label: 'Alto Garças',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100508,
    label: 'Alto Paraguai',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100607,
    label: 'Alto Taquari',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5100805,
    label: 'Apiacás',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101001,
    label: 'Araguaiana',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101209,
    label: 'Araguainha',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101258,
    label: 'Araputanga',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101308,
    label: 'Arenápolis',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101407,
    label: 'Aripuanã',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101605,
    label: 'Barão de Melgaço',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101704,
    label: 'Barra do Bugres',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101803,
    label: 'Barra do Garças',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101852,
    label: 'Bom Jesus do Araguaia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5101902,
    label: 'Brasnorte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102504,
    label: 'Cáceres',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102603,
    label: 'Campinápolis',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102637,
    label: 'Campo Novo do Parecis',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102678,
    label: 'Campo Verde',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102686,
    label: 'Campos de Júlio',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102694,
    label: 'Canabrava do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102702,
    label: 'Canarana',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102793,
    label: 'Carlinda',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5102850,
    label: 'Castanheira',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103007,
    label: 'Chapada dos Guimarães',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103056,
    label: 'Cláudia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103106,
    label: 'Cocalinho',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103205,
    label: 'Colíder',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103254,
    label: 'Colniza',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103304,
    label: 'Comodoro',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103353,
    label: 'Confresa',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103361,
    label: "Conquista D'Oeste",
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103379,
    label: 'Cotriguaçu',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103403,
    label: 'Cuiabá',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103437,
    label: 'Curvelândia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103452,
    label: 'Denise',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103502,
    label: 'Diamantino',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103601,
    label: 'Dom Aquino',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103700,
    label: 'Feliz Natal',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103809,
    label: "Figueirópolis D'Oeste",
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103858,
    label: 'Gaúcha do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103908,
    label: 'General Carneiro',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5103957,
    label: "Glória D'Oeste",
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104104,
    label: 'Guarantã do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104203,
    label: 'Guiratinga',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104500,
    label: 'Indiavaí',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104526,
    label: 'Ipiranga do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104542,
    label: 'Itanhangá',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104559,
    label: 'Itaúba',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104609,
    label: 'Itiquira',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104807,
    label: 'Jaciara',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5104906,
    label: 'Jangada',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105002,
    label: 'Jauru',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105101,
    label: 'Juara',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105150,
    label: 'Juína',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105176,
    label: 'Juruena',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105200,
    label: 'Juscimeira',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105234,
    label: "Lambari D'Oeste",
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105259,
    label: 'Lucas do Rio Verde',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105309,
    label: 'Luciara',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105507,
    label: 'Vila Bela da Santíssima Trindade',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105580,
    label: 'Marcelândia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105606,
    label: 'Matupá',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105622,
    label: "Mirassol d'Oeste",
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5105903,
    label: 'Nobres',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106000,
    label: 'Nortelândia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106109,
    label: 'Nossa Senhora do Livramento',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106158,
    label: 'Nova Bandeirantes',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106174,
    label: 'Nova Nazaré',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106182,
    label: 'Nova Lacerda',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106190,
    label: 'Nova Santa Helena',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106208,
    label: 'Nova Brasilândia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106216,
    label: 'Nova Canaã do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106224,
    label: 'Nova Mutum',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106232,
    label: 'Nova Olímpia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106240,
    label: 'Nova Ubiratã',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106257,
    label: 'Nova Xavantina',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106265,
    label: 'Novo Mundo',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106273,
    label: 'Novo Horizonte do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106281,
    label: 'Novo São Joaquim',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106299,
    label: 'Paranaíta',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106307,
    label: 'Paranatinga',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106315,
    label: 'Novo Santo Antônio',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106372,
    label: 'Pedra Preta',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106422,
    label: 'Peixoto de Azevedo',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106455,
    label: 'Planalto da Serra',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106505,
    label: 'Poconé',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106653,
    label: 'Pontal do Araguaia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106703,
    label: 'Ponte Branca',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106752,
    label: 'Pontes e Lacerda',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106778,
    label: 'Porto Alegre do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106802,
    label: 'Porto dos Gaúchos',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106828,
    label: 'Porto Esperidião',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5106851,
    label: 'Porto Estrela',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107008,
    label: 'Poxoréu',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107040,
    label: 'Primavera do Leste',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107065,
    label: 'Querência',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107107,
    label: 'São José dos Quatro Marcos',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107156,
    label: 'Reserva do Cabaçal',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107180,
    label: 'Ribeirão Cascalheira',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107198,
    label: 'Ribeirãozinho',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107206,
    label: 'Rio Branco',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107248,
    label: 'Santa Carmem',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107263,
    label: 'Santo Afonso',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107297,
    label: 'São José do Povo',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107305,
    label: 'São José do Rio Claro',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107354,
    label: 'São José do Xingu',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107404,
    label: 'São Pedro da Cipa',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107578,
    label: 'Rondolândia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107602,
    label: 'Rondonópolis',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107701,
    label: 'Rosário Oeste',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107743,
    label: 'Santa Cruz do Xingu',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107750,
    label: 'Salto do Céu',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107768,
    label: 'Santa Rita do Trivelato',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107776,
    label: 'Santa Terezinha',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107792,
    label: 'Santo Antônio do Leste',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107800,
    label: 'Santo Antônio do Leverger',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107859,
    label: 'São Félix do Araguaia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107875,
    label: 'Sapezal',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107883,
    label: 'Serra Nova Dourada',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107909,
    label: 'Sinop',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107925,
    label: 'Sorriso',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107941,
    label: 'Tabaporã',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5107958,
    label: 'Tangará da Serra',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108006,
    label: 'Tapurah',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108055,
    label: 'Terra Nova do Norte',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108105,
    label: 'Tesouro',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108204,
    label: 'Torixoréu',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108303,
    label: 'União do Sul',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108352,
    label: 'Vale de São Domingos',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108402,
    label: 'Várzea Grande',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108501,
    label: 'Vera',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108600,
    label: 'Vila Rica',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108808,
    label: 'Nova Guarita',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108857,
    label: 'Nova Marilândia',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108907,
    label: 'Nova Maringá',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5108956,
    label: 'Nova Monte Verde',
    state: {value: 51, initials: 'MT', label: 'Mato Grosso'},
  },
  {
    value: 5200050,
    label: 'Abadia de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200100,
    label: 'Abadiânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200134,
    label: 'Acreúna',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200159,
    label: 'Adelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200175,
    label: 'Água Fria de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200209,
    label: 'Água Limpa',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200258,
    label: 'Águas Lindas de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200308,
    label: 'Alexânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200506,
    label: 'Aloândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200555,
    label: 'Alto Horizonte',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200605,
    label: 'Alto Paraíso de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200803,
    label: 'Alvorada do Norte',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200829,
    label: 'Amaralina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200852,
    label: 'Americano do Brasil',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5200902,
    label: 'Amorinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201108,
    label: 'Anápolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201207,
    label: 'Anhanguera',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201306,
    label: 'Anicuns',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201405,
    label: 'Aparecida de Goiânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201454,
    label: 'Aparecida do Rio Doce',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201504,
    label: 'Aporé',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201603,
    label: 'Araçu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201702,
    label: 'Aragarças',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5201801,
    label: 'Aragoiânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5202155,
    label: 'Araguapaz',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5202353,
    label: 'Arenópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5202502,
    label: 'Aruanã',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5202601,
    label: 'Aurilândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5202809,
    label: 'Avelinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203104,
    label: 'Baliza',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203203,
    label: 'Barro Alto',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203302,
    label: 'Bela Vista de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203401,
    label: 'Bom Jardim de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203500,
    label: 'Bom Jesus de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203559,
    label: 'Bonfinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203575,
    label: 'Bonópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203609,
    label: 'Brazabrantes',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203807,
    label: 'Britânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203906,
    label: 'Buriti Alegre',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203939,
    label: 'Buriti de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5203962,
    label: 'Buritinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204003,
    label: 'Cabeceiras',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204102,
    label: 'Cachoeira Alta',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204201,
    label: 'Cachoeira de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204250,
    label: 'Cachoeira Dourada',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204300,
    label: 'Caçu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204409,
    label: 'Caiapônia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204508,
    label: 'Caldas Novas',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204557,
    label: 'Caldazinha',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204607,
    label: 'Campestre de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204656,
    label: 'Campinaçu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204706,
    label: 'Campinorte',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204805,
    label: 'Campo Alegre de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204854,
    label: 'Campo Limpo de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204904,
    label: 'Campos Belos',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5204953,
    label: 'Campos Verdes',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205000,
    label: 'Carmo do Rio Verde',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205059,
    label: 'Castelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205109,
    label: 'Catalão',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205208,
    label: 'Caturaí',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205307,
    label: 'Cavalcante',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205406,
    label: 'Ceres',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205455,
    label: 'Cezarina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205471,
    label: 'Chapadão do Céu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205497,
    label: 'Cidade Ocidental',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205513,
    label: 'Cocalzinho de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205521,
    label: 'Colinas do Sul',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205703,
    label: 'Córrego do Ouro',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205802,
    label: 'Corumbá de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5205901,
    label: 'Corumbaíba',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206206,
    label: 'Cristalina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206305,
    label: 'Cristianópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206404,
    label: 'Crixás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206503,
    label: 'Cromínia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206602,
    label: 'Cumari',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206701,
    label: 'Damianópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206800,
    label: 'Damolândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5206909,
    label: 'Davinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207105,
    label: 'Diorama',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207253,
    label: 'Doverlândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207352,
    label: 'Edealina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207402,
    label: 'Edéia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207501,
    label: 'Estrela do Norte',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207535,
    label: 'Faina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207600,
    label: 'Fazenda Nova',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207808,
    label: 'Firminópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5207907,
    label: 'Flores de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208004,
    label: 'Formosa',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208103,
    label: 'Formoso',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208152,
    label: 'Gameleira de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208301,
    label: 'Divinópolis de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208400,
    label: 'Goianápolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208509,
    label: 'Goiandira',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208608,
    label: 'Goianésia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208707,
    label: 'Goiânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208806,
    label: 'Goianira',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5208905,
    label: 'Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209101,
    label: 'Goiatuba',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209150,
    label: 'Gouvelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209200,
    label: 'Guapó',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209291,
    label: 'Guaraíta',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209408,
    label: 'Guarani de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209457,
    label: 'Guarinos',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209606,
    label: 'Heitoraí',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209705,
    label: 'Hidrolândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209804,
    label: 'Hidrolina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209903,
    label: 'Iaciara',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209937,
    label: 'Inaciolândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5209952,
    label: 'Indiara',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210000,
    label: 'Inhumas',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210109,
    label: 'Ipameri',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210158,
    label: 'Ipiranga de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210208,
    label: 'Iporá',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210307,
    label: 'Israelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210406,
    label: 'Itaberaí',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210562,
    label: 'Itaguari',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210604,
    label: 'Itaguaru',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210802,
    label: 'Itajá',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5210901,
    label: 'Itapaci',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211008,
    label: 'Itapirapuã',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211206,
    label: 'Itapuranga',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211305,
    label: 'Itarumã',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211404,
    label: 'Itauçu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211503,
    label: 'Itumbiara',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211602,
    label: 'Ivolândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211701,
    label: 'Jandaia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211800,
    label: 'Jaraguá',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5211909,
    label: 'Jataí',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212006,
    label: 'Jaupaci',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212055,
    label: 'Jesúpolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212105,
    label: 'Joviânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212204,
    label: 'Jussara',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212253,
    label: 'Lagoa Santa',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212303,
    label: 'Leopoldo de Bulhões',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212501,
    label: 'Luziânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212600,
    label: 'Mairipotaba',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212709,
    label: 'Mambaí',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212808,
    label: 'Mara Rosa',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212907,
    label: 'Marzagão',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5212956,
    label: 'Matrinchã',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213004,
    label: 'Maurilândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213053,
    label: 'Mimoso de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213087,
    label: 'Minaçu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213103,
    label: 'Mineiros',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213400,
    label: 'Moiporá',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213509,
    label: 'Monte Alegre de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213707,
    label: 'Montes Claros de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213756,
    label: 'Montividiu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213772,
    label: 'Montividiu do Norte',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213806,
    label: 'Morrinhos',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213855,
    label: 'Morro Agudo de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5213905,
    label: 'Mossâmedes',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214002,
    label: 'Mozarlândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214051,
    label: 'Mundo Novo',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214101,
    label: 'Mutunópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214408,
    label: 'Nazário',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214507,
    label: 'Nerópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214606,
    label: 'Niquelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214705,
    label: 'Nova América',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214804,
    label: 'Nova Aurora',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214838,
    label: 'Nova Crixás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214861,
    label: 'Nova Glória',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214879,
    label: 'Nova Iguaçu de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5214903,
    label: 'Nova Roma',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215009,
    label: 'Nova Veneza',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215207,
    label: 'Novo Brasil',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215231,
    label: 'Novo Gama',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215256,
    label: 'Novo Planalto',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215306,
    label: 'Orizona',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215405,
    label: 'Ouro Verde de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215504,
    label: 'Ouvidor',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215603,
    label: 'Padre Bernardo',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215652,
    label: 'Palestina de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215702,
    label: 'Palmeiras de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215801,
    label: 'Palmelo',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5215900,
    label: 'Palminópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5216007,
    label: 'Panamá',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5216304,
    label: 'Paranaiguara',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5216403,
    label: 'Paraúna',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5216452,
    label: 'Perolândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5216809,
    label: 'Petrolina de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5216908,
    label: 'Pilar de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5217104,
    label: 'Piracanjuba',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5217203,
    label: 'Piranhas',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5217302,
    label: 'Pirenópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5217401,
    label: 'Pires do Rio',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5217609,
    label: 'Planaltina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5217708,
    label: 'Pontalina',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218003,
    label: 'Porangatu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218052,
    label: 'Porteirão',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218102,
    label: 'Portelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218300,
    label: 'Posse',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218391,
    label: 'Professor Jamil',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218508,
    label: 'Quirinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218607,
    label: 'Rialma',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218706,
    label: 'Rianápolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218789,
    label: 'Rio Quente',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218805,
    label: 'Rio Verde',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5218904,
    label: 'Rubiataba',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219001,
    label: 'Sanclerlândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219100,
    label: 'Santa Bárbara de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219209,
    label: 'Santa Cruz de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219258,
    label: 'Santa Fé de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219308,
    label: 'Santa Helena de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219357,
    label: 'Santa Isabel',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219407,
    label: 'Santa Rita do Araguaia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219456,
    label: 'Santa Rita do Novo Destino',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219506,
    label: 'Santa Rosa de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219605,
    label: 'Santa Tereza de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219704,
    label: 'Santa Terezinha de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219712,
    label: 'Santo Antônio da Barra',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219738,
    label: 'Santo Antônio de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219753,
    label: 'Santo Antônio do Descoberto',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219803,
    label: 'São Domingos',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5219902,
    label: 'São Francisco de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220009,
    label: "São João d'Aliança",
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220058,
    label: 'São João da Paraúna',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220108,
    label: 'São Luís de Montes Belos',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220157,
    label: 'São Luiz do Norte',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220207,
    label: 'São Miguel do Araguaia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220264,
    label: 'São Miguel do Passa Quatro',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220280,
    label: 'São Patrício',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220405,
    label: 'São Simão',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220454,
    label: 'Senador Canedo',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220504,
    label: 'Serranópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220603,
    label: 'Silvânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220686,
    label: 'Simolândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5220702,
    label: "Sítio d'Abadia",
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221007,
    label: 'Taquaral de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221080,
    label: 'Teresina de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221197,
    label: 'Terezópolis de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221304,
    label: 'Três Ranchos',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221403,
    label: 'Trindade',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221452,
    label: 'Trombas',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221502,
    label: 'Turvânia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221551,
    label: 'Turvelândia',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221577,
    label: 'Uirapuru',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221601,
    label: 'Uruaçu',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221700,
    label: 'Uruana',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221809,
    label: 'Urutaí',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221858,
    label: 'Valparaíso de Goiás',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5221908,
    label: 'Varjão',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5222005,
    label: 'Vianópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5222054,
    label: 'Vicentinópolis',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5222203,
    label: 'Vila Boa',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5222302,
    label: 'Vila Propício',
    state: {value: 52, initials: 'GO', label: 'Goiás'},
  },
  {
    value: 5300108,
    label: 'Brasília',
    state: {value: 53, initials: 'DF', label: 'Distrito Federal'},
  },
];
