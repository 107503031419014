import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '15px',
    boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.3)`,
  },
  top: {
    display: 'flex',
    height: 'auto',
    padding: '16px 16px 32px 16px',
    marginBottom: 16,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  identifierContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
}));
