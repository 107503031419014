import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto';

import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';

import 'locale/i18n';

import App from './App';
import theme from './theme';

import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root'),
);
