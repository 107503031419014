import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

export const FormError = ({title}) => (
  <Box m={2}>
    <Alert variant="outlined" severity="error">
      {title}
    </Alert>
  </Box>
);

FormError.propTypes = {
  title: PropTypes.string.isRequired,
};
