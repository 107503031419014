import React, {useEffect} from 'react';
import ReactGA from 'react-ga';

import {Outlet, useLocation} from 'react-router-dom';

import {useStyles} from './styles';

export const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize('UA-170516559-1', {
        debug: false,
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.pageview(location.pathname);
    }
  }, [location.pathname]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Outlet />
    </div>
  );
};

Layout.propTypes = {};
