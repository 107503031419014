import {format, isValid} from 'date-fns';
import {v4 as uuidv4} from 'uuid';

export const guestsTransform = guests => {
  if (guests) {
    const newGuests = guests.map(guest => {
      const birthday = new Date(guest.dados?.dtnascimento);
      return {
        checkinOK: guest.checkinok || false,
        id: guest.chave || uuidv4(),
        uuid: uuidv4(),
        name: guest.nome || null,
        email: guest.email || null,
        mainGuest: guest.principal || false,

        // PERSONAL INFORMATION
        firstName: guest.dados?.dsnome || null,
        lastName: guest.dados?.dssobrenome || null,
        birthday: isValid(birthday) ? format(birthday, 'dd/MM/yyyy') : null,
        ageQualify: guest.dados?.cdagequalify || null,
        genre: guest.dados?.flsexo || null,
        nationality: guest.dados?.dsnacionalidade || null,
        nationalityCode: guest.dados?.idnacionalidade || null,
        maritalStatus: guest.dados?.flestadocivil || null,
        documentType: guest.dados?.idtipodoc || null,
        document: guest.dados?.dsnumerodoc || null,
        issuer: guest.dados?.dsorgao || null,
        idCard: guest.dados?.iddoctitular || null,
        occupation:
          guest.dados?.dsprofissao && guest.dados?.cdprofissao
            ? guest.dados?.dsprofissao
            : null,
        occupationCode:
          guest.dados?.cdprofissao && guest.dados?.dsprofissao
            ? guest.dados?.cdprofissao
            : null,
        // ADDRESS
        country: guest.dados?.dspais || null,
        countryCode: guest.dados?.idpais || null,
        zipCode: guest.dados?.idcep || null,
        state: guest.dados?.dsestado || null,
        stateCode: guest.dados?.idestado || null,
        city: guest.dados?.dscidade || null,
        cityCode: guest.dados?.idcidade || null,
        address: guest.dados?.dsendereco || null,
        number: guest.dados?.dsnumero || null,
        neighborhood: guest.dados?.dsbairro || null,
        complement: guest.dados?.dscomplemento || null,
        phone: guest.dados?.dsfoneres || null,
        cellPhone: guest.dados?.dscelular || null,
        // TRAVEL INFORMATION
        purposeTrip: guest.dados?.idmotivoviagem || null,
        transportation: guest.dados?.idmeiotransp || null,
        lastDepartureCountry: guest.dados?.dspaisorig || null,
        lastDepartureCountryCode: guest.dados?.idpaisorig || null,
        lastDepartureState: guest.dados?.dsestadoorig || null,
        lastDepartureStateCode: guest.dados?.idestadoorig || null,
        lastDepartureCity: guest.dados?.dscidadeorig || null,
        lastDepartureCityCode: guest.dados?.idcidadeorig || null,
        nextDestinationCountry: guest.dados?.dspaisdest || null,
        nextDestinationCountryCode: guest.dados?.idpaisdest || null,
        nextDestinationState: guest.dados?.dsestadodest || null,
        nextDestinationStateCode: guest.dados?.idestadodest || null,
        nextDestinationCity: guest.dados?.dscidadedest || null,
        nextDestinationCityCode: guest.dados?.idcidadedest || null,
        files: guest.dados?.files || [],
        membership: guest.dados?.dsrewardscode || null,
        licensePlate: guest.dados?.nmplaca,
      };
    });
    return newGuests;
  }
  return null;
};

const roomsTransform = rooms => {
  if (rooms) {
    return rooms.map(room => ({
      id: room.chave,
      idquarto: room.idquarto,
      type: room.tipo,
      name: room.nometipo,
      adults: parseInt(room.adultos, 10),
      children: parseInt(room.criancas, 10),
      guests: guestsTransform(room.hospedes),
    }));
  }
  return null;
};

export const transformReservationResponse = reservation => {
  if (reservation.localizador && reservation.reserva && reservation.quartos) {
    return {
      identifier: String(reservation.localizador).toUpperCase(),
      reservationCode: String(reservation.reserva).toUpperCase(),
      rooms: roomsTransform(reservation.quartos),
    };
  }
  return null;
};
