import React from 'react';

export const TermsEN = () => (
  <>
    <p>
      <small>In effect on July 8th, 2020.</small>
    </p>
    <p>
      This document contain information about the services of Desbravador
      Check-in, how it works, legal guidelines and, in special, about the
      processing of personal data, the object of the processing and rights and
      obligations related
    </p>
    <p>Read this document thoroughly.</p>
    <p>
      The agreement with terms and conditions of use indicates your free and
      informed consent about the provisions presented on this document.
    </p>

    <h3>1. Key Terms</h3>
    <p>The terms below have the following meaning when used on this documen</p>
    <p>
      <b>Guest</b> - individual that temporarily stay at hotel, hostels, someone
      else’s house, etc.
    </p>
    <p>
      <b>Lodging</b> - facilities provided to hold the reception trade and to
      accommodate travelers and tourists in general.
    </p>
    <p>
      <b>Desbravador</b> - a company specialized on supplying technology
      services and products.
    </p>
    <p>
      <b>Check-in</b> - guest register at a hotel.
    </p>
    <p>
      <b>On-line</b> - connected to the internet, also available to access
      information on real time.
    </p>

    <h3>2. Agreement</h3>
    <p>
      When using our services and/or access any Desbravador Check-in content or
      material, you will celebrate a bonding contract with our platform.
    </p>
    <p>
      Your agreement with us includes acceptance of these Terms of Use. You
      agree that you read, understood and will comply with this documen.
    </p>
    <p>
      To use Desbravador Check-in you need to (1) have a valid personal
      identification, (2) have a valid e-mail address, and (3) have 18 years or
      more and be legally responsible for yourself to be able to celebrate this
      contract and not prevented by any applicable law.
    </p>
    <p>
      Also, you declare that all register information provided through the
      platform Desbravador Check-in to the Lodging are true, precise and
      complete, and agree to keep this way.
    </p>
    <p>
      You are aware that all information and personal data are provided with
      your consent as holder of the data, and are aware of the conditions of
      this Terms of Use and the extension of the authorization of use of your
      personal data by the Lodgin.
    </p>
    <p>
      Desbravador Check-in has the purpose of guarantee and transparency on
      personal data use by any Lodging, having legit interest on your request,
      so that the service can by provided with all excellence expected.
    </p>
    <p>
      If you do not agree (or can not comply) with this document then you can
      not use Desbravador Check-in.
    </p>

    <h3>3 - What it is and how it works?</h3>
    <p>
      Desbravador Check-in is a free tool that allows you to make your online
      check-in in several Lodging all over the world in an anticipated, simple
      and practical way to smooth your arrival at the hotel.
    </p>

    <p>
      <b>
        After booking with an establishment that uses Desbravador Check-in, you
        will receive a link from the hotel through on your e-mail so you can
        check-in online.
      </b>
    </p>

    <p>
      To realize your online check-in it is necessary to accept our Terms of Use
      and type your passport to begin. Fill all personal data and informations
      about the trip requested, and then click on the `Send` button to send your
      register file to the Lodging booked.
    </p>

    <p>
      The Lodging selected will receive your information in a Guest Register
      file virtually. After realizing your check-in through Desbravador
      Check-in, all you have to do is head for the Lodging reception and
      retrieve your keys.
    </p>

    <h3>3.1 - Legal notice and general dispositions</h3>
    <p>
      Desbravador Check-in is a facilitation tool between the Lodging and the
      Guest and, does not have any bond or responsibility in this commercial
      relationship, and can not be accountable in fortuitous cases, force
      majeure, by any act, omission, damage, fraud, tragedy, crime and/or
      attempted coup, embarrassment and any other forms of damage to any party.
    </p>
    <p>
      <b>
        The guest is subject to the policies established by the Lodging at the
        moment of the booking effectuation.
      </b>
    </p>
    <p>
      Thereby, when booking an accommodation you accept and agree with the
      cancellation conditions and no show, and with any other terms and
      conditions (described) of the Lodging that may be applied to your booking,
      including provided services and/or offered products.
    </p>
    <p>
      <b>
        Desbravador Check-in does not provide booking cancellation option or
        reversal of realized check-in.
      </b>
    </p>
    <p>
      In case you want to review, edit or cancel your booking, or even
      effectuate payment, booking extension, people addition on the same
      accommodation, room change and extra services, you will have to request
      directly to the Lodging, and it will proceed accordingly with its
      policies.
    </p>
    <p>
      If you have to arrive later on check-in date, or if you are only arriving
      on the next day, communicate (immediately and on time) to the Lodging so
      its known about your arrival and avoid the cancellation of your room and
      charge of no show rate. Desbravador Check-in do not have any
      responsibility for the consequences of your late arrival or cancellation
      or charge for no show made by the hotel.
    </p>
    <p>
      Under any circumstances, Desbravador Check-in, including its directors,
      employees, representatives, subsidiaries, affiliated companies,
      distributors, partners, licensed, agents or any other person involved on
      the creation, sponsorship or creation of Desbravador Check-in and its
      contents, can be responsibilities for:
    </p>
    <ul>
      <li>
        any damage or punishable loss, special, direct or consequential, moral
        damage, any loss of production, profit, income, contract, and reputation
        or influence damage or loss, indemnification right loss;
      </li>
      <li>any information error of the Lodging shown on our website;</li>
      <li>
        services or products provided by the Lodging or any business partners;
      </li>
      <li>
        any damage (direct, indirect, consequential or punishable), losses or
        costs supported, legal costs, incurred or payed by you, resultant or
        related to use, impossibility of use or our website failure, or;
      </li>
      <li>
        any damage (personal), death, property damage or others (direct,
        indirect, special, consequential or punishable) damages, losses or costs
        supported, legal costs, incurred or payed by you, due to acts (legal),
        errors, infractions, negligence, document fraud and/or ideological
        falsehood, attempted coup, intentional misconduct, omissions,
        non-compliance, false informations, crime, or objective non-contractual
        liability (totally or partially) to the Lodging or any of its business
        partners (including its employees, administrative directors, agents,
        representatives or affiliated companies), of which products and services
        are (direct or indirect) available, are offered or promoted by the own
        establishment, including any cancellation (partial), overbooking,
        strike, force majeure, fortuitous case or any other event out of our
        control, independently of legal theory, unrelated with the fact that
        Desbravador Check-in adverted of possibility of the damages, and even if
        a solution fails on its essential purpose.
      </li>
    </ul>

    <h3>4. Rights granted to Desbravador Check-in</h3>
    <p>
      You grand the rights and permissions to utilize your personal data to:
    </p>
    <ul>
      <li>Detect and prevent frauds.</li>
      <li>Develop website improvements.</li>
      <li>
        Send your Guest Register File to the Lodging selected, as part of your
        check-in process, according to the regulation and procedures applicable,
        in case the hotel is in Brazil and sends the file.
      </li>
      <li>Allow direct communication with the Lodging.</li>
      <li>
        Share all register data with the booked hotel, and with the PMS it
        utilizes for booking management.
      </li>
      <li>
        Attach to your check-in register, in our software, the check-in data of
        your travel companions, when they fill the Guest Register File on
        Desbravador Check-in, in case the hotel is in Brazil.
      </li>
      <li>
        You authorize Desbravador Check-in to request and store new data and
        personal information to fulfill your register, with the purpose of
        allowing the Lodging to better comprehend your preferences and habits of
        life and consumption for better experience during your stay.
      </li>
      <li>
        You authorize expressly, irrefutably and unconditionally, Desbravador
        Check-in to pass on all information on you register to the Lodging where
        you are going to stay, as part as your check-in process, being this the
        main purpose of this platform. This process has legal support on
        Brazil`s Data Law (LGPD), mainly on article 6th and 7h.
      </li>
      <li>
        When you fill your register you expressly authorize Desbravador Check-in
        to have access to your personal data and to transmit it and share it,
        when necessary, with the Lodging, with the purpose of fulfilling this
        platforms goal. Desbravador Check-in commits to adopt all safety
        measures and good practices to assure the secrecy of the data on the
        limits and terms of this contract.
      </li>
      <li>
        It is your knowledge that, from the moment that the Lodging, has access
        to your personal data, it will be solely and exclusive to the Lodging,
        the treatment, secrecy and safety of your personal data.
      </li>
    </ul>

    <h3>5. Copyrights</h3>
    <p>
      Desbravador Check-in is available for personal use, being forbidden, for
      any reason:
    </p>
    <ul>
      <li>
        The copy, redistribution, transfer, sale or availability to the general
        public of any part of Desbravador Check-in software and its contents, or
        do in any other way, use that is not expressly allowed in this agreement
        or applicable law, or that violates, in any way, intellectual property
        rights (as copyrights);
      </li>
      <li>
        Execute reverse engineering, decompile, dismantle, modify or create
        derivative work based on Desbravador Check-in software, its contents or
        any part of it;
      </li>
      <li>
        Outwit any technology used by Desbravador Check-in, in search of any
        advantage or personal gain;
      </li>
      <li>
        Sell, rent, sublicense or lease any part of Desbravador Check-in or its
        content;
      </li>
      <li>
        Artificially modify the count of check-ins or manipulate in any way
        Desbravador Check-in using a script or any other automated process;
      </li>
    </ul>

    <h3>6. Limitations and software modifications</h3>
    <p>
      Desbravador Check-in will undertake its bests efforts to maintain the
      software on operation. However, possible technical difficulties or
      maintenance may occur, and in consequence result on temporary
      interruptions. Desbravador Check-in reserves the right to, periodically
      and at any time, modify or discontinue, temporarily or permanent,
      functions and resources of Desbravador Check-in, with or without notice,
      without it implying in any accountability, except where its forbidden by
      law, related to any interruption, modification or discontinuation of
      Desbravador Check-In or any function or resource.
    </p>

    <h3>7. Safety, guarantee and liability</h3>
    <p>
      Desbravador Check-in strives to provide the best service possible. You use
      Desbravador Check-in by free and spontaneous will, by your account and
      risk. Desbravador Check-in does not assure that the service is free of
      malware or other prejudicial content. Besides, Desbravador Check-in does
      not do statements or assure, endorse, guarantee or take responsibility for
      other third party applications (or its contents), or for another product
      or disclosed service or offered by a third party on (or through)
      Desbravador Check-in, or any websites with hyperlinks, or integrated in
      any banner or other advertising. Desbravador Check-in is to responsible by
      the content provided by you ir your online check-in. You understand and
      agree that Desbravador Check-in is not responsible by any transaction
      between you and the Lodging, or by your disclosed data on Desbravador
      Check-in, or through it.
    </p>
    <p>
      Desbravador Check-in adopts procedures to prevent the unauthorized access
      and wrongful use of your personal data, in a way that they are protected.
      Also applies safety procedures and technical and physical restrictions to
      access and use the personal data on our servers. Only authorized employees
      can access the information, during work performance.
    </p>

    <h3>8. Privacy Policy</h3>
    <p>
      Desbravador Check-in respects the privacy of its clients and the companies
      that it works. A lot of importance is given to the trust put in its work
      and it commit to protect and keep all personal and business data given.
      All information collected are related with the service provided by
      Desbravador Check-in and its features.
    </p>
    <p>
      Desbravador Check-in requires miscellaneous personal data, to several
      purposes, such as:
    </p>
    <ul>
      <li>Detect and prevent fraud.</li>
      <li>Develop improvements on the website.</li>
      <li>
        Send your Guest Register File to the Lodging selected, as part as your
        check-in process, in agreement with the law and procedures, when
        applicable.
      </li>
      <li>Allow direct communication with the Lodging.</li>
      <li>Share your register data with the hotel you are staying.</li>
      <li>
        Link to your register file, in our software, the check in data of your
        travelers companions, when they check-in by Desbravador Check-in.
      </li>
    </ul>
    <p>
      You are entirely responsible by all information given in your Desbravador
      Check-in register, being entirely responsible by the effects caused by
      this information, being held liable on a criminal and civil basis by acts
      against the law, misleading advertising, obscene acts and copyrights
      violation. It is up to you to check the information shared on Desbravador
      Check-in and the need of correction or updates.
    </p>

    <h3>9. Alterations on this document</h3>
    <p>
      Occasionally, we can, by our own criteria, make alterations on this Terms
      and Conditions of Use. When the document is edited we will publish a
      notice on our website desbravador.com.br/pt/termos. In case you keep using
      Desbravador Check-in after the alteration have been made, we will
      understand that you agree with it. Therefore, make sure you read any
      notice of this kind. If you do not wish to use Desbravador Check-in after
      the alterations, you can end your contract by e-mail,
      suporte@desbravador.com.br, with the subject “Exclusion of data
      Desbravador Check-in”
    </p>
    <p>
      You can also request information related to the privacy of your data,
      whenever you wish, and we will reply as soon as possible.
    </p>
    <p>
      If any prevision on this Terms of Use is considered invalid or
      unenforceable in accordance to applicable law, it will be replaced by a
      valid and forceable prevision that best identifies the intention of the
      original provision and all terms and conditions remaining will continue on
      effect.
    </p>

    <h3>10. Validity and register termination</h3>
    <p>
      This contract have validity until terminated by you or Desbravador
      Check-in. However, you agree and confirm that the access to the content
      allowed by you is irrevocable and, therefore, continues after the
      termination or validity of this contract. Desbravador Check-in can
      terminate the agreement or suspend your access to the software any time,
      including the case of its unauthorized or suspicious use, or
      non-compliance with the agreemen
    </p>
    <p>
      It is necessary to request to Desbravador Check-in the exclusion of you
      register and personal data of the database, with the communication of
      revoking consent.
    </p>
    <p>
      The personal data will be excluded in up to 30 (thirty) days after the
      request, remaining the authorization to safe keep to the purpose listed on
      article 16th of Brazil’s Data Law (LGPD). And, after the exclusion, in
      case you want to reactivate your register, the data will not be
      recoverable and you will have to realize a new register on Desbravador
      Check-in website.
    </p>
    <p>
      To request the exclusion of your Desbravador Check-in register, send an
      e-mail to “suporte@desbravador.com.br” with the subject “Exclusion of Data
      Desbravador Check-in”. This section is in effect in the allowed measure by
      the applicable law. You can cancel this agreement at any time, without any
      cost.
    </p>
    <p>
      If not required the exclusion, the data will remain active and protected
      accordingly with Brazil’s Data Law (LGPD).
    </p>

    <h3>11. Legislation and jurisdiction</h3>
    <p>
      The present Terms of Use shall be governed by the Brazilians Law in effect
      on the day that you consent to the agreement. Its elected as jurisdiction
      to settle any controversy caused by its terms, conditions and provisions,
      the district court in the capital, São Paulo, in São Paulo State,
      expressly waiving any other, no more special or privileged it may be.
    </p>
  </>
);
