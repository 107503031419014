import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Divider,
  Button,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '15px',
    boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.3)`,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  identifierContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    minWidth: 150,
  },
  success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    width: 50,
    height: 35,
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    width: 50,
    height: 35,
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
  },
}));

export const GuestList = ({
  logo,
  identifier,
  options,
  guestId,
  roomId,
  handleChangeGuest,
}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const [active, setActive] = useState({
    guestId,
    roomId,
  });

  return (
    <Box className={classes.root} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="logo hotel" />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.identifierContainer}>
            <Typography className={classes.heading} component="h4">
              {identifier}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Box my={2}>
        <List>
          {options.map(option => {
            const el = [];
            if (option.guests && option.guests.length > 0) {
              el.push(
                <ListSubheader className={classes.subheader} key={option.type}>
                  {/* {option.name} - {option.idquarto} */}
                </ListSubheader>,
              );
              option.guests.forEach(guest => {
                el.push(
                  <ListItem
                    key={guest.id}
                    dense
                    button
                    onClick={() => {
                      setActive({
                        guestId: guest.id,
                        roomId: option.id,
                      });
                    }}>
                    <ListItemIcon>
                      <Checkbox
                        color="primary"
                        edge="start"
                        checked={
                          guest.id === active.guestId &&
                          option.id === active.roomId
                        }
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText id={guest.id} primary={`${guest.name}`} />
                    <ListItemSecondaryAction>
                      {guest.checkinOK ? (
                        <div className={classes.success}>
                          <CheckIcon />
                        </div>
                      ) : (
                        <div className={classes.info}>
                          <InfoIcon />
                        </div>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>,
                );
              });
              return el;
            }
            return el;
          })}
        </List>

        <Divider />

        <div className={classes.buttons}>
          <Button
            size="medium"
            disabled={!active.guestId}
            variant="contained"
            color="primary"
            onClick={() => {
              handleChangeGuest(active);
            }}
            className={classes.button}>
            {t('actions.continue')}
          </Button>
        </div>
      </Box>
    </Box>
  );
};

GuestList.defaultProps = {
  guestId: null,
  roomId: null,
};

GuestList.propTypes = {
  logo: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  guestId: PropTypes.string,
  roomId: PropTypes.string,
  handleChangeGuest: PropTypes.func.isRequired,
};
