/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {withStyles} from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';

import Dropzone from 'react-dropzone';
import {isImage, readFile} from './helpers';
import PreviewList from './PreviewList';

const styles = ({palette, shape, spacing}) => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '250px',
    backgroundColor: palette.background.paper,
    border: 'dashed',
    borderColor: palette.divider,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  active: {
    animation: '$progress 2s linear infinite !important',
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${palette.background.paper}, ${palette.background.paper} 25px, ${palette.divider} 25px, ${palette.divider} 50px)`,
    backgroundSize: '150% 100%',
    border: 'solid',
    borderColor: palette.primary.light,
  },
  invalid: {
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${palette.error.light}, ${palette.error.light} 25px, ${palette.error.dark} 25px, ${palette.error.dark} 50px)`,
    borderColor: palette.error.main,
  },
  textContainer: {
    textAlign: 'center',
  },
  text: {
    marginBottom: spacing(3),
    marginTop: spacing(3),
  },
  icon: {
    width: 51,
    height: 51,
    color: palette.text.primary,
  },
});

const defaultGetPreviewIcon = (fileObject, classes) => {
  if (isImage(fileObject.file)) {
    return (
      <img
        className={classes.image}
        role="presentation"
        src={fileObject.data}
        alt=""
      />
    );
  }

  return <AttachFileIcon className={classes.image} />;
};

const DropzoneAreaBase = props => {
  const {enqueueSnackbar} = useSnackbar();

  const notifyAlert = (snackbarMessage, snackbarVariant) => {
    enqueueSnackbar(snackbarMessage, {
      variant: snackbarVariant,
    });
  };

  const handleDropAccepted = async acceptedFiles => {
    const {
      fileObjects,
      filesLimit,
      getFileAddedMessage,
      getFileLimitExceedMessage,
      onAdd,
    } = props;

    if (
      filesLimit > 1 &&
      fileObjects.length + acceptedFiles.length > filesLimit
    ) {
      notifyAlert(getFileLimitExceedMessage(filesLimit), 'error');
      return;
    }

    const fileObjs = await Promise.all(
      acceptedFiles.map(async file => {
        const data = await readFile(file);
        return {
          file,
          data,
        };
      }),
    );

    if (onAdd) {
      onAdd(fileObjs);
    }

    notifyAlert(getFileAddedMessage(), 'success');
  };

  const handleDropRejected = rejectedFiles => {
    const {filesLimit, fileObjects, getFileLimitExceedMessage} = props;

    let message = '';
    if (fileObjects.length + rejectedFiles.length > filesLimit) {
      message = getFileLimitExceedMessage();
    } else {
      rejectedFiles.forEach(rejectedFile => {
        message = rejectedFile.errors[0].message;
      });
    }

    notifyAlert(message, 'error');
  };

  const handleRemove = fileIndex => event => {
    event.stopPropagation();

    const {fileObjects, getFileRemovedMessage, onDelete} = props;

    const removedFileObj = fileObjects[fileIndex];

    if (onDelete) {
      onDelete(removedFileObj, fileIndex);
    }

    notifyAlert(getFileRemovedMessage(), 'info');
  };

  const {
    acceptedFiles,
    classes,
    dropzoneText,
    fileObjects,
    filesLimit,
    getPreviewIcon,
    maxFileSize,
    previewText,
    showPreviews,
    previewsInDropzoneVisible,
    useChipsForPreview,
  } = props;

  const acceptFiles = acceptedFiles?.join(',');
  const isMultiple = filesLimit > 1;
  const previewsVisible = showPreviews && fileObjects.length > 0;

  return (
    <>
      <Dropzone
        accept={acceptFiles}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
        maxSize={maxFileSize}
        multiple={isMultiple}>
        {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
          <div
            {...getRootProps()}
            className={clsx(
              classes.root,
              isDragActive && classes.active,
              isDragReject && classes.invalid,
            )}>
            <input {...getInputProps()} />

            <div className={classes.textContainer}>
              <Typography variant="h5" component="p" className={classes.text}>
                {dropzoneText}
              </Typography>
              <CloudUploadIcon className={classes.icon} />
            </div>

            {previewsInDropzoneVisible && (
              <PreviewList
                fileObjects={fileObjects}
                handleRemove={handleRemove}
                getPreviewIcon={getPreviewIcon}
                useChipsForPreview={useChipsForPreview}
              />
            )}
          </div>
        )}
      </Dropzone>

      {previewsVisible && (
        <>
          <Typography variant="subtitle1" component="span">
            {previewText}
          </Typography>

          <PreviewList
            fileObjects={fileObjects}
            handleRemove={handleRemove}
            getPreviewIcon={getPreviewIcon}
            useChipsForPreview={useChipsForPreview}
          />
        </>
      )}
    </>
  );
};

DropzoneAreaBase.defaultProps = {
  acceptedFiles: ['image/*'],
  filesLimit: 1,
  fileObjects: [],
  maxFileSize: 3000000,
  dropzoneText: 'Drag and drop a file here or click',
  previewText: 'Preview:',
  showPreviews: false,
  previewsInDropzoneVisible: false,
  useChipsForPreview: false,
  getFileLimitExceedMessage: () => `Maximum allowed number of files exceeded`,
  getFileAddedMessage: () => `File successfully added.`,
  getFileRemovedMessage: () => `File removed.`,
  getPreviewIcon: defaultGetPreviewIcon,
  onAdd: () => {},
  onDelete: () => {},
};

export const FileObjectShape = PropTypes.shape({});

DropzoneAreaBase.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  filesLimit: PropTypes.number,
  fileObjects: PropTypes.arrayOf(FileObjectShape),
  maxFileSize: PropTypes.number,
  dropzoneText: PropTypes.string,
  showPreviews: PropTypes.bool,
  previewsInDropzoneVisible: PropTypes.bool,
  useChipsForPreview: PropTypes.bool,
  previewText: PropTypes.string,
  getFileLimitExceedMessage: PropTypes.func,
  getFileAddedMessage: PropTypes.func,
  getFileRemovedMessage: PropTypes.func,
  getPreviewIcon: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withStyles(styles, {name: 'MuiDropzoneArea'})(DropzoneAreaBase);
