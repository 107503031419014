export const personalInfoFields = [
  'email',
  'firstName',
  'lastName',
  'birthday',
  'genre',
  'nationalityCode',
  'maritalStatus',
  'documentType',
  'document',
  'idCard',
  'issuer',
  'occupationCode',
];

export const addressFields = [
  'countryCode',
  'zipCode',
  'state',
  'city',
  'address',
  'number',
  'neighborhood',
  'complement',
  'phone',
  'cellPhone',
];

export const travelInfoFields = [
  'purposeTrip',
  'transportation',
  'licensePlate',
  'lastDepartureCountryCode',
  'lastDepartureState',
  'lastDepartureCity',
  'nextDestinationCountryCode',
  'nextDestinationState',
  'nextDestinationCity',
];
