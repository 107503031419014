export const INITIAL_VALUES = {
  // PERSONAL INFORMATION
  email: '',
  firstName: '',
  lastName: '',
  birthday: null,
  age: 99,
  genre: '',
  nationality: '',
  nationalityCode: null,
  maritalStatus: '',
  documentType: 'CPF',
  document: '',
  occupation: '',
  occupationCode: null,
  // ADDRESS
  country: '',
  countryCode: null,
  zipCode: '',
  city: '',
  cityCode: null,
  state: '',
  stateCode: null,
  address: '',
  number: '',
  neighborhood: '',
  complement: '',
  phone: '',
  cellPhone: '',
};
