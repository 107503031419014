const mapDocumentTypes = document => {
  if (document === 'CPF') {
    return 'CPF';
  }
  if (document === 'RG') {
    return 'RG';
  }
  if (document === 'CIE') {
    return 'CIE';
  }
  if (document === 'DNI') {
    return 'DNI';
  }
  if (document === 'CN') {
    return 'CN';
  }
  if (document === 'PASSAPORTE') {
    return 'PASSPORT';
  }
  return document;
};

export const transformConfig = config => {
  const newConf = {
    logo: config.logohotel1,
    occupations: config.profissoes
      ? config.profissoes.map(p => ({
          label: p.descricao,
          value: parseInt(p.profissao, 10),
        }))
      : [],
    documentsNotAllowed: config.documentsNotAllowed
      ? config.documentsNotAllowed.map(field => mapDocumentTypes(field))
      : [],
    optionalFields: config.optionalFields || [],
    hideOptionalFields: config.hideOptionalFields || false,
    optionalHolderEmail: config.optionalHolderEmail || false,
    termsOfUse: config.termodeuso,
  };
  return newConf;
};
