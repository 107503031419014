/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Link,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {useStyles} from './styles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export const TermsModalHotel = ({terms}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={e => {
          e.preventDefault();
          handleClickOpen();
        }}>
        {t('form.readTermsHotel')}
      </Link>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
        fullWidth>
        <DialogTitle>{t('form.termosOfUseHotel')}</DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{__html: terms}}
            className={classes.content}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TermsModalHotel.defaultProps = {
  terms: '',
};

TermsModalHotel.propTypes = {
  terms: PropTypes.string,
};
