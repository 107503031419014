import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {createFileFromUrl, readFile} from './helpers';

import DropzoneDialogBase from './DropzoneDialogBase';

const DropzoneDialog = props => {
  const [fileObjects, setFileObjects] = useState([]);

  const {initialFiles, open} = props;

  useEffect(() => {
    const loadInitialFiles = async () => {
      try {
        const fileObjs = await Promise.all(
          initialFiles.map(async initialFile => {
            let file;
            if (typeof initialFile === 'string') {
              file = await createFileFromUrl(initialFile);
            } else {
              file = initialFile;
            }
            const data = await readFile(file);

            return {
              file,
              data,
            };
          }),
        );

        setFileObjects(fileObjs);
      } catch (err) {
        //
      }
    };

    if (open) {
      loadInitialFiles();
    }
  }, [initialFiles, open]);

  const addFiles = async newFileObjects => {
    const {filesLimit} = props;
    let files = [];
    if (filesLimit <= 1) {
      files = [].concat(newFileObjects[0]);
    } else {
      files = [].concat(fileObjects, newFileObjects);
    }

    setFileObjects(files);
  };

  const deleteFile = (removedFileObj, removedFileObjIdx) => {
    const {onDelete} = props;

    const remainingFileObjs = fileObjects.filter(
      (fileObject, i) => i !== removedFileObjIdx,
    );

    if (onDelete) {
      onDelete(removedFileObj.file);
    }

    setFileObjects(remainingFileObjs);
  };

  const handleClose = event => {
    const {onClose} = props;
    if (onClose) {
      onClose(event);
    }
  };

  const handleSave = event => {
    const {onSave} = props;

    onSave(
      fileObjects.map(fileObject => fileObject.data),
      event,
    );
  };

  return (
    <DropzoneDialogBase
      {...props}
      fileObjects={fileObjects}
      onAdd={files => {
        addFiles(files);
      }}
      onDelete={(removedFileObj, removedFileObjIdx) => {
        deleteFile(removedFileObj, removedFileObjIdx);
      }}
      onClose={event => {
        handleClose(event);
      }}
      onSave={event => {
        handleSave(event);
      }}
    />
  );
};

DropzoneDialog.defaultProps = {
  filesLimit: 1,
  initialFiles: [],
};

DropzoneDialog.propTypes = {
  ...DropzoneDialogBase.propTypes,
  filesLimit: PropTypes.number,
  initialFiles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]),
  ),
  onSave: PropTypes.func.isRequired,
};

export default DropzoneDialog;
