import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import DropzoneAreaBase from './DropzoneAreaBase';

const splitDropzoneDialogProps = allProps => {
  const {
    cancelButtonText,
    dialogProps,
    dialogTitle,
    fullWidth,
    maxWidth,
    onClose,
    onSave,
    open,
    submitButtonText,
    ...dropzoneAreaProps
  } = allProps;

  return [
    {
      cancelButtonText,
      dialogProps,
      dialogTitle,
      fullWidth,
      maxWidth,
      onClose,
      onSave,
      open,
      submitButtonText,
    },
    dropzoneAreaProps,
  ];
};

const DropzoneDialogBase = props => {
  const [dropzoneDialogProps, dropzoneAreaProps] =
    splitDropzoneDialogProps(props);
  const {
    cancelButtonText,
    dialogTitle,
    fullWidth,
    maxWidth,
    onClose,
    onSave,
    open,
    submitButtonText,
  } = dropzoneDialogProps;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose(event);
        }
      }}
      open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        <DropzoneAreaBase {...dropzoneAreaProps} />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {cancelButtonText}
        </Button>

        <Button color="primary" onClick={onSave}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DropzoneDialogBase.defaultProps = {
  open: false,
  dialogTitle: 'Upload file',
  fullWidth: true,
  maxWidth: 'sm',
  cancelButtonText: 'Cancel',
  submitButtonText: 'Confirm',
  showPreviews: true,
  previewsInDropzoneVisible: false,
};

DropzoneDialogBase.propTypes = {
  ...DropzoneAreaBase.propTypes,
  open: PropTypes.bool,
  dialogTitle: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showPreviews: PropTypes.bool,
  previewsInDropzoneVisible: PropTypes.bool,
};

export default DropzoneDialogBase;
