import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import {useFormikContext, Field} from 'formik';

import {useTranslation} from 'react-i18next';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

import {cepSearch} from 'services/cepSearch';

import {BRAZIL_IBGE_CODE} from 'helpers/constants';

import {CountrySelect} from 'components/form/countrySelect';
import {StateSelect} from 'components/form/stateSelect';
import {CitySelect} from 'components/form/citySelect';

import {addressFields} from './util';

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from './styles';

const AddressForm = ({
  expanded,
  handleChangePanel,
  optionalFields,
  hideOptionalFields,
}) => {
  const {values, handleChange, errors, setValues} = useFormikContext();
  const [searchingZipCode, setSearchingZipCode] = useState(false);

  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const requiredCountry = useMemo(
    () => !optionalFields.includes('countryCode'),
    [optionalFields],
  );
  const showCountry = useMemo(() => {
    if (!requiredCountry && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredCountry, hideOptionalFields]);

  const requiredState = useMemo(
    () => !optionalFields.includes('state'),
    [optionalFields],
  );
  const showState = useMemo(() => {
    if (!requiredState && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredState, hideOptionalFields]);

  const requiredCity = useMemo(
    () => !optionalFields.includes('city'),
    [optionalFields],
  );
  const showCity = useMemo(() => {
    if (!requiredCity && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredCity, hideOptionalFields]);

  const requiredZipCode = useMemo(
    () => !optionalFields.includes('zipCode'),
    [optionalFields],
  );
  const showZipCode = useMemo(() => {
    if (!requiredZipCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredZipCode, hideOptionalFields]);

  const requiredAddress = useMemo(
    () => !optionalFields.includes('address'),
    [optionalFields],
  );
  const showAddress = useMemo(() => {
    if (!requiredAddress && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredAddress, hideOptionalFields]);

  const requiredNumber = useMemo(
    () => !optionalFields.includes('number'),
    [optionalFields],
  );
  const showNumber = useMemo(() => {
    if (!requiredNumber && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredNumber, hideOptionalFields]);

  const requiredNeighborhood = useMemo(
    () => !optionalFields.includes('neighborhood'),
    [optionalFields],
  );
  const showNeighborhood = useMemo(() => {
    if (!requiredNeighborhood && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredNeighborhood, hideOptionalFields]);

  const showComplement = useMemo(() => {
    if (hideOptionalFields) {
      return false;
    }
    return true;
  }, [hideOptionalFields]);

  const requiredPhone = useMemo(
    () => !optionalFields.includes('phone'),
    [optionalFields],
  );
  const showPhone = useMemo(() => {
    if (!requiredPhone && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredPhone, hideOptionalFields]);

  const requiredCellPhone = useMemo(
    () => !optionalFields.includes('cellPhone'),
    [optionalFields],
  );
  const showCellPhone = useMemo(() => {
    if (!requiredCellPhone && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredCellPhone, hideOptionalFields]);

  const hideForm = useMemo(() => {
    if (
      !showCountry &&
      !showState &&
      !showCity &&
      !showZipCode &&
      !showAddress &&
      !showNumber &&
      !showNeighborhood &&
      !showComplement &&
      !showPhone &&
      !showCellPhone
    ) {
      return true;
    }
    return false;
  }, [
    showCountry,
    showState,
    showCity,
    showZipCode,
    showAddress,
    showNumber,
    showNeighborhood,
    showComplement,
    showPhone,
    showCellPhone,
  ]);

  const searchZipCode = async () => {
    setSearchingZipCode(true);
    const search = values.zipCode;
    try {
      const result = await cepSearch(search);
      if (result) {
        setValues(
          {
            ...values,
            ...result,
          },
          true,
        );
      }
      setSearchingZipCode(false);
    } catch (e) {
      setSearchingZipCode(false);
    }
  };

  const renderCountry = () =>
    showCountry ? (
      <Grid item xs={12} sm={8} md={6}>
        <Field name="country">
          {({field}) => (
            <CountrySelect
              id="country"
              name={field.name}
              countryField="country"
              countryCodeField="countryCode"
              onChange={({country, countryCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  country,
                  countryCode,
                  state: '',
                  stateCode: null,
                  city: '',
                  cityCode: null,
                }));
              }}
              placeholder={t('form.country')}
              disabled={false}
              isBrazil={i18n.language === 'pt'}
              required={requiredCountry}
            />
          )}
        </Field>
      </Grid>
    ) : null;

  const renderState = () =>
    showState ? (
      <Grid item xs={12} sm={6} md={6}>
        <Field name="state">
          {({field}) => (
            <StateSelect
              id="state"
              name={field.name}
              stateField="state"
              stateCodeField="stateCode"
              isSelect={values.countryCode === BRAZIL_IBGE_CODE}
              onChange={({state, stateCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  state,
                  stateCode,
                  city: '',
                  cityCode: null,
                }));
              }}
              placeholder={t('form.state')}
              disabled={!values.countryCode}
              countryCode={values.countryCode}
              required={requiredState}
            />
          )}
        </Field>
      </Grid>
    ) : null;

  const renderCity = () => {
    let disabled = false;
    if (!values.stateCode && !values.countryCode) {
      disabled = true;
    }
    if (values.countryCode === BRAZIL_IBGE_CODE && !values.stateCode) {
      disabled = true;
    }
    return showCity ? (
      <Field name="city">
        {({field}) => (
          <Grid item xs={12} sm={6} md={6}>
            <CitySelect
              id="city"
              name={field.name}
              cityField="city"
              cityCodeField="cityCode"
              isSelect={values.countryCode === BRAZIL_IBGE_CODE}
              values={values}
              errors={errors}
              onChange={({city, cityCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  city,
                  cityCode,
                }));
              }}
              placeholder={t('form.city')}
              disabled={disabled}
              stateCode={values.stateCode}
              required={requiredCity}
            />
          </Grid>
        )}
      </Field>
    ) : null;
  };

  const panelIsInvalid = useMemo(() => {
    let hasErrors = false;
    addressFields.forEach(field => {
      if (errors[field]) {
        hasErrors = true;
      }
    });
    return hasErrors;
  }, [errors]);

  if (hideForm) {
    return null;
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(event, isExpanded) => {
        handleChangePanel(event, isExpanded);
      }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div>
          <Typography className={classes.heading}>
            {t('form.currentAddress')}
          </Typography>
          {panelIsInvalid ? (
            <Typography className={classes.headingInfoError}>
              {t('validations.invalidForm')}
            </Typography>
          ) : (
            <Typography className={classes.headingInfoSuccess}>
              {t('validations.formOK')}
            </Typography>
          )}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          {renderCountry()}

          {showZipCode ? (
            <Grid item xs={12} sm={4} md={6}>
              <Field name="zipCode">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.zipCode || ''}
                    error={!!errors.zipCode}
                    helperText={errors.zipCode || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="tel"
                    required={requiredZipCode}
                    name={field.name}
                    id="zipCode"
                    label={t('form.zipCode')}
                    fullWidth
                    variant="outlined"
                    autoComplete="nope"
                    InputProps={
                      values.countryCode === BRAZIL_IBGE_CODE
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={searchingZipCode}
                                  aria-label=""
                                  onClick={() => {
                                    searchZipCode();
                                  }}
                                  onMouseDown={() => {}}
                                  edge="end">
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {renderState()}

          {renderCity()}

          {showAddress ? (
            <Grid item xs={12} sm={12} md={9}>
              <Field name="address">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.address || ''}
                    error={!!errors.address}
                    helperText={errors.address || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="text"
                    required={requiredAddress}
                    name={field.name}
                    id="address"
                    label={t('form.address')}
                    fullWidth
                    variant="outlined"
                    autoComplete="nope"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showNumber ? (
            <Grid item xs={12} sm={4} md={3}>
              <Field name="number">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.number || ''}
                    error={!!errors.number}
                    helperText={errors.number || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="text"
                    required={requiredNumber}
                    name={field.name}
                    id="number"
                    label={t('form.number')}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showNeighborhood ? (
            <Grid item xs={12} sm={8} md={4}>
              <Field name="neighborhood">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.neighborhood || ''}
                    error={!!errors.neighborhood}
                    helperText={errors.neighborhood || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="text"
                    required={showNeighborhood}
                    name={field.name}
                    id="neighborhood"
                    label={t('form.neighborhood')}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showComplement ? (
            <Grid item xs={12} sm={12} md={8}>
              <Field name="complement">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.complement || ''}
                    error={!!errors.complement}
                    helperText={errors.complement || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="text"
                    required={false}
                    name={field.name}
                    id="complement"
                    label={t('form.complement')}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showPhone ? (
            <Grid item xs={12} sm={6} md={6}>
              <Field name="phone">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.phone || ''}
                    error={!!errors.phone}
                    helperText={errors.phone || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="tel"
                    required={requiredPhone}
                    name={field.name}
                    id="phone"
                    label={t('form.phone')}
                    fullWidth
                    variant="outlined"
                    autoComplete="nope"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showCellPhone ? (
            <Grid item xs={12} sm={6} md={6}>
              <Field name="cellPhone">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.cellPhone || ''}
                    error={!!errors.cellPhone}
                    helperText={errors.cellPhone || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    type="tel"
                    required={requiredCellPhone}
                    name={field.name}
                    id="cellPhone"
                    label={t('form.cellPhone')}
                    fullWidth
                    variant="outlined"
                    autoComplete="nope"
                  />
                )}
              </Field>
            </Grid>
          ) : null}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

AddressForm.defaultProps = {
  optionalFields: [],
  hideOptionalFields: false,
};

AddressForm.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleChangePanel: PropTypes.func.isRequired,
  optionalFields: PropTypes.arrayOf(PropTypes.string),
  hideOptionalFields: PropTypes.bool,
};

export default AddressForm;
