/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {makeStyles} from '@material-ui/core/styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  item: {
    marginTop: 5,
    marginBottom: 0,
  },
}));

export const EmailExistModal = ({
  open,
  handleClose,
  handleConfirm,
  email,
  data,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          handleClose();
        }}>
        <DialogTitle />
        <DialogContent>
          <DialogContentText>
            {t('form.emailFound')} <b>{email}</b> {t('form.followInformation')}.
          </DialogContentText>
          <Divider className={classes.divider} />
          {data?.firstName ? (
            <Typography className={classes.item} variant="body2">
              <b>{t('form.firstName')}:</b> {data?.firstName}
            </Typography>
          ) : null}
          {data?.lastName ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.lastName')}:</b> {data?.lastName}
            </Typography>
          ) : null}
          {data?.birthday ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.birthday')}:</b> {data?.birthday}
            </Typography>
          ) : null}
          {data?.genre ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.genre')}:</b> {data?.genre}
            </Typography>
          ) : null}
          {data?.nationality ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.nationality')}:</b> {data?.nationality}
            </Typography>
          ) : null}
          {data?.maritalStatus ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.maritalStatus')}:</b> {data?.maritalStatus}
            </Typography>
          ) : null}
          {data?.documentType ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.documentType')}:</b> {data?.documentType}
            </Typography>
          ) : null}
          {data?.document ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.document')}:</b> {data?.document}
            </Typography>
          ) : null}
          {data?.idCard ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.idCard')}:</b> {data?.idCard}
            </Typography>
          ) : null}
          {data?.issuer ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.issuer')}:</b> {data?.issuer}
            </Typography>
          ) : null}
          {data?.occupation ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.occupation')}:</b> {data?.occupation}
            </Typography>
          ) : null}
          {data?.country ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.country')}:</b> {data?.country}
            </Typography>
          ) : null}
          {data?.zipCode ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.zipCode')}:</b> {data?.zipCode}
            </Typography>
          ) : null}
          {data?.state ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.state')}:</b> {data?.state}
            </Typography>
          ) : null}
          {data?.city ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.city')}:</b> {data?.city}
            </Typography>
          ) : null}
          {data?.address ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.address')}:</b> {data?.address}
            </Typography>
          ) : null}
          {data?.number ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.number')}:</b> {data?.number}
            </Typography>
          ) : null}
          {data?.neighborhood ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.neighborhood')}:</b> {data?.neighborhood}
            </Typography>
          ) : null}
          {data?.complement ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.complement')}:</b> {data?.complement}
            </Typography>
          ) : null}
          {data?.phone ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.phone')}:</b> {data?.phone}
            </Typography>
          ) : null}
          {data?.cellPhone ? (
            <Typography className={classes.item} variant="body2" component="p">
              <b>{t('form.cellPhone')}:</b> {data?.cellPhone}
            </Typography>
          ) : null}
          <Divider className={classes.divider} />
          <DialogContentText>{t('form.useThisInformation')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary">
            {t('actions.no')}
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
            }}
            color="primary"
            autoFocus>
            {t('actions.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EmailExistModal.defaultProps = {
  email: '',
};

EmailExistModal.defaultProps = {
  data: null,
};

EmailExistModal.propTypes = {
  email: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
};
