import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Chip, Box} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';

import {useTranslation} from 'react-i18next';

import {DropzoneDialog} from './components';

export const UploadDocumet = ({onSave, initialFiles}) => {
  const {t} = useTranslation();
  const [state, setState] = useState({
    open: false,
  });

  const handleClose = () => {
    setState({
      open: false,
    });
  };

  const handleSave = fileList => {
    setState({
      open: false,
    });
    onSave(fileList);
  };

  const handleOpen = () => {
    setState({
      open: true,
    });
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center">
        <Button
          onClick={() => {
            handleOpen();
          }}
          color="primary"
          variant="outlined"
          startIcon={<AttachmentIcon />}>
          {t('upload.buttonTitle')}
        </Button>

        {initialFiles && initialFiles.length > 0 ? (
          <div style={{marginLeft: '10px'}}>
            <Chip
              icon={<AttachmentIcon />}
              label={`(${initialFiles.length}) ${t('upload.files')}`}
              onClick={() => {
                handleOpen();
              }}
              variant="outlined"
            />
          </div>
        ) : null}
      </Box>

      <DropzoneDialog
        open={state.open}
        initialFiles={initialFiles}
        onSave={fileList => {
          handleSave(fileList);
        }}
        onClose={() => {
          handleClose();
        }}
        filesLimit={5}
        acceptedFiles={['image/*', 'application/pdf']}
        showPreviews={false}
        previewsInDropzoneVisible
        maxFileSize={2000000} // 2MB
        dialogTitle={t('upload.title')}
        cancelButtonText={t('actions.cancel')}
        submitButtonText={t('actions.confirm')}
        dropzoneText={t('upload.dropzoneText')}
        previewText={t('upload.preview')}
        getFileLimitExceedMessage={() => t('upload.fileLimitExceed')}
        getFileAddedMessage={() => t('upload.fileAdded')}
        getFileRemovedMessage={() => t('upload.fileRemoved')}
      />
    </div>
  );
};

UploadDocumet.defaultProps = {
  initialFiles: [],
};

UploadDocumet.propTypes = {
  onSave: PropTypes.func.isRequired,
  initialFiles: PropTypes.arrayOf(PropTypes.string),
};
