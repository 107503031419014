import React, {useState, forwardRef, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Button, FormControlLabel, Checkbox, Grid} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';

import {Formik, Form} from 'formik';

import Header from './Header';
import PersonalInfoForm from './PersonalInfoForm';
import AddressForm from './AddressForm';
import {TermsModal} from '../../../home/components/form/termsModal';
import {TermsModalHotel} from '../../../home/components/form/termsModalHotel';

import {useStyles} from './styles';

import {FormSchema} from './rules';

import {mapFormValues} from '../../../home/utils';
import {INITIAL_VALUES} from '../../utils';

export const RegisterForm = forwardRef((props, formikRef) => {
  const {config, handleSubmit} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsHotel, setAcceptTermsHotel] = useState(false);
  const [expanded, setExpanded] = useState('panel1');

  const handleChangePanel = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const optionalFields = useMemo(
    () => mapFormValues(config.optionalFields || []),
    [config],
  );

  return (
    <div className={classes.root}>
      <Formik
        innerRef={formikRef}
        enableReinitialize={false}
        initialValues={{
          ...INITIAL_VALUES,
        }}
        validationSchema={FormSchema(optionalFields)}
        validateOnMount={false}
        onSubmit={values => {
          if (!acceptTerms || !acceptTermsHotel) {
            enqueueSnackbar(t('msg.termsOfUser'), {
              variant: 'warning',
            });

            formikRef.current.setSubmitting(false);
            return;
          }
          handleSubmit(values);
        }}>
        {({isSubmitting, isValidating, submitForm}) => (
          <>
            <Form noValidate autoComplete="off">
              <Header logo={config?.logo} />

              <PersonalInfoForm
                expanded={expanded === 'panel1'}
                handleChangePanel={handleChangePanel('panel1')}
                occupations={config?.occupations}
                optionalFields={optionalFields}
              />

              <AddressForm
                expanded={expanded === 'panel2'}
                handleChangePanel={handleChangePanel('panel2')}
                optionalFields={optionalFields}
              />

              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className={classes.agreements}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          onChange={() => {
                            setAcceptTerms(!acceptTerms);
                          }}
                          value
                          color="primary"
                          checked={acceptTerms}
                        />
                      }
                      label={t('form.acceptTerms')}
                    />

                    <TermsModal />
                  </div>
                  <div className={classes.agreements}>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          onChange={() => {
                            setAcceptTermsHotel(!acceptTermsHotel);
                          }}
                          value
                          color="primary"
                          checked={acceptTermsHotel}
                        />
                      }
                      label={t('form.acceptTermsHotel')}
                    />

                    <TermsModalHotel terms={config.termsOfUse} />
                  </div>
                </Grid>
              </Grid>

              <div className={classes.buttons}>
                <Button
                  size="medium"
                  disabled={isSubmitting || isValidating}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    submitForm();
                  }}
                  className={classes.button}>
                  {t('actions.checkin')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
});

RegisterForm.defaultProps = {};

RegisterForm.propTypes = {
  config: PropTypes.shape({
    optionalFields: PropTypes.arrayOf(PropTypes.string),
    termsOfUse: PropTypes.string,
    logo: PropTypes.string,
    occupations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
