import * as Yup from 'yup';
import i18n from 'locale/i18n';
import {isValid, isFuture, isToday, parse} from 'date-fns';

const REQUIRED = i18n.t('validations.required');
const INVALID_DATE = i18n.t('validations.invalidDate');

export const FormSchema = () =>
  Yup.object().shape({
    identifier: Yup.string()
      .when('check', {
        is: val => val === 'IDENTIFIER',
        then: Yup.string().required(REQUIRED),
      })
      .nullable(),
    document: Yup.string()
      .when('check', {
        is: val => val === 'DOCUMENT',
        then: Yup.string().required(REQUIRED),
      })
      .nullable(),
    lastName: Yup.string().required(REQUIRED).nullable(),
    checkinDate: Yup.string()
      .required(REQUIRED)
      .test('validDate', INVALID_DATE, value => {
        if (!value) {
          return true;
        }
        const date = parse(value, 'dd/MM/yyyy', new Date());
        return isValid(date) && (isToday(date) || isFuture(date));
      })
      .nullable(),
  });
