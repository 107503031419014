import React, {useMemo} from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import {useFormikContext, Field} from 'formik';

import {useTranslation} from 'react-i18next';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {BRAZIL_IBGE_CODE} from 'helpers/constants';

import {CountrySelect} from 'components/form/countrySelect';
import {StateSelect} from 'components/form/stateSelect';
import {CitySelect} from 'components/form/citySelect';

import {travelInfoFields} from './util';

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from './styles';

const TravelInfoForm = ({
  expanded,
  handleChangePanel,
  optionalFields,
  hideOptionalFields,
}) => {
  const {values, handleChange, errors, setValues} = useFormikContext();
  const classes = useStyles();
  const {t, i18n} = useTranslation();

  const requiredPurposeTrip = useMemo(
    () => !optionalFields.includes('purposeTrip'),
    [optionalFields],
  );
  const showPurposeTrip = useMemo(() => {
    if (!requiredPurposeTrip && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredPurposeTrip, hideOptionalFields]);

  const requiredTransportation = useMemo(
    () => !optionalFields.includes('transportation'),
    [optionalFields],
  );
  const showTransportation = useMemo(() => {
    if (!requiredTransportation && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredTransportation, hideOptionalFields]);

  const requiredLicensePlate = useMemo(
    () => !optionalFields.includes('licensePlate'),
    [optionalFields],
  );
  const showLicensePlate = useMemo(() => {
    if (!requiredLicensePlate && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredLicensePlate, hideOptionalFields]);

  const requiredLastDepartureCountryCode = useMemo(
    () => !optionalFields.includes('lastDepartureCountryCode'),
    [optionalFields],
  );
  const showLastDepartureCountryCode = useMemo(() => {
    if (!requiredLastDepartureCountryCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredLastDepartureCountryCode, hideOptionalFields]);

  const requiredLastDepartureStateCode = useMemo(
    () => !optionalFields.includes('lastDepartureStateCode'),
    [optionalFields],
  );
  const showLastDepartureStateCode = useMemo(() => {
    if (!requiredLastDepartureStateCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredLastDepartureStateCode, hideOptionalFields]);

  const requiredLastDepartureCityCode = useMemo(
    () => !optionalFields.includes('lastDepartureCityCode'),
    [optionalFields],
  );
  const showLastDepartureCityCode = useMemo(() => {
    if (!requiredLastDepartureCityCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredLastDepartureCityCode, hideOptionalFields]);

  const showLastDeparture = useMemo(
    () =>
      showLastDepartureCountryCode ||
      showLastDepartureStateCode ||
      showLastDepartureCityCode,
    [
      showLastDepartureCountryCode,
      showLastDepartureStateCode,
      showLastDepartureCityCode,
    ],
  );

  const requiredNextDestinationCountryCode = useMemo(
    () => !optionalFields.includes('nextDestinationCountryCode'),
    [optionalFields],
  );
  const showNextDestinationCountryCode = useMemo(() => {
    if (!requiredNextDestinationCountryCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredNextDestinationCountryCode, hideOptionalFields]);

  const requiredNextDestinationStateCode = useMemo(
    () => !optionalFields.includes('nextDestinationStateCode'),
    [optionalFields],
  );
  const showNextDestinationStateCode = useMemo(() => {
    if (!requiredNextDestinationStateCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredNextDestinationStateCode, hideOptionalFields]);

  const requiredNextDestinationCityCode = useMemo(
    () => !optionalFields.includes('nextDestinationCityCode'),
    [optionalFields],
  );
  const showNextDestinationCityCode = useMemo(() => {
    if (!requiredNextDestinationCityCode && hideOptionalFields) {
      return false;
    }
    return true;
  }, [requiredNextDestinationCityCode, hideOptionalFields]);

  const showNextDestination = useMemo(
    () =>
      showNextDestinationCountryCode ||
      showNextDestinationStateCode ||
      showNextDestinationCityCode,
    [
      showNextDestinationCountryCode,
      showNextDestinationStateCode,
      showNextDestinationCityCode,
    ],
  );

  const hideForm = useMemo(
    () =>
      !showPurposeTrip &&
      !showTransportation &&
      !showLicensePlate &&
      !showLastDeparture &&
      !showNextDestination,
    [
      showPurposeTrip,
      showTransportation,
      showLicensePlate,
      showLastDeparture,
      showNextDestination,
    ],
  );

  const renderLastDepartureCountry = () =>
    showLastDepartureCountryCode ? (
      <Grid item xs={12} sm={12} md={4}>
        <Field name="lastDepartureCountry">
          {({field}) => (
            <CountrySelect
              id="lastDepartureCountry"
              name={field.name}
              countryField="lastDepartureCountry"
              countryCodeField="lastDepartureCountryCode"
              onChange={({country, countryCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  lastDepartureCountry: country,
                  lastDepartureCountryCode: countryCode,
                  lastDepartureState: '',
                  lastDepartureStateCode: null,
                  lastDepartureCity: '',
                  lastDepartureCityCode: null,
                }));
              }}
              placeholder={t('form.country')}
              disabled={false}
              isBrazil={i18n.language === 'pt'}
              required={requiredLastDepartureCountryCode}
            />
          )}
        </Field>
      </Grid>
    ) : null;

  const renderLastDepartureState = () =>
    showLastDepartureStateCode ? (
      <Grid item xs={12} sm={6} md={4}>
        <Field name="lastDepartureState">
          {({field}) => (
            <StateSelect
              id="lastDepartureState"
              name={field.name}
              stateField="lastDepartureState"
              stateCodeField="lastDepartureStateCode"
              isSelect={values.lastDepartureCountryCode === BRAZIL_IBGE_CODE}
              onChange={({state, stateCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  lastDepartureState: state,
                  lastDepartureStateCode: stateCode,
                  lastDepartureCity: '',
                  lastDepartureCityCode: null,
                }));
              }}
              placeholder={t('form.state')}
              disabled={!values.lastDepartureCountryCode}
              countryCode={values.lastDepartureCountryCode}
              required={requiredLastDepartureStateCode}
            />
          )}
        </Field>
      </Grid>
    ) : null;

  const renderLastDepartureCity = () => {
    let disabled = false;
    if (!values.lastDepartureStateCode && !values.lastDepartureCountryCode) {
      disabled = true;
    }
    if (
      values.lastDepartureCountryCode === BRAZIL_IBGE_CODE &&
      !values.lastDepartureStateCode
    ) {
      disabled = true;
    }
    return showLastDepartureCityCode ? (
      <Grid item xs={12} sm={6} md={4}>
        <Field name="lastDepartureCity">
          {({field}) => (
            <CitySelect
              id="lastDepartureCity"
              name={field.name}
              cityField="lastDepartureCity"
              cityCodeField="lastDepartureCityCode"
              isSelect={values.lastDepartureCountryCode === BRAZIL_IBGE_CODE}
              values={values}
              errors={errors}
              onChange={({city, cityCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  lastDepartureCity: city,
                  lastDepartureCityCode: cityCode,
                }));
              }}
              placeholder={t('form.city')}
              disabled={disabled}
              stateCode={values.lastDepartureStateCode}
              required={requiredLastDepartureCityCode}
            />
          )}
        </Field>
      </Grid>
    ) : null;
  };

  const renderNexDestinationCountry = () =>
    showNextDestinationCountryCode ? (
      <Grid item xs={12} sm={6} md={4}>
        <Field name="nextDestinationCountry">
          {({field}) => (
            <CountrySelect
              id="nextDestinationCountry"
              name={field.name}
              countryField="nextDestinationCountry"
              countryCodeField="nextDestinationCountryCode"
              onChange={({country, countryCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  nextDestinationCountry: country,
                  nextDestinationCountryCode: countryCode,
                  nextDestinationState: '',
                  nextDestinationStateCode: null,
                  nextDestinationCity: '',
                  nextDestinationCityCode: null,
                }));
              }}
              placeholder={t('form.country')}
              disabled={false}
              isBrazil={i18n.language === 'pt'}
              required={requiredNextDestinationCountryCode}
            />
          )}
        </Field>
      </Grid>
    ) : null;

  const renderNextDestinationState = () =>
    showNextDestinationStateCode ? (
      <Grid item xs={12} sm={6} md={4}>
        <Field name="nextDestinationState">
          {({field}) => (
            <StateSelect
              id="nextDestinationState"
              name={field.name}
              stateField="nextDestinationState"
              stateCodeField="nextDestinationStateCode"
              isSelect={values.nextDestinationCountryCode === BRAZIL_IBGE_CODE}
              onChange={({state, stateCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  nextDestinationState: state,
                  nextDestinationStateCode: stateCode,
                  nextDestinationCity: '',
                  nextDestinationCityCode: null,
                }));
              }}
              placeholder={t('form.state')}
              disabled={!values.nextDestinationCountryCode}
              countryCode={values.nextDestinationCountryCode}
              required={requiredNextDestinationStateCode}
            />
          )}
        </Field>
      </Grid>
    ) : null;

  const renderNextDestinationCity = () => {
    let disabled = false;
    if (
      !values.nextDestinationStateCode &&
      !values.nextDestinationCountryCode
    ) {
      disabled = true;
    }
    if (
      values.nextDestinationCountryCode === BRAZIL_IBGE_CODE &&
      !values.nextDestinationStateCode
    ) {
      disabled = true;
    }
    return showNextDestinationCityCode ? (
      <Grid item xs={12} sm={6} md={4}>
        <Field name="nextDestinationCity">
          {({field}) => (
            <CitySelect
              id="nextDestinationCity"
              name={field.name}
              cityField="nextDestinationCity"
              cityCodeField="nextDestinationCityCode"
              isSelect={values.nextDestinationCountryCode === BRAZIL_IBGE_CODE}
              values={values}
              errors={errors}
              onChange={({city, cityCode}) => {
                setValues(prevValue => ({
                  ...prevValue,
                  nextDestinationCity: city,
                  nextDestinationCityCode: cityCode,
                }));
              }}
              placeholder={t('form.city')}
              disabled={disabled}
              stateCode={values.nextDestinationStateCode}
              required={requiredNextDestinationCityCode}
            />
          )}
        </Field>
      </Grid>
    ) : null;
  };

  const panelIsInvalid = useMemo(() => {
    let hasErrors = false;
    travelInfoFields.forEach(field => {
      if (errors[field]) {
        hasErrors = true;
      }
    });
    return hasErrors;
  }, [errors]);

  if (hideForm) {
    return null;
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(event, isExpanded) => {
        handleChangePanel(event, isExpanded);
      }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <div>
          <Typography className={classes.heading}>
            {t('form.travelInformation')}
          </Typography>
          {panelIsInvalid ? (
            <Typography className={classes.headingInfoError}>
              {t('validations.invalidForm')}
            </Typography>
          ) : (
            <Typography className={classes.headingInfoSuccess}>
              {t('validations.formOK')}
            </Typography>
          )}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          {showPurposeTrip ? (
            <Grid item xs={12} sm={6} md={6}>
              <Field name="purposeTrip">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.purposeTrip || ''}
                    error={!!errors.purposeTrip}
                    helperText={errors.purposeTrip || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    select
                    required={requiredPurposeTrip}
                    id="purposeTrip"
                    name={field.name}
                    label={t('form.purposeTrip')}
                    fullWidth
                    variant="outlined">
                    <MenuItem key="1" value="1">
                      {t('purposeTrip.recreationVacation')}
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      {t('purposeTrip.business')}
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      {t('purposeTrip.conferenceFair')}
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      {t('purposeTrip.familyFriends')}
                    </MenuItem>
                    <MenuItem key="5" value="5">
                      {t('purposeTrip.studiesCourses')}
                    </MenuItem>
                    <MenuItem key="6" value="6">
                      {t('purposeTrip.religion')}
                    </MenuItem>
                    <MenuItem key="7" value="7">
                      {t('purposeTrip.health')}
                    </MenuItem>
                    <MenuItem key="8" value="8">
                      {t('purposeTrip.shopping')}
                    </MenuItem>
                    <MenuItem key="9" value="9">
                      {t('purposeTrip.other')}
                    </MenuItem>
                  </TextField>
                )}
              </Field>
            </Grid>
          ) : null}

          {showTransportation ? (
            <Grid item xs={12} sm={6} md={6}>
              <Field name="transportation">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.transportation || ''}
                    error={!!errors.transportation}
                    helperText={errors.transportation || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    select
                    required={requiredTransportation}
                    id="transportation"
                    name={field.name}
                    label={t('form.transportation')}
                    fullWidth
                    variant="outlined">
                    <MenuItem key="1" value="1">
                      {t('transportation.plane')}
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      {t('transportation.car')}
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      {t('transportation.bus')}
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      {t('transportation.motorbike')}
                    </MenuItem>
                    <MenuItem key="5" value="5">
                      {t('transportation.ship')}
                    </MenuItem>
                    <MenuItem key="6" value="6">
                      {t('transportation.train')}
                    </MenuItem>
                    <MenuItem key="7" value="7">
                      {t('transportation.other')}
                    </MenuItem>
                  </TextField>
                )}
              </Field>
            </Grid>
          ) : null}

          {showLicensePlate ? (
            <Grid item xs={12} sm={6} md={6}>
              <Field name="licensePlate">
                {({field}) => (
                  <TextField
                    size="small"
                    value={values.licensePlate || ''}
                    error={!!errors.licensePlate}
                    helperText={errors.licensePlate || ''}
                    onChange={e => {
                      handleChange(e);
                    }}
                    required={requiredLicensePlate}
                    id="licensePlate"
                    name={field.name}
                    label={t('form.licensePlate')}
                    fullWidth
                    variant="outlined"
                  />
                )}
              </Field>
            </Grid>
          ) : null}

          {showLastDeparture ? (
            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.secondaryHeading}>
                  {t('form.lastDeparture')}
                </Typography>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setValues(prevValue => ({
                      ...prevValue,
                      lastDepartureCountryCode: values.countryCode,
                      lastDepartureCountry: values.country,
                      lastDepartureStateCode: values.stateCode,
                      lastDepartureState: values.state,
                      lastDepartureCityCode: values.cityCode,
                      lastDepartureCity: values.city,
                    }));
                  }}>
                  {t('form.useHomeAddress')}
                </Button>
              </Box>
            </Grid>
          ) : null}

          {renderLastDepartureCountry()}

          {renderLastDepartureState()}

          {renderLastDepartureCity()}

          {showNextDestination ? (
            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.secondaryHeading}>
                  {t('form.nextDestination')}
                </Typography>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setValues(prevValue => ({
                      ...prevValue,
                      nextDestinationCountryCode: values.countryCode,
                      nextDestinationCountry: values.country,
                      nextDestinationStateCode: values.stateCode,
                      nextDestinationState: values.state,
                      nextDestinationCityCode: values.cityCode,
                      nextDestinationCity: values.city,
                    }));
                  }}>
                  {t('form.useHomeAddress')}
                </Button>
              </Box>
            </Grid>
          ) : null}

          {renderNexDestinationCountry()}

          {renderNextDestinationState()}

          {renderNextDestinationCity()}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

TravelInfoForm.defaultProps = {
  optionalFields: [],
  hideOptionalFields: false,
};

TravelInfoForm.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleChangePanel: PropTypes.func.isRequired,
  optionalFields: PropTypes.arrayOf(PropTypes.string),
  hideOptionalFields: PropTypes.bool,
};

export default TravelInfoForm;
