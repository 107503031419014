import React from 'react';
import {ThumbUp} from '@material-ui/icons';
import {Box, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export const SurveySuccess = () => {
  const {t} = useTranslation();
  return (
    <div>
      <Typography variant="h5" component="p" color="primary" align="center">
        {t('survey.thanks')}
      </Typography>
      <Typography variant="h6" component="p" color="textPrimary" align="center">
        {t('survey.thanksDescription')}
      </Typography>
      <Box justifyContent="center" display="flex">
        <ThumbUp fontSize="large" color="primary" />
      </Box>
    </div>
  );
};
