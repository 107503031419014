import {red} from '@material-ui/core/colors';
import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#007dc3',
      main: '#007dc3',
      dark: '#002884',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#8a8a8a',
      secondary: '#757474',
    },
  },
});

export default theme;
