import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0',
  },
  surveyTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  surveyItemButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  starButton: {
    cursor: 'pointer',
    position: 'relative',
    border: 0,
    backgroundColor: '#fff',
    borderRadius: '50%',
    margin: '0 10px',
    padding: '2px',
    opacity: 0.5,
    outline: 'none',
  },
  starButtonActive: {
    opacity: 1,
  },
  commentButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 0,
    marginBottom: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  commentButtonTitle: {
    marginRight: '10px',
  },
}));
