import React from 'react';
import PropTypes from 'prop-types';

import {useFormikContext} from 'formik';

import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {STATE_OPTIONS} from 'helpers/constants';

export const StateSelect = ({
  isSelect,
  id,
  name,
  stateField,
  stateCodeField,
  onChange,
  placeholder,
  disabled,
  countryCode,
  required,
  showErrorWithoutTouched,
}) => {
  const {errors, values, touched, setFieldTouched} = useFormikContext();

  const isInvalid = () => {
    if (showErrorWithoutTouched) {
      return !!errors[stateField];
    }
    return !!errors[stateField] && !!touched[stateField];
  };

  const errorText = () => {
    if (showErrorWithoutTouched) {
      return errors[stateField] || '';
    }
    return touched[stateField] ? errors[stateField] || '' : '';
  };

  return isSelect ? (
    <Autocomplete
      value={
        values[stateField] && values[stateCodeField]
          ? {
              label: values[stateField],
              value: values[stateCodeField],
            }
          : null
      }
      onChange={(event, newValue) => {
        if (newValue) {
          const value = {
            state: newValue.label,
            stateCode: newValue.value,
          };
          onChange(value);
        } else {
          const value = {
            state: '',
            stateCode: null,
          };
          onChange(value);
        }
      }}
      onBlur={() => {
        setFieldTouched(stateField);
      }}
      size="small"
      id={id}
      name={name}
      style={{width: '100%'}}
      options={countryCode ? STATE_OPTIONS : []}
      classes={{}}
      autoHighlight
      getOptionLabel={option => option.label || ''}
      getOptionSelected={option => option}
      renderOption={option => <>{option.label}</>}
      autoComplete={false}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          error={isInvalid()}
          helperText={errorText()}
          required={required}
          label={placeholder}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'nope',
          }}
        />
      )}
    />
  ) : (
    <TextField
      size="small"
      value={values[stateField] || ''}
      error={isInvalid()}
      helperText={errorText()}
      onChange={e => {
        const value = {
          state: e.target.value,
          stateCode: null,
        };
        onChange(value);
      }}
      onBlur={() => {
        setFieldTouched(stateField);
      }}
      type="text"
      required={required}
      id={id}
      name={name}
      label={placeholder}
      fullWidth
      variant="outlined"
      disabled={disabled}
      autoComplete="nope"
    />
  );
};

StateSelect.defaultProps = {
  countryCode: null,
  required: true,
  showErrorWithoutTouched: true,
};

StateSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stateField: PropTypes.string.isRequired,
  stateCodeField: PropTypes.string.isRequired,
  isSelect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  countryCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  showErrorWithoutTouched: PropTypes.bool,
};
